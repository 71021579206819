import {ICreateDashboardUserDto, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrganizationUserCreateActions {
    CREATE_DASHBOARD_USER = 'DASHBOARD_USER_CREATE: CREATE_DASHBOARD_USER',
    CREATE_DASHBOARD_USER_SUCCESSFULLY = 'DASHBOARD_USER_CREATE: CREATE_DASHBOARD_USER_SUCCESSFULLY',
    CREATE_DASHBOARD_USER_FAILED = 'DASHBOARD_USER_CREATE: CREATE_DASHBOARD_USER_FAILED',

    SHOW_MODAL = 'DASHBOARD_USER_CREATE: SHOW_MODAL',
    HIDE_MODAL = 'DASHBOARD_USER_CREATE: HIDE_MODAL',
}

export const CREATE_DASHBOARD_USER = (model: ICreateDashboardUserDto): StoreAction<OrganizationUserCreateActions> => ({
    type: OrganizationUserCreateActions.CREATE_DASHBOARD_USER, payload: model
});

export const CREATE_DASHBOARD_USER_SUCCESSFULLY = (): StoreAction<OrganizationUserCreateActions> => ({
    type: OrganizationUserCreateActions.CREATE_DASHBOARD_USER_SUCCESSFULLY
});

export const CREATE_DASHBOARD_USER_FAILED = (error: OperationErrorType): StoreAction<OrganizationUserCreateActions> => ({
    type: OrganizationUserCreateActions.CREATE_DASHBOARD_USER_FAILED, payload: error
});

export const CREATE_DASHBOARD_USER_SHOW_MODAL = (organization: IOrganizationOutputDto): StoreAction<OrganizationUserCreateActions> => ({
    type: OrganizationUserCreateActions.SHOW_MODAL, payload: organization
});

export const CREATE_DASHBOARD_USER_HIDE_MODAL = (): StoreAction<OrganizationUserCreateActions> => ({
    type: OrganizationUserCreateActions.HIDE_MODAL
});
