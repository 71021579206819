import {ChangedSearchOption, ChangedSearchOptions, ControlValidator, FormControlValidators, PagingQuery} from "orpyx-web-common";
import { OrpyxCareUsersListPageMeta } from "./orpyx-care-users-list-page.interface";
import { OrpyxCareUsersSearchQuery } from "../../models/orpyx-care-users-search-query";

const OrpyxCareUsersListSearchValidators = new ControlValidator([
    FormControlValidators.minLength(2),
    FormControlValidators.maxLength(32),
]);

export const onOrpyxCareUsersListPageLoaded = (props: OrpyxCareUsersListPageMeta) => () => {
    const params = new URLSearchParams(props.location.search);

    const query = OrpyxCareUsersSearchQuery.parse(
        params.has('term') ? params.get('term')! : undefined,

        params.has(OrpyxCareUsersSearchQuery.PARAM_ORDER_BY) ? params.get(OrpyxCareUsersSearchQuery.PARAM_ORDER_BY)! : undefined,
        params.has(OrpyxCareUsersSearchQuery.PARAM_ORDER_BY_ASC) ? params.get(OrpyxCareUsersSearchQuery.PARAM_ORDER_BY_ASC)! : "true",

        params.has(OrpyxCareUsersSearchQuery.PARAM_OFFSET) ? params.get(OrpyxCareUsersSearchQuery.PARAM_OFFSET)! : undefined,
        params.has(OrpyxCareUsersSearchQuery.PARAM_COUNT) ? params.get(OrpyxCareUsersSearchQuery.PARAM_COUNT)! : undefined,
    );

    props.fetchUsers(query);
    props.fetchDashboardRoles();
};

export const onOrpyxCareUsersListItemSelected = (props: OrpyxCareUsersListPageMeta, orpyxUserId: number): void => {
    const url = `/admin/orpyx-care/users/details/${orpyxUserId}`;
    props.history!.push(url);
};

export const onOrpyxCareUsersListSearchPageChanged = (props: OrpyxCareUsersListPageMeta, page: number): void => {
    const searchQuery: OrpyxCareUsersSearchQuery = {...props.search, offset: page};
    updateSearchQuery(props, searchQuery);
};

export const onOrpyxCareUsersListSearchDisplayCountChanged = (props: OrpyxCareUsersListPageMeta, count: number): void => {
    const searchQuery: OrpyxCareUsersSearchQuery = {...props.search, count: count, offset: PagingQuery.DEFAULT_PAGE};
    updateSearchQuery(props, searchQuery);
};

export const onOrpyxCareUsersListSearchTermValidate = (term: string | undefined): string | undefined => {
    return OrpyxCareUsersListSearchValidators.validate(term);
};

export const onOrpyxCareUsersListSearchOptionsChanged = (props: OrpyxCareUsersListPageMeta) => (options: ChangedSearchOptions): void => {
    if (options.changed === ChangedSearchOption.Term) {
        //  Reset offset to default, if term changed
        //  Total may be less than offset for the new term
        const searchQuery: OrpyxCareUsersSearchQuery = {
            ...props.search,
            term: options.term,
            offset: PagingQuery.DEFAULT_PAGE
        };
        updateSearchQuery(props, searchQuery);
    } else if (options.changed === ChangedSearchOption.OrderBy) {
        const searchQuery: OrpyxCareUsersSearchQuery = {
            ...props.search,
            orderBy: options.orderBy?.value,
            orderByAsc: options.orderBy?.orderByAsc ?? OrpyxCareUsersSearchQuery.DEFAULT_ORDER_BY_METHOD
        };
        updateSearchQuery(props, searchQuery);
    }
};

const updateSearchQuery = (props: OrpyxCareUsersListPageMeta, searchQuery: OrpyxCareUsersSearchQuery): void => {
    props.fetchUsers(searchQuery);
};
