import {createSelector} from "reselect";
import {getPatientsStoreState} from "../../store/patients-selectors";

export class PatientDeleteSelectors {

    static readonly state = createSelector(getPatientsStoreState, state => state.delete);

    static readonly error = createSelector(PatientDeleteSelectors.state, state => state.error);
    static readonly patient = createSelector(PatientDeleteSelectors.state, state => state.patient!);

    static readonly inProcess = createSelector(PatientDeleteSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(PatientDeleteSelectors.state, state => state.toggle);

    static readonly actionAfterComplete = createSelector(PatientDeleteSelectors.state, state => state.actionAfterComplete);
}

export default PatientDeleteSelectors;
