import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrpyxUserDto, OrpyxRoleType} from "admins-dashboard/http-clients/index";

export enum OrpyxUserRoleAssignActions {
    ASSIGN_ROLE = 'ORPYX_USER_ROLES_ASSIGN: ASSIGN_ROLE',
    ASSIGN_ROLE_SUCCESSFULLY = 'ORPYX_USER_ROLES_ASSIGN: ASSIGN_ROLE_SUCCESSFULLY',
    ASSIGN_ROLE_FAILED = 'ORPYX_USER_ROLES_ASSIGN: ASSIGN_ROLE_FAILED',

    ASSIGN_ROLE_SHOW_MODAL = 'ORPYX_USER_ROLES_ASSIGN: ASSIGN_ROLE_SHOW_MODAL',
    ASSIGN_ROLE_HIDE_MODAL = 'ORPYX_USER_ROLES_ASSIGN: ASSIGN_ROLE_HIDE_MODAL',
}

export const ASSIGN_ROLE = (orpyxRoleType: OrpyxRoleType): StoreAction<OrpyxUserRoleAssignActions> => ({
    type: OrpyxUserRoleAssignActions.ASSIGN_ROLE, payload: orpyxRoleType
});

export const ASSIGN_ROLE_SUCCESSFULLY = (): StoreAction<OrpyxUserRoleAssignActions> => ({
    type: OrpyxUserRoleAssignActions.ASSIGN_ROLE_SUCCESSFULLY,
});

export const ASSIGN_ROLE_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserRoleAssignActions> => ({
    type: OrpyxUserRoleAssignActions.ASSIGN_ROLE_FAILED, payload: error
});

export const ASSIGN_ROLE_SHOW_MODAL = (user: IOrpyxUserDto): StoreAction<OrpyxUserRoleAssignActions> => ({
    type: OrpyxUserRoleAssignActions.ASSIGN_ROLE_SHOW_MODAL, payload: user
});

export const ASSIGN_ROLE_HIDE_MODAL = (): StoreAction<OrpyxUserRoleAssignActions> => ({
    type: OrpyxUserRoleAssignActions.ASSIGN_ROLE_HIDE_MODAL
});
