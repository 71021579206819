import {Theme} from "@material-ui/core";
import {createStyles, makeStyles} from '@material-ui/styles';
import {Teal} from "orpyx-web-common";

export const useOrganizationsListTemplateStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: '0 30px',
    },
    content: {
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        padding: '27px 50px',
    },
    title: {
        fontSize: '20px',
        color: Teal,
    },
    topBlock: {
        marginBottom: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    table: {},
    greyRow: {
        '&:nth-child(even)': {
            backgroundColor: 'rgba(151,158,166,0.07)',
        }
    }
}));
