import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import produce from "immer";
import {OrganizationUserRestoreActions} from "./organization-user-restore.actions";

export interface OrganizationUserRestoreState extends IModalWindow {
    dashboardUser: IUserOutputDto  | undefined;
    organization: IOrganizationOutputDto | undefined;

    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrganizationUserRestoreState = {
    ...ModalWindowInitialState,

    dashboardUser: undefined,
    organization: undefined,
    actionAfterComplete: StoreActionAfterComplete.None
};

const OrganizationUserRestoreReducers = (state = initialState, action: StoreAction<OrganizationUserRestoreActions>): OrganizationUserRestoreState => {
    return produce(state, (draft: OrganizationUserRestoreState) => {
        switch (action.type) {
            case OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrganizationUserRestoreActions.SHOW_MODAL:
                draft.dashboardUser = action.payload.user;
                draft.organization = action.payload.organization;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationUserRestoreActions.HIDE_MODAL:
                draft.dashboardUser = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default OrganizationUserRestoreReducers;
