import {OrpyxUsersReducers} from "../orpyx-users/store/orpyx-users-store-reducers";
import OrpyxRolesReducers from "../orpyx-roles/store/orpyx-roles-reducers";
import {combineReducers} from 'redux';
import {OrganizationsReducers} from "../organizations/store/organizations-store-reducers";
import DashboardUserTitlesReducers from "../dashboard-user-titles/store/dashboard-user-titles-reducers";
import {PatientsReducers} from "../patients/store/patients-reducers";
import AdminAuthReducers from "../auth/store/admin-auth-reducers";
import {ApplicationAdminSharedStoreReducers} from "../shared/store/application-admin-shared-store.state";
import AuditLoggingDownloadReducer from "../audit-logging/store/audit-logging-download.reducers";
import { OrpyxCareUsersReducers } from "../orpyx-care-users/store/orpyx-care-users-store-reducers";
import DashboardRolesReducers from "../dashboard-roles/dashboard-roles-reducers";

export const AdminStoreReducers = combineReducers({
    orpyxUsers: OrpyxUsersReducers,
    orpyxRoles: OrpyxRolesReducers,
    orpyxCareUsers: OrpyxCareUsersReducers,

    organizations: OrganizationsReducers,
    dashboardUserTitles: DashboardUserTitlesReducers,
    dashboardRoles: DashboardRolesReducers,

    patients: PatientsReducers,
    auth: AdminAuthReducers,

    auditLogging: AuditLoggingDownloadReducer,
    shared: ApplicationAdminSharedStoreReducers,
});
