import {createSelector} from "reselect";
import {getOrganizationsState} from "../../store/organizations-store-selectors";

export class OrganizationUpdateSelectors {

    static readonly state = createSelector(getOrganizationsState, state => state.update);
    static readonly error = createSelector(OrganizationUpdateSelectors.state, state => state.error);
    static readonly organization = createSelector(OrganizationUpdateSelectors.state, state => state.organization!);
    static readonly actionAfterComplete = createSelector(OrganizationUpdateSelectors.state, state => state.actionAfterComplete);

    static readonly inProcess = createSelector(OrganizationUpdateSelectors.state, state => state.inProcess);
    static readonly initialized = createSelector(OrganizationUpdateSelectors.state, state => state.initialized);
    static readonly toggle = createSelector(OrganizationUpdateSelectors.state, state => state.toggle);
}
