import {combineReducers} from "redux";
import OrganizationCreateReducers from "../organization-create/store/organization-create-reducers";
import {OrganizationsListReducers} from "../organizations-list/store/organizations-list-reducers";
import {OrganizationStoreReducers} from "../organization/store/organization-store-reducers";
import OrganizationDeleteReducers from "../organization-delete/store/organization-delete.reducers";
import OrganizationRestoreReducers from "../organization-restore/store/organization-restore.reducers";
import OrganizationUpdateReducers from "../organization-update/store/organization-update.reducers";

export const OrganizationsReducers = combineReducers({
    create: OrganizationCreateReducers,
    delete: OrganizationDeleteReducers,
    restore: OrganizationRestoreReducers,
    update: OrganizationUpdateReducers,

    list: OrganizationsListReducers,
    organization: OrganizationStoreReducers,
});

