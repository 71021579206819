import {FormikBag} from "formik";
import {PatientsRawTemperatureFormProps, PatientsRawTemperatureFormValues} from "./patients-raw-temperature-form.interface";
import moment from "moment";

export const onSubmitPatientsRawTemperatureForm = (values: PatientsRawTemperatureFormValues, bag: FormikBag<PatientsRawTemperatureFormProps, PatientsRawTemperatureFormValues>) => {
    bag.props.onSubmit({
        begin: moment.utc(values.begin),
        end: moment.utc(values.end),
        insolesSerialNumbers: values.insolesSerialNumbers.map(x => +x.substring(0, x.indexOf('|') - 1)),
        saveInsolesToSeparateFiles: false
    });
};
