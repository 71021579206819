import {IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientDevicesListActions} from "./patient-devices-list-actions";

export interface PatientDevicesListState {
    loading: boolean;

    error: OperationErrorType;
    devices: IListProductsResultEntry[];
}

const initialState: PatientDevicesListState = {
    loading: false,
    error: undefined,
    devices: []
};

export const PatientDevicesListReducers = (state = initialState, action: StoreAction<PatientDevicesListActions>): PatientDevicesListState => {
    return produce(state, (draft: PatientDevicesListState) => {
        switch (action.type) {
            case PatientDevicesListActions.FETCH_PATIENT_DEVICES:
                draft.loading = true;
                break;

            case PatientDevicesListActions.FETCH_PATIENT_DEVICES_FAILED:
                draft.loading = false;
                draft.error = action.payload;
                draft.devices = [];
                break;

            case PatientDevicesListActions.FETCH_PATIENT_DEVICES_SUCCESSFULLY: {
                draft.devices = action.payload!;
                draft.error = undefined;
                draft.loading = false;
                break;
            }
        }
    });
};

export default PatientDevicesListReducers;
