import {combineReducers} from "redux";
import PatientsListReducers from "../patients-list/store/patients-list-reducers";
import PatientsCreateReducers from "../patients-create/store/patients-create-reducers";
import PatientInformationReducers from "../patient-information/store/patient-information-reducers";
import {PatientDevicesReducers} from "../patient-devices/store/patient-devices-reducers";
import PatientDeleteReducer from "../patient-delete/store/patient-delete.reducers";
import PatientRestoreReducer from "../patient-restore/store/patient-restore.reducers";
import PatientRawTemperatureExportReducer from "../patients-raw-temperature/store/patients-raw-temperature.reducers";

export const PatientsReducers = combineReducers({
    list: PatientsListReducers,
    create: PatientsCreateReducers,
    delete: PatientDeleteReducer,
    restore: PatientRestoreReducer,
    update: PatientInformationReducers,
    devices: PatientDevicesReducers,
    temperature: PatientRawTemperatureExportReducer,
});
