import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientDeleteModalEvents, PatientDeleteModalMeta, PatientDeleteModalOwnProps, PatientDeleteModalProps} from "./patient-delete-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {PATIENT_DELETE, PATIENT_DELETE_HIDE_MODAL} from "../../store/patient-delete.actions";
import PatientDeleteSelectors from "../../store/patient-delete.selectors";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";

const PatientDeleteModalTemplate = (props: PatientDeleteModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm delete patient
            </ModalWindowHeader>

            <div>Are you sure you want to delete patient <b> {fullNamePipe(props.patient.patient)}
            </b> with sn <b>{props.patient.patient!.clientPatientIdentifier}
            </b> in organization <b>{props.patient.organization!.name}</b>?
            </div>

            <OperationProgress error={props.error} inProcess={props.inProcess}/>

            <ModalWindowSubmitButton
                disabled={props.inProcess}
                onSubmit={props.confirmPatientDelete}
                content={'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientDeleteModalOwnProps => {
    return {
        patient: PatientDeleteSelectors.patient(state),
        inProcess: PatientDeleteSelectors.inProcess(state),
        error: PatientDeleteSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientDeleteModalProps): PatientDeleteModalEvents => {
    return bindActionCreators({
        confirmPatientDelete: PATIENT_DELETE
    }, dispatch);
};

export const PatientDeleteModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(PatientDeleteModalTemplate)),
    closeModalActionCreator: PATIENT_DELETE_HIDE_MODAL,
    modalToggleSelector: PatientDeleteSelectors.toggle
});
