import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ICreatePatientDto, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export enum PatientsCreateActions {
    CREATE_PATIENT_USER = 'ORGANIZATION_PATIENT_CREATE: CREATE_PATIENT_USER',
    CREATE_PATIENT_USER_SUCCESSFULLY = 'ORGANIZATION_PATIENT_CREATE: CREATE_PATIENT_USER_SUCCESSFULLY',
    CREATE_PATIENT_USER_FAILED = 'ORGANIZATION_PATIENT_CREATE: CREATE_PATIENT_USER_FAILED',

    SELECT_ORGANIZATION = 'ORGANIZATION_PATIENT_CREATE: SELECT_ORGANIZATION',
    SHOW_MODAL = 'ORGANIZATION_PATIENT_CREATE: SHOW_MODAL',
    HIDE_MODAL = 'ORGANIZATION_PATIENT_CREATE: HIDE_MODAL',
}

export const CREATE_PATIENT_USER = (model: ICreatePatientDto): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.CREATE_PATIENT_USER, payload: model
});

export const CREATE_PATIENT_USER_SUCCESSFULLY = (): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.CREATE_PATIENT_USER_SUCCESSFULLY
});

export const CREATE_PATIENT_USER_FAILED = (error: OperationErrorType): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.CREATE_PATIENT_USER_FAILED, payload: error
});

export const CREATE_PATIENT_USER_SHOW_MODAL = (organization: IOrganizationOutputDto | null): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.SHOW_MODAL, payload: organization
});

export const CREATE_PATIENT_SELECT_ORGANIZATION = (organization: IOrganizationOutputDto | null): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.SELECT_ORGANIZATION, payload: organization
});

export const CREATE_PATIENT_USER_HIDE_MODAL = (): StoreAction<PatientsCreateActions> => ({
    type: PatientsCreateActions.HIDE_MODAL
});
