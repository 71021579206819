import {combineReducers} from "redux";
import {OrganizationDropdownFiltersReducers, OrganizationDropdownFilterStates} from "../organization-dropdown-filter/store/organization-dropdown-filters-reducers";
import {PatientDropdownFiltersReducers, PatientDropdownFilterStates} from "../patient-dropdown-filter/store/patient-dropdown-filters.reducers";
import {
    PatientInsolesAutoCompleteReducers,
    PatientInsolesAutoCompleteStates
} from "../patient-insoles-auto-complete/store/patient-insoles-auto-complete.reducers";

export interface ApplicationAdminSharedStoreState {
    organizationDropDownFilter: OrganizationDropdownFilterStates;
    patientDropDownFilter: PatientDropdownFilterStates;
    patientInsolesAutoComplete: PatientInsolesAutoCompleteStates
}

export const ApplicationAdminSharedStoreReducers = combineReducers({
    organizationDropDownFilter: OrganizationDropdownFiltersReducers,
    patientDropDownFilter: PatientDropdownFiltersReducers,
    patientInsolesAutoComplete: PatientInsolesAutoCompleteReducers
});

