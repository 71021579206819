import * as React from "react";
import {memo} from "react";

import {useHomePageNavbarStyle} from "../../../../app/home-page/components/home-page-navbar/home-page-navbar/home-page-navbar.style";
import {AdminDashboardNavbarWrapper} from "./admin-dashboard-navbar";
import {NavLink} from "react-router-dom";
import logo from "../../../../../images/Orpyx-SI-Logo.svg";

export const AdminPagesNavBar = memo(() => {
    const classes = useHomePageNavbarStyle();

    return (
        <header className={classes.appBar}>
            <div>
                <NavLink
                    exact={true}
                    to="/"
                    activeClassName="active"
                    className={`Title-header ${classes.logoClass}`}>
                    <img src={logo} alt="Home" height="50px"/>
                </NavLink>
            </div>
            <div>
                <AdminDashboardNavbarWrapper usePrimaryLinkColor={true}/>
            </div>
        </header>
    )
});


