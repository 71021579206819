import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ORPYX_USER_SEND_INVITE_FAILED, ORPYX_USER_SEND_INVITE_SUCCESSFULLY, OrpyxUserSendInviteActions} from "./orpyx-user-send-invite.actions";
import {ENQUEUE_SNACKBAR, fullNamePipe, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import {AdminOrpyxUsersInviteHttpClient, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";

const API_VERSIONS = '1';

function* onOrpyxUserSendInvite(action: StoreAction<OrpyxUserSendInviteActions, IOrpyxUserDto>) {
    const user = action.payload!;

    try {
        const api = new AdminOrpyxUsersInviteHttpClient(ORPYXSI_ADMIN_URL);

        const data: IOrpyxUserDto = yield call(
            [api, api.sendOrpyxUserInvite],
            user.orpyxUserId!,
            API_VERSIONS
        );

        yield put(ORPYX_USER_SEND_INVITE_SUCCESSFULLY(user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Invitation for admin ${fullNamePipe(data)} successfully sent`,
            options: {variant: "success"}
        }));

    } catch (e) {

        yield put(ORPYX_USER_SEND_INVITE_FAILED(user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed sent invitation for admin ${fullNamePipe(user)}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* OrpyxUserSendInviteSagas() {
    yield all([
        takeEvery(OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE, onOrpyxUserSendInvite),
    ]);
}
