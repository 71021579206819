import {OrganizationsListPageProps} from "./organizations-list-page.interface";
import {OrganizationsSearchQuery, toQueryString} from "../../models/organizations-search-query";
import { IOrganizationOutputDto } from "admins-dashboard";


export const onOrganizationsListPageSearchQueryChanged = (props: OrganizationsListPageProps) => (searchQuery: OrganizationsSearchQuery): void => {
    const urlQuery: string = toQueryString(searchQuery);
    const url = `/admin/organizations/list?${urlQuery}`;
    props.history!.push(url);
};

export const onOrganizationsListOrganizationSelected = (props: OrganizationsListPageProps) => (organization: IOrganizationOutputDto): void => {
    const url = `/admin/organizations/details/${organization.id}`;
    props.history!.push(url);
};
