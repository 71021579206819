import adminHasRequiredRolePipe from "../../../../../pipes/admin-has-required-role-pipe";
import {UserAccountStatus} from "admins-dashboard/http-clients/index";
import {OrganizationUserActionsProps} from "./organization-user-information-actions.interface";
import {AdminDashboardUsersPolicies} from "../../../../../policies/admin-dashboard-users-policies";
import {buildAdvancedMenuElement, BuildAdvancedMenuElementOptions, createAdvancedMenu} from "orpyx-web-common";

const resetPasswordOptions: BuildAdvancedMenuElementOptions<OrganizationUserActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminDashboardUsersPolicies.ResetPassword, props.availableRoles),
};

const deleteUserOptions: BuildAdvancedMenuElementOptions<OrganizationUserActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminDashboardUsersPolicies.Delete, props.availableRoles) && props.dashboardUser.user!.status !== UserAccountStatus.Deactivated,
    onSelected: props => props.events.showDeleteUserModal(props.dashboardUser.organization!, props.dashboardUser.user!),
};

const restoreUserOptions: BuildAdvancedMenuElementOptions<OrganizationUserActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminDashboardUsersPolicies.Restore, props.availableRoles) && props.dashboardUser.user!.status === UserAccountStatus.Deactivated,
    onSelected: props => props.events.showRestoreUserModal(props.dashboardUser!.organization!, props.dashboardUser.user!),
};

const sendUserInviteOptions: BuildAdvancedMenuElementOptions<OrganizationUserActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminDashboardUsersPolicies.Invite, props.availableRoles) && props.dashboardUser.user!.status === UserAccountStatus.NotActivated,
    onSelected: props => props.events.sendUserInvite(props.dashboardUser!.organization!, props.dashboardUser!.user!),
};

const revokeUserInviteOptions: BuildAdvancedMenuElementOptions<OrganizationUserActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminDashboardUsersPolicies.Invite, props.availableRoles) && props.dashboardUser.user!.status === UserAccountStatus.NotActivated,
    onSelected: props => props.events.revokeUserInvite(props.dashboardUser!.organization!, props.dashboardUser!.user!),
};

export const OrganizationUserActions = createAdvancedMenu<OrganizationUserActionsProps>({
    actionBuilder: [
        buildAdvancedMenuElement<OrganizationUserActionsProps>({text: 'Reset password', disabled: true}, resetPasswordOptions),

        buildAdvancedMenuElement<OrganizationUserActionsProps>({text: 'Delete user'}, deleteUserOptions),
        buildAdvancedMenuElement<OrganizationUserActionsProps>({text: 'Restore user'}, restoreUserOptions),

        buildAdvancedMenuElement<OrganizationUserActionsProps>({text: 'Send user invite'}, sendUserInviteOptions),
        buildAdvancedMenuElement<OrganizationUserActionsProps>({text: 'Revoke user invite'}, revokeUserInviteOptions),
    ]
});