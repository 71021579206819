import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {OrganizationsSearchQuery} from "../models/organizations-search-query";
import {IOrganizationOutputDto, IOrganizationOutputDtoPagingResult} from "admins-dashboard/http-clients/index";

export enum OrganizationsListActions {
    INITIALIZE_SUCCESSFULLY = 'ORGANIZATIONS_LIST: INITIALIZE__SUCCESSFULLY',

    FETCH_ORGANIZATIONS = 'ORGANIZATIONS_LIST: FETCH_ORGANIZATIONS',
    FETCH_ORGANIZATIONS_BEGIN = 'ORGANIZATIONS_LIST: FETCH_ORGANIZATIONS_BEGIN',
    FETCH_ORGANIZATIONS_SUCCESSFULLY = 'ORGANIZATIONS_LIST: FETCH_ORGANIZATIONS_SUCCESSFULLY',
    FETCH_ORGANIZATIONS_FAILED = 'ORGANIZATIONS_LIST: FETCH_ORGANIZATIONS_FAILED',
}

export const ORGANIZATIONS_INITIALIZE_SUCCESSFULLY = (query: OrganizationsSearchQuery): StoreAction<OrganizationsListActions> => ({
    type: OrganizationsListActions.INITIALIZE_SUCCESSFULLY, payload: query
});

export const FETCH_ORGANIZATIONS = (): StoreAction<OrganizationsListActions> => ({
    type: OrganizationsListActions.FETCH_ORGANIZATIONS
});

export const FETCH_ORGANIZATIONS_BEGIN = (): StoreAction<OrganizationsListActions> => ({
    type: OrganizationsListActions.FETCH_ORGANIZATIONS_BEGIN
});

export const FETCH_ORGANIZATIONS_SUCCESSFULLY = (organizations: IOrganizationOutputDtoPagingResult): StoreAction<OrganizationsListActions> => ({
    type: OrganizationsListActions.FETCH_ORGANIZATIONS_SUCCESSFULLY, payload: organizations
});

export const FETCH_ORGANIZATIONS_FAILED = (error: OperationErrorType): StoreAction<OrganizationsListActions> => ({
    type: OrganizationsListActions.FETCH_ORGANIZATIONS_FAILED, payload: error
});
