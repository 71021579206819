import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserRestoreActions {
    RESTORE_ORPYX_USER = 'RESTORE_ORPYX_USER: RESTORE_ORPYX_USER',
    RESTORE_ORPYX_USER_SUCCESSFULLY = 'RESTORE_ORPYX_USER: RESTORE_ORPYX_USER_SUCCESSFULLY',
    RESTORE_ORPYX_USER_FAILED = 'RESTORE_ORPYX_USER: RESTORE_ORPYX_USER_FAILED',

    SHOW_MODAL = 'RESTORE_ORPYX_USER: SHOW_MODAL',
    HIDE_MODAL = 'RESTORE_ORPYX_USER: HIDE_MODAL',
}

export const RESTORE_ORPYX_USER = (): StoreAction<OrpyxUserRestoreActions> => ({
    type: OrpyxUserRestoreActions.RESTORE_ORPYX_USER,
});

export const RESTORE_ORPYX_USER_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrpyxUserRestoreActions> => ({
    type: OrpyxUserRestoreActions.RESTORE_ORPYX_USER_SUCCESSFULLY, payload: actionAfterComplete
});

export const RESTORE_ORPYX_USER_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserRestoreActions> => ({
    type: OrpyxUserRestoreActions.RESTORE_ORPYX_USER_FAILED, payload: error
});

export const RESTORE_ORPYX_USER_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (user: IOrpyxUserDto): StoreAction<OrpyxUserRestoreActions> => ({
    type: OrpyxUserRestoreActions.SHOW_MODAL, payload: {user, actionAfterComplete}
});

export const RESTORE_ORPYX_USER_HIDE_MODAL = (): StoreAction<OrpyxUserRestoreActions> => ({
    type: OrpyxUserRestoreActions.HIDE_MODAL,
});