import * as React from 'react';
import {memo} from 'react';
import {OrganizationsListPageProps} from "./organizations-list-page.interface";
import {ApplicationBreadcrumbsItem} from "orpyx-web-common";
import {OrganizationsListBlock} from "../organizations-list-template/organizations-list-template";
import { withRouter } from 'react-router-dom';
import {onOrganizationsListOrganizationSelected, onOrganizationsListPageSearchQueryChanged} from "./organizations-list-page.hooks";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";

const generatePageBreadcrumbs = (): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Organizations',
        url: '/admin/organizations/list'
    }];

    return generateBreadcrumbs(items);
};

export const OrganizationsListPage = memo(withRouter((props: OrganizationsListPageProps) => {
    return (<OrganizationsListBlock
        onOrganizationSelected={onOrganizationsListOrganizationSelected(props)}
        onSearchQueryChanged={onOrganizationsListPageSearchQueryChanged(props)}
        breadcrumbs={generatePageBreadcrumbs()}
    />);
}));
