import produce from "immer";
import {OrganizationUserInformationActions} from "./organization-user-information-page.actions";
import {IGetDashboardUserInformationResponse} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface OrganizationUserInformationState {
    initialized: boolean;
    isUserUpdating: boolean,

    user: IGetDashboardUserInformationResponse | undefined,
    error: OperationErrorType,

}

const initialState: OrganizationUserInformationState = {
    initialized: false,
    isUserUpdating: false,

    error: undefined,
    user: undefined,
};

const OrganizationUserInformationReducers = (state = initialState, action: StoreAction<OrganizationUserInformationActions>): OrganizationUserInformationState => {
    return produce(state, (draft: OrganizationUserInformationState) => {
        switch (action.type) {
            case OrganizationUserInformationActions.INITIALIZE:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = undefined;
                break;
            case OrganizationUserInformationActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.user = action.payload;
                break;
            case OrganizationUserInformationActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = action.payload;
                break;

            case OrganizationUserInformationActions.UPDATE_USER:
                draft.isUserUpdating = true;
                draft.error = undefined;
                break;

            case OrganizationUserInformationActions.UPDATE_USER_SUCCESSFULLY:
                draft.isUserUpdating = false;
                draft.error = undefined;
                break;

            case OrganizationUserInformationActions.UPDATE_USER_FAILED:
                draft.isUserUpdating = false;
                draft.error = action.payload;
                break;

        }
    })
};

export default OrganizationUserInformationReducers;
