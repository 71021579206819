import produce from "immer";
import {OrpyxUserCreateActions} from "./orpyx-user-create-actions";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export interface OrpyxUserCreateState extends IModalWindow {
    createdUser: IOrpyxUserDto | undefined;
}

const initialState: OrpyxUserCreateState = {
    createdUser: undefined,
    ...ModalWindowInitialState
};

const OrpyxUserCreateReducer = (state = initialState, action: any): OrpyxUserCreateState => {
    return produce(state, (draft: OrpyxUserCreateState) => {
        switch (action.type) {
            case OrpyxUserCreateActions.SHOW_MODAL:
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrpyxUserCreateActions.HIDE_MODAL:
                ModalWindowStore.onToggleModal(draft, false);
                break;

            case OrpyxUserCreateActions.CREATE_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrpyxUserCreateActions.CREATE_USER_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrpyxUserCreateActions.CREATE_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    })
};

export default OrpyxUserCreateReducer;
