import {all, call, delay, put, select, spawn, takeEvery} from "redux-saga/effects";

import {push} from "connected-react-router";
import log from 'loglevel';
import {
    ADMIN_LOGIN_COMPLETE,
    ADMIN_LOGIN_FAILED,
    ADMIN_LOGIN_FETCH_USER_PROFILE,
    ADMIN_LOGIN_FETCH_USER_PROFILE_FAILED,
    ADMIN_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY,
    ADMIN_LOGIN_SUCCESSFULLY,
    ADMIN_LOGOUT_FAILED,
    ADMIN_LOGOUT_SUCCESSFULLY,
    AdminAuthActions
} from "./admin-auth-actions";
import {adminAzureB2cCredentials} from "../admin-azure-b2c-credentials";
import {AuthResponse} from "msal";
import {AdminAzureActor} from "../models/admin-azure-token";
import {ENQUEUE_SNACKBAR, OperationErrorPipe, OperationErrorType, StoreAction} from "orpyx-web-common";
import AdminAzureTokenStorage from "./actor/admin-azure-token-storage";
import {AdminOrpyxUserProfileHttpClient, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../appConstants";
import {AdminAuthSelectors} from "./admin-auth-selectors";
import AdminActorStorage from "./actor/admin-actor-storage";

const API_VERSION = '1';

function* authorize() {
    try {
        yield adminAzureB2cCredentials.agentApp.loginPopup({
            scopes: adminAzureB2cCredentials.SCOPES.login,
            redirectUri: encodeURI(origin),
            forceRefresh: true,
            prompt: 'login',
        });
        
        const authResponse: AuthResponse = yield tryAcquireToken();

        const actor: AdminAzureActor = {
            accountIdentifier: authResponse.account.accountIdentifier,
            name: authResponse.account.name,
            accessToken: {
                token: authResponse.accessToken,
                expiredAt: authResponse.expiresOn
            },
        };

        yield put(ADMIN_LOGIN_SUCCESSFULLY(actor));
        yield put(ADMIN_LOGIN_FETCH_USER_PROFILE());
    } catch (e) {
        yield put(ADMIN_LOGIN_FAILED(e));
    }
}

function* onFetchOrpyxUserRoles() {
    try {
        const api = new AdminOrpyxUserProfileHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrpyxUserDto = yield call(
            [api, api.getOrpyxUserProfile],
            API_VERSION
        );

        yield put(ADMIN_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY(data));
        yield put(ADMIN_LOGIN_COMPLETE());
    } catch (e) {
        yield put(ADMIN_LOGIN_FETCH_USER_PROFILE_FAILED(e));
    }
}

function* tryAcquireToken() {
    try {
        const response: AuthResponse = yield adminAzureB2cCredentials.agentApp.acquireTokenSilent({
            scopes: [adminAzureB2cCredentials.SCOPES.acquireToken],
            redirectUri: encodeURI(origin)
        });
        return response;
    } catch (e) {
        try {
            yield onLogout();
            const response: AuthResponse = yield adminAzureB2cCredentials.agentApp.acquireTokenPopup({
                scopes: [adminAzureB2cCredentials.SCOPES.acquireToken],
                redirectUri: encodeURI(origin)
            });
            return response;
        } catch (ee) {
            return undefined;
        }
    }
}

function* onProcessRefreshToken() {
    const azureActor = yield select(AdminAuthSelectors.azureActor);

    if (!azureActor) {
        return;
    }

    const response: AuthResponse | undefined = yield tryAcquireToken();
    if (response && response.accessToken && response.expiresOn) {
        const actor: AdminAzureActor = {
            name: azureActor.name,
            accountIdentifier: azureActor.accountIdentifier,
            accessToken: {
                token: response.accessToken,
                expiredAt: response.expiresOn
            }
        };
        AdminAzureTokenStorage.saveActor(actor);
        yield put(ADMIN_LOGIN_SUCCESSFULLY(actor));
    } else {
        yield put(ADMIN_LOGOUT_SUCCESSFULLY());

        yield put(ENQUEUE_SNACKBAR({
            message: `Login failed`,
            options: {variant: 'error'}
        }));
    }
}

function* onRefreshToken() {
    while (true) {
        const minutes = 7;
        yield delay(minutes * 60 * 1000);
        yield onProcessRefreshToken();
    }
}

function onFetchOrpyxUserRolesSuccess(action: StoreAction<AdminAuthActions, IOrpyxUserDto>) {
    AdminActorStorage.saveActorProfile(action.payload!);
}

function* onLogout() {
    try {
        //  Reset cache & state, then process logout
        yield put(ADMIN_LOGOUT_SUCCESSFULLY());

        yield adminAzureB2cCredentials.agentApp.logout();
    } catch (e) {
        yield put(ADMIN_LOGOUT_FAILED(e));
    }
}

function* onLogoutComplete() {
    AdminAzureTokenStorage.resetActor();
    AdminActorStorage.resetActorProfile();

    yield put(push("/"));
}

function onLogoutFailed(action: StoreAction<AdminAuthActions, OperationErrorType>) {
    log.error(`onLogoutFailed: ${OperationErrorPipe(action.payload)}`);
}

function onLoginSuccess(action: StoreAction<AdminAuthActions, AdminAzureActor>) {
    const actor = action.payload!;
    AdminAzureTokenStorage.saveActor(actor);
}

function* onLoginComplete() {
    yield put(push("/admin"));

    const actor = yield select(AdminAuthSelectors.azureActor);
    yield put(ENQUEUE_SNACKBAR({
        message: `${actor.name} welcome to Orpyx!`,
        options: {variant: 'success'}
    }));

}

function* onLoginFailed(action: StoreAction<AdminAuthActions, OperationErrorType>) {
    AdminAzureTokenStorage.resetActor();
    AdminActorStorage.resetActorProfile();

    yield put(ENQUEUE_SNACKBAR({
        message: `Login failed. ${OperationErrorPipe(action.payload)}`,
        options: {variant: 'error'}
    }));

    yield put(push("/"));
}

export function* AdminAuthSagas() {
    yield all([
        spawn(onRefreshToken),
        takeEvery(AdminAuthActions.LOGIN, authorize),
        takeEvery(AdminAuthActions.LOGIN_SUCCESSFULLY, onLoginSuccess),
        takeEvery(AdminAuthActions.LOGIN_COMPLETE, onLoginComplete),
        takeEvery(AdminAuthActions.LOGIN_FAILED, onLoginFailed),

        takeEvery(AdminAuthActions.FETCH_USER_PROFILE, onFetchOrpyxUserRoles),
        takeEvery(AdminAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY, onFetchOrpyxUserRolesSuccess),
        takeEvery(AdminAuthActions.FETCH_USER_PROFILE_FAILED, onLoginFailed),

        takeEvery(AdminAuthActions.LOGOUT, onLogout),
        takeEvery(AdminAuthActions.LOGOUT_SUCCESSFULLY, onLogoutComplete),
        takeEvery(AdminAuthActions.LOGOUT_FAILED, onLogoutComplete),
        takeEvery(AdminAuthActions.LOGOUT_FAILED, onLogoutFailed),
    ]);
}
