import {OrpyxCareUsersListActions} from "./orpyx-care-users-list-actions";
import produce from "immer";
import {IListOrpyxCareUsersResultEntry, ListOrpyxCareUserPropertyOrderBy} from "admins-dashboard/http-clients/index";
import {OrpyxCareUsersSearchQuery} from "../models/orpyx-care-users-search-query";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface OrpyxCareUsersListState {
    initialized: boolean;
    loading: boolean;
    error: OperationErrorType;

    total: number,
    search: OrpyxCareUsersSearchQuery;

    users: IListOrpyxCareUsersResultEntry[];

    filter: string;
}

const initialState: OrpyxCareUsersListState = {
    initialized: false,
    loading: false,
    error: undefined,

    total: 0,
    search: new OrpyxCareUsersSearchQuery(undefined, ListOrpyxCareUserPropertyOrderBy.FirstName, true, undefined, undefined),

    users: [],

    filter: 'All',
};

const OrpyxCareUsersListReducer = (state = initialState, action: StoreAction<OrpyxCareUsersListActions>): OrpyxCareUsersListState => {
    return produce(state, (draft: OrpyxCareUsersListState) => {
        switch (action.type) {
            case OrpyxCareUsersListActions.FETCH_USERS:
                draft.search = action.payload;
                draft.error = undefined;
                break;
            case OrpyxCareUsersListActions.FETCH_USERS_BEGIN:
                draft.error = undefined;
                draft.loading = true;
                break;
            case OrpyxCareUsersListActions.FETCH_USERS_SUCCESSFULLY:
                draft.error = undefined;
                draft.users = action.payload.items;
                draft.total = action.payload.total;
                draft.loading = false;
                draft.initialized = true;
                break;
            case OrpyxCareUsersListActions.FETCH_USERS_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                draft.initialized = true;
                break;

            case OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER:
                draft.error = undefined;
                draft.loading = true;
                break;
            case OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY:
                draft.error = undefined;
                draft.loading = false;
                draft.filter = action.payload;
                break;
            case OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;            
        }
    });
};

export default OrpyxCareUsersListReducer;
