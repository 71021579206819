import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ExportRawPatientTemperatureStatisticsQuery} from "../models/export-raw-patient-temperature-statistics-query";

export enum PatientsRawTemperatureActions {
    DOWNLOAD_RAW_PATIENT_TEMPERATURE = 'RAW_PATIENT_TEMPERATURE: DOWNLOAD_RAW_PATIENT_TEMPERATURE',
    DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY = 'RAW_PATIENT_TEMPERATURE: DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY',
    DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED = 'RAW_PATIENT_TEMPERATURE: DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED',
}

export const DOWNLOAD_RAW_PATIENT_TEMPERATURE = (query: ExportRawPatientTemperatureStatisticsQuery): StoreAction<PatientsRawTemperatureActions> => ({
    type: PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE, payload: query
});

export const DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY = (): StoreAction<PatientsRawTemperatureActions> => ({
    type: PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY
});

export const DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED = (error: OperationErrorType): StoreAction<PatientsRawTemperatureActions> => ({
    type: PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED, payload: error
});
