import {createSelector} from "reselect";
import {getOrpyxUsersState} from "../../store/orpyx-users-store-selectors";

export class OrpyxUserInformationSelectors {
    static readonly state = createSelector(getOrpyxUsersState, state => state.information);

    static readonly user = createSelector(OrpyxUserInformationSelectors.state, state => state.user);
    static readonly error = createSelector(OrpyxUserInformationSelectors.state, state => state.error);

    static readonly initialized = createSelector(OrpyxUserInformationSelectors.state, state => state.initialized);
    static readonly isUserUpdating = createSelector(OrpyxUserInformationSelectors.state, state => state.isUserUpdating);
}

export default OrpyxUserInformationSelectors;
