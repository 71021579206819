import produce from "immer";
import {OrpyxCareUserInformationActions} from "./orpyx-care-user-information-page.actions";
import {IGetOrpyxCareUserInformationResponse} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface OrpyxCareUserInformationState {
    initialized: boolean;
    isUserUpdating: boolean,

    user: IGetOrpyxCareUserInformationResponse | undefined,
    error: OperationErrorType,
}

const initialState: OrpyxCareUserInformationState = {
    initialized: false,
    isUserUpdating: false,

    error: undefined,
    user: undefined,
};

const OrpyxCareUserInformationReducers = (state = initialState, action: StoreAction<OrpyxCareUserInformationActions>): OrpyxCareUserInformationState => {
    return produce(state, (draft: OrpyxCareUserInformationState) => {
        switch (action.type) {
            case OrpyxCareUserInformationActions.INITIALIZE:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = undefined;
                break;
            case OrpyxCareUserInformationActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.user = action.payload;
                break;
            case OrpyxCareUserInformationActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = action.payload;
                break;

            case OrpyxCareUserInformationActions.UPDATE_USER:
                draft.isUserUpdating = true;
                draft.error = undefined;
                break;

            case OrpyxCareUserInformationActions.UPDATE_USER_SUCCESSFULLY:
                draft.isUserUpdating = false;
                draft.error = undefined;
                break;

            case OrpyxCareUserInformationActions.UPDATE_USER_FAILED:
                draft.isUserUpdating = false;
                draft.error = action.payload;
                break;

        }
    })
};

export default OrpyxCareUserInformationReducers;
