import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationUpdateModalEvents, OrganizationUpdateModalMeta, OrganizationUpdateModalOwnProps, OrganizationUpdateModalProps} from "./organization-update-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {createModalWindow, ModalWindowHeader, OperationProgress} from "orpyx-web-common";

import {ORGANIZATION_UPDATE, ORGANIZATION_UPDATE_HIDE_MODAL} from "../../store/organization-update.actions";
import {OrganizationUpdateSelectors} from "../../store/organization-update.selectors";
import {LazyLoader} from "orpyx-web-common";

const OrganizationUpdateFormWrapper = React.lazy(() => import("../organization-update-form/organization-update-form"));

const OrganizationUpdateModalTemplate = (props: OrganizationUpdateModalMeta) => {
    return (<>
            <ModalWindowHeader>
                Update Organization Information
            </ModalWindowHeader>

            <LazyLoader height={40} width={300} rows={8}>
                {props.initialized ? <OrganizationUpdateFormWrapper
                    organization={props.organization}
                    inProcess={props.inProcess}
                    error={props.error}
                    onSubmit={props.updateOrganization}
                /> : <OperationProgress initialized={props.initialized} error={props.error}/>}
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationUpdateModalOwnProps => {
    return {
        error: OrganizationUpdateSelectors.error(state),
        organization: OrganizationUpdateSelectors.organization(state),

        inProcess: OrganizationUpdateSelectors.inProcess(state),
        initialized: OrganizationUpdateSelectors.initialized(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationUpdateModalProps): OrganizationUpdateModalEvents => {
    return bindActionCreators({
        updateOrganization: ORGANIZATION_UPDATE,
    }, dispatch);
};

export const OrganizationUpdateModalWindow = (props: OrganizationUpdateModalProps) => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationUpdateModalTemplate)),
    closeModalActionCreator: ORGANIZATION_UPDATE_HIDE_MODAL,
    modalToggleSelector: OrganizationUpdateSelectors.toggle,
});
