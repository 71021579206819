import {all, call, put, takeEvery} from 'redux-saga/effects';
import {DASHBOARD_USER_SEND_INVITE_FAILED, DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY, OrganizationUserSendInviteActions} from "./organization-user-send-invite.actions";
import {AdminDashboardUserInviteHttpClient, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";

const API_VERSIONS = '1';

function* onOrganizationUserSendInvite(action: StoreAction<OrganizationUserSendInviteActions, { user: IUserOutputDto, organizationId: number}>) {
    const {user, organizationId} = action.payload!;

    try {
        const api = new AdminDashboardUserInviteHttpClient(ORPYXSI_ADMIN_URL);

        const data: IUserOutputDto = yield call(
            [api, api.sendDashboardUserInvite],
            organizationId,
            user.id!,
            API_VERSIONS
        );

        yield put(DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY( user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Invitation for health care practitioner ${fullNamePipe(data)} successfully sent`,
            options: {variant: "success"}
        }));

    } catch (e) {

        yield put(DASHBOARD_USER_SEND_INVITE_FAILED(user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed sent invitation for health care practitioner ${fullNamePipe(user)}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* OrganizationUserSendInviteSagas() {
    yield all([
        takeEvery(OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE, onOrganizationUserSendInvite),
    ]);
}
