import {createSelector} from "reselect";
import {ApplicationAdminSharedStoreSelectors} from "../../store/application-admin-shared-store.selectors";
import {OrganizationDropdownFilterScope} from "./organization-dropdown-filters.types";

export class OrganizationDropdownFilterSelectors {
    static readonly states = createSelector(ApplicationAdminSharedStoreSelectors.state, state => state.organizationDropDownFilter);

    static readonly state = (scope: OrganizationDropdownFilterScope) => createSelector(OrganizationDropdownFilterSelectors.states, state => {
        return state[scope];
    });

    static readonly organizations = (scope: OrganizationDropdownFilterScope) => createSelector(OrganizationDropdownFilterSelectors.state(scope), state => state.organizations);

    static readonly error = (scope: OrganizationDropdownFilterScope) => createSelector(OrganizationDropdownFilterSelectors.state(scope), state => state.error);
    static readonly loading = (scope: OrganizationDropdownFilterScope) => createSelector(OrganizationDropdownFilterSelectors.state(scope), state => state.loading);

    static readonly term = (scope: OrganizationDropdownFilterScope) => createSelector(OrganizationDropdownFilterSelectors.state(scope), state => state.term);
}
