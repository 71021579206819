import {createSelector} from "reselect";
import {OrpyxCareUsersStoreSelectors} from "../../store/orpyx-care-users-store-selectors";

export class OrpyxCareUserInformationSelectors {
    static readonly state = OrpyxCareUsersStoreSelectors.information;

    static readonly user = createSelector(OrpyxCareUserInformationSelectors.state, state => state.user);
    static readonly error = createSelector(OrpyxCareUserInformationSelectors.state, state => state.error);

    static readonly initialized = createSelector(OrpyxCareUserInformationSelectors.state, state => state.initialized);
    static readonly isUserUpdating = createSelector(OrpyxCareUserInformationSelectors.state, state => state.isUserUpdating);
}

export default OrpyxCareUserInformationSelectors;
