import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {AdminOrganizationHttpClient, IOrganizationNameDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {OrganizationDropdownFilterActions, SEARCH_ORGANIZATIONS_FAILED, SEARCH_ORGANIZATIONS_SUCCESSFULLY} from "./organization-dropdown-filter-actions";
import {OrganizationDropdownFilterSelectors} from "./organization-dropdown-filter-selectors";
import {ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import {OrganizationDropdownFilterScope} from "./organization-dropdown-filters.types";

const API_VERSIONS = '1';

function* onSearchOrganizations(action: StoreAction<OrganizationDropdownFilterActions, any, OrganizationDropdownFilterScope>) {
    //  Search typing debounce 500ms then takeLatest & search
    yield delay(500);

    try {
        const scope = action.scope!;
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);

        const searchTerm: string = yield select(OrganizationDropdownFilterSelectors.term(scope))!;
        const data: IOrganizationNameDto[] = yield call([api, api.searchOrganizations],
            searchTerm,
            API_VERSIONS
        );

        yield put(SEARCH_ORGANIZATIONS_SUCCESSFULLY(action.scope!)(data));
    } catch (e) {
        yield put(SEARCH_ORGANIZATIONS_FAILED(action.scope!)(e));
        yield put(ENQUEUE_SNACKBAR({
            message: `Failed search organizations, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* OrganizationDropdownFilterSagas() {
    yield all([
        yield takeLatest(OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS, onSearchOrganizations),
    ]);
}


