import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {AdminAzureActor} from "../models/admin-azure-token";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum AdminAuthActions {
    LOGIN = 'ADMIN_AUTH: LOGIN',
    LOGIN_SUCCESSFULLY = 'ADMIN_AUTH: LOGIN_SUCCESSFULLY',
    LOGIN_COMPLETE = 'ADMIN_AUTH: LOGIN_COMPLETE',
    LOGIN_FAILED = 'ADMIN_AUTH: LOGIN_FAILED',

    LOGOUT = 'ADMIN_AUTH: LOGOUT',
    LOGOUT_SUCCESSFULLY = 'ADMIN_AUTH: LOGOUT_SUCCESSFULLY',
    LOGOUT_FAILED = 'ADMIN_AUTH: LOGOUT_FAILED',

    FETCH_USER_PROFILE = 'ADMIN_AUTH: FETCH_USER_PROFILE',
    FETCH_USER_PROFILE_SUCCESSFULLY = 'ADMIN_AUTH: FETCH_USER_PROFILE_SUCCESSFULLY',
    FETCH_USER_PROFILE_FAILED = 'ADMIN_AUTH: FETCH_USER_PROFILE_FAILED',
}

export const ADMIN_LOGIN = (): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGIN
});

export const ADMIN_LOGIN_SUCCESSFULLY = (actor: AdminAzureActor): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGIN_SUCCESSFULLY, payload: actor
});

export const ADMIN_LOGIN_FAILED = (error: OperationErrorType): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGIN_FAILED, payload: error
});

export const ADMIN_LOGIN_COMPLETE = (): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGIN_COMPLETE
});

export const ADMIN_LOGOUT = (): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGOUT
});

export const ADMIN_LOGOUT_SUCCESSFULLY = (): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGOUT_SUCCESSFULLY
});

export const ADMIN_LOGOUT_FAILED = (error: OperationErrorType): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.LOGOUT_FAILED, payload: error
});

export const ADMIN_LOGIN_FETCH_USER_PROFILE = (): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.FETCH_USER_PROFILE
});

export const ADMIN_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY = (roles: IOrpyxUserDto): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY, payload: roles
});

export const ADMIN_LOGIN_FETCH_USER_PROFILE_FAILED = (error: OperationErrorType): StoreAction<AdminAuthActions> => ({
    type: AdminAuthActions.FETCH_USER_PROFILE_FAILED, payload: error
});
