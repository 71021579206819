import {Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";
import {createStyles, makeStyles} from '@material-ui/styles';

export const useOrpyxUserInformationPageStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: '30px',
    },
    paper: {
        padding: '20px',
        marginBottom: '30px',
    },
    title: {
        fontSize: '20px',
        color: Teal,
    }
}));
