import {IModalWindow, ModalWindowStore, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientInsolesCreateActions} from "./patient-insoles-create-actions";
import {IListProductsResultEntry} from "admins-dashboard/http-clients/index";

export interface PatientInsolesCreateState extends IModalWindow {
    device: IListProductsResultEntry | undefined;
}

const initialState: PatientInsolesCreateState = {
    toggle: false,
    inProcess: false,

    device: undefined,
    error: undefined,
};

const PatientInsolesCreateReducers = (state = initialState, action: StoreAction<PatientInsolesCreateActions>): PatientInsolesCreateState => {
    return produce(state, (draft: PatientInsolesCreateState) => {
        switch (action.type) {
            case PatientInsolesCreateActions.CREATE_PATIENT_INSOLE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientInsolesCreateActions.CREATE_PATIENT_INSOLE_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientInsolesCreateActions.CREATE_PATIENT_INSOLE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientInsolesCreateActions.SHOW_MODAL:
                draft.device = action.payload;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientInsolesCreateActions.HIDE_MODAL:
                draft.device = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default PatientInsolesCreateReducers;
