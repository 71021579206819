import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import produce from "immer";
import {PatientsCreateActions} from "./patients-create-actions";

export interface PatientsCreateState extends IModalWindow {
    organization: IOrganizationOutputDto | undefined;
}

const initialState: PatientsCreateState = {
    ...ModalWindowInitialState,
    organization: undefined,
};

const PatientsCreateReducers = (state = initialState, action: StoreAction<PatientsCreateActions>): PatientsCreateState => {
    return produce(state, (draft: PatientsCreateState) => {
        switch (action.type) {
            case PatientsCreateActions.CREATE_PATIENT_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientsCreateActions.CREATE_PATIENT_USER_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientsCreateActions.CREATE_PATIENT_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientsCreateActions.SHOW_MODAL:
                draft.organization = action.payload;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientsCreateActions.SELECT_ORGANIZATION:
                draft.organization = action.payload;
                break;

            case PatientsCreateActions.HIDE_MODAL:
                draft.organization = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default PatientsCreateReducers;
