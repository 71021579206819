import * as React from "react";
import {IOrpyxUserInformationActionsProps} from "./orpyx-user-information-actions.interface";
import adminHasRequiredRolePipe from "../../../../pipes/admin-has-required-role-pipe";
import {UserAccountStatus} from "admins-dashboard/http-clients/index";
import AdminOrpyxUsersPolicies from "../../../../policies/admin-orpyx-users-policies";
import {buildAdvancedMenuElement, BuildAdvancedMenuElementOptions, createAdvancedMenu} from "orpyx-web-common";

const resetPasswordOptions: BuildAdvancedMenuElementOptions<IOrpyxUserInformationActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminOrpyxUsersPolicies.ResetPassword, props.availableRoles),
};

const deleteUserOptions: BuildAdvancedMenuElementOptions<IOrpyxUserInformationActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminOrpyxUsersPolicies.Delete, props.availableRoles) && props.user.status !== UserAccountStatus.Deactivated,
    onSelected: props => props.events.showDeleteUserModal(props.user),
};

const restoreUserOptions: BuildAdvancedMenuElementOptions<IOrpyxUserInformationActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminOrpyxUsersPolicies.Restore, props.availableRoles) && props.user.status === UserAccountStatus.Deactivated,
    onSelected: props => props.events.showRestoreUserModal(props.user),
};

const sendUserInviteOptions: BuildAdvancedMenuElementOptions<IOrpyxUserInformationActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminOrpyxUsersPolicies.Invite, props.availableRoles) && props.user.status === UserAccountStatus.NotActivated,
    onSelected: props => props.events.sendUserInvite(props.user),
};

const revokeUserInviteOptions: BuildAdvancedMenuElementOptions<IOrpyxUserInformationActionsProps> = {
    canDisplay: props => adminHasRequiredRolePipe(AdminOrpyxUsersPolicies.Invite, props.availableRoles) && props.user.status === UserAccountStatus.NotActivated,
    onSelected: props => props.events.revokeUserInvite(props.user),
};

export const OrpyxUserActions = createAdvancedMenu<IOrpyxUserInformationActionsProps>({
    actionBuilder: [
        buildAdvancedMenuElement<IOrpyxUserInformationActionsProps>({text: 'Reset password', disabled: true}, resetPasswordOptions),

        buildAdvancedMenuElement<IOrpyxUserInformationActionsProps>({text: 'Delete user'}, deleteUserOptions),
        buildAdvancedMenuElement<IOrpyxUserInformationActionsProps>({text: 'Restore user'}, restoreUserOptions),

        buildAdvancedMenuElement<IOrpyxUserInformationActionsProps>({text: 'Send user invite'}, sendUserInviteOptions),
        buildAdvancedMenuElement<IOrpyxUserInformationActionsProps>({text: 'Revoke user invite'}, revokeUserInviteOptions),
    ]
});