import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IListDashboardUsersResultEntry, IListDashboardUsersResultEntryPagingResult} from "admins-dashboard/http-clients/index";
import {DashboardUsersSearchQuery} from "../models/dashboard-users-search-query";

export enum OrganizationUsersListActions {
    USER_SELECT = 'DASHBOARD_USER: USER_SELECT',

    REFRESH_DASHBOARD_USERS = 'DASHBOARD_USERS: REFRESH_DASHBOARD_USERS',
    FETCH_DASHBOARD_USERS = 'DASHBOARD_USERS: FETCH_USERS',
    FETCH_DASHBOARD_USERS_BEGIN = 'DASHBOARD_USERS: FETCH_USERS_BEGIN',
    FETCH_DASHBOARD_USERS_SUCCESSFULLY = 'DASHBOARD_USERS: FETCH_USERS_SUCCESSFULLY',
    FETCH_DASHBOARD_USERS_FAILED = 'DASHBOARD_USERS: FETCH_USERS_FAILED',
}

export const REFRESH_DASHBOARD_USERS = (): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.REFRESH_DASHBOARD_USERS
});

export const DASHBOARD_USERS_USER_SELECTED = (dashboardUser: IListDashboardUsersResultEntry): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.USER_SELECT, payload: dashboardUser
});

export const FETCH_DASHBOARD_USERS = (query: DashboardUsersSearchQuery): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.FETCH_DASHBOARD_USERS, payload: query
});

export const FETCH_DASHBOARD_USERS_BEGIN = (): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.FETCH_DASHBOARD_USERS_BEGIN
});

export const FETCH_DASHBOARD_USERS_SUCCESSFULLY = (users: IListDashboardUsersResultEntryPagingResult): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.FETCH_DASHBOARD_USERS_SUCCESSFULLY, payload: users
});

export const FETCH_DASHBOARD_USERS_FAILED = (error: OperationErrorType): StoreAction<OrganizationUsersListActions> => ({
    type: OrganizationUsersListActions.FETCH_DASHBOARD_USERS_FAILED, payload: error
});