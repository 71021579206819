import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FETCH_PATIENTS_FAILED, FETCH_PATIENTS_SUCCESSFULLY, PATIENTS_INITIALIZE_FAILED, PATIENTS_INITIALIZE_SUCCESSFULLY, PatientsListActions} from "./patients-list-actions";
import {AdminOrganizationHttpClient, AdminPatientsHttpClient, IOrganizationOutputDto, IGetPatientsListResult, IGetPatientsListResultPagingResult,} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {PatientsListSelectors} from "./patients-list-selectors";
import {PatientsSearchQuery} from "../models/PatientsSearchQuery";
import {convertOffsetToPage, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {PatientsCreateActions} from "../../patients-create/store/patients-create-actions";
import {PatientInformationActions} from "../../patient-information/store/patient-information-actions";
import {push} from "connected-react-router";
import {PatientDeleteActions} from "../../patient-delete/store/patient-delete.actions";
import {PatientRestoreActions} from "../../patient-restore/store/patient-restore.actions";

const API_VERSIONS = '1';

function* onRefreshPatientsList() {
    try {
        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);

        const searchQuery: PatientsSearchQuery = yield select(PatientsListSelectors.search)!;

        //  TODO: Need rename & refactory on back-end offset to page
        //  Now if page 1, offset 0
        const data: IGetPatientsListResultPagingResult = yield call(
            [api, api.getPatients],
            searchQuery.organizationId,
            convertOffsetToPage(searchQuery.offset),
            searchQuery.count,

            searchQuery.term,
            searchQuery.orderByAsc,
            searchQuery.orderBy,

            API_VERSIONS
        );

        yield put(FETCH_PATIENTS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_PATIENTS_FAILED(e));
    }
}

function* onPatientSelected(action: StoreAction<PatientsListActions, IGetPatientsListResult>) {
    const patient = action.payload!;
    const organizationId = patient.organization!.id!;
    const patientId = patient.patient!.patientId!;

    const url = `/admin/organizations/${organizationId}/patients/details/${patientId}`;
    yield put(push(url));
}

function* onFetchOrganizationInformation(action: StoreAction<PatientsListActions, number | undefined>) {
    try {
        const organizationId = action.payload;

        if (!organizationId) {
            //  Reset organization information, then load all patients
            yield put(PATIENTS_INITIALIZE_SUCCESSFULLY(undefined));
            return;
        }

        //  Load organization information, then load organization patients
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrganizationOutputDto = yield call(
            [api, api.getOrganization],
            organizationId!,
            API_VERSIONS
        );

        yield put(PATIENTS_INITIALIZE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(PATIENTS_INITIALIZE_FAILED(e));
    }
}

export default function* PatientsListSagas() {
    yield all([
        yield takeEvery(PatientsCreateActions.CREATE_PATIENT_USER_SUCCESSFULLY, onRefreshPatientsList),
        yield takeEvery(whenStoreActionAfterComplete(PatientDeleteActions.PATIENT_DELETE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshPatientsList),
        yield takeEvery(whenStoreActionAfterComplete(PatientRestoreActions.PATIENT_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshPatientsList),

        //  TODO: Check UPDATE_PATIENT_USER_SUCCESSFULLY
        yield takeEvery(PatientInformationActions.UPDATE_PATIENT_USER_SUCCESSFULLY, onRefreshPatientsList),

        yield takeEvery(PatientsListActions.INITIALIZE, onFetchOrganizationInformation),

        yield takeEvery(PatientsListActions.REFRESH_PATIENTS, onRefreshPatientsList),
        yield takeEvery(PatientsListActions.FETCH_PATIENTS, onRefreshPatientsList),

        yield takeEvery(PatientsListActions.SELECT_PATIENT, onPatientSelected),
    ]);
}
