import {AdminOrganizationHttpClient, IOrganizationInformationDto, OrganizationInformationDto} from "admins-dashboard/http-clients/index";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {CREATE_ORGANIZATION_FAILED, CREATE_ORGANIZATION_SUCCESSFULLY, OrganizationCreateActions} from "./organization-create-actions";
import {ENQUEUE_SNACKBAR, StoreAction} from "orpyx-web-common";

const API_VERSIONS = '1';

function* onCreateOrganization(action: StoreAction<OrganizationCreateActions, IOrganizationInformationDto>) {
    try {
        const model = new OrganizationInformationDto(action.payload!);
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);

        const data: IOrganizationInformationDto = yield call(
            [api, api.createOrganization],
            API_VERSIONS,
            model
        );

        yield put(CREATE_ORGANIZATION_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully created organization ${data.name} in ${model.city}, ${model.state}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(CREATE_ORGANIZATION_FAILED(e));
    }
}

export default function* OrganizationCreateSagas() {
    yield all([
        takeEvery(OrganizationCreateActions.CREATE_ORGANIZATION, onCreateOrganization),
    ]);
}

