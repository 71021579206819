import produce from "immer";
import {PatientRestoreActions} from "./patient-restore.actions";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";


export interface PatientRestoreState extends IModalWindow {
    patient: IGetPatientsListResult | IGetPatientInformationResult | undefined;
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: PatientRestoreState = {
    ...ModalWindowInitialState,
    actionAfterComplete: StoreActionAfterComplete.None,

    patient: undefined,
};

export default (state = initialState, action: StoreAction<PatientRestoreActions>): PatientRestoreState => {
    return produce(state, (draft: PatientRestoreState) => {
        switch (action.type) {
            case PatientRestoreActions.SHOW_MODAL:
                draft.patient = action.payload.patient;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientRestoreActions.PATIENT_RESTORE_SUCCESSFULLY:
            case PatientRestoreActions.HIDE_MODAL:
                draft.patient = undefined;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientRestoreActions.PATIENT_RESTORE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientRestoreActions.PATIENT_RESTORE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    })
};


