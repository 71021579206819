import {IAddInsoleDto, IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientInsolesCreateActions {
    CREATE_PATIENT_INSOLE = 'CREATE_PATIENT_INSOLE: CREATE_PATIENT_INSOLE',
    CREATE_PATIENT_INSOLE_SUCCESSFULLY = 'CREATE_PATIENT_INSOLE: CREATE_PATIENT_INSOLE_SUCCESSFULLY',
    CREATE_PATIENT_INSOLE_FAILED = 'CREATE_PATIENT_INSOLE: CREATE_PATIENT_INSOLE_FAILED',

    SHOW_MODAL = 'CREATE_PATIENT_INSOLE: SHOW_MODAL',
    HIDE_MODAL = 'CREATE_PATIENT_INSOLE: HIDE_MODAL',
}

export const CREATE_PATIENT_INSOLE = (model: IAddInsoleDto): StoreAction<PatientInsolesCreateActions> => ({
    type: PatientInsolesCreateActions.CREATE_PATIENT_INSOLE, payload: model
});

export const CREATE_PATIENT_INSOLE_SUCCESSFULLY = (): StoreAction<PatientInsolesCreateActions> => ({
    type: PatientInsolesCreateActions.CREATE_PATIENT_INSOLE_SUCCESSFULLY
});

export const CREATE_PATIENT_INSOLE_FAILED = (error: OperationErrorType): StoreAction<PatientInsolesCreateActions> => ({
    type: PatientInsolesCreateActions.CREATE_PATIENT_INSOLE_FAILED, payload: error
});

export const CREATE_PATIENT_INSOLE_SHOW_MODAL = (device: IListProductsResultEntry): StoreAction<PatientInsolesCreateActions> => ({
    type: PatientInsolesCreateActions.SHOW_MODAL, payload: device
});

export const CREATE_PATIENT_INSOLE_HIDE_MODAL = (): StoreAction<PatientInsolesCreateActions> => ({
    type: PatientInsolesCreateActions.HIDE_MODAL
});
