import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import produce from "immer";
import {OrpyxUserDeleteActions} from "./orpyx-user-delete-actions";

export interface OrpyxUserDeleteState extends IModalWindow {
    user: IOrpyxUserDto | undefined,
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrpyxUserDeleteState = {
    ...ModalWindowInitialState,

    user: undefined,
    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrpyxUserDeleteReducers = (state = initialState, action: StoreAction<OrpyxUserDeleteActions>): OrpyxUserDeleteState => {
    return produce(state, (draft: OrpyxUserDeleteState) => {
        switch (action.type) {
            case OrpyxUserDeleteActions.DELETE_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrpyxUserDeleteActions.DELETE_USER_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrpyxUserDeleteActions.DELETE_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrpyxUserDeleteActions.DELETE_USER_SHOW_MODAL:
                draft.actionAfterComplete = action.payload!.actionAfterComplete;
                draft.user = action.payload!.user;

                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrpyxUserDeleteActions.DELETE_USER_HIDE_MODAL:
                ModalWindowStore.onToggleModal(draft, false);
                draft.user = undefined;
                break;
        }
    })
};

export default OrpyxUserDeleteReducers;
