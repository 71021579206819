import {createSelector} from "reselect";
import {ApplicationAdminSharedStoreSelectors} from "../../store/application-admin-shared-store.selectors";
import {PatientDropdownFilterScope} from "./patient-dropdown-filter.types";

export class PatientDropdownFilterSelectors {
    static readonly states = createSelector(ApplicationAdminSharedStoreSelectors.state, state => state.patientDropDownFilter);

    static readonly state = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.states, state => {
        return state[scope];
    });

    static readonly patients = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.state(scope), state => state.patients);

    static readonly error = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.state(scope), state => state.error);
    static readonly loading = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.state(scope), state => state.loading);

    static readonly term = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.state(scope), state => state.term);
    static readonly organizationId = (scope: PatientDropdownFilterScope) => createSelector(PatientDropdownFilterSelectors.state(scope), state => state.organizationId);
}
