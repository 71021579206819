import {createSelector} from "reselect";
import {getAdminStoreState} from "../../store/admin-store-selectors";

export class DashboardUserTitlesSelectors {
    static readonly state = createSelector(getAdminStoreState, state => state.dashboardUserTitles);

    static readonly titles = createSelector(DashboardUserTitlesSelectors.state, state => state.titles);

    static readonly initialized = createSelector(DashboardUserTitlesSelectors.state, state => state.initialized);
    static readonly error = createSelector(DashboardUserTitlesSelectors.state, state => state.error);
}
