import {StoreAction} from "orpyx-web-common";
import {PatientDropdownFilterActions} from "./patient-dropdown-filter.actions";
import produce from "immer";
import {PatientDropdownFilterScope} from "./patient-dropdown-filter.types";
import {initialPatientDropdownFilterState, PatientDropdownFilterState, PatientDropdownFilterReducers} from "./patient-dropdown-filter.reducers";

export interface PatientDropdownFilterStates {
    auditLoggingPage: PatientDropdownFilterState;
}

const initialStates: PatientDropdownFilterStates = {
    auditLoggingPage: initialPatientDropdownFilterState,
};

export const PatientDropdownFiltersReducers = (state = initialStates, action: StoreAction<PatientDropdownFilterActions, any, PatientDropdownFilterScope>): PatientDropdownFilterStates => {
    const scopeName = action.scope!;

    return produce(state, (draft: PatientDropdownFilterStates) => {
        const scopeState: PatientDropdownFilterState = draft[scopeName];
        draft[scopeName] = PatientDropdownFilterReducers(scopeState, action);
    });
};
