import {combineReducers} from "redux";
import OrganizationUsersListReducers from "../organization-users-list/store/organization-users-list-reducers";
import OrganizationUserCreateReducers from "../organization-user-create/store/organization-user-create-reducers";
import OrganizationUserDeleteReducers from "../organization-user-delete/store/organization-user-delete-reducers";
import OrganizationUserRestoreReducers from "../organization-user-restore/store/organization-user-restore.reducers";
import OrganizationUserInformationReducers from "../organization-user-information/store/organization-user-information-page.reducers";
import OrganizationUserSendInviteReducer from "../organization-user-send-invite/store/organization-user-send-invite.reducers";
import OrganizationUserRevokeInviteReducer from "../organization-user-revoke-invite/store/organization-user-revoke-invite.reducers";

export const OrganizationUsersReducers = combineReducers({
    list: OrganizationUsersListReducers,
    create: OrganizationUserCreateReducers,
    delete: OrganizationUserDeleteReducers,
    restore: OrganizationUserRestoreReducers,
    information: OrganizationUserInformationReducers,

    sendInvite: OrganizationUserSendInviteReducer,
    revokeInvite: OrganizationUserRevokeInviteReducer,
});

