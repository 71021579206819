import {combineReducers} from "redux";
import OrpyxUsersListReducer from "../orpyx-users-list/store/orpyx-users-list-reducers";
import OrpyxUserCreateReducer from "../orpyx-user-create/store/orpyx-user-create-reducers";
import OrpyxUserInformationReducers from "../orpyx-user-information/store/orpyx-user-information-reducers";
import OrpyxUserRoleRevokeReducers from "../orpyx-user-role-revoke/store/orpyx-user-role-revoke-reducers";
import {OrpyxUserRoleAssignReducers} from "../orpyx-user-role-assign/store/orpyx-user-role-assign-reducers";
import OrpyxUserDeleteReducers from "../orpyx-user-delete/store/orpyx-user-delete-reducers";
import OrpyxUserSendInviteReducer from "../orpyx-user-send-invite/store/orpyx-user-send-invite.reducers";
import OrpyxUserRevokeInviteReducer from "../orpyx-user-revoke-invite/store/orpyx-user-revoke-invite.reducers";
import OrpyxUserRestoreReducers from "../orpyx-user-restore/store/orpyx-user-restore.reducers";

export const OrpyxUsersReducers = combineReducers({
    create: OrpyxUserCreateReducer,
    list: OrpyxUsersListReducer,
    information: OrpyxUserInformationReducers,
    revokeUserRole: OrpyxUserRoleRevokeReducers,
    assignUserRole: OrpyxUserRoleAssignReducers,
    delete: OrpyxUserDeleteReducers,
    restore: OrpyxUserRestoreReducers,

    revokeInvite: OrpyxUserRevokeInviteReducer,
    sendInvite: OrpyxUserSendInviteReducer
});

