import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {DashboardRole} from "admins-dashboard/http-clients/index";

export enum DashboardRolesActions {
    INITIALIZE = 'DASHBOARD_ROLES: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'DASHBOARD_ROLES: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'DASHBOARD_ROLES: INITIALIZE_FAILED',
}

export const DASHBOARD_ROLES_INITIALIZE = (): StoreAction<DashboardRolesActions> => ({
    type: DashboardRolesActions.INITIALIZE
});

export const DASHBOARD_ROLES_INITIALIZE_SUCCESSFULLY = (roles: DashboardRole[]): StoreAction<DashboardRolesActions> => ({
    type: DashboardRolesActions.INITIALIZE_SUCCESSFULLY, payload: roles
});

export const DASHBOARD_ROLES_INITIALIZE_FAILED = (error: OperationErrorType): StoreAction<DashboardRolesActions> => ({
    type: DashboardRolesActions.INITIALIZE_FAILED, payload: error
});
