import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ICreateOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserCreateActions {
    CREATE_USER = 'ORPYX_USERS_CREATE: CREATE_USER',
    CREATE_USER_SUCCESSFULLY = 'ORPYX_USERS_CREATE: CREATE_USER_SUCCESSFULLY',
    CREATE_USER_FAILED = 'ORPYX_USERS_CREATE: CREATE_USER_FAILED',

    SHOW_MODAL = 'ORPYX_USERS_CREATE: SHOW_MODAL',
    HIDE_MODAL = 'ORPYX_USERS_CREATE: HIDE_MODAL',
}

export const SHOW_MODAL = (): StoreAction<OrpyxUserCreateActions> => ({
    type: OrpyxUserCreateActions.SHOW_MODAL
});

export const HIDE_MODAL = (): StoreAction<OrpyxUserCreateActions> => ({
    type: OrpyxUserCreateActions.HIDE_MODAL
});

export const CREATE_USER = (model: ICreateOrpyxUserDto): StoreAction<OrpyxUserCreateActions> => ({
    type: OrpyxUserCreateActions.CREATE_USER, payload: model
});

export const CREATE_USER_SUCCESSFULLY = (): StoreAction<OrpyxUserCreateActions> => ({
    type: OrpyxUserCreateActions.CREATE_USER_SUCCESSFULLY
});

export const CREATE_USER_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserCreateActions> => ({
    type: OrpyxUserCreateActions.CREATE_USER_FAILED, payload: error
});

