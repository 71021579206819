import {ListOrpyxUserPropertyOrderBy} from "admins-dashboard/http-clients/index";
import {createListSearchOrderItem, ListSearchOrderByItem, ListSearchOrderByItemTextResolver, orderByAlphabetPipe} from "orpyx-web-common";

export const ListOrpyxUserPropertyOrderByPipe: ListSearchOrderByItemTextResolver<ListOrpyxUserPropertyOrderBy> = (order: ListOrpyxUserPropertyOrderBy, orderByAsc?: boolean): string => {
    switch (order) {
        case ListOrpyxUserPropertyOrderBy.OrpyxUserId:
            return 'Id';
        case ListOrpyxUserPropertyOrderBy.FirstName:
            return orderByAlphabetPipe('First name', orderByAsc);
        case ListOrpyxUserPropertyOrderBy.LastName:
            return orderByAlphabetPipe('Last name', orderByAsc);
        case ListOrpyxUserPropertyOrderBy.FullName:
            return orderByAlphabetPipe('Full name', orderByAsc);
        case ListOrpyxUserPropertyOrderBy.Email:
            return 'Email';
        case ListOrpyxUserPropertyOrderBy.CreationDate:
            return 'Date added';
        case ListOrpyxUserPropertyOrderBy.Status:
            return 'Status';
    }
    return `Unknown: ${order}`;
};

export const orpyxUsersListOrderItems: ListSearchOrderByItem[] = [
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.OrpyxUserId, ListOrpyxUserPropertyOrderByPipe),

    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.FirstName, ListOrpyxUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.FirstName, ListOrpyxUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.LastName, ListOrpyxUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.LastName, ListOrpyxUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.FullName, ListOrpyxUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.FullName, ListOrpyxUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.Email, ListOrpyxUserPropertyOrderByPipe),
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.CreationDate, ListOrpyxUserPropertyOrderByPipe),
    createListSearchOrderItem<ListOrpyxUserPropertyOrderBy>(ListOrpyxUserPropertyOrderBy.Status, ListOrpyxUserPropertyOrderByPipe),
];
