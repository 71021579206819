import {combineReducers} from "redux";
import OrpyxCareUserInformationReducers from "../orpyx-care-user-information/store/orpyx-care-user-information-page.reducers";
import OrpyxCareUsersListReducer from "../orpyx-care-care-users-list/store/orpyx-care-users-list-reducer";
import OrpyxCareConfirmationModalReducers from "../orpyx-care-confirmation-modal/store/orpyx-care-confirmation-reducers";

export const OrpyxCareUsersReducers = combineReducers({
    confirmation: OrpyxCareConfirmationModalReducers,
    list: OrpyxCareUsersListReducer,
    information: OrpyxCareUserInformationReducers
});

