import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {
    OrganizationUserRestoreModalEvents,
    OrganizationUserRestoreModalMeta,
    OrganizationUserRestoreModalOwnProps,
    OrganizationUserRestoreModalProps
} from "./organization-user-restore-modal.interface";
import OrganizationUserRestoreSelectors from "../../store/organization-user-restore.selectors";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {OrganizationInformationSelectors} from "../../../../organization-information/store/organization-information-selectors";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";
import {DASHBOARD_USER_RESTORE, DASHBOARD_USER_RESTORE_HIDE_MODAL} from "../../store/organization-user-restore.actions";

const OrganizationUserRestoreModal = (props: OrganizationUserRestoreModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm restore health care practitioner
            </ModalWindowHeader>

            <div> Are you sure you want to restore deleted health care practitioner <b> {fullNamePipe(props.dashboardUser)}</b>?</div>

            <OperationProgress inProcess={props.inProcess} error={props.error}/>
            <ModalWindowSubmitButton
                onSubmit={props.confirmDashboardUserRestore}
                disabled={props.inProcess}
                content={'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationUserRestoreModalOwnProps => {
    return {
        error: OrganizationUserRestoreSelectors.error(state),
        inProcess: OrganizationUserRestoreSelectors.inProcess(state),
        dashboardUser: OrganizationUserRestoreSelectors.dashboardUser(state)!,
        organization: OrganizationInformationSelectors.organization(state)!,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationUserRestoreModalProps): OrganizationUserRestoreModalEvents => {
    return bindActionCreators({
        confirmDashboardUserRestore: DASHBOARD_USER_RESTORE,
    }, dispatch);
};

export const OrganizationUserRestoreModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationUserRestoreModal)),
    closeModalActionCreator: DASHBOARD_USER_RESTORE_HIDE_MODAL,
    modalToggleSelector: OrganizationUserRestoreSelectors.toggle
});
