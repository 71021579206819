import {createStyles, Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";
import {makeStyles} from "@material-ui/core/styles";

export const OrganizationInformationPageStyle = createStyles({
    root: {
        margin: '30px',
    },
    organizationInfo: {
        borderRadius: '4px',
        background: '#575757',
        marginBottom: 20,
        color: '#FFF',
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '18px',
        fontWeight: 100,
        '& span': {
            fontWeight: 500,
            marginRight: 15,
        },
        '& div': {
            marginRight: 40
        },
    },
    content: {
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        padding: '27px 50px',
    },
    title: {
        fontSize: '20px',
        color: Teal,
        float: 'left'
    },
    buttons: {
        float: 'right'
    },
    topBlock: {
        marginBottom: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    table: {},
});

export const useOrganizationInformationPageStyle = makeStyles((theme: Theme) => OrganizationInformationPageStyle);
export default useOrganizationInformationPageStyle;
