import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {PatientsSearchQuery} from "../models/PatientsSearchQuery";
import {IOrganizationOutputDto, IGetPatientsListResult, IGetPatientsListResultPagingResult} from "admins-dashboard/http-clients/index";

export enum PatientsListActions {
    INITIALIZE  = 'PATIENTS_LIST: INITIALIZE',
    INITIALIZE_SUCCESSFULLY  = 'PATIENTS_LIST: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED  = 'PATIENTS_LIST: INITIALIZE_FAILED',

    REFRESH_PATIENTS = 'PATIENTS_LIST: REFRESH_PATIENTS',

    FETCH_PATIENTS = 'PATIENTS_LIST: FETCH_PATIENTS',
    FETCH_PATIENTS_SUCCESSFULLY = 'PATIENTS_LIST: FETCH_PATIENTS_SUCCESSFULLY',
    FETCH_PATIENTS_FAILED = 'PATIENTS_LIST: FETCH_PATIENTS_FAILED',

    SELECT_PATIENT = 'PATIENTS_LIST: SELECT_PATIENT',
}

export const REFRESH_PATIENTS = (): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.REFRESH_PATIENTS
});

export const PATIENTS_INITIALIZE = (organizationId: number| undefined): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.INITIALIZE, payload: organizationId
});

export const PATIENTS_INITIALIZE_SUCCESSFULLY = (organization: IOrganizationOutputDto | undefined): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.INITIALIZE_SUCCESSFULLY, payload: organization
});

export const PATIENTS_INITIALIZE_FAILED = (error: OperationErrorType): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.INITIALIZE_FAILED, payload: error
});

export const FETCH_PATIENTS = (query: PatientsSearchQuery): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS, payload: query
});

export const FETCH_PATIENTS_SUCCESSFULLY = (patients: IGetPatientsListResultPagingResult): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS_SUCCESSFULLY, payload: patients
});

export const PATIENTS_LIST_SELECT_PATIENT = (patient: IGetPatientsListResult): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.SELECT_PATIENT, payload: patient
});

export const FETCH_PATIENTS_FAILED = (error: OperationErrorType): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS_FAILED, payload: error
});
