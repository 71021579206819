import {OrpyxUsersListSearchRoleFilterMeta} from "./orpyx-users-list-search-role-filter.interface";
import * as React from "react";
import {Dispatch, SetStateAction} from "react";
import {parseEnum} from "orpyx-web-common";

import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

const parseSearchRoleFilter = (event: React.ChangeEvent<{ value: unknown }>): OrpyxRoleType | undefined =>{
    if (event.target.value) {
        const orpyxRoleType = parseEnum(OrpyxRoleType, event.target.value as string);
        return orpyxRoleType;
    } else {
        return undefined;
    }
};

export const onOrpyxUsersListSearchRoleFilterChanged = (props: OrpyxUsersListSearchRoleFilterMeta, setSelectedRole: Dispatch<SetStateAction<any>>) => (event: React.ChangeEvent<{ value: unknown }>): void => {
    const orpyxRoleType = parseSearchRoleFilter(event);
    setSelectedRole(orpyxRoleType);
    props.onRoleSelected(orpyxRoleType);
};
