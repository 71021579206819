import {AdminAzureActor} from "../../models/admin-azure-token";
import {JsonParseResult} from "orpyx-web-common";

export class AdminAzureTokenStorage {
    private static readonly keyName = ' AdminAzureToken';

    public static readonly resetActor = (): void => {
        sessionStorage.removeItem(AdminAzureTokenStorage.keyName);
    };

    public static readonly saveActor = (actor: AdminAzureActor): void => {
        sessionStorage.removeItem(AdminAzureTokenStorage.keyName);
        sessionStorage.setItem(AdminAzureTokenStorage.keyName, JSON.stringify(actor));
    };

    public static readonly getActor = (): AdminAzureActor | undefined => {
        const json = sessionStorage.getItem(AdminAzureTokenStorage.keyName);
        if (!json) {
            return undefined;
        }

        const obj: JsonParseResult<AdminAzureActor> = JSON.parse(json);
        if (!obj || !obj.accessToken || !obj.accountIdentifier) {
            return undefined;
        }
        
        const actor: AdminAzureActor = {
            name: obj.name,
            accountIdentifier: obj.accountIdentifier,
            accessToken: {
                token: obj.accessToken.token,
                expiredAt: new Date(obj.accessToken.expiredAt),
            },
        };

        return actor;
    };


}

export default AdminAzureTokenStorage;
