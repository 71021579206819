import {createSelector} from "reselect";
import {getPatientDevicesStoreState} from "../../store/patient-devices-selectors";

export class PatientDevicesUpdateSelectors {
    static readonly state = createSelector(getPatientDevicesStoreState, state => state.update);
    static readonly error = createSelector(PatientDevicesUpdateSelectors.state, state => state.error);
    static readonly device = createSelector(PatientDevicesUpdateSelectors.state, state => state.device);

    static readonly inProcess = createSelector(PatientDevicesUpdateSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(PatientDevicesUpdateSelectors.state, state => state.toggle);
}
