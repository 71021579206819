import {OrpyxRoleType} from "admins-dashboard/http-clients/index";
import AdminOrpyxUsersPolicies from "../policies/admin-orpyx-users-policies";
import {ActionUrl} from "orpyx-web-common";
import AdminAuditLoggingPolicies from "../policies/admin-audit-logging-policies";
import AdminExportRawDataPolicies from "../policies/admin-export-raw-data-policies";

export interface AdminDashboardActionUrl extends ActionUrl {
    readonly requiredRole: OrpyxRoleType | OrpyxRoleType[];
}

export const adminDashboardUrls: AdminDashboardActionUrl[] = [
    {
        url: `/admin/organizations/list`,
        title: 'Organizations',
        requiredRole: [OrpyxRoleType.Manufacturing, OrpyxRoleType.OrganizationsAdmin],
    },
    {
        url: `/admin/organizations/patients`,
        title: 'Patients',
        requiredRole: [OrpyxRoleType.Manufacturing]
    },
    {
        url: `/admin/orpyx-care/users/list`,
        title: 'Orpyx Care Users',
        requiredRole: AdminOrpyxUsersPolicies.List
    },
    {
        url: `/admin/orpyx/users/list`,
        title: 'Orpyx Admin Users',
        requiredRole: [AdminOrpyxUsersPolicies.List]
    },
    {
        url: `/admin/audit-logging`,
        title: 'Audit logging',
        requiredRole: [AdminAuditLoggingPolicies.Export]
    },
    {
        url: `/admin/organizations/patients/statistics/temperature`,
        title: 'Export Temperature',
        requiredRole: AdminExportRawDataPolicies.Allow
    }
];
