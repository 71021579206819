import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FETCH_DASHBOARD_USERS_BEGIN, FETCH_DASHBOARD_USERS_FAILED, FETCH_DASHBOARD_USERS_SUCCESSFULLY, OrganizationUsersListActions, REFRESH_DASHBOARD_USERS} from "./organization-users-list-actions";
import {AdminDashboardUserHttpClient, IListDashboardUsersResultEntry, IListDashboardUsersResultEntryPagingResult, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {convertOffsetToPage, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {OrganizationInformationSelectors} from "../../../organization-information/store/organization-information-selectors";
import OrganizationUsersListSelectors from "./organization-users-list-selectors";
import {DashboardUsersSearchQuery} from "../models/dashboard-users-search-query";
import {OrganizationUserCreateActions} from "../../organization-user-create/store/organization-user-create-actions";
import {OrganizationUserDeleteActions} from "../../organization-user-delete/store/organization-user-delete-actions";
import {OrganizationUserRestoreActions} from "../../organization-user-restore/store/organization-user-restore.actions";
import {push} from "connected-react-router";

const API_VERSIONS = '1';

function* onSelectedOrganizationUser(action: StoreAction<OrganizationUsersListActions, IListDashboardUsersResultEntry>) {
    const user = action.payload!;
    const organization: IOrganizationOutputDto = yield select(OrganizationInformationSelectors.organization)!;
    const url = `/admin/organizations/${organization.id}/doctors/details/${user!.user!.id}`;
    yield put(push(url));
}

function* onRefreshOrganizationUsersList() {
    try {
        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);

        const organization: IOrganizationOutputDto = yield select(OrganizationInformationSelectors.organization)!;
        const searchQuery: DashboardUsersSearchQuery = yield select(OrganizationUsersListSelectors.search)!;

        const data: IListDashboardUsersResultEntryPagingResult = yield call(
            [api, api.getDashboardUsersList],
            organization.id!,
            searchQuery.orderByAsc,
            searchQuery.orderBy,
            searchQuery.term,
            convertOffsetToPage(searchQuery.offset),
            searchQuery.count,
            API_VERSIONS
        );

        yield put(FETCH_DASHBOARD_USERS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_DASHBOARD_USERS_FAILED(e));
    }
}

function* onFetchOrganizationUsersList() {
    //  Enable progress bar
    yield put(FETCH_DASHBOARD_USERS_BEGIN());

    //  Start process fetch users
    yield put(REFRESH_DASHBOARD_USERS());
}

export default function* OrganizationUsersListSagas() {
    yield all([
        yield takeEvery(OrganizationUsersListActions.USER_SELECT, onSelectedOrganizationUser),

        yield takeEvery(OrganizationUserCreateActions.CREATE_DASHBOARD_USER_SUCCESSFULLY, onRefreshOrganizationUsersList),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrganizationUsersList),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrganizationUsersList),

        yield takeEvery(OrganizationUsersListActions.REFRESH_DASHBOARD_USERS, onRefreshOrganizationUsersList),

        yield takeEvery(OrganizationUsersListActions.FETCH_DASHBOARD_USERS, onFetchOrganizationUsersList),
    ]);
}

