import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";
import {PatientDeleteActions} from "./patient-delete.actions";
import produce from "immer";

export interface PatientDeleteState extends IModalWindow {
    patient: IGetPatientsListResult | IGetPatientInformationResult | undefined;
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: PatientDeleteState = {
    ...ModalWindowInitialState,

    actionAfterComplete: StoreActionAfterComplete.None,
    patient: undefined,
};

const PatientDeleteReducer = (state = initialState, action: StoreAction<PatientDeleteActions>): PatientDeleteState => {
    return produce(state, (draft: PatientDeleteState) => {
        switch (action.type) {
            case PatientDeleteActions.PATIENT_DELETE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientDeleteActions.HIDE_MODAL:
            case PatientDeleteActions.PATIENT_DELETE_SUCCESSFULLY:
                draft.patient = undefined;
                draft.actionAfterComplete = StoreActionAfterComplete.None;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientDeleteActions.PATIENT_DELETE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientDeleteActions.SHOW_MODAL:
                draft.patient = action.payload.patient;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

        }
    });
};

export default PatientDeleteReducer;
