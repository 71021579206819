import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientInformationPageEvents, PatientInformationPageMeta, PatientInformationPageOwnProps, PatientInformationPageProps} from "./patient-information-page.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {usePatientInformationPageStyle} from "./patient-information-page.style";
import {Container, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import {PatientInformationSelectors} from "../../store/patient-information-selectors";
import {INITIALIZE_PATIENT_USER, INITIALIZE_PATIENT_USER_FAILED, UPDATE_PATIENT_USER} from "../../store/patient-information-actions";
import {onPatientInformationPageLoaded} from "./patient-information-page.hooks";
import {CREATE_PATIENT_DEVICE_SHOW_MODAL, PatientDevicesCreateActionAfterComplete} from "../../../patient-devices/patient-devices-create/store/patient-devices-create-actions";
import {withRouter} from "react-router";
import {IGetPatientInformationResult} from "admins-dashboard/http-clients/index";
import {
    ApplicationBreadcrumbs,
    ApplicationBreadcrumbsItem,
    deactivatedSuffixPipe,
    fullNamePipe,
    LazyLoader,
    OperationProgress,
    OperationSkeleton,
    StoreActionAfterComplete
} from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {PatientInformationPageActions} from "../patient-information-page-actions/patient-information-page-actions";
import {PATIENT_DELETE_SHOW_MODAL} from "../../../patient-delete/store/patient-delete.actions";
import {PATIENT_RESTORE_SHOW_MODAL} from "../../../patient-restore/store/patient-restore.actions";

const PatientInformationFormWrapper = React.lazy(() => import("../patient-information-form/patient-information-form"));
const PatientInformationPageDevices = React.lazy(() => import("../patient-information-page-devices/patient-information-page-devices"));


const generatePageBreadcrumbs = (patient: IGetPatientInformationResult | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Patients',
        url: '/admin/organizations/patients'
    }];

    if (patient && patient.organization) {
        items.push({
            name: deactivatedSuffixPipe(patient.organization.name!, patient.organization.deleted!),
            url: `/admin/organizations/${patient.organization.id}/patients`
        });

        items.push({
            name: fullNamePipe(patient.patient)!,
            url: `/admin/organizations/${patient.organization.id}/patients/details/${patient.patient!.patientId}`
        });
    }

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationPageOwnProps => {
    return {
        error: PatientInformationSelectors.error(state),
        inProcess: PatientInformationSelectors.inProcess(state),
        initialized: PatientInformationSelectors.initialized(state),

        patient: PatientInformationSelectors.patient(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInformationPageProps): PatientInformationPageEvents => {
    return bindActionCreators({
        updatePatient: UPDATE_PATIENT_USER,
        loadPatientInformation: INITIALIZE_PATIENT_USER,
        showErrorMessage: INITIALIZE_PATIENT_USER_FAILED,
        openCreateDeviceModal: CREATE_PATIENT_DEVICE_SHOW_MODAL(PatientDevicesCreateActionAfterComplete.RefreshUserInformation),
        showDeletePatientModal: PATIENT_DELETE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
        showRestorePatientModal: PATIENT_RESTORE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
    }, dispatch);
};

const PatientInformationContent = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: PatientInformationPageMeta) => {
    const classes = usePatientInformationPageStyle();
    const canManagePatientInformation = !props.patient.organization!.deleted! && !props.patient.patient!.deleted!;

    return (<Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.patient)}/>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    Patient information
                </div>

                <LazyLoader height={80} rows={4}>
                    <PatientInformationFormWrapper
                        canManagePatientInformation={canManagePatientInformation}
                        patient={props.patient}
                        inProcess={props.inProcess}
                        error={props.error}
                        onSubmit={props.updatePatient}
                    />
                </LazyLoader>
            </Paper>

            <PatientInformationPageActions
                patient={props.patient}
                showDeletePatientModal={props.showDeletePatientModal}
                showRestorePatientModal={props.showRestorePatientModal}
            />

        </Grid>
        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    Patient devices
                </div>

                <LazyLoader height={80} rows={4}>
                    <PatientInformationPageDevices
                        canManagePatientDevices={canManagePatientInformation}
                        openCreateDeviceModal={props.openCreateDeviceModal}
                        patient={props.patient}
                    />
                </LazyLoader>
            </Paper>
        </Grid>

    </Grid>);
})));

const PatientInformationError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: PatientInformationPageMeta) => {
    const classes = usePatientInformationPageStyle();

    return (<Grid container className={classes.root} spacing={4}>

        <Grid item xs={12}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.patient)}/>
        </Grid>

        <Grid item xs={3}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    Patient information
                </div>

                <OperationProgress initialized={props.initialized} error={props.error}/>
                <OperationSkeleton height={80} rows={4}/>
            </Paper>
        </Grid>
        <Grid item xs={9}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    Patient devices
                </div>

                <OperationSkeleton height={80} rows={4}/>
            </Paper>
        </Grid>

    </Grid>);
})));

const PatientInformationPage = (props: PatientInformationPageMeta) => {
    useEffect(onPatientInformationPageLoaded(props), []);

    return (
        <Container>
            {props.initialized ? <PatientInformationContent/> : <PatientInformationError/>}
        </Container>
    );
};


export const PatientInformationPageWrapper = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientInformationPage)));
