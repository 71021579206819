import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {StoreAction} from "orpyx-web-common";

export enum OrganizationUserRevokeInviteActions {

    DASHBOARD_USER_REVOKE_INVITE = 'DASHBOARD_USER_REVOKE_INVITE: DASHBOARD_USER_REVOKE_INVITE',
    DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY = 'DASHBOARD_USER_REVOKE_INVITE: DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY',
    DASHBOARD_USER_REVOKE_INVITE_FAILED = 'DASHBOARD_USER_REVOKE_INVITE: DASHBOARD_USER_REVOKE_INVITE_FAILED',
}

export const DASHBOARD_USER_REVOKE_INVITE = (organization: IOrganizationOutputDto, user: IUserOutputDto): StoreAction<OrganizationUserRevokeInviteActions> => ({
    type: OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE, payload: {user, organizationId: organization.id}
});

export const DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY = (user: IUserOutputDto): StoreAction<OrganizationUserRevokeInviteActions> => ({
    type: OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY, payload: user
});

export const DASHBOARD_USER_REVOKE_INVITE_FAILED = (user: IUserOutputDto): StoreAction<OrganizationUserRevokeInviteActions> => ({
    type: OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE_FAILED, payload: user
});

