import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationUserDeleteModalEvents, OrganizationUserDeleteModalMeta, OrganizationUserDeleteModalOwnProps, OrganizationUserDeleteModalProps} from "./organization-user-delete-modal.interface";
import OrganizationUserDeleteSelectors from "../../store/organization-user-delete-selectors";
import {DELETE_DASHBOARD_USER, DELETE_DASHBOARD_USER_HIDE_MODAL} from "../../store/organization-user-delete-actions";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {OrganizationInformationSelectors} from "../../../../organization-information/store/organization-information-selectors";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";

const OrganizationUserDeleteModal = (props: OrganizationUserDeleteModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm delete health care practitioner
            </ModalWindowHeader>

            <div> Are you sure you want to delete health care practitioner <b> {fullNamePipe(props.dashboardUser)}</b>?</div>

            <OperationProgress inProcess={props.inProcess} error={props.error}/>
            <ModalWindowSubmitButton
                onSubmit={props.confirmUserDelete}
                disabled={props.inProcess}
                content={'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationUserDeleteModalOwnProps => {
    return {
        error: OrganizationUserDeleteSelectors.error(state),
        inProcess: OrganizationUserDeleteSelectors.inProcess(state),
        dashboardUser: OrganizationUserDeleteSelectors.dashboardUser(state)!,
        organization: OrganizationInformationSelectors.organization(state)!,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationUserDeleteModalProps): OrganizationUserDeleteModalEvents => {
    return bindActionCreators({
        confirmUserDelete: DELETE_DASHBOARD_USER,
    }, dispatch);
};

export const OrganizationUserDeleteModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationUserDeleteModal)),
    closeModalActionCreator: DELETE_DASHBOARD_USER_HIDE_MODAL,
    modalToggleSelector: OrganizationUserDeleteSelectors.isModalActive
});
