import OrganizationUsersListSagas from "../organization-users-list/store/organization-users-list-sagas";
import {all} from 'redux-saga/effects';
import OrganizationUserCreateSagas from "../organization-user-create/store/organization-user-create-sagas";
import OrganizationUserDeleteSagas from "../organization-user-delete/store/organization-user-delete-sagas";
import OrganizationUserRestoreSagas from "../organization-user-restore/store/organization-user-restore.sagas";
import OrganizationUserInformationSages from "../organization-user-information/store/organization-user-information-page.sagas";
import OrganizationUserRevokeInviteSagas from "../organization-user-revoke-invite/store/organization-user-revoke-invite.sagas";
import OrganizationUserSendInviteSagas from "../organization-user-send-invite/store/organization-user-send-invite.sagas";

export default function* OrganizationUsersSagas() {
    yield all([
        OrganizationUsersListSagas(),
        OrganizationUserCreateSagas(),
        OrganizationUserInformationSages(),

        OrganizationUserDeleteSagas(),
        OrganizationUserRestoreSagas(),

        OrganizationUserRevokeInviteSagas(),
        OrganizationUserSendInviteSagas(),
    ]);
}
