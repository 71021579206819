import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ISearchPatientInsolesResponseEntry} from "admins-dashboard/http-clients/index";
import {PatientInsolesAutoCompleteScope} from "./patient-insoles-auto-complete.types";

export enum PatientInsolesAutoCompleteActions {
    SEARCH_PATIENT_INSOLES = 'SEARCH_PATIENT_INSOLES: SEARCH_PATIENT_INSOLES',
    SEARCH_PATIENT_INSOLES_SUCCESSFULLY = 'SEARCH_PATIENT_INSOLES: SEARCH_PATIENT_INSOLES_SUCCESSFULLY',
    SEARCH_PATIENT_INSOLES_FAILED = 'SEARCH_PATIENT_INSOLES: SEARCH_PATIENT_INSOLES_FAILED',
}

export const SEARCH_PATIENT_INSOLES = (scope: PatientInsolesAutoCompleteScope) => (term: string | undefined | null): StoreAction<PatientInsolesAutoCompleteActions> => ({
    type: PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES, payload: term, scope
});

export const SEARCH_PATIENT_INSOLES_SUCCESSFULLY = (scope: PatientInsolesAutoCompleteScope) => (insoles: ISearchPatientInsolesResponseEntry[]): StoreAction<PatientInsolesAutoCompleteActions> => ({
    type: PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES_SUCCESSFULLY, payload: insoles, scope
});

export const SEARCH_PATIENT_INSOLES_FAILED = (scope: PatientInsolesAutoCompleteScope) => (error: OperationErrorType): StoreAction<PatientInsolesAutoCompleteActions> => ({
    type: PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES_FAILED, payload: error, scope
});
