import {IOrpyxUserDtoPagingResult} from "admins-dashboard/http-clients/index";
import {OrpyxUsersSearchQuery} from "../models/orpyx-users-search-query";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrpyxUsersListActions {
    FETCH_USERS = 'ORPYX_USERS_LIST: FETCH_USERS',
    FETCH_USERS_BEGIN = 'ORPYX_USERS_LIST: FETCH_USERS_BEGIN',
    FETCH_USERS_SUCCESSFULLY = 'ORPYX_USERS_LIST: FETCH_USERS_SUCCESSFULLY',
    FETCH_USERS_FAILED = 'ORPYX_USERS_LIST: FETCH_USERS_FAILED',
}

export const FETCH_USERS = (query: OrpyxUsersSearchQuery): StoreAction<OrpyxUsersListActions> => ({
    type: OrpyxUsersListActions.FETCH_USERS, payload: query
});

export const FETCH_USERS_BEGIN = (): StoreAction<OrpyxUsersListActions> => ({
    type: OrpyxUsersListActions.FETCH_USERS_BEGIN,
});

export const FETCH_USERS_SUCCESSFULLY = (users: IOrpyxUserDtoPagingResult): StoreAction<OrpyxUsersListActions> => ({
    type: OrpyxUsersListActions.FETCH_USERS_SUCCESSFULLY, payload: users
});

export const FETCH_USERS_FAILED = (error: OperationErrorType): StoreAction<OrpyxUsersListActions> => ({
    type: OrpyxUsersListActions.FETCH_USERS_FAILED, payload: error
});
