import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminProductHttpClient, CreateProductWith2InsolesDto, ICreateProductWith2InsolesDto, IListProductsResultEntry, IUpdateProductResult, IGetPatientInformationResult} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {PatientDevicesUpdateActions, UPDATE_PATIENT_DEVICE_FAILED, UPDATE_PATIENT_DEVICE_SUCCESSFULLY} from "./patient-devices-update-actions";
import {PatientDevicesUpdateSelectors} from "./patient-devices-update-selectors";
import {PatientInformationSelectors} from "../../../patient-information/store/patient-information-selectors";

const API_VERSIONS = '1';

function* onUpdatePatientDevice(action: StoreAction<PatientDevicesUpdateActions, ICreateProductWith2InsolesDto>) {
    try {
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient)!;
        const device: IListProductsResultEntry = yield select(PatientDevicesUpdateSelectors.device)!;
        const model = new CreateProductWith2InsolesDto(action.payload!);
        const api = new AdminProductHttpClient(ORPYXSI_ADMIN_URL);

        const data: IUpdateProductResult = yield call(
            [api, api.updatePatientProduct],
            patient.patient!.patientId!,
            device.productId!,
            API_VERSIONS,
            model
        );

        yield put(UPDATE_PATIENT_DEVICE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully updated product with sn ${data.productSerialNumber}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(UPDATE_PATIENT_DEVICE_FAILED(e));
    }
}

export default function* PatientDevicesUpdateSagas() {
    yield all([
        takeEvery(PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE, onUpdatePatientDevice),
    ]);
}

