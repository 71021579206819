import {createSelector} from "reselect";
import {getPatientsStoreState} from "../../store/patients-selectors";
import {getPagesCount} from "orpyx-web-common";

export class PatientsListSelectors {
    static readonly state = createSelector(getPatientsStoreState, state => state.list);

    static readonly organization = createSelector(PatientsListSelectors.state, state => state.organization);

    static readonly patients = createSelector(PatientsListSelectors.state, state => state.patients);
    static readonly error = createSelector(PatientsListSelectors.state, state => state.error);
    static readonly search = createSelector(PatientsListSelectors.state, state => state.search);

    static readonly initialized = createSelector(PatientsListSelectors.state, state => state.initialized);
    static readonly loading = createSelector(PatientsListSelectors.state, state => state.loading);
    static readonly total = createSelector(PatientsListSelectors.state, state => state.total);

    static readonly pages = createSelector(PatientsListSelectors.state, state => getPagesCount(state.total, state.search.count));
}
