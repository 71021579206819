import {OrpyxUsersListSearchRoleFilterMeta} from "./orpyx-users-list-search-role-filter.interface";
import * as React from "react";
import {memo, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useOrpyxUsersListPageStyle} from "../orpyx-users-list-page/orpyx-users-list-page.style";
import {onOrpyxUsersListSearchRoleFilterChanged} from "./orpyx-users-list-search-role-filter.hooks";
import {convertToDefaultInputValue} from "orpyx-web-common";

export const OrpyxUsersListSearchRoleFilter = memo((props: OrpyxUsersListSearchRoleFilterMeta) => {
    const classes = useOrpyxUsersListPageStyle();

    const [selectedRole, setRole] = useState(props.orpyxRoleType);
    const onRoleSelected = onOrpyxUsersListSearchRoleFilterChanged(props, setRole);
    const selectedRoleValue = convertToDefaultInputValue(selectedRole, 0);

    return (
        <div className={classes.sort}>
            <span>
                Role
            </span>

            <FormControl variant="outlined" className={classes.formControl}>
                <Select onChange={event => onRoleSelected(event)} value={selectedRoleValue}>
                    <MenuItem value="0">
                        <em>None</em>
                    </MenuItem>

                    {props.roles.map(role => (<MenuItem
                        key={role.orpyxRoleId}
                        value={role.role}>
                        {role.name}
                    </MenuItem>))}

                </Select>
            </FormControl>

        </div>
    );
});
