import {IPatientInformationPageActionsProps} from "./patient-information-page-actions.interface";
import Paper from "@material-ui/core/Paper";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import * as React from "react";
import {usePatientInformationPageStyle} from "../patient-information-page/patient-information-page.style";
import AdminPatientsPolicies from "../../../../policies/admin-patients-policies";
import {PatientDeleteModalWindow} from "../../../patient-delete/components/patient-delete-modal/patient-delete-modal";
import {PatientRestoreModalWindow} from "../../../patient-restore/components/patient-restore-modal/patient-restore-modal";
import {ActionButton} from "orpyx-web-common";

export const PatientInformationPageActions = (props: IPatientInformationPageActionsProps) => {
    const classes = usePatientInformationPageStyle();

    return (
        <Paper className={classes.paper}>
            <div className={classes.title}>
                Patient actions
            </div>

            {props.patient.patient!.deleted ? <AdminBlockWrapper requiredRole={AdminPatientsPolicies.Restore}>
                <PatientRestoreModalWindow/>

                <ActionButton
                    disabled={props.patient.organization!.deleted!}
                    onSubmit={() => props.showRestorePatientModal(props.patient)}
                    content={"Restore patient"}
                />

            </AdminBlockWrapper> : <AdminBlockWrapper requiredRole={AdminPatientsPolicies.Delete}>
                <PatientDeleteModalWindow/>

                <ActionButton
                    disabled={props.patient.organization!.deleted!}
                    onSubmit={() => props.showDeletePatientModal(props.patient)}
                    content={" Delete patient"}
                />
            </AdminBlockWrapper>}

        </Paper>
    );
};