import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrganizationInformationDto} from "admins-dashboard/http-clients/index";

export enum OrganizationCreateActions {
    CREATE_ORGANIZATION = 'ORGANIZATION_CREATE: CREATE_ORGANIZATION',
    CREATE_ORGANIZATION_SUCCESSFULLY = 'ORGANIZATION_CREATE: CREATE_ORGANIZATION_SUCCESSFULLY',
    CREATE_ORGANIZATION_FAILED = 'ORGANIZATION_CREATE: CREATE_ORGANIZATION_FAILED',

    SHOW_MODAL = 'ORGANIZATION_CREATE: SHOW_MODAL',
    HIDE_MODAL = 'ORGANIZATION_CREATE: HIDE_MODAL',
}


export const CREATE_ORGANIZATION = (model: IOrganizationInformationDto): StoreAction<OrganizationCreateActions> => ({
    type: OrganizationCreateActions.CREATE_ORGANIZATION, payload: model
});

export const CREATE_ORGANIZATION_SUCCESSFULLY = (): StoreAction<OrganizationCreateActions> => ({
    type: OrganizationCreateActions.CREATE_ORGANIZATION_SUCCESSFULLY
});

export const CREATE_ORGANIZATION_FAILED = (error: OperationErrorType): StoreAction<OrganizationCreateActions> => ({
    type: OrganizationCreateActions.CREATE_ORGANIZATION_FAILED, payload: error
});

export const CREATE_ORGANIZATION_SHOW_MODAL = (): StoreAction<OrganizationCreateActions> => ({
    type: OrganizationCreateActions.SHOW_MODAL
});

export const CREATE_ORGANIZATION_HIDE_MODAL = (): StoreAction<OrganizationCreateActions> => ({
    type: OrganizationCreateActions.HIDE_MODAL
});
