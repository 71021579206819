import {createSelector} from "reselect";
import {getPatientDevicesStoreState} from "../../store/patient-devices-selectors";

export class PatientInsolesDeleteSelectors {
    static readonly state = createSelector(getPatientDevicesStoreState, state => state.deleteInsole);
    static readonly error = createSelector(PatientInsolesDeleteSelectors.state, state => state.error);

    static readonly device = createSelector(PatientInsolesDeleteSelectors.state, state => state.device);
    static readonly insole = createSelector(PatientInsolesDeleteSelectors.state, state => state.insole);

    static readonly inProcess = createSelector(PatientInsolesDeleteSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(PatientInsolesDeleteSelectors.state, state => state.toggle);
}
