import {IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientDevicesListActions {
    FETCH_PATIENT_DEVICES = 'ORGANIZATION_PATIENT_DEVICES_LIST: FETCH_PATIENT_DEVICES',
    FETCH_PATIENT_DEVICES_SUCCESSFULLY = 'ORGANIZATION_PATIENT_DEVICES_LIST: FETCH_PATIENT_DEVICES_SUCCESSFULLY',
    FETCH_PATIENT_DEVICES_FAILED = 'ORGANIZATION_PATIENT_DEVICES_LIST: FETCH_PATIENT_DEVICES_FAILED',
}

export const FETCH_PATIENT_DEVICES = (): StoreAction<PatientDevicesListActions> => ({
    type: PatientDevicesListActions.FETCH_PATIENT_DEVICES
});

export const FETCH_PATIENT_DEVICES_SUCCESSFULLY = (devices: IListProductsResultEntry[]): StoreAction<PatientDevicesListActions> => ({
    type: PatientDevicesListActions.FETCH_PATIENT_DEVICES_SUCCESSFULLY, payload: devices
});

export const FETCH_PATIENT_DEVICES_FAILED = (error: OperationErrorType): StoreAction<PatientDevicesListActions> => ({
    type: PatientDevicesListActions.FETCH_PATIENT_DEVICES_FAILED, payload: error
});
