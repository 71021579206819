import * as React from 'react';
import {Theme, Typography} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Teal} from "orpyx-web-common";
import {Menu} from '../../components/Menu';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: '30px',
    },
    title: {
        color: Teal,
        fontSize: 'larger',
    },
    text: {
        marginTop: '20px',
        textAlign: 'justify',
    },
    link: {
        color: Teal,
        textDecoration: 'none'
    },
    content:{
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        maxWidth: 900,
        padding: '27px 50px',
        margin: '0 auto',
    },
}));

function Support() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Menu />
            <div className={classes.content}>
                <Typography className={classes.title}>Orpyx Customer Care</Typography>
                <Typography className={classes.text}>
                    Orpyx is happy to provide assistance for anyone who has questions about
                    or is experiencing technical difficulties with our products. 
                    Our knowledgeable support staff will be happy to help you with any questions. 
                    Email or call us and we’ll get back to you within one business day.
                </Typography>
                <Typography className={classes.text}>
                    Orpyx Customer Care Hours:<br/>
                </Typography>
                <Typography className={classes.text}>
                    Monday - Friday: 9:00AM - 5:00PM Mountain time (excluding stat holidays)<br/>
                </Typography>
                <Typography className={classes.text}>
                    Email: <a href="mailto:support@orpyx.com" className={classes.link}>support@orpyx.com</a><br/>
                    Call us: +1.855.996.7799<br/>
                    <a href="https://www.orpyx.zendesk.com" className={classes.link}>www.orpyx.zendesk.com</a>
                </Typography>
            </div>
        </div>
    );
}

export default Support;
