import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";

export enum OrganizationUserRestoreActions {
    DASHBOARD_USER_RESTORE = 'DASHBOARD_USER_RESTORE: DASHBOARD_USER_RESTORE',
    DASHBOARD_USER_RESTORE_SUCCESSFULLY = 'DASHBOARD_USER_RESTORE: DASHBOARD_USER_RESTORE_SUCCESSFULLY',
    DASHBOARD_USER_RESTORE_FAILED = 'DASHBOARD_USER_RESTORE: DASHBOARD_USER_RESTORE_FAILED',

    SHOW_MODAL = 'DASHBOARD_USER_RESTORE: SHOW_MODAL',
    HIDE_MODAL = 'DASHBOARD_USER_RESTORE: HIDE_MODAL',
}

export const DASHBOARD_USER_RESTORE = (): StoreAction<OrganizationUserRestoreActions> => ({
    type: OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE
});

export const DASHBOARD_USER_RESTORE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrganizationUserRestoreActions> => ({
    type: OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_SUCCESSFULLY, payload: actionAfterComplete
});

export const DASHBOARD_USER_RESTORE_FAILED = (error: OperationErrorType): StoreAction<OrganizationUserRestoreActions> => ({
    type: OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_FAILED, payload: error
});

export const DASHBOARD_USER_RESTORE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (organization: IOrganizationOutputDto, user: IUserOutputDto): StoreAction<OrganizationUserRestoreActions> => ({
    type: OrganizationUserRestoreActions.SHOW_MODAL, payload: {organization, user, actionAfterComplete}
});

export const DASHBOARD_USER_RESTORE_HIDE_MODAL = (): StoreAction<OrganizationUserRestoreActions> => ({
    type: OrganizationUserRestoreActions.HIDE_MODAL
});
