import {default as React, useEffect, useState} from "react";
import {Field, withFormik} from "formik";
import FormLabel from '@material-ui/core/FormLabel';
import {TextField} from "formik-material-ui";
import {Box} from "@material-ui/core";
import {getFirstMonthDay, getLastMonthDay, isNullOrEmpty, ModalWindowSubmitButton, OperationProgress, PropertyNamePipe, useModalWindowStyle} from "orpyx-web-common";
import {onSubmitPatientsRawTemperatureForm} from "./patients-raw-temperature-form.hooks";
import {PatientsRawTemperatureFormMeta, PatientsRawTemperatureFormProps, PatientsRawTemperatureFormValues} from "./patients-raw-temperature-form.interface";
import {PatientsRawTemperatureFormValidator} from "./patients-raw-temperature-form.validator";
import moment from "moment";
import {PatientInsolesAutoComplete} from "../../../../shared/patient-insoles-auto-complete/components/patient-insoles-auto-complete";
import { AutocompleteSelectValue } from "modules/admin/shared/autocomplete-select/autocomplete-select.interface";

const PatientsRawTemperatureFormTemplate = (props: PatientsRawTemperatureFormMeta) => {
    const classes = useModalWindowStyle();
    useEffect(() => {
        props.setSubmitting(props.inProcess)
    }, [props.inProcess]);

    const onInsolesSelected = (insoles: AutocompleteSelectValue[] | null) => {
        props.values.insolesSerialNumbers = insoles?.length ? insoles.map(x => x.name) : [];
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <Box display="flex" flexDirection="row" mb={3}>
                <Box width="40%">
                    <h3 className={props.classes.header}>
                        Date
                    </h3>

                    <div className={props.classes.group}>

                        <FormLabel required={true}>
                            Begin
                        </FormLabel>
                        <Field
                            type="date"
                            component={TextField}
                            className={classes.input}
                            placeholder={'Enter date'}
                            name={PropertyNamePipe(props.values, 'begin')}
                        />
                    </div>

                    <div className={props.classes.group}>
                        <FormLabel required={true}>
                            End
                        </FormLabel>
                        <Field
                            type="date"
                            component={TextField}
                            className={classes.input}
                            placeholder={'Enter date'}
                            name={PropertyNamePipe(props.values, 'end')}
                        />
                    </div>
                </Box>

                <Box ml={5} width="60%">
                    <h3 className={props.classes.header}>
                        Insole Serial Numbers
                    </h3>

                    <div className={props.classes.group}>

                        <PatientInsolesAutoComplete
                            onSelected={onInsolesSelected}
                            scope={"common"}
                        />
                    </div>
                </Box>

            </Box>


            <OperationProgress error={props.error} inProcess={props.isSubmitting}/>

            <ModalWindowSubmitButton
                disabled={props.isSubmitting}
                content={'Submit'}
            />
        </form>
    );
};

const PatientsRawTemperatureForm = withFormik<PatientsRawTemperatureFormProps, PatientsRawTemperatureFormValues>({

    validate: PatientsRawTemperatureFormValidator,
    handleSubmit: onSubmitPatientsRawTemperatureForm,

    mapPropsToValues: (props: PatientsRawTemperatureFormProps): PatientsRawTemperatureFormValues => ({
        begin: getFirstMonthDay(moment())!.format("yyyy-MM-DD"),
        end: getLastMonthDay(moment())!.format("yyyy-MM-DD"),
        insolesSerialNumbers: []
    }),
})(PatientsRawTemperatureFormTemplate);


export default PatientsRawTemperatureForm;


