import {createSelector} from "reselect";
import {OrganizationUsersSelectors} from "../../store/organization-users-selectors";

export class OrganizationUserCreateSelectors {

    static readonly state = createSelector(OrganizationUsersSelectors.state, state => state.create);
    static readonly error = createSelector(OrganizationUserCreateSelectors.state, state => state.error);

    static readonly inProcess = createSelector(OrganizationUserCreateSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(OrganizationUserCreateSelectors.state, state => state.toggle);

    static readonly organization = createSelector(OrganizationUserCreateSelectors.state, state => state.organization);
}

export default OrganizationUserCreateSelectors;
