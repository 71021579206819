import {all} from 'redux-saga/effects';
import PatientDevicesListSagas from "../patient-devices-list/store/patient-devices-list-sagas";
import PatientDevicesCreateSagas from "../patient-devices-create/store/patient-devices-create-sagas";
import PatientInsolesCreateSagas from "../patient-insoles-create/store/patient-insoles-create-sagas";
import PatientDevicesUpdateSagas from "../patient-devices-update/store/patient-devices-update-sagas";
import PatientInsolesDeleteSagas from "../patient-insoles-delete/store/patient-insoles-delete-sagas";

export function* PatientDevicesSagas() {
    yield all([
        PatientDevicesListSagas(),
        PatientDevicesCreateSagas(),
        PatientDevicesUpdateSagas(),

        PatientInsolesCreateSagas(),
        PatientInsolesDeleteSagas(),
    ]);
}
