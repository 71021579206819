import produce from "immer";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {OrganizationsSearchQuery} from "../models/organizations-search-query";
import {OrganizationsListActions} from "./organizations-list-actions";

export interface OrganizationsListState {
    initialized: boolean;
    loading: boolean;
    total: number,
    organizations: IOrganizationOutputDto[];
    error: OperationErrorType | undefined;
    search: OrganizationsSearchQuery;
}

const initialState: OrganizationsListState = {
    initialized: false,
    loading: false,
    total: 0,
    search: new OrganizationsSearchQuery(),
    organizations: [],
    error: undefined,
};

export const OrganizationsListReducers = (state = initialState, action: StoreAction<OrganizationsListActions>): OrganizationsListState => {
    return produce(state, (draft: OrganizationsListState) => {
        switch (action.type) {
            case OrganizationsListActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.search = action.payload;
                break;
            case OrganizationsListActions.FETCH_ORGANIZATIONS_BEGIN:
                draft.loading = true;
                break;
            case OrganizationsListActions.FETCH_ORGANIZATIONS_SUCCESSFULLY:
                draft.organizations = action.payload.items;
                draft.total = action.payload.total;
                draft.error = undefined;
                draft.loading = false;
                break;
            case OrganizationsListActions.FETCH_ORGANIZATIONS_FAILED:
                draft.organizations = [];
                draft.total = 0;
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    })
};

export default OrganizationsListReducers;
