import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {AdminOrpyxUsersHttpClient, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete} from "orpyx-web-common";
import {OrpyxUserRestoreActions, RESTORE_ORPYX_USER_FAILED, RESTORE_ORPYX_USER_SUCCESSFULLY} from "./orpyx-user-restore.actions";
import OrpyxUserRestoreSelectors from "./orpyx-user-restore.selectors";

const API_VERSIONS = '1';

function* onOrpyxUserRestore() {
    try {
        const user: IOrpyxUserDto = yield select(OrpyxUserRestoreSelectors.user);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrpyxUserRestoreSelectors.actionAfterComplete);

        const userId = user.orpyxUserId!;

        const api = new AdminOrpyxUsersHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrpyxUserDto = yield call(
            [api, api.restoreOrpyxUser],
            userId,
            API_VERSIONS
        );

        yield put(RESTORE_ORPYX_USER_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `User ${fullNamePipe(data)} successfully restored after deleted`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(RESTORE_ORPYX_USER_FAILED(e));
    }
}

export default function* OrpyxUserRestoreSagas() {
    yield all([
        yield takeEvery(OrpyxUserRestoreActions.RESTORE_ORPYX_USER, onOrpyxUserRestore),

    ]);
}

