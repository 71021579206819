import produce from "immer";
import {OrganizationUpdateActions} from "./organization-update.actions";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export interface OrganizationUpdateState extends IModalWindow {
    initialized: boolean;

    actionAfterComplete: StoreActionAfterComplete;
    organization: IOrganizationOutputDto | undefined;
}

const initialState: OrganizationUpdateState = {
    ...ModalWindowInitialState,

    initialized: false,

    actionAfterComplete: StoreActionAfterComplete.None,
    organization: undefined,
};

const OrganizationUpdateReducers = (state = initialState, action: StoreAction<OrganizationUpdateActions>): OrganizationUpdateState => {
    return produce(state, (draft: OrganizationUpdateState) => {
        switch (action.type) {
            case OrganizationUpdateActions.SHOW_MODAL:
                draft.initialized = false;
                draft.organization = undefined;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationUpdateActions.ORGANIZATION_INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.organization = action.payload;
                break;

            case OrganizationUpdateActions.ORGANIZATION_INITIALIZE_FAILED:
                draft.initialized = false;
                draft.error = action.payload;
                break;

            case OrganizationUpdateActions.ORGANIZATION_UPDATE_SUCCESSFULLY:
            case OrganizationUpdateActions.HIDE_MODAL:
                draft.organization = undefined;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationUpdateActions.ORGANIZATION_UPDATE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationUpdateActions.ORGANIZATION_UPDATE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    });
};
export default OrganizationUpdateReducers;
