import {StoreAction} from "orpyx-web-common";
import {OrganizationDropdownFilterActions} from "./organization-dropdown-filter-actions";
import {initialOrganizationDropdownFilterState, OrganizationDropdownFilterReducers, OrganizationDropdownFilterState} from "./organization-dropdown-filter-reducers";
import produce from "immer";
import {OrganizationDropdownFilterScope} from "./organization-dropdown-filters.types";

export interface OrganizationDropdownFilterStates {
    patientsListPage: OrganizationDropdownFilterState;
    patientsCreateForm: OrganizationDropdownFilterState;
    auditLoggingPage: OrganizationDropdownFilterState;
    orpyxCareUserInformationPage: OrganizationDropdownFilterState;
}

const initialStates: OrganizationDropdownFilterStates = {
    patientsCreateForm: initialOrganizationDropdownFilterState,
    patientsListPage: initialOrganizationDropdownFilterState,
    auditLoggingPage: initialOrganizationDropdownFilterState,
    orpyxCareUserInformationPage: initialOrganizationDropdownFilterState,
};

export const OrganizationDropdownFiltersReducers = (state = initialStates, action: StoreAction<OrganizationDropdownFilterActions, any, OrganizationDropdownFilterScope>): OrganizationDropdownFilterStates => {
    const scopeName = action.scope!;

    return produce(state, (draft: OrganizationDropdownFilterStates) => {
        const scopeState: OrganizationDropdownFilterState = draft[scopeName];
        draft[scopeName] = OrganizationDropdownFilterReducers(scopeState, action);
    });
};
