import produce from "immer";
import {IDashboardUserTitleOutputDto,} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {DashboardUserTitlesActions} from "./dashboard-user-titles-actions";

export interface DashboardUserTitlesState {
    initialized: boolean;
    titles: IDashboardUserTitleOutputDto[];
    error?: OperationErrorType;
}

const initialState: DashboardUserTitlesState = {
    initialized: false,
    titles: []
};

const DashboardUserTitlesReducers = (state = initialState, action:  StoreAction<DashboardUserTitlesActions>): DashboardUserTitlesState => {
    return produce(state, (draft: DashboardUserTitlesState) => {
        switch (action.type) {
            case DashboardUserTitlesActions.INITIALIZE:
                draft.initialized = false;
                draft.error = undefined;

                break;

            case DashboardUserTitlesActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.titles = action.payload;
                draft.error = undefined;
                break;

            case DashboardUserTitlesActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.error = action.payload;
                draft.titles = [];
                break;
        }
    })
};

export default DashboardUserTitlesReducers;
