import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientRestoreModalEvents, PatientRestoreModalMeta, PatientRestoreModalOwnProps, PatientRestoreModalProps} from "./patient-restore-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import PatientRestoreSelectors from "../../store/patient-restore.selectors";
import {PATIENT_RESTORE, PATIENT_RESTORE_HIDE_MODAL} from "../../store/patient-restore.actions";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";


const PatientRestoreModalTemplate = (props: PatientRestoreModalMeta) => {

    return (
        <>
            <ModalWindowHeader>
                Confirm restore deleted patient
            </ModalWindowHeader>

            <div>Are you sure you want to restore deleted
                patient <b> {fullNamePipe(props.patient.patient)}</b> with sn <b>{props.patient.patient!.clientPatientIdentifier}
                </b> in organization <b>{props.patient.organization!.name}</b>?
            </div>

            <OperationProgress error = {props.error} inProcess = {props.inProcess} />

            <ModalWindowSubmitButton
                disabled = {props.inProcess}
                onSubmit = {props.confirmPatientRestore}
                content = {'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientRestoreModalOwnProps => {
    return {
        patient: PatientRestoreSelectors.patient(state),

        inProcess: PatientRestoreSelectors.inProcess(state),
        error: PatientRestoreSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientRestoreModalProps): PatientRestoreModalEvents => {
    return bindActionCreators({
        confirmPatientRestore: PATIENT_RESTORE
    }, dispatch);
};

export const PatientRestoreModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(PatientRestoreModalTemplate)),
    closeModalActionCreator: PATIENT_RESTORE_HIDE_MODAL,
    modalToggleSelector: PatientRestoreSelectors.toggle
});



