import {createSelector} from "reselect";
import {getOrganizationsState} from "../../store/organizations-store-selectors";

export class OrganizationStoreSelectors {
    static readonly state = createSelector(getOrganizationsState, state => state.organization);
    static readonly users = createSelector(OrganizationStoreSelectors.state, state => state.users);
    static readonly information = createSelector(OrganizationStoreSelectors.state, state => state.information);
}

export default OrganizationStoreSelectors;
