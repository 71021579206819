import produce from "immer";
import {IOrpyxRoleDto} from "admins-dashboard/http-clients/index";
import {OrpyxRolesActions} from "./orpyx-roles-actions";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface OrpyxRolesState {
    initialized: boolean;
    roles: IOrpyxRoleDto[];
    error: OperationErrorType;
}

const initialState: OrpyxRolesState = {
    initialized: false,
    roles: [],
    error: undefined,
};

const OrpyxRolesReducers = (state = initialState, action:  StoreAction<OrpyxRolesActions>): OrpyxRolesState => {
    return produce(state, (draft: OrpyxRolesState) => {
        switch (action.type) {
            case OrpyxRolesActions.INITIALIZE:
                break;

            case OrpyxRolesActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.roles = action.payload;
                draft.error = undefined;
                break;

            case OrpyxRolesActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.error = action.payload;
                draft.roles = [];
                break;
        }
    })
};

export default OrpyxRolesReducers;
