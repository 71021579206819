import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORGANIZATION_DELETE_FAILED, ORGANIZATION_DELETE_SUCCESSFULLY, OrganizationDeleteActions} from "./organization-delete.actions";
import {AdminOrganizationHttpClient, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, StoreActionAfterComplete} from "orpyx-web-common";
import {OrganizationDeleteSelectors} from "./organization-delete.selectors";

const API_VERSIONS = '1';

function* onDeleteOrganization() {
    try {
        const organization: IOrganizationOutputDto = yield select(OrganizationDeleteSelectors.organization);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrganizationDeleteSelectors.actionAfterComplete);

        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrganizationOutputDto = yield call(
            [api, api.deleteOrganization],
            organization.id!,
            API_VERSIONS
        );

        yield put(ORGANIZATION_DELETE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Organization ${data.name} successfully deleted`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(ORGANIZATION_DELETE_FAILED(e));
    }
}

export default function* OrganizationDeleteSagas() {
    yield all([
        takeEvery(OrganizationDeleteActions.ORGANIZATION_DELETE, onDeleteOrganization),
    ]);
}
