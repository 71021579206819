import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {OrpyxUserInformationActions} from "./orpyx-user-information-actions";

export interface OrpyxUserInformationState {
    initialized: boolean;
    user: IOrpyxUserDto | undefined,
    error?: OperationErrorType,

    isUserUpdating: boolean,
}

const initialState: OrpyxUserInformationState = {
    initialized: false,
    isUserUpdating: false,
    user: undefined
};

const OrpyxUserInformationReducers = (state = initialState, action: StoreAction<OrpyxUserInformationActions>): OrpyxUserInformationState => {
    return produce(state, (draft: OrpyxUserInformationState) => {
        switch (action.type) {
            case OrpyxUserInformationActions.INITIALIZE:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = undefined;
                break;
            case OrpyxUserInformationActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.user = action.payload;
                break;
            case OrpyxUserInformationActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.user = undefined;
                draft.error = action.payload;
                break;

            case OrpyxUserInformationActions.UPDATE_USER:
                draft.isUserUpdating = true;
                draft.error = undefined;
                break;

            case OrpyxUserInformationActions.UPDATE_USER_SUCCESSFULLY:
                draft.isUserUpdating = false;
                draft.error = undefined;
                break;

            case OrpyxUserInformationActions.UPDATE_USER_FAILED:
                draft.isUserUpdating = false;
                draft.error = action.payload;
                break;

        }
    })
};

export default OrpyxUserInformationReducers;
