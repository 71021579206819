import {useAdminDashboardPageStyleStyles} from "../admin-dashboard-page/admin-dashboard-page.style";
import {AdminBlockWrapper} from "../admin-block/admin-block";
import {Box, Paper} from "@material-ui/core";
import {Link} from "react-router-dom";
import * as React from "react";
import {memo} from "react";
import {AdminDashboardUrlBlockProps} from "./admin-dashboard-url-block.interface";

export const AdminDashboardUrlBlock = memo((props: AdminDashboardUrlBlockProps) => {
    const classes = useAdminDashboardPageStyleStyles();

    return (<AdminBlockWrapper requiredRole={props.url.requiredRole}>
        <Box width="33%" mr={2}>
            <Link to={props.url.url} className={classes.link}>
                <Paper className={classes.paper}>
                    {props.url.title}
                </Paper>
            </Link>
        </Box>
    </AdminBlockWrapper>);
});