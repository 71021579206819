import {createSelector} from "reselect";
import OrganizationStoreSelectors from "../../organization/store/organization-store-selectors";

export class OrganizationUsersSelectors {
    static readonly state = createSelector(OrganizationStoreSelectors.state, state => state.users);
    static readonly list = createSelector(OrganizationUsersSelectors.state, state => state.list);
    static readonly delete = createSelector(OrganizationUsersSelectors.state, state => state.delete);
    static readonly create = createSelector(OrganizationUsersSelectors.state, state => state.create);
    static readonly information = createSelector(OrganizationUsersSelectors.state, state => state.information);
}
