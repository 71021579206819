import {OrpyxUsersListPageMeta} from "./orpyx-users-list-page.interface";
import {OrpyxUsersSearchQuery, toQueryString} from "../../models/orpyx-users-search-query";
import {ChangedSearchOption, ChangedSearchOptions, ControlValidator, FormControlValidators, PagingQuery} from "orpyx-web-common";
import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

const OrpyxUsersListSearchValidators = new ControlValidator([
    FormControlValidators.minLength(2),
    FormControlValidators.maxLength(32),
]);

export const onOrpyxUsersListPageLoaded = (props: OrpyxUsersListPageMeta) => () => {
    const params = new URLSearchParams(props.location.search);

    const query = OrpyxUsersSearchQuery.parse(
        params.has('term') ? params.get('term')! : undefined,

        params.has(OrpyxUsersSearchQuery.PARAM_ORDER_BY) ? params.get(OrpyxUsersSearchQuery.PARAM_ORDER_BY)! : undefined,
        params.has(OrpyxUsersSearchQuery.PARAM_ORDER_BY_ASC) ? params.get(OrpyxUsersSearchQuery.PARAM_ORDER_BY_ASC)! : undefined,

        params.has('orpyxRoleType') ? params.get('orpyxRoleType')! : undefined,

        params.has(OrpyxUsersSearchQuery.PARAM_OFFSET) ? params.get(OrpyxUsersSearchQuery.PARAM_OFFSET)! : undefined,
        params.has(OrpyxUsersSearchQuery.PARAM_COUNT) ? params.get(OrpyxUsersSearchQuery.PARAM_COUNT)! : undefined,
    );

    props.fetchUsers(query);
};

export const onOrpyxUsersListItemSelected = (props: OrpyxUsersListPageMeta, orpyxUserId: number): void => {
    const url = `/admin/orpyx/users/details/${orpyxUserId}`;
    props.history!.push(url);
};

export const onOrpyxUsersListSearchPageChanged = (props: OrpyxUsersListPageMeta, page: number): void => {
    const searchQuery: OrpyxUsersSearchQuery = {...props.search, offset: page};
    updateSearchQuery(props, searchQuery);
};

export const onOrpyxUsersListSearchDisplayCountChanged = (props: OrpyxUsersListPageMeta, count: number): void => {
    const searchQuery: OrpyxUsersSearchQuery = {...props.search, count: count, offset: PagingQuery.DEFAULT_PAGE};
    updateSearchQuery(props, searchQuery);
};

export const onOrpyxUsersListSearchTermValidate = (term: string | undefined): string | undefined => {
    return OrpyxUsersListSearchValidators.validate(term);
};

export const onOrpyxUsersListSearchOptionsChanged = (props: OrpyxUsersListPageMeta) => (options: ChangedSearchOptions): void => {
    if (options.changed === ChangedSearchOption.Term) {
        //  Reset offset to default, if term changed
        //  Total may be less than offset for the new term
        const searchQuery: OrpyxUsersSearchQuery = {
            ...props.search,
            term: options.term,
            offset: PagingQuery.DEFAULT_PAGE
        };
        updateSearchQuery(props, searchQuery);
    } else if (options.changed === ChangedSearchOption.OrderBy) {
        const searchQuery: OrpyxUsersSearchQuery = {
            ...props.search,
            orderBy: options.orderBy?.value,
            orderByAsc: options.orderBy?.orderByAsc ?? OrpyxUsersSearchQuery.DEFAULT_ORDER_BY_METHOD
        };
        updateSearchQuery(props, searchQuery);
    }
};

export const onOrpyxUsersListSearchRoleChanged = (props: OrpyxUsersListPageMeta, orpyxRoleType: OrpyxRoleType | undefined): void => {
    //  Reset offset to default, if search role changed
    //  Total may be less than offset for the new role
    const searchQuery: OrpyxUsersSearchQuery = {...props.search, orpyxRoleType, offset: PagingQuery.DEFAULT_PAGE};
    updateSearchQuery(props, searchQuery);
};

const updateSearchQuery = (props: OrpyxUsersListPageMeta, searchQuery: OrpyxUsersSearchQuery): void => {
    const urlQuery: string = toQueryString(searchQuery);
    const url = `/admin/orpyx/users/list?${urlQuery}`;
    props.history!.push(url);
};
