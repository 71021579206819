import * as React from 'react';
import {Route, Switch} from 'react-router';
import NoMatch from 'pages/NoMatch';
import Footer from 'components/Footer';
import Support from 'pages/Support';
import AboutUs from 'pages/AboutUs';
import Faq from 'pages/Faq';
import {OrganizationsListPage} from 'modules/admin/organizations/organizations-list/components/organizations-list-page/organizations-list-page';
import {OrpyxUsersListPageWrapper} from "../modules/admin/orpyx-users/orpyx-users-list/components/orpyx-users-list-page/orpyx-users-list-page";
import {OrpyxUserInformationPageWrapper} from "../modules/admin/orpyx-users/orpyx-user-information/components/orpyx-user-information-page/orpyx-user-information-page";
import {OrganizationInformationPageWrapper} from "../modules/admin/organizations/organization-information/components/organization-information-page/organization-information-page";
import {PatientsListPageWrapper} from "../modules/admin/patients/patients-list/components/patients-list-page/patients-list-page";
import {PatientInformationPageWrapper} from "../modules/admin/patients/patient-information/components/patient-information-page/patient-information-page";
import {AdminDashboardPage} from "../modules/admin/components/admin-dashboard-page/admin-dashboard-page";
import AdminPageRouteWrapper from "../modules/admin/components/admin-page-route/admin-page-route";
import {OrpyxRoleType} from "admins-dashboard/http-clients/index";
import {HomePage} from "../modules/app/home-page/components/home-page/home-page";
import AdminOrpyxUsersPolicies from "../modules/admin/policies/admin-orpyx-users-policies";
import AdminOrganizationsPolicies from "../modules/admin/policies/admin-organizations-policies";
import AdminPatientsPolicies from "../modules/admin/policies/admin-patients-policies";
import {AdminPagesNavBar} from "../modules/admin/components/admin-dashboard-navbar/admin-dashboard-navbar/admin-pages-nav-bar";
import {OrganizationUserInformationPage} from "../modules/admin/organizations/organization-users/organization-user-information/components/organization-user-information-page/organization-user-information-page";
import {AdminDashboardUsersPolicies} from "../modules/admin/policies/admin-dashboard-users-policies";
import {AuditLoggingPageWrapper} from "../modules/admin/audit-logging/components/audit-logging-page/audit-logging-page";
import AdminAuditLoggingPolicies from "../modules/admin/policies/admin-audit-logging-policies";
import {PatientsRawTemperaturePage} from "../modules/admin/patients/patients-raw-temperature/components/patients-raw-temperature-page/patients-raw-temperature-page";
import AdminExportRawDataPolicies from 'modules/admin/policies/admin-export-raw-data-policies';
import {OrpyxCareUsersListPageWrapper} from '../modules/admin/orpyx-care-users/orpyx-care-care-users-list/components/orpyx-care-users-list-page/orpyx-care-users-list-page';
import { OrpyxCareUserInformationPage } from '../modules/admin/orpyx-care-users/orpyx-care-user-information/components/orpyx-care-user-information-page/orpyx-care-user-information-page';

const requiredSomeAdminRole = [
    OrpyxRoleType.Admin,
    OrpyxRoleType.OrganizationsAdmin,
    OrpyxRoleType.Manufacturing,
    OrpyxRoleType.Audit,

];

const routes = (
    <div>
        <Route path="/admin" component={AdminPagesNavBar}/>
        <Switch>
            <Route exact={true} path="/" component={HomePage}/>

            <Route path="/aboutus" component={AboutUs}/>
            <Route path="/faq" component={Faq}/>
            <Route path="/support" component={Support}/>

            <AdminPageRouteWrapper
                path="/admin/orpyx-care/users/list"
                component={OrpyxCareUsersListPageWrapper}
                requiredRole={AdminOrpyxUsersPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/orpyx-care/users/details/:dashboardUserId"
                component={OrpyxCareUserInformationPage}
                exact={true}
                requiredRole={AdminOrpyxUsersPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/orpyx/users/list"
                component={OrpyxUsersListPageWrapper}
                requiredRole={AdminOrpyxUsersPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/orpyx/users/details/:orpyxUserId"
                component={OrpyxUserInformationPageWrapper}
                requiredRole={AdminOrpyxUsersPolicies.Information}
            />
          
            <AdminPageRouteWrapper
                path="/admin"
                exact={true}
                component={AdminDashboardPage}
                requiredRole={requiredSomeAdminRole}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/list"
                component={OrganizationsListPage}
                requiredRole={AdminOrganizationsPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/details/:organizationId"
                component={OrganizationInformationPageWrapper}
                requiredRole={AdminOrganizationsPolicies.Information}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/:organizationId/doctors/details/:dashboardUserId"
                component={OrganizationUserInformationPage}
                exact={true}
                requiredRole={AdminDashboardUsersPolicies.Information}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/:organizationId/patients"
                component={PatientsListPageWrapper}
                exact={true}
                requiredRole={AdminPatientsPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/:organizationId/patients/details/:patientId"
                component={PatientInformationPageWrapper}
                exact={true}
                requiredRole={AdminPatientsPolicies.Information}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/patients"
                component={PatientsListPageWrapper}
                exact={true}
                requiredRole={AdminPatientsPolicies.List}
            />

            <AdminPageRouteWrapper
                path="/admin/audit-logging"
                component={AuditLoggingPageWrapper}
                requiredRole={AdminAuditLoggingPolicies.Export}
            />

            <AdminPageRouteWrapper
                path="/admin/organizations/patients/statistics/temperature"
                component={PatientsRawTemperaturePage}
                requiredRole={AdminExportRawDataPolicies.Allow}
            />

            <Route component={NoMatch}/>
        </Switch>
        <Footer/>
    </div>
);
export default routes;

