import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

export const adminHasRequiredRolePipe = (requiredRoles: OrpyxRoleType | OrpyxRoleType[], availableRoles: OrpyxRoleType[]): boolean => {
    if (Array.isArray(requiredRoles)) {
        const containsRequiredRole = availableRoles.some(role => requiredRoles.some(requiredRole => requiredRole === role));
        return containsRequiredRole;
    } else {
        const containsRequiredRole = availableRoles.some(role => role === requiredRoles);
        return containsRequiredRole;
    }
};

export default adminHasRequiredRolePipe;
