import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import produce from "immer";
import {OrganizationUserDeleteActions} from "./organization-user-delete-actions";

export interface OrganizationUserDeleteState extends IModalWindow {
    dashboardUser: IUserOutputDto | undefined;
    organization: IOrganizationOutputDto | undefined;

    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrganizationUserDeleteState = {
    ...ModalWindowInitialState,

    dashboardUser: undefined,
    organization: undefined,

    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrganizationUserDeleteReducers = (state = initialState, action: StoreAction<OrganizationUserDeleteActions>): OrganizationUserDeleteState => {
    return produce(state, (draft: OrganizationUserDeleteState) => {
        switch (action.type) {
            case OrganizationUserDeleteActions.DELETE_DASHBOARD_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrganizationUserDeleteActions.SHOW_MODAL:
                draft.dashboardUser = action.payload.user;
                draft.organization = action.payload.organization;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;
            case OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_SUCCESSFULLY:
            case OrganizationUserDeleteActions.HIDE_MODAL:
                draft.dashboardUser = undefined;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;
        }
    });
};

export default OrganizationUserDeleteReducers;
