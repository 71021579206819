import {OperationResultCode} from "admins-dashboard/http-clients/index";
import {OrpyxCareUserInformationPageMeta} from "./orpyx-care-user-information-page.interface";

export const onOrpyxCareUserInformationPageLoaded = (props: OrpyxCareUserInformationPageMeta) => () => {

    if (props.match.isExact && props.match.params.dashboardUserId) {
        const dashboardUserId = parseInt(props.match.params.dashboardUserId);

        if (isNaN(dashboardUserId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid Orpyx Care User Id: ${props.match.params.dashboardUserId}`
            });
        } else {
            props.loadUserInformation(dashboardUserId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid query`
        });
    }
};