import {all, call, put, takeEvery} from 'redux-saga/effects';
import {AdminOrpyxRolesHttpClient, OrpyxRoleDto} from "admins-dashboard/http-clients/index";
import {ORPYX_ROLES_INITIALIZE_FAILED, ORPYX_ROLES_INITIALIZE_SUCCESSFULLY, OrpyxRolesActions} from "./orpyx-roles-actions";
import {ORPYXSI_ADMIN_URL} from "../../../../appConstants";

const API_VERSION = '1';

function* onGetOrpyxRoles() {
    try {
        const api = new AdminOrpyxRolesHttpClient(ORPYXSI_ADMIN_URL);
        const data: OrpyxRoleDto[] = yield call([api, api.getOrpyxRoles], API_VERSION);
        yield put(ORPYX_ROLES_INITIALIZE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(ORPYX_ROLES_INITIALIZE_FAILED(e));
    }
}

export default function* OrpyxRolesSagas() {
    yield all([
        takeEvery(OrpyxRolesActions.INITIALIZE, onGetOrpyxRoles)
    ]);
}
