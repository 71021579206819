import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FETCH_PATIENT_DEVICES_FAILED, FETCH_PATIENT_DEVICES_SUCCESSFULLY, PatientDevicesListActions} from "./patient-devices-list-actions";
import {AdminPatientHttpClient, IGetPatientInformationResult, IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {PatientDevicesCreateActionAfterComplete, whenPatientDevicesCreateActionAfterComplete} from "../../patient-devices-create/store/patient-devices-create-actions";
import {PatientInformationSelectors} from "../../../patient-information/store/patient-information-selectors";
import {PatientInsolesCreateActions} from "../../patient-insoles-create/store/patient-insoles-create-actions";
import {PatientDevicesUpdateActions} from "../../patient-devices-update/store/patient-devices-update-actions";
import {PatientInsolesDeleteActions} from "../../patient-insoles-delete/store/patient-insoles-delete-actions";

const API_VERSIONS = '1';

function* onFetchPatientDevices() {
    try {
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient);

        const api = new AdminPatientHttpClient(ORPYXSI_ADMIN_URL);

        const data: IListProductsResultEntry[] = yield call(
            [api, api.getPatientProductsList],
            patient.patient!.patientId!,
            API_VERSIONS,
        );

        yield put(FETCH_PATIENT_DEVICES_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_PATIENT_DEVICES_FAILED(e));
    }
}

export default function* PatientDevicesListSagas() {
    yield all([
        takeEvery(PatientDevicesListActions.FETCH_PATIENT_DEVICES, onFetchPatientDevices),

        takeEvery(whenPatientDevicesCreateActionAfterComplete(PatientDevicesCreateActionAfterComplete.RefreshUserInformation), onFetchPatientDevices),
        takeEvery(PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE_SUCCESSFULLY, onFetchPatientDevices),

        takeEvery(PatientInsolesCreateActions.CREATE_PATIENT_INSOLE_SUCCESSFULLY, onFetchPatientDevices),
        takeEvery(PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE_SUCCESSFULLY, onFetchPatientDevices),
    ]);
}

