import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {AdminBlockEvents, AdminBlockMeta, AdminBlockOwnProps, AdminBlockProps} from "./admin-block.interface";
import {IOrpyxApplicationStore} from "../../../store/application.reducers";
import {AdminAuthSelectors} from "../../auth/store/admin-auth-selectors";
import {OrpyxRoleType} from "admins-dashboard/http-clients/index";
import adminAvailableRolesPipe from "../../pipes/admin-available-roles-pipe";
import adminHasRequiredRolePipe from "../../pipes/admin-has-required-role-pipe";

const AdminBlock = (props: AdminBlockMeta) => {
    const availableRoles: OrpyxRoleType[] = adminAvailableRolesPipe(props.roles);
    const containsRequiredRole = adminHasRequiredRolePipe(props.requiredRole, availableRoles);
    return (<>
        {containsRequiredRole && props.children}
    </>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): AdminBlockOwnProps => {
    return {
        roles: AdminAuthSelectors.roles(state),
        isLoggedIn: AdminAuthSelectors.isLoggedIn(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: AdminBlockProps): AdminBlockEvents => {
    return bindActionCreators({}, dispatch);
};

export const AdminBlockWrapper = memo(connect(mapStateToProps, mapDispatchToProps)(AdminBlock));

