import {DropdownSelectValue} from "../../shared/dropdown-select/dropdown-select.interface";
import { GenerateReportPatientOptions } from "admins-dashboard";

export const PatientOptionValues: DropdownSelectValue[] = [
    {
        value: GenerateReportPatientOptions.ContainsPatientIdentifier,
        name: 'Contains patient identifier'
    },
    {
        value: GenerateReportPatientOptions.EqualPatientIdentifier,
        name: 'Equal patient identifier'
    },
];
