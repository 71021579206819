import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IUserOutputDto} from "admins-dashboard/http-clients/index";

export enum OrpyxCareConfirmationModalActions {

    ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED = 'ORPYX_CARE_CONFIRMATION_MODAL: ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED',
    ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY = 'ORPYX_CARE_CONFIRMATION_MODAL: ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY',
    ORPYX_CARE_CONFIRMATION_MODAL_FAILED = 'ORPYX_CARE_CONFIRMATION_MODAL: ORPYX_CARE_CONFIRMATION_MODAL_FAILED',

    SHOW_MODAL = 'ORPYX_CARE_CONFIRMATION_MODAL: SHOW_MODAL',
    HIDE_MODAL = 'ORPYX_CARE_CONFIRMATION_MODAL: HIDE_MODAL',
}

export const ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED = (): StoreAction<OrpyxCareConfirmationModalActions> => ({
    type: OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED,
});

export const ORPYX_CARE_CONFIRMATION_MODAL_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (user: IUserOutputDto, permission: string): StoreAction<OrpyxCareConfirmationModalActions> => ({
    type: OrpyxCareConfirmationModalActions.SHOW_MODAL, payload: {user, permission, actionAfterComplete}
});

export const ORPYX_CARE_CONFIRMATION_MODAL_HIDE_MODAL = (): StoreAction<OrpyxCareConfirmationModalActions> => ({
    type: OrpyxCareConfirmationModalActions.HIDE_MODAL,
});

export const ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrpyxCareConfirmationModalActions> => ({
    type: OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY, payload: actionAfterComplete
});

export const ORPYX_CARE_CONFIRMATION_MODAL_FAILED = (error: OperationErrorType): StoreAction<OrpyxCareConfirmationModalActions> => ({
    type: OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_FAILED, payload: error
});

