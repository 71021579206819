import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrpyxUserDto, IUpdateOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserInformationActions {
    INITIALIZE = 'ORPYX_USERS_INFORMATION: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'ORPYX_USERS_INFORMATION: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'ORPYX_USERS_INFORMATION: INITIALIZE_FAILED',

    UPDATE_USER = 'ORPYX_USERS_INFORMATION: UPDATE_USER',
    UPDATE_USER_SUCCESSFULLY = 'ORPYX_USERS_INFORMATION: UPDATE_USER_SUCCESSFULLY',
    UPDATE_USER_FAILED = 'ORPYX_USERS_INFORMATION: UPDATE_USER_FAILED',
}

export const INITIALIZE_ORPYX_USER_INFORMATION = (orpyxUserId: number): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.INITIALIZE,
    payload: orpyxUserId
});

export const INITIALIZE_ORPYX_USER_INFORMATION_SUCCESSFULLY = (orpyxUser: IOrpyxUserDto): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.INITIALIZE_SUCCESSFULLY,
    payload: orpyxUser
});

export const INITIALIZE_ORPYX_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.INITIALIZE_FAILED,
    payload: error
});

export const UPDATE_ORPYX_USER_INFORMATION = (query: { model: IUpdateOrpyxUserDto, orpyxUserId: number }): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.UPDATE_USER,
    payload: query
});

export const UPDATE_ORPYX_USER_INFORMATION_SUCCESSFULLY = (): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.UPDATE_USER_SUCCESSFULLY
});

export const UPDATE_ORPYX_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserInformationActions> => ({
    type: OrpyxUserInformationActions.UPDATE_USER_FAILED,
    payload: error
});
