import {default as React, memo, useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {ORPYX_ROLES_INITIALIZE} from "../../../../orpyx-roles/store/orpyx-roles-actions";
import {connect} from "react-redux";
import {onOrpyxUserRoleAssignModalLoaded} from "./orpyx-user-role-assign-modal.hooks";
import {OrpyxUserRoleAssignModalEvents, OrpyxUserRoleAssignModalMeta, OrpyxUserRoleAssignModalOwnProps} from "./orpyx-user-role-assign-modal.interface";
import {
    getOrpyxUserAssignAvailableRoles,
    getOrpyxUserAssignRoleError,
    getOrpyxUserAssignRoleUser,
    isOrpyxUserAssignRoleInProcess,
    isOrpyxUserAssignRoleModalActive
} from "../../store/orpyx-user-role-assign-selectors";
import {ASSIGN_ROLE, ASSIGN_ROLE_HIDE_MODAL} from "../../store/orpyx-user-role-assign-actions";
import {OrpyxRolesSelectors} from "../../../../orpyx-roles/store/orpyx-roles-selectors";
import {createModalWindow, ModalWindowHeader} from "orpyx-web-common";
import {LazyLoader, LazyLoaderFallback} from "orpyx-web-common";

const OrpyxUserRoleAssignFormWrapper = React.lazy(() => import("../orpyx-user-role-assign-form/orpyx-user-role-assign-form"));

export const OrpyxUserRoleAssignModal = (props: OrpyxUserRoleAssignModalMeta) => {
    useEffect(onOrpyxUserRoleAssignModalLoaded(props), []);

    return (
        <>
            <ModalWindowHeader>
                Assign new role for user
            </ModalWindowHeader>

            <LazyLoader height={40} width={300} rows={8}>
                {!props.isOrpyxRolesLoaded && <LazyLoaderFallback height={40} width={300} rows={8}/>}
                {props.isOrpyxRolesLoaded && <OrpyxUserRoleAssignFormWrapper
                    onSubmit={props.assignRole}
                    inProcess={props.inProcess}
                    error={props.error}
                    roles={props.orpyxRoles}
                />}
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserRoleAssignModalOwnProps => {
    return {
        error: getOrpyxUserAssignRoleError(state),
        inProcess: isOrpyxUserAssignRoleInProcess(state),
        orpyxRoles: getOrpyxUserAssignAvailableRoles(state),
        isOrpyxRolesLoaded: OrpyxRolesSelectors.initialized(state),
        user: getOrpyxUserAssignRoleUser(state)!
    };
};

const mapDispatchToProps = (dispatch: Dispatch): OrpyxUserRoleAssignModalEvents => {
    return bindActionCreators({
        loadOrpyxRoles: ORPYX_ROLES_INITIALIZE,
        assignRole: ASSIGN_ROLE
    }, dispatch);
};

export const OrpyxUserRoleAssignModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserRoleAssignModal)),
    closeModalActionCreator: ASSIGN_ROLE_HIDE_MODAL,
    modalToggleSelector: isOrpyxUserAssignRoleModalActive
});
