import {createSelector} from "reselect";
import {OrganizationUsersSelectors} from "../../store/organization-users-selectors";

export class OrganizationUserInformationSelectors {
    static readonly state = OrganizationUsersSelectors.information;

    static readonly user = createSelector(OrganizationUserInformationSelectors.state, state => state.user);
    static readonly error = createSelector(OrganizationUserInformationSelectors.state, state => state.error);

    static readonly initialized = createSelector(OrganizationUserInformationSelectors.state, state => state.initialized);
    static readonly isUserUpdating = createSelector(OrganizationUserInformationSelectors.state, state => state.isUserUpdating);
}

export default OrganizationUserInformationSelectors;
