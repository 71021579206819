import * as React from "react";
import {memo} from "react";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {
    OrpyxUserInformationRolesRevokeModalEvents,
    OrpyxUserInformationRolesRevokeModalMeta,
    OrpyxUserInformationRolesRevokeModalOwnProps
} from "./orpyx-user-information-roles-revoke-modal.interface";
import {REVOKE_ROLE, REVOKE_ROLE_HIDE_MODAL} from "../../store/orpyx-user-role-revoke-actions";
import {getOrpyxUserRevokeRoleError, getOrpyxUserRevokeRoleModel, isOrpyxUserRevokeRoleInProcess, isOrpyxUserRevokeRoleModalActive} from "../../store/orpyx-user-role-revoke-selectors";
import {createModalWindow, DateTimePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";


const OrpyxUserInformationRolesRevokeModal = (props: OrpyxUserInformationRolesRevokeModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm revoke user role
            </ModalWindowHeader>

            <div> Are you sure you want to revoke the <b> {props.userRole.role!.name}</b> rights?</div>

            <OperationProgress
                error={props.error}
                inProcess={props.inProcess}
            />

            <ModalWindowSubmitButton
                disabled={props.inProcess}
                onSubmit={props.confirmUserRoleRevoke}
                content={'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserInformationRolesRevokeModalOwnProps => {
    return {
        error: getOrpyxUserRevokeRoleError(state),
        inProcess: isOrpyxUserRevokeRoleInProcess(state),
        userRole: getOrpyxUserRevokeRoleModel(state)!,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): OrpyxUserInformationRolesRevokeModalEvents => {
    return bindActionCreators({
        confirmUserRoleRevoke: REVOKE_ROLE
    }, dispatch);
};

export const OrpyxUserInformationRolesRevokeModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserInformationRolesRevokeModal)),
    closeModalActionCreator: REVOKE_ROLE_HIDE_MODAL,
    modalToggleSelector: isOrpyxUserRevokeRoleModalActive,
});
