import {StoreAction} from "orpyx-web-common";
import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";

export enum OrganizationUserSendInviteActions {

    DASHBOARD_USER_SEND_INVITE = 'DASHBOARD_USER_SEND_INVITE: DASHBOARD_USER_SEND_INVITE',
    DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY = 'DASHBOARD_USER_SEND_INVITE: DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY',
    DASHBOARD_USER_SEND_INVITE_FAILED = 'DASHBOARD_USER_SEND_INVITE: DASHBOARD_USER_SEND_INVITE_FAILED',
}

export const DASHBOARD_USER_SEND_INVITE = (organization: IOrganizationOutputDto, user: IUserOutputDto): StoreAction<OrganizationUserSendInviteActions> => ({
    type: OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE, payload: {user, organizationId: organization.id}
});

export const DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY = (user: IUserOutputDto): StoreAction<OrganizationUserSendInviteActions> => ({
    type: OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY, payload: user
});

export const DASHBOARD_USER_SEND_INVITE_FAILED = (user: IUserOutputDto): StoreAction<OrganizationUserSendInviteActions> => ({
    type: OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE_FAILED, payload: user
});

