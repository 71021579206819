import {OrpyxUserInformationPageEvents, OrpyxUserInformationPageMeta, OrpyxUserInformationPageOwnProps, OrpyxUserInformationPageProps} from "./orpyx-user-information-page.interface";
import * as React from "react";
import {memo, useEffect} from "react";
import {onOrpyxUserInformationPageLoaded} from "./orpyx-user-information-page.hooks";
import {useOrpyxUserInformationPageStyle} from "./orpyx-user-information-page.style";
import {Container, Grid} from "@material-ui/core";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {INITIALIZE_ORPYX_USER_INFORMATION, INITIALIZE_ORPYX_USER_INFORMATION_FAILED, UPDATE_ORPYX_USER_INFORMATION} from "../../store/orpyx-user-information-actions";
import Paper from "@material-ui/core/Paper";
import {OrpyxUserInformationRolesRevokeModalWrapper} from "../../../orpyx-user-role-revoke/components/orpyx-user-role-revoke-modal/orpyx-user-information-roles-revoke-modal";
import {REVOKE_ROLE_SHOW_MODAL} from "../../../orpyx-user-role-revoke/store/orpyx-user-role-revoke-actions";
import {OrpyxUserRoleAssignModalWrapper} from "../../../orpyx-user-role-assign/components/orpyx-user-role-assign-modal/orpyx-user-role-assign-modal";
import {ASSIGN_ROLE_SHOW_MODAL} from "../../../orpyx-user-role-assign/store/orpyx-user-role-assign-actions";
import {OrpyxUserDeleteModalWindow} from "../../../orpyx-user-delete/components/orpyx-user-delete-modal/orpyx-user-delete-modal";
import {DELETE_ORPYX_USER_SHOW_MODAL} from "../../../orpyx-user-delete/store/orpyx-user-delete-actions";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import AdminOrpyxUsersPolicies from "../../../../policies/admin-orpyx-users-policies";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import OrpyxUserInformationSelectors from "../../store/orpyx-user-information-selectors";
import {
    ActionButton,
    ApplicationBreadcrumbs,
    ApplicationBreadcrumbsItem,
    fullNamePipe,    
    LazyLoader,
    OperationProgress,
    OperationSkeleton,
    StoreActionAfterComplete
} from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {OrpyxUserRestoreModalWindow} from "../../../orpyx-user-restore/components/orpyx-user-restore-modal/orpyx-user-restore-modal";
import {RESTORE_ORPYX_USER_SHOW_MODAL} from "../../../orpyx-user-restore/store/orpyx-user-restore.actions";
import {OrpyxUserActions} from "../orpyx-user-information-actions/orpyx-user-information-actions";
import {ORPYX_USER_SEND_INVITE} from "../../../orpyx-user-send-invite/store/orpyx-user-send-invite.actions";
import {ORPYX_USER_REVOKE_INVITE} from "../../../orpyx-user-revoke-invite/store/orpyx-user-revoke-invite.actions";
import {AdminAuthSelectors} from "../../../../auth/store/admin-auth-selectors";
import {IOrpyxUserInformationActionsProps} from "../orpyx-user-information-actions/orpyx-user-information-actions.interface";
import {UserAccountStatus} from "admins-dashboard/http-clients/index";

const OrpyxUserInformationRolesTable = React.lazy(() => import("../orpyx-user-information-roles-table/orpyx-user-information-roles-table"));
const OrpyxUserInformationFormWrapper = React.lazy(() => import("../orpyx-user-information-form/orpyx-user-information-form"));

const generatePageBreadcrumbs = (user: IOrpyxUserDto | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Orpyx Admin Users',
        url: '/Admin/Orpyx/Users/List'
    }];

    if (user) {
        items.push({
            name: fullNamePipe(user)!,
            url: `/Admin/Orpyx/Users/Details/${user.orpyxUserId}`
        });
    }

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserInformationPageOwnProps => {
    return {
        initialized: OrpyxUserInformationSelectors.initialized(state),
        user: OrpyxUserInformationSelectors.user(state)!,

        error: OrpyxUserInformationSelectors.error(state),
        isUserUpdating: OrpyxUserInformationSelectors.isUserUpdating(state),

        availableRoles: AdminAuthSelectors.availableRoles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrpyxUserInformationPageProps): OrpyxUserInformationPageEvents => {
    return bindActionCreators({
        loadUserInformation: INITIALIZE_ORPYX_USER_INFORMATION,
        updateUserInformation: UPDATE_ORPYX_USER_INFORMATION,
        showRevokeUserRoleModal: REVOKE_ROLE_SHOW_MODAL,
        showAssignUserRoleModal: ASSIGN_ROLE_SHOW_MODAL,

        showDeleteUserModal: DELETE_ORPYX_USER_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
        showRestoreUserModal: RESTORE_ORPYX_USER_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),

        sendUserInvite: ORPYX_USER_SEND_INVITE,
        revokeUserInvite: ORPYX_USER_REVOKE_INVITE,

        showErrorMessage: INITIALIZE_ORPYX_USER_INFORMATION_FAILED
    }, dispatch);
};

const OrpyxUserInformationContent = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();
    const canManageUserInformation = props.user.status !== UserAccountStatus.Deactivated;

    const propsSelector = (): IOrpyxUserInformationActionsProps => ({
        user: props.user,
        availableRoles: props.availableRoles,
        events: {
            showDeleteUserModal: props.showDeleteUserModal,
            showRestoreUserModal: props.showRestoreUserModal,

            sendUserInvite: props.sendUserInvite,
            revokeUserInvite: props.revokeUserInvite,
        }
    });

    return (<Grid container className={classes.root} spacing={4}>
        <Grid item xs={12}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.user)}/>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    User information

                    <OrpyxUserActions
                        tooltip={'Orpyx Admin User actions'}
                        propsSelector={propsSelector}
                    />
                </div>

                <LazyLoader height={80} rows={4}>
                    <OrpyxUserInformationFormWrapper
                        error={props.error}
                        user={props.user}
                        canManageUserInformation={canManageUserInformation}
                        onSubmit={model => props.updateUserInformation({model: model, orpyxUserId: props.user.orpyxUserId!})}
                        inProcess={props.isUserUpdating}
                    />
                </LazyLoader>

            </Paper>
        </Grid>

        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    User roles
                </div>

                <LazyLoader height={40} rows={6}>
                    <OrpyxUserInformationRolesTable
                        userRoles={props.user.roles}
                        canManageUserInformation={canManageUserInformation}
                        onUserRoleRevoke={userRole => props.showRevokeUserRoleModal(props.user.orpyxUserId!, userRole)}
                    />
                </LazyLoader>

                <AdminBlockWrapper requiredRole={AdminOrpyxUsersPolicies.ManageRoles}>
                    <ActionButton
                        disabled={!canManageUserInformation}
                        onSubmit={() => props.showAssignUserRoleModal(props.user)}
                        content={"+ Add role"}
                    />
                </AdminBlockWrapper>

            </Paper>
        </Grid>

        <OrpyxUserInformationRolesRevokeModalWrapper/>
        <OrpyxUserRoleAssignModalWrapper/>
        <OrpyxUserDeleteModalWindow/>
        <OrpyxUserRestoreModalWindow/>
    </Grid>);
})));

const OrpyxUserInformationError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();

    return (<Grid container className={classes.root} spacing={4}>

        <Grid item xs={12}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.user)}/>
        </Grid>

        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    User information
                </div>

                <OperationProgress initialized={props.initialized} error={props.error}/>
                <OperationSkeleton height={80} rows={4}/>
            </Paper>
        </Grid>

        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <div className={classes.title}>
                    User roles
                </div>
                <OperationSkeleton height={40} rows={6}/>
            </Paper>
        </Grid>

    </Grid>);
})));

const OrpyxUserInformationPage = (props: OrpyxUserInformationPageMeta) => {
    useEffect(onOrpyxUserInformationPageLoaded(props), [props.location]);

    return (
        <Container>
            {props.initialized ? <OrpyxUserInformationContent/> : <OrpyxUserInformationError/>}
        </Container>
    );
};

export const OrpyxUserInformationPageWrapper = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserInformationPage)));

