import * as React from "react";
import {memo, useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {
    AutocompleteMultipleSelectMeta,
    AutocompleteMultipleSelectValue
} from "./autocomplete-multiple-select.interface";


export const AutocompleteMultipleSelect = memo((props: AutocompleteMultipleSelectMeta<any>) => {

    const [selectedEntity, setSelectedEntity] = useState<AutocompleteMultipleSelectValue[]>([]);

    const onResetSearchTerm = () => {
        setSelectedEntity([{
            id: -1,
            name: ''
        }]);
    };

    const onEntitySelected = (event1: any, value: AutocompleteMultipleSelectValue[]) => {
        setSelectedEntity(value);
        props.onSelected(value);
    };

    const onChangeSearchTerm = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.value) {
            props.onSearch(event.target.value);
        }
        setSelectedEntity([{
            id: -1,
            name: event.target.value
        }]);
    };

    return (
        <Autocomplete
            multiple
            style={{width: '100%'}}
            getOptionLabel={(option) => option.name!}
            options={props.values}
            loading={props.loading}

            onChange={onEntitySelected}
            onReset={onResetSearchTerm}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.placeholder}
                    variant="outlined"
                    onChange={onChangeSearchTerm}
                />
            )}
            renderOption={(option) => option.name}
        />
    );
});
