import {createSelector} from "reselect";
import OrganizationStoreSelectors from "../../organization/store/organization-store-selectors";

export class OrganizationInformationSelectors {
    static readonly state = createSelector(OrganizationStoreSelectors.state, state => state.information);

    static readonly organization = createSelector(OrganizationInformationSelectors.state, state => state.organization!);
    static readonly error = createSelector(OrganizationInformationSelectors.state, state => state.error);
    static readonly initialized = createSelector(OrganizationInformationSelectors.state, state => state.initialized);
}
