import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from "redux-saga";
import createRootReducer from 'modules/store/application.reducers';
import {composeWithDevTools} from 'redux-devtools-extension';

import rootSaga from "modules/store/application.sagas";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware
    )
  )
);

// then run the saga
sagaMiddleware.run(rootSaga);
