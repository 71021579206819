import {createSelector} from "reselect";
import {getPatientDevicesStoreState} from "../../store/patient-devices-selectors";

export class PatientInsolesCreateSelectors {
    static readonly state = createSelector(getPatientDevicesStoreState, state => state.createInsole);
    static readonly error = createSelector(PatientInsolesCreateSelectors.state, state => state.error);
    static readonly device = createSelector(PatientInsolesCreateSelectors.state, state => state.device);

    static readonly inProcess = createSelector(PatientInsolesCreateSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(PatientInsolesCreateSelectors.state, state => state.toggle);
}

