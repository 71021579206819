import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminOrganizationHttpClient, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, StoreActionAfterComplete} from "orpyx-web-common";
import {ORGANIZATION_RESTORE_FAILED, ORGANIZATION_RESTORE_SUCCESSFULLY, OrganizationRestoreActions} from "./organization-restore.actions";
import {OrganizationRestoreSelectors} from "./organization-restore.selectors";

const API_VERSIONS = '1';

function* onRestoreOrganization() {
    try {
        const organization: IOrganizationOutputDto = yield select(OrganizationRestoreSelectors.organization);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrganizationRestoreSelectors.actionAfterComplete);

        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrganizationOutputDto = yield call(
            [api, api.restoreOrganization],
            organization.id!,
            API_VERSIONS
        );

        yield put(ORGANIZATION_RESTORE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Organization ${data.name} successfully restored after deleted`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(ORGANIZATION_RESTORE_FAILED(e));
    }
}

export default function* OrganizationRestoreSagas() {
    yield all([
        takeEvery(OrganizationRestoreActions.ORGANIZATION_RESTORE, onRestoreOrganization),
    ]);
}
