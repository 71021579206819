import produce from "immer";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction} from "orpyx-web-common";
import {OrganizationCreateActions} from "./organization-create-actions";

export interface OrganizationCreateState extends IModalWindow {

}

const initialState: OrganizationCreateState = {
    ...ModalWindowInitialState,
};

const OrganizationCreateReducers = (state = initialState, action: StoreAction<OrganizationCreateActions>): OrganizationCreateState => {
    return produce(state, (draft: OrganizationCreateState) => {
        switch (action.type) {
            case OrganizationCreateActions.CREATE_ORGANIZATION:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationCreateActions.CREATE_ORGANIZATION_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationCreateActions.CREATE_ORGANIZATION_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrganizationCreateActions.SHOW_MODAL:
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationCreateActions.HIDE_MODAL:
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default OrganizationCreateReducers;
