import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

export class AdminOrganizationsPolicies {
    static readonly Create = OrpyxRoleType.OrganizationsAdmin;
    static readonly Delete = OrpyxRoleType.OrganizationsAdmin;
    static readonly Restore = OrpyxRoleType.OrganizationsAdmin;

    static readonly Update = OrpyxRoleType.OrganizationsAdmin;
    static readonly List = [OrpyxRoleType.OrganizationsAdmin, OrpyxRoleType.Manufacturing];
    static readonly Information = OrpyxRoleType.OrganizationsAdmin;
}

export default AdminOrganizationsPolicies;
