import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import * as React from "react";
import {memo} from "react";
import OrpyxUserRestoreSelectors from "../../store/orpyx-user-restore.selectors";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";
import {RESTORE_ORPYX_USER, RESTORE_ORPYX_USER_HIDE_MODAL} from "../../store/orpyx-user-restore.actions";
import {OrpyxUserRestoreModalEvents, OrpyxUserRestoreModalMeta, OrpyxUserRestoreModalOwnProps} from "./orpyx-user-restore-modal.interface";

const OrpyxUserRestoreModalTemplate = (props: OrpyxUserRestoreModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm restore user
            </ModalWindowHeader>

            <div> Are you sure you want to restore deleted user <b> {fullNamePipe(props.user)}</b>?</div>
            <div> Roles: {props.user.roles!.map(role => role!.role!.name).join(', ')}</div>

            <OperationProgress error = {props.error} inProcess = {props.inProcess} />

            <ModalWindowSubmitButton
                onSubmit = {props.confirmUserDelete}
                disabled = {props.inProcess}
                content = {'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserRestoreModalOwnProps => {
    return {
        error: OrpyxUserRestoreSelectors.error(state),
        inProcess: OrpyxUserRestoreSelectors.inProcess(state),
        user: OrpyxUserRestoreSelectors.user(state)!,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): OrpyxUserRestoreModalEvents => {
    return bindActionCreators({
        confirmUserDelete: RESTORE_ORPYX_USER
    }, dispatch);
};

export const OrpyxUserRestoreModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserRestoreModalTemplate)),
    closeModalActionCreator: RESTORE_ORPYX_USER_HIDE_MODAL,
    modalToggleSelector: OrpyxUserRestoreSelectors.toggle
});
