import produce from "immer";
import {OrganizationRestoreActions} from "./organization-restore.actions";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";

export interface OrganizationRestoreState extends IModalWindow {
    organization: IOrganizationOutputDto | undefined;
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrganizationRestoreState = {
    ...ModalWindowInitialState,

    organization: undefined,
    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrganizationRestoreReducers = (state = initialState, action: StoreAction<OrganizationRestoreActions>): OrganizationRestoreState => {
    return produce(state, (draft: OrganizationRestoreState) => {
        switch (action.type) {
            case OrganizationRestoreActions.SHOW_MODAL:
                draft.organization = action.payload.organization;
                draft.actionAfterComplete = action.payload.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationRestoreActions.ORGANIZATION_RESTORE_SUCCESSFULLY:
            case OrganizationRestoreActions.HIDE_MODAL:
                draft.organization = undefined;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationRestoreActions.ORGANIZATION_RESTORE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationRestoreActions.ORGANIZATION_RESTORE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    })
};


export default OrganizationRestoreReducers;
