import produce from "immer";
import {OrpyxUserRevokeInviteActions} from "./orpyx-user-revoke-invite.actions";
import {StoreAction, UsersInProcess} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export interface OrpyxUserRevokeInviteState {
    usersInProcess: UsersInProcess;
}

const initialState: OrpyxUserRevokeInviteState = {
    usersInProcess: {},
};

const OrpyxUserRevokeInviteReducer = (state = initialState, action: StoreAction<OrpyxUserRevokeInviteActions>): OrpyxUserRevokeInviteState => {
    return produce(state, (draft: OrpyxUserRevokeInviteState) => {
        switch (action.type) {
            case OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE:{
                const orpyxUser = action.payload as IOrpyxUserDto;
                draft.usersInProcess[orpyxUser.orpyxUserId!] = true;
                break;
            }
            case OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY:{
                const orpyxUser = action.payload as IOrpyxUserDto;
                delete draft.usersInProcess[orpyxUser.orpyxUserId!];
                break;
            }
            case OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE_FAILED:{
                const orpyxUser = action.payload as IOrpyxUserDto;
                delete draft.usersInProcess[orpyxUser.orpyxUserId!];
                break;
            }
        }
    })
};

export default OrpyxUserRevokeInviteReducer;
