import * as React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {Teal} from "orpyx-web-common";
import {Menu} from '../../components/Menu';

const useStyles = makeStyles({
        root: {
        margin: '30px',
    },
    title: {
        color: Teal,
        fontSize: 'larger',
    },
    text: {
        marginTop: '20px',
        textAlign: 'justify',
    },
    link: {
        color: Teal,
        textDecoration: 'none'
    },
    textBold: {
        fontWeight: 'bold',
    },
    headerbox: {
        color: 'white',
        backgroundColor: Teal,
        borderLeftColor: 'white',
        borderLeftWidth: 'thin',
        borderLeftStyle: 'solid',
    },
    textBox: {
        textAlign: 'justify',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    line: {
        backgroundColor: 'white',
    },
    footnote: {
        fontSize: 'smaller',
    },
    grid: {
        marginTop: '10px',
    },
    shadedTextBox: {
        backgroundColor: 'lightgray',
        textAlign: 'justify',
        paddingLeft: '5px',
        paddingRight: '5px',
        borderLeftColor: 'white',
        borderLeftWidth: 'thin',
        borderLeftStyle: 'solid',
    },
    content:{
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        maxWidth: 900,
        padding: '27px 50px',
        margin: '0 auto',
    },
});

function Faq() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Menu />
            <div className={classes.content}>
                <Typography className={classes.title}>Frequently Asked Questions</Typography>
                <Typography className={classes.text}>
                    Q: Which codes can I use for Remote Monitoring?<br/>
                    A: New remote physiologic monitoring CPT codes (~$1,000/patient/year) 
                    and ~$240/patient/year with HCPCS code A5514.
                </Typography>
                <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12}>
                        <Box border={1}>
                        <Grid container>
                            <Grid item xs={12} className={classes.headerbox}>
                                Relevant Reimbursement Code Descriptions
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={2} className={classes.headerbox}>
                                HCPCS A5514
                            </Grid>
                            <Grid item xs={10} className={classes.textBox}>
    For diabetics only, multiple density insert, made by direct carving with cam technology from a rectified cad
    model created from a digitized scan of the patient, total contact with patient's foot, including arch, base
    layer minimum of 3/16 inch material of shore a 35 durometer (or higher), includes arch filler and other
    shaping material, custom fabricated, each, three pairs per year
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={2} className={classes.headerbox}>
                                HCPCS A9279
                            </Grid>
                            <Grid item xs={10} className={classes.shadedTextBox}>
    Monitoring feature/device, stand-alone or integrated, any type, includes all accessories, components and
    electronics, not otherwise classified
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={2} className={classes.headerbox}>
                                CPT 99453
                            </Grid>
                            <Grid item xs={10} className={classes.textBox}>
    Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory
    flow rate), initial; set-up and patient education on use of equipment
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={2} className={classes.headerbox}>
                                CPT 99454
                            </Grid>
                            <Grid item xs={10} className={classes.shadedTextBox}>
    Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory
    flow rate), initial; device(s) supply with daily recording(s) or programmed alert(s) transmission, each 30
    days
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={2} className={classes.headerbox}>
                                CPT 99457
                            </Grid>
                            <Grid item xs={10} className={classes.textBox}>
    Remote physiologic monitoring treatment management services, 20 minutes or more of clinical
    staff/physician/ other qualified health care professional time in a calendar month requiring interactive
    communication with the patient/caregiver during the month
                            </Grid>
                        </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.footnote}>
                    The reimbursement information provided is intended to provide general information concerning coding of Orpyx products only. Orpyx does not guarantee coverage or payment for any products. The ultimate responsibility for
    proper coding, satisfying reimbursement requirements, and obtaining reimbursement remains with the provider. Coding and coverage policies and guidelines are complex, can vary from one carrier or region to another, and are
    updated frequently. Providers should check with their local carriers or intermediaries often and should consult with counsel, a reimbursement specialist for coding, coverage, or billing questions.
                    </Grid>

                    <Grid item xs={12}>
                        <Box border={1} >
                        <Grid container>
                            <Grid item xs={12} className={classes.headerbox}>
                                Potential Reimbursement Scenarios
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            Codes
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            *physician payment when performed in the non -facility(office)
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            *physician payment when performed in the facility (HOPD)
                            </Grid>
                            <Grid item xs={3} className={classes.headerbox}>
                            *OPPS payments payment to the center:
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>
                            <Grid item xs={3} className={classes.headerbox}>
                            A5514
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $44.56 x 6 = $243.36
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $44.56 x 6 = $243.36
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $44.56 x 6 = $243.36 **This is
    payment to the physician. Center
    can only get paid if they have DME
    license
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            CPT 99453
                            </Grid>

                            <Grid item xs={3} className={classes.shadedTextBox}>
                            $19.46 x 3 = $58.38
    (if each pair of insoles is
    dispensed individually with
    medical necessity)
                            </Grid>

                            <Grid item xs={3} className={classes.shadedTextBox}>
                            Not payable
                            </Grid>
                            <Grid item xs={3} className={classes.shadedTextBox}>
                            $115.85 x3 = 347.55
    (if each pair of insoles is dispensed
    individually with medical
    necessity)
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            CPT 99454
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $64.15 x3 = $192.45
    (if each pair of insoles is
    dispensed individually with
    medical necessity)
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            Not payable
                            </Grid>
                            <Grid item xs={3} className={classes.textBox}>
                            $37.16 x3 = $111.48
    (if each pair of insoles is dispensed
    individually with medical
    necessity)
                            </Grid>

                            <Grid item xs={12} className={classes.headerbox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            CPT 99457
                            </Grid>

                            <Grid item xs={3} className={classes.shadedTextBox}>
                            $51.54 x 12= 618.48
                            </Grid>

                            <Grid item xs={3} className={classes.shadedTextBox}>
                            ***CPT 99457 - $32.44 x 12 = $389.28
                            </Grid>
                            <Grid item xs={3} className={classes.shadedTextBox}>
                            **TBD ($16.00 x 12= $192.00 clinic &amp; $16.44x 12= 197.28 Dr.)
                            </Grid>

                            <Grid item xs={12} className={classes.shadedTextBox}>
                                <Divider className={classes.line}></Divider>
                            </Grid>

                            <Grid item xs={3} className={classes.headerbox}>
                            Total per patient per year:
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $1,112.67
                            </Grid>

                            <Grid item xs={3} className={classes.textBox}>
                            $650.64
                            </Grid>
                            <Grid item xs={3} className={classes.textBox}>
                            $794.39
                            </Grid>
                        </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.footnote}>
                    ** If both the physician and the clinic staff member doing the monitoring are employees of the clinic, the physician can leverage the nurse to monitor the patient on his/her behalf and inform him/her if there’s an issue. In this
    scenario the physician would bill the CPT and WCA track the number of patients the RN/NP is monitoring and apply a charge to the physician. In this model we assumed WCA would charge $16.00 per patient
    *** If in the clinic and leveraging the clinic staff as mentioned above this amount is potentially distributed as suggested.
                    </Grid>

                </Grid>
            </div>
        </div>
    );
}

export default Faq;
