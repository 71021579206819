import {StoreAction} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserRevokeInviteActions {

    ORPYX_USER_REVOKE_INVITE = 'ORPYX_USER_REVOKE_INVITE: ORPYX_USER_REVOKE_INVITE',
    ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY = 'ORPYX_USER_REVOKE_INVITE: ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY',
    ORPYX_USER_REVOKE_INVITE_FAILED = 'ORPYX_USER_REVOKE_INVITE: ORPYX_USER_REVOKE_INVITE_FAILED',
}

export const ORPYX_USER_REVOKE_INVITE = (user: IOrpyxUserDto): StoreAction<OrpyxUserRevokeInviteActions> => ({
    type: OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE, payload: user
});

export const ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY = ( user: IOrpyxUserDto): StoreAction<OrpyxUserRevokeInviteActions> => ({
    type: OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY, payload: user
});

export const ORPYX_USER_REVOKE_INVITE_FAILED = ( user: IOrpyxUserDto): StoreAction<OrpyxUserRevokeInviteActions> => ({
    type: OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE_FAILED, payload: user
});

