import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientsCreateModalEvents, PatientsCreateModalMeta, PatientsCreateModalOwnProps, PatientsCreateModalProps} from "./patients-create-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {CREATE_PATIENT_SELECT_ORGANIZATION, CREATE_PATIENT_USER, CREATE_PATIENT_USER_HIDE_MODAL} from "../../store/patients-create-actions";
import {PatientsCreateSelectors} from "../../store/patients-create-selectors";
import {createModalWindow, LazyLoader, ModalWindowHeader} from "orpyx-web-common";

const PatientsCreateFormWrapper = React.lazy(() => import("../patients-create-form/patients-create-form"));


export const PatientsCreateModal = (props: PatientsCreateModalMeta) => {
    return (<>
            <ModalWindowHeader>
                Create New Patient
            </ModalWindowHeader>

            <LazyLoader height={60} width={300} rows={9}>
                <PatientsCreateFormWrapper
                    organization={props.organization}
                    inProcess={props.inProcess}
                    error={props.error}
                    onSubmit={props.createPatient}
                    onOrganizationSelected={props.onOrganizationSelected}
                />
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsCreateModalOwnProps => {
    return {
        organization: PatientsCreateSelectors.organization(state)!,

        error: PatientsCreateSelectors.error(state),
        inProcess: PatientsCreateSelectors.inProcess(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsCreateModalProps): PatientsCreateModalEvents => {
    return bindActionCreators({
        createPatient: CREATE_PATIENT_USER,
        onOrganizationSelected: CREATE_PATIENT_SELECT_ORGANIZATION
    }, dispatch);
};

export const PatientsCreateModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(PatientsCreateModal)),
    closeModalActionCreator: CREATE_PATIENT_USER_HIDE_MODAL,
    modalToggleSelector: PatientsCreateSelectors.isModalActive,
});
