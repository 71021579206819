import {all} from 'redux-saga/effects';
import PatientsListSagas from "../patients-list/store/patients-list-sagas";
import PatientsCreateSagas from "../patients-create/store/patients-create-sagas";
import PatientInformationSagas from "../patient-information/store/patient-information-sagas";
import {PatientDevicesSagas} from "../patient-devices/store/patient-devices-sagas";
import PatientDeleteSagas from "../patient-delete/store/patient-delete.sagas";
import PatientRestoreSagas from "../patient-restore/store/patient-restore.sagas";
import DownloadRawPatientTemperatureSages from "../patients-raw-temperature/store/patients-raw-temperature.sagas";

export function* PatientsSagas() {
    yield all([
        PatientsListSagas(),
        PatientsCreateSagas(),
        PatientDeleteSagas(),
        PatientRestoreSagas(),
        PatientInformationSagas(),
        PatientDevicesSagas(),
        DownloadRawPatientTemperatureSages(),
    ]);
}
