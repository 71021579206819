import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationRestoreModalEvents, OrganizationRestoreModalMeta, OrganizationRestoreModalOwnProps, OrganizationRestoreModalProps} from "./organization-restore-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {createModalWindow, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";
import {OrganizationRestoreSelectors} from "../../store/organization-restore.selectors";
import {ORGANIZATION_RESTORE, ORGANIZATION_RESTORE_HIDE_MODAL} from "../../store/organization-restore.actions";

const OrganizationRestoreModalTemplate = (props: OrganizationRestoreModalMeta) => {

    return (
        <>
            <ModalWindowHeader>
                Confirm restore organization
            </ModalWindowHeader>

            <div>
                Are you sure you want to restore deleted organization <b> {props.organization.name}</b>?
            </div>

            <OperationProgress error = {props.error} inProcess = {props.inProcess} />

            <ModalWindowSubmitButton
                disabled = {props.inProcess}
                onSubmit = {props.confirmOrganizationRestore}
                content = {'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationRestoreModalOwnProps => {
    return {
        inProcess: OrganizationRestoreSelectors.inProcess(state),
        error: OrganizationRestoreSelectors.error(state),

        organization: OrganizationRestoreSelectors.organization(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationRestoreModalProps): OrganizationRestoreModalEvents => {
    return bindActionCreators({
        confirmOrganizationRestore: ORGANIZATION_RESTORE
    }, dispatch);
};

export const OrganizationRestoreModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationRestoreModalTemplate)),
    closeModalActionCreator: ORGANIZATION_RESTORE_HIDE_MODAL,
    modalToggleSelector: OrganizationRestoreSelectors.toggle
});



