import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";

export enum PatientRestoreActions {

    PATIENT_RESTORE = 'PATIENT_RESTORE: PATIENT_RESTORE',
    PATIENT_RESTORE_SUCCESSFULLY = 'PATIENT_RESTORE: PATIENT_RESTORE_SUCCESSFULLY',
    PATIENT_RESTORE_FAILED = 'PATIENT_RESTORE: PATIENT_RESTORE_FAILED',

    SHOW_MODAL = 'PATIENT_RESTORE: SHOW_MODAL',
    HIDE_MODAL = 'PATIENT_RESTORE: HIDE_MODAL',
}

export const PATIENT_RESTORE = (): StoreAction<PatientRestoreActions> => ({
    type: PatientRestoreActions.PATIENT_RESTORE,
});

export const PATIENT_RESTORE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (patient: IGetPatientsListResult | IGetPatientInformationResult): StoreAction<PatientRestoreActions> => ({
    type: PatientRestoreActions.SHOW_MODAL, payload: {patient, actionAfterComplete}
});

export const PATIENT_RESTORE_HIDE_MODAL = (): StoreAction<PatientRestoreActions> => ({
    type: PatientRestoreActions.HIDE_MODAL,
});

export const PATIENT_RESTORE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<PatientRestoreActions> => ({
    type: PatientRestoreActions.PATIENT_RESTORE_SUCCESSFULLY, payload: actionAfterComplete
});

export const PATIENT_RESTORE_FAILED = (error: OperationErrorType): StoreAction<PatientRestoreActions> => ({
    type: PatientRestoreActions.PATIENT_RESTORE_FAILED, payload: error
});
