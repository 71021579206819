import {all, call, put, takeEvery} from 'redux-saga/effects';
import {DASHBOARD_USER_REVOKE_INVITE_FAILED, DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY, OrganizationUserRevokeInviteActions} from "./organization-user-revoke-invite.actions";
import {AdminDashboardUserInviteHttpClient, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, OperationErrorPipe, StoreAction} from "orpyx-web-common";

import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";

const API_VERSIONS = '1';

function* onOrganizationUserRevokeInvite(action: StoreAction<OrganizationUserRevokeInviteActions, { user: IUserOutputDto, organizationId: number }>) {
    const {user, organizationId} = action.payload!;

    try {
        const api = new AdminDashboardUserInviteHttpClient(ORPYXSI_ADMIN_URL);

        const data: IUserOutputDto = yield call(
            [api, api.revokeDashboardUserInvite],
            organizationId,
            user.id!,
            API_VERSIONS
        );

        yield put(DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY(data));

        yield put(ENQUEUE_SNACKBAR({
            message: `Invitation for health care practitioner ${fullNamePipe(user)} successfully revoked`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(DASHBOARD_USER_REVOKE_INVITE_FAILED(user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed revoke invitation for health care practitioner ${fullNamePipe(user)}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* OrganizationUserRevokeInviteSagas() {
    yield all([
        takeEvery(OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE, onOrganizationUserRevokeInvite),
    ]);
}