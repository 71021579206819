import {ListOrganizationsPropertyOrderBy} from "admins-dashboard/http-clients/index";
import {createListSearchOrderItem, ListSearchOrderByItem, orderByAlphabetPipe} from "orpyx-web-common";

export const OrganizationsListOrderByPipe = (order: ListOrganizationsPropertyOrderBy, orderByAsc?: boolean): string => {
    switch (order) {
        case ListOrganizationsPropertyOrderBy.OrganizationId:
            return 'Id';
        case ListOrganizationsPropertyOrderBy.Name:
            return orderByAlphabetPipe('Organization name', orderByAsc);
        case ListOrganizationsPropertyOrderBy.Address:
            return 'Organization address';
        case ListOrganizationsPropertyOrderBy.State:
            return orderByAlphabetPipe('Organization state', orderByAsc);
        case ListOrganizationsPropertyOrderBy.Phone:
            return 'Organization phone';
        case ListOrganizationsPropertyOrderBy.ContactPerson:
            return 'Contact person';
        case ListOrganizationsPropertyOrderBy.Email:
            return 'Email';
        case ListOrganizationsPropertyOrderBy.ZipCode:
            return 'Organization ZipCode';
        case ListOrganizationsPropertyOrderBy.City:
            return orderByAlphabetPipe('Organization city', orderByAsc);
        case ListOrganizationsPropertyOrderBy.CreationDate:
            return 'Creation date';
        case ListOrganizationsPropertyOrderBy.Deleted:
            return 'Deleted';
    }
    return `Unknown: ${order}`;
};

export const organizationsListOrderByItems: ListSearchOrderByItem[] = [
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.OrganizationId, OrganizationsListOrderByPipe),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Name, OrganizationsListOrderByPipe, true),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Name, OrganizationsListOrderByPipe, false),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Phone, OrganizationsListOrderByPipe),

    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Address, OrganizationsListOrderByPipe),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.State, OrganizationsListOrderByPipe, true),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.State, OrganizationsListOrderByPipe, false),

    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.City, OrganizationsListOrderByPipe, true),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.City, OrganizationsListOrderByPipe, false),

    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Deleted, OrganizationsListOrderByPipe),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.CreationDate, OrganizationsListOrderByPipe),

    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.ContactPerson, OrganizationsListOrderByPipe),
    createListSearchOrderItem<ListOrganizationsPropertyOrderBy>(ListOrganizationsPropertyOrderBy.Email, OrganizationsListOrderByPipe),
];