import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import * as React from "react";
import {memo} from "react";
import {OrpyxUserDeleteModalEvents, OrpyxUserDeleteModalMeta, OrpyxUserDeleteModalOwnProps} from "./orpyx-user-delete-modal.interface";
import OrpyxUserDeleteSelectors from "../../store/orpyx-user-delete-selectors";
import {DELETE_ORPYX_USER, DELETE_ORPYX_USER_HIDE_MODAL} from "../../store/orpyx-user-delete-actions";
import {createModalWindow, fullNamePipe, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";

const OrpyxUserDeleteModalTemplate = (props: OrpyxUserDeleteModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Confirm delete user
            </ModalWindowHeader>

            <div> Are you sure you want to delete user <b> {fullNamePipe(props.user)}</b>?</div>
            <div> Roles: {props.user.roles!.map(role => role!.role!.name).join(', ')}</div>

            <OperationProgress error = {props.error} inProcess = {props.inProcess} />

            <ModalWindowSubmitButton
                onSubmit = {props.confirmUserDelete}
                disabled = {props.inProcess}
                content = {'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserDeleteModalOwnProps => {
    return {
        error: OrpyxUserDeleteSelectors.error(state),
        inProcess: OrpyxUserDeleteSelectors.inProcess(state),
        user: OrpyxUserDeleteSelectors.user(state)!,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): OrpyxUserDeleteModalEvents => {
    return bindActionCreators({
        confirmUserDelete: DELETE_ORPYX_USER
    }, dispatch);
};

export const OrpyxUserDeleteModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserDeleteModalTemplate)),
    closeModalActionCreator: DELETE_ORPYX_USER_HIDE_MODAL,
    modalToggleSelector: OrpyxUserDeleteSelectors.toggle
});
