import OrpyxUsersListSages from "../orpyx-users-list/store/orpyx-users-list-sagas";
import OrpyxUserCreateSagas from "../orpyx-user-create/store/orpyx-user-create-sagas";
import OrpyxRolesSagas from "../../orpyx-roles/store/orpyx-roles-sagas";
import {all} from 'redux-saga/effects';
import OrpyxUserInformationSages from "../orpyx-user-information/store/orpyx-user-information-sagas";
import OrpyxUserRolesRevokeSages from "../orpyx-user-role-revoke/store/orpyx-user-role-revoke-sagas";
import OrpyxUserRoleAssignSagas from "../orpyx-user-role-assign/store/orpyx-user-role-assign-sagas";
import OrpyxUserDeleteSagas from "../orpyx-user-delete/store/orpyx-user-delete-sagas";
import OrpyxUserRevokeInviteSagas from "../orpyx-user-revoke-invite/store/orpyx-user-revoke-invite.sagas";
import OrpyxUserSendInviteSagas from "../orpyx-user-send-invite/store/orpyx-user-send-invite.sagas";
import OrpyxUserRestoreSagas from "../orpyx-user-restore/store/orpyx-user-restore.sagas";

export function* OrpyxUsersSages() {
    yield all([
        OrpyxUsersListSages(),
        OrpyxRolesSagas(),
        OrpyxUserCreateSagas(),
        OrpyxUserInformationSages(),
        OrpyxUserRolesRevokeSages(),
        OrpyxUserRoleAssignSagas(),

        OrpyxUserDeleteSagas(),
        OrpyxUserRestoreSagas(),

        OrpyxUserRevokeInviteSagas(),
        OrpyxUserSendInviteSagas(),
    ]);
}
