import {FormControlValidators} from "orpyx-web-common";
import {AuditLoggingFilterFormProps, AuditLoggingFilterFormValues} from "./audit-logging-filter-form.interface";
import {ActorType, PhiAccessType, GenerateReportPatientOptions} from "admins-dashboard";

const AuditLoggingFilterFormValidators = FormControlValidators.createValidators<AuditLoggingFilterFormValues>({
    begin: [
        FormControlValidators.required,
    ],
    end: [
        FormControlValidators.required,],
    organizationId: [
        FormControlValidators.numeric,
    ],
    actorType: [
        FormControlValidators.enumValue(ActorType)
    ],
    actorIdentifier: [],
    patientIdentifier: [],
    accessType: [
        FormControlValidators.enumValue(PhiAccessType),
    ],
    patientOptions: [
        FormControlValidators.enumValue(GenerateReportPatientOptions),
    ],
});

export const AuditLoggingFilterFormValidator = (values: AuditLoggingFilterFormValues, props: AuditLoggingFilterFormProps) => {
    const errors = AuditLoggingFilterFormValidators.validate(values);
    return errors;
}
