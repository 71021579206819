import {createSelector} from "reselect";
import {ApplicationAdminSharedStoreSelectors} from "../../store/application-admin-shared-store.selectors";
import {PatientInsolesAutoCompleteScope} from "./patient-insoles-auto-complete.types";

export class PatientInsolesAutoCompleteSelectors {
    static readonly states = createSelector(ApplicationAdminSharedStoreSelectors.state, state => state.patientInsolesAutoComplete);

    static readonly state = (scope: PatientInsolesAutoCompleteScope) => createSelector(PatientInsolesAutoCompleteSelectors.states, state => {
        return state[scope];
    });

    static readonly insoles = (scope: PatientInsolesAutoCompleteScope) => createSelector(PatientInsolesAutoCompleteSelectors.state(scope), state => state.insoles);

    static readonly error = (scope: PatientInsolesAutoCompleteScope) => createSelector(PatientInsolesAutoCompleteSelectors.state(scope), state => state.error);
    static readonly loading = (scope: PatientInsolesAutoCompleteScope) => createSelector(PatientInsolesAutoCompleteSelectors.state(scope), state => state.loading);

    static readonly term = (scope: PatientInsolesAutoCompleteScope) => createSelector(PatientInsolesAutoCompleteSelectors.state(scope), state => state.term);
}
