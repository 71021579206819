import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {OrpyxUserRoleAssignActions} from "./orpyx-user-role-assign-actions";

export interface  OrpyxUserRoleAssignState {
    user: IOrpyxUserDto | undefined,
    error?: OperationErrorType,

    inProcess: boolean,
    toggleModal: boolean;
}

const initialState: OrpyxUserRoleAssignState = {
    inProcess: false,
    toggleModal: false,
    user: undefined
}

export const OrpyxUserRoleAssignReducers = (state = initialState, action: StoreAction<OrpyxUserRoleAssignActions>): OrpyxUserRoleAssignState => {
    return produce(state, (draft: OrpyxUserRoleAssignState) => {
        switch (action.type) {
            case OrpyxUserRoleAssignActions.ASSIGN_ROLE:
                draft.inProcess = true;
                draft.error = undefined;
                break;

            case OrpyxUserRoleAssignActions.ASSIGN_ROLE_SHOW_MODAL:
                draft.inProcess = false;
                draft.toggleModal = true;
                draft.error = undefined;
                draft.user = action.payload;
                break;

            case OrpyxUserRoleAssignActions.ASSIGN_ROLE_HIDE_MODAL:
                draft.inProcess = false;
                draft.toggleModal = false;
                draft.error = undefined;
                draft.user = undefined;
                break;

            case OrpyxUserRoleAssignActions.ASSIGN_ROLE_SUCCESSFULLY:
                draft.inProcess = false;
                draft.toggleModal = false;
                draft.user = undefined;
                draft.error = undefined;
                break;

            case OrpyxUserRoleAssignActions.ASSIGN_ROLE_FAILED:
                draft.inProcess = false;
                draft.error = action.payload;
                break;

        }
    })
};

export default OrpyxUserRoleAssignReducers;
