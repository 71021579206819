import {ICreateProductWith2InsolesDto, IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientDevicesUpdateActions {
    UPDATE_PATIENT_DEVICE = 'UPDATE_PATIENT_DEVICE: UPDATE_PATIENT_DEVICE',
    UPDATE_PATIENT_DEVICE_SUCCESSFULLY = 'UPDATE_PATIENT_DEVICE: UPDATE_PATIENT_DEVICE_SUCCESSFULLY',
    UPDATE_PATIENT_DEVICE_FAILED = 'UPDATE_PATIENT_DEVICE: UPDATE_PATIENT_DEVICE_FAILED',

    SHOW_MODAL = 'UPDATE_PATIENT_DEVICE: SHOW_MODAL',
    HIDE_MODAL = 'UPDATE_PATIENT_DEVICE: HIDE_MODAL',
}

export const UPDATE_PATIENT_DEVICE = (model: ICreateProductWith2InsolesDto): StoreAction<PatientDevicesUpdateActions> => ({
    type: PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE, payload: model
});

export const UPDATE_PATIENT_DEVICE_SUCCESSFULLY = (): StoreAction<PatientDevicesUpdateActions> => ({
    type: PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE_SUCCESSFULLY
});

export const UPDATE_PATIENT_DEVICE_FAILED = (error: OperationErrorType): StoreAction<PatientDevicesUpdateActions> => ({
    type: PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE_FAILED, payload: error
});

export const UPDATE_PATIENT_DEVICE_SHOW_MODAL = (device: IListProductsResultEntry): StoreAction<PatientDevicesUpdateActions> => ({
    type: PatientDevicesUpdateActions.SHOW_MODAL, payload: device
});

export const UPDATE_PATIENT_DEVICE_HIDE_MODAL = (): StoreAction<PatientDevicesUpdateActions> => ({
    type: PatientDevicesUpdateActions.HIDE_MODAL
});
