import {createListSearchOrderItem, ListSearchOrderByItem, orderByAlphabetPipe} from "orpyx-web-common";
import {GetPatientsListPropertyOrderBy} from "admins-dashboard/http-clients/index";

export const PatientsListOrderPipe = (order: GetPatientsListPropertyOrderBy, orderByAsc?: boolean): string => {
    switch (order) {
        case GetPatientsListPropertyOrderBy.PatientId:
            return 'Id';
        case GetPatientsListPropertyOrderBy.FirstName:
            return orderByAlphabetPipe('First name', orderByAsc);
        case GetPatientsListPropertyOrderBy.LastName:
            return orderByAlphabetPipe('Last name', orderByAsc);
        case GetPatientsListPropertyOrderBy.NamesPair:
            return orderByAlphabetPipe('Full name', orderByAsc);
        case GetPatientsListPropertyOrderBy.ClientPatientIdentifier:
            return 'Patient Identifier';
        case GetPatientsListPropertyOrderBy.CreatedAt:
            return 'Created at';
        case GetPatientsListPropertyOrderBy.Organization:
            return 'Organization';
        case GetPatientsListPropertyOrderBy.Deleted:
            return 'Deleted';
    }
    return `Unknown: ${order}`;
};

export const PatientsListOrderPipeItems: ListSearchOrderByItem[] = [
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.PatientId, PatientsListOrderPipe),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.FirstName, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.FirstName, PatientsListOrderPipe, false),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.LastName, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.LastName, PatientsListOrderPipe, false),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.NamesPair, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.NamesPair, PatientsListOrderPipe, false),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.ClientPatientIdentifier, PatientsListOrderPipe),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.Organization, PatientsListOrderPipe),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.CreatedAt, PatientsListOrderPipe),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.Deleted, PatientsListOrderPipe),
];
