import {AdminOrpyxUsersHttpClient, IOrpyxUserDtoPagingResult} from "admins-dashboard/http-clients/index";
import {all, call, put, select, take, takeEvery} from 'redux-saga/effects';
import {FETCH_USERS_BEGIN, FETCH_USERS_FAILED, FETCH_USERS_SUCCESSFULLY, OrpyxUsersListActions} from "./orpyx-users-list-actions";
import {OrpyxUsersSearchQuery} from "../models/orpyx-users-search-query";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {convertOffsetToPage, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {OrpyxUserDeleteActions} from "../../orpyx-user-delete/store/orpyx-user-delete-actions";
import {OrpyxUserCreateActions} from "../../orpyx-user-create/store/orpyx-user-create-actions";
import {ORPYX_ROLES_INITIALIZE, OrpyxRolesActions} from "../../../orpyx-roles/store/orpyx-roles-actions";
import {OrpyxUserRestoreActions} from "../../orpyx-user-restore/store/orpyx-user-restore.actions";
import {OrpyxRolesSelectors} from "../../../orpyx-roles/store/orpyx-roles-selectors";
import {OrpyxUsersListSelectors} from "./orpyx-users-list-selectors";

const API_VERSION = '1';

function* onFetchOrpyxUsers() {
    const isOrpyxRolesLoaded = yield select(OrpyxRolesSelectors.initialized);
    if (isOrpyxRolesLoaded) {
        yield put(FETCH_USERS_BEGIN());
        yield onRefreshOrpyxUsers();
    } else {
        yield put(ORPYX_ROLES_INITIALIZE());

        const orpyxRolesInitializeResult: StoreAction<OrpyxRolesActions> = yield take([OrpyxRolesActions.INITIALIZE_SUCCESSFULLY, OrpyxRolesActions.INITIALIZE_FAILED]);
        if (orpyxRolesInitializeResult.type === OrpyxRolesActions.INITIALIZE_SUCCESSFULLY) {
            yield put(FETCH_USERS_BEGIN());
            yield onRefreshOrpyxUsers();
        }
    }
}

function* onRefreshOrpyxUsers() {
    try {
        const api = new AdminOrpyxUsersHttpClient(ORPYXSI_ADMIN_URL);
        const searchQuery: OrpyxUsersSearchQuery = yield select(OrpyxUsersListSelectors.search);

        const data: IOrpyxUserDtoPagingResult = yield call(
            [api, api.getOrpyxUsersList],
            searchQuery.term,
            searchQuery.orderByAsc,
            searchQuery.orderBy,
            searchQuery.orpyxRoleType,
            convertOffsetToPage(searchQuery.offset),
            searchQuery.count,
            API_VERSION
        );

        yield put(FETCH_USERS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_USERS_FAILED(e));
    }
}

export default function* OrpyxUsersListSages() {
    yield all([
        yield takeEvery(OrpyxUsersListActions.FETCH_USERS, onFetchOrpyxUsers),

        //  Refresh users after create or delete
        yield takeEvery(whenStoreActionAfterComplete(OrpyxUserDeleteActions.DELETE_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrpyxUsers),
        yield takeEvery(whenStoreActionAfterComplete(OrpyxUserRestoreActions.RESTORE_ORPYX_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrpyxUsers),
        yield takeEvery(OrpyxUserCreateActions.CREATE_USER_SUCCESSFULLY, onRefreshOrpyxUsers),

    ]);
}
