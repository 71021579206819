import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserDeleteActions {
    DELETE_USER = 'ORPYX_USER_DELETE: DELETE_USER',
    DELETE_USER_SUCCESSFULLY = 'ORPYX_USER_DELETE: DELETE_USER_SUCCESSFULLY',
    DELETE_USER_FAILED = 'ORPYX_USER_DELETE: DELETE_USER_FAILED',

    DELETE_USER_SHOW_MODAL = 'ORPYX_USER_DELETE: DELETE_USER_SHOW_MODAL',
    DELETE_USER_HIDE_MODAL = 'ORPYX_USER_DELETE: DELETE_USER_HIDE_MODAL',
}

export const DELETE_ORPYX_USER = (): StoreAction<OrpyxUserDeleteActions> => ({
    type: OrpyxUserDeleteActions.DELETE_USER,
});

export const DELETE_ORPYX_USER_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrpyxUserDeleteActions> => ({
    type: OrpyxUserDeleteActions.DELETE_USER_SUCCESSFULLY, payload: actionAfterComplete
});

export const DELETE_ORPYX_USER_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserDeleteActions> => ({
    type: OrpyxUserDeleteActions.DELETE_USER_FAILED, payload: error
});

export const DELETE_ORPYX_USER_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (user: IOrpyxUserDto | IOrpyxUserDto): StoreAction<OrpyxUserDeleteActions> => ({
    type: OrpyxUserDeleteActions.DELETE_USER_SHOW_MODAL, payload: {user, actionAfterComplete}
});

export const DELETE_ORPYX_USER_HIDE_MODAL = (): StoreAction<OrpyxUserDeleteActions> => ({
    type: OrpyxUserDeleteActions.DELETE_USER_HIDE_MODAL,
});