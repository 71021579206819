import {createSelector} from "reselect";
import {getPatientsStoreState} from "../../store/patients-selectors";

export class PatientsCreateSelectors {
    static readonly state = createSelector(getPatientsStoreState, state => state.create);
    static readonly error = createSelector(PatientsCreateSelectors.state, state => state.error);

    static readonly inProcess = createSelector(PatientsCreateSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(PatientsCreateSelectors.state, state => state.toggle);

    static readonly organization = createSelector(PatientsCreateSelectors.state, state => state.organization);
}
