import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {AdminOrpyxUsersRolesHttpClient, IAssignedRoleDto, IRevokeOrpyxUserRoleResult} from "admins-dashboard/http-clients/index";
import {OrpyxUserRoleRevokeActions, REVOKE_ROLE_FAILED, REVOKE_ROLE_SUCCESSFULLY} from "./orpyx-user-role-revoke-actions";
import {ENQUEUE_SNACKBAR, fullNamePipe} from "orpyx-web-common";
import {getOrpyxUserRevokeRoleModel, getOrpyxUserRevokeRoleUserId} from "./orpyx-user-role-revoke-selectors";

const API_VERSIONS = '1';

function* onRevokeOrpyxUserRole() {
    try {
        const model: IAssignedRoleDto = yield select(getOrpyxUserRevokeRoleModel);
        const userId: number = yield select(getOrpyxUserRevokeRoleUserId);

        const api = new AdminOrpyxUsersRolesHttpClient(ORPYXSI_ADMIN_URL);
        const data: IRevokeOrpyxUserRoleResult = yield call(
            [api, api.revokeOrpyxUserRole],
            userId,
            model.role!.role!,
            API_VERSIONS
        );

        yield put(REVOKE_ROLE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Role ${data.revokedRole!.name} successfully revoked for user ${fullNamePipe(data.orpyxUser)}`,
            options: {variant: 'success'}
        }));

    } catch (e) {
        yield put(REVOKE_ROLE_FAILED(e));
    }
}

export default function* OrpyxUserRolesRevokeSages() {
    yield all([
        yield takeEvery(OrpyxUserRoleRevokeActions.REVOKE_ROLE, onRevokeOrpyxUserRole),

    ]);
}

