import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {
    INITIALIZE_ORGANIZATION_UPDATE_FAILED,
    INITIALIZE_ORGANIZATION_UPDATE_SUCCESSFULLY,
    ORGANIZATION_UPDATE_FAILED,
    ORGANIZATION_UPDATE_SUCCESSFULLY,
    OrganizationUpdateActions
} from "./organization-update.actions";
import {AdminOrganizationHttpClient, IOrganizationInformationDto, IOrganizationOutputDto, OrganizationInformationDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {OrganizationUpdateSelectors} from "./organization-update.selectors";

const API_VERSIONS = '1';

function* onFetchOrganization(action: StoreAction<OrganizationUpdateActions, { organizationId: number, actionAfterComplete: StoreActionAfterComplete }>) {
    const {organizationId} = action.payload!;

    try {
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrganizationOutputDto = yield call(
            [api, api.getOrganization],
            organizationId,
            API_VERSIONS,
        );

        yield put(INITIALIZE_ORGANIZATION_UPDATE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(INITIALIZE_ORGANIZATION_UPDATE_FAILED(e));
    }
}

function* onUpdateOrganization(action: StoreAction<OrganizationUpdateActions, IOrganizationInformationDto>) {
    try {
        const model: OrganizationInformationDto = new OrganizationInformationDto(action.payload!);
        const organization: IOrganizationOutputDto = yield select(OrganizationUpdateSelectors.organization);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrganizationUpdateSelectors.actionAfterComplete);

        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrganizationOutputDto = yield call(
            [api, api.updateOrganization],
            organization.id!,
            API_VERSIONS,
            model
        );

        yield put(ORGANIZATION_UPDATE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Organization ${data.name} successfully updated`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(ORGANIZATION_UPDATE_FAILED(e));
    }
}

export default function* OrganizationUpdateSagas() {
    yield all([
        takeEvery(OrganizationUpdateActions.ORGANIZATION_UPDATE, onUpdateOrganization),
        takeEvery(OrganizationUpdateActions.SHOW_MODAL, onFetchOrganization),
    ]);
}
