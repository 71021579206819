import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {AdminOrganizationHttpClient, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {ORGANIZATION_INFORMATION_INITIALIZE_FAILED, ORGANIZATION_INFORMATION_INITIALIZE_SUCCESSFULLY, OrganizationInformationActions} from "./organization-information-actions";
import {OrganizationUpdateActions} from "../../organization-update/store/organization-update.actions";
import {OrganizationInformationSelectors} from "./organization-information-selectors";
import {OrganizationDeleteActions} from "../../organization-delete/store/organization-delete.actions";
import {OrganizationRestoreActions} from "../../organization-restore/store/organization-restore.actions";

const API_VERSIONS = '1';

function* getOrganizationInformation(organizationId: number) {
    try {
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);

        const data: IOrganizationOutputDto = yield call(
            [api, api.getOrganization],
            organizationId,
            API_VERSIONS
        );

        yield put(ORGANIZATION_INFORMATION_INITIALIZE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(ORGANIZATION_INFORMATION_INITIALIZE_FAILED(e));
    }
}

function* onFetchOrganizationInformation(action: StoreAction<OrganizationInformationActions, number>) {
    yield getOrganizationInformation(action.payload!);
}

function* onRefreshOrganizationInformation(action: StoreAction<OrganizationInformationActions, number>) {
    const organization: IOrganizationOutputDto = yield select(OrganizationInformationSelectors.organization);
    yield getOrganizationInformation(organization.id!);
}

export default function* OrganizationInformationSagas() {
    yield all([
        yield takeEvery(OrganizationInformationActions.INITIALIZE, onFetchOrganizationInformation),

        yield takeEvery(whenStoreActionAfterComplete(OrganizationUpdateActions.ORGANIZATION_UPDATE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrganizationInformation),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationDeleteActions.ORGANIZATION_DELETE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrganizationInformation),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationRestoreActions.ORGANIZATION_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrganizationInformation),
    ]);
}

