import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {OrganizationDropdownFilterActions} from "./organization-dropdown-filter-actions";

export interface OrganizationDropdownFilterState {
    loading: boolean;

    organizations: IOrganizationOutputDto[];

    term: string | undefined;
    error?: OperationErrorType;
}

export const initialOrganizationDropdownFilterState: OrganizationDropdownFilterState = {
    loading: false,
    error: undefined,

    term: undefined,
    organizations: [],
};

export const OrganizationDropdownFilterReducers = (state = initialOrganizationDropdownFilterState, action: StoreAction<OrganizationDropdownFilterActions>): OrganizationDropdownFilterState => {
    return produce(state, (draft: OrganizationDropdownFilterState) => {
        switch (action.type) {
            case OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS:
                draft.loading = true;
                draft.term = action.payload;
                break;

            case OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS_SUCCESSFULLY: {
                const result = action.payload! as IOrganizationOutputDto[];
                draft.organizations = result!;
                draft.loading = false;
                draft.error = undefined;
                break;
            }

            case OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS_FAILED:
                draft.loading = false;
                draft.error = action.payload!;
                draft.organizations = [];
                break;
        }
    })
};
