import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {AdminProductHttpClient, ISearchPatientInsolesResponseEntry} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {PatientInsolesAutoCompleteActions, SEARCH_PATIENT_INSOLES_FAILED, SEARCH_PATIENT_INSOLES_SUCCESSFULLY} from "./patient-insoles-auto-complete.actions";
import {PatientInsolesAutoCompleteSelectors} from "./patient-insoles-auto-complete.selectors";
import {ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import {PatientInsolesAutoCompleteScope} from "./patient-insoles-auto-complete.types";

const API_VERSIONS = '1';

function* onSearchPatientInsoles(action: StoreAction<PatientInsolesAutoCompleteActions, any, PatientInsolesAutoCompleteScope>) {
    //  Search typing debounce 500ms then takeLatest & search
    yield delay(500);

    try {
        const scope = action.scope!;
        const api = new AdminProductHttpClient(ORPYXSI_ADMIN_URL);

        const searchTerm: string = yield select(PatientInsolesAutoCompleteSelectors.term(scope))!;
        const data: ISearchPatientInsolesResponseEntry[] = yield call([api, api.searchPatientInsoles],
            searchTerm,
            API_VERSIONS
        );

        yield put(SEARCH_PATIENT_INSOLES_SUCCESSFULLY(action.scope!)(data));
    } catch (e) {
        yield put(SEARCH_PATIENT_INSOLES_FAILED(action.scope!)(e));
        yield put(ENQUEUE_SNACKBAR({
            message: `Failed search patient insoles, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* PatientInsolesAutoCompleteSagas() {
    yield all([
        yield takeLatest(PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES, onSearchPatientInsoles),
    ]);
}
