import {AdminOrpyxCareUserHttpClient, DashboardRolePermissionFilterDto, IListOrpyxCareUsersResultEntryPagingResult} from "admins-dashboard/http-clients/index";
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FETCH_USERS_BEGIN, FETCH_USERS_FAILED, FETCH_USERS_SUCCESSFULLY, OrpyxCareUsersListActions, UPDATE_PERMISSIONS_FILTER_FAILED, UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY} from "./orpyx-care-users-list-actions";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {convertOffsetToPage} from "orpyx-web-common";
import { OrpyxCareUsersListSelectors } from "./orpyx-care-users-list-selectors";
import { OrpyxCareUsersSearchQuery } from "../models/orpyx-care-users-search-query";
import { OrpyxCareConfirmationModalActions } from "../../orpyx-care-confirmation-modal/store/orpyx-care-confirmation-actions";

const API_VERSION = '1';

function* onFetchOrpyxCareUsers() {
    yield put(FETCH_USERS_BEGIN());
    yield onRefreshOrpyxCareUsers();
}

function* onRefreshOrpyxCareUsers() {
    try {
        const api = new AdminOrpyxCareUserHttpClient(ORPYXSI_ADMIN_URL);
        const searchQuery: OrpyxCareUsersSearchQuery = yield select(OrpyxCareUsersListSelectors.search);
        const permissionFilter: DashboardRolePermissionFilterDto = yield select(OrpyxCareUsersListSelectors.filter);

        const data: IListOrpyxCareUsersResultEntryPagingResult = yield call(
            [api, api.getOrpyxCareUsersList],
            searchQuery.orderByAsc,
            searchQuery.orderBy,
            searchQuery.term,
            permissionFilter,
            convertOffsetToPage(searchQuery.offset),
            searchQuery.count,
            API_VERSION
        );

        yield put(FETCH_USERS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_USERS_FAILED(e));
    }
}

function* onUpdatePermissionsFilter(filter: any) {
    try {
        yield put(UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY(filter.payload));
        yield onRefreshOrpyxCareUsers();
    } catch (e) {
        yield put(UPDATE_PERMISSIONS_FILTER_FAILED(e));
    }
}

export default function* OrpyxCareUsersListSagas() {
    yield all([
        yield takeEvery(OrpyxCareUsersListActions.FETCH_USERS, onFetchOrpyxCareUsers),
        yield takeEvery(OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY, onRefreshOrpyxCareUsers),
        yield takeEvery(OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER, onUpdatePermissionsFilter)
    ]);
}
