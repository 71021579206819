import {createSelector} from "reselect";
import {getOrganizationsState} from "../../store/organizations-store-selectors";

export class OrganizationDeleteSelectors {

    static readonly state = createSelector(getOrganizationsState, state => state.delete);
    static readonly error = createSelector(OrganizationDeleteSelectors.state, state => state.error);
    static readonly organization = createSelector(OrganizationDeleteSelectors.state, state => state.organization!);

    static readonly actionAfterComplete = createSelector(OrganizationDeleteSelectors.state, state => state.actionAfterComplete);
    static readonly inProcess = createSelector(OrganizationDeleteSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(OrganizationDeleteSelectors.state, state => state.toggle);
}
