import * as React from "react";
import {useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientsListPageEvents, PatientsListPageMeta, PatientsListPageOwnProps, PatientsListPageProps} from "./patients-list-page.interface";

import {usePatientsListPageStyle} from "./patients-list-page.style";
import {
    onPatientsListCountChanged,
    onPatientsListOrganizationChanged,
    onPatientsListOrganizationReload,
    onPatientsListPageChanged,
    onPatientsListPageLoaded,
    onPatientsListSearchOptionsChanged,
    onPatientsListTermValidation
} from "./patients-list-page.hooks";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {PatientsListSelectors} from "../../store/patients-list-selectors";
import {FETCH_PATIENTS, PATIENTS_INITIALIZE} from "../../store/patients-list-actions";
import {withRouter} from "react-router";
import {ActionButton, ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, DatePipe, deactivatedSuffixPipe, LazyLoader, ListPagination, ListSearch} from "orpyx-web-common";
import {generateBreadcrumbs} from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {Container} from "@material-ui/core";
import {PatientsCreateModalWrapper} from "../../../patients-create/components/patients-create-modal/patients-create-modal";
import {CREATE_PATIENT_USER_SHOW_MODAL} from "../../../patients-create/store/patients-create-actions";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import AdminPatientsPolicies from "../../../../policies/admin-patients-policies";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {PatientDeleteModalWindow} from "../../../patient-delete/components/patient-delete-modal/patient-delete-modal";
import {PatientRestoreModalWindow} from "../../../patient-restore/components/patient-restore-modal/patient-restore-modal";
import {PatientsListOrderPipeItems} from "../../enum/patients-list-order";
import {OrganizationAutoComplete} from "../../../../shared/organization-dropdown-filter/components/organization-auto-complete";

const PatientsListTableWrapper = React.lazy(() => import( "../patients-list-table/patients-list-table"));

const generatePageBreadcrumbs = (organization: IOrganizationOutputDto | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Patients',
        url: '/admin/organizations/patients'
    }];

    if (organization) {
        items.push({
            name: deactivatedSuffixPipe(organization.name!, organization.deleted!),
            url: `/admin/organizations/${organization.id}/patients`
        });
    }

    return generateBreadcrumbs(items);
};

const PatientsListPage = (props: PatientsListPageMeta) => {
    const classes = usePatientsListPageStyle();

    useEffect(onPatientsListPageLoaded(props), [props.location]);
    useEffect(onPatientsListOrganizationReload(props), [props.match.params.organizationId]);

    return (
        <div className={classes.root}>
            {props.organization && <div className={classes.organizationInfo}>
                <div><span>Organization Name:</span> {props.organization.name} </div>
                <div><span>Organization address:</span> {props.organization.address?.street} </div>
                <div><span>Phone number:</span> {props.organization.phoneNumber} </div>
                <div><span>Date added:</span> {DatePipe(props.organization.creationDate)} </div>
            </div>}

            <Container>
                <div>
                    <ListSearch
                        term={props.search.term}
                        inProcess={props.loading}
                        orderBy={props.search.orderBy}
                        orderByAsc={props.search.orderByAsc}
                        orderByItems={PatientsListOrderPipeItems}
                        placeholder={'Search patients by name or Patient ID'}
                        onSearch={onPatientsListSearchOptionsChanged(props)}
                        onSearchValidate={onPatientsListTermValidation}
                    />
                </div>

                <div>
                    <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.organization)}/>
                </div>

                <div className={classes.content}>
                    <div className={classes.topBlock}>
                        <div className={classes.title}>
                            {props.organization ? <>
                                {props.total} patients in {deactivatedSuffixPipe(props.organization.name!, props.organization.deleted!)}
                            </> : <>
                                {props.total} patients
                            </>}
                        </div>
                        <OrganizationAutoComplete
                            scope={'patientsListPage'}
                            key={props.organization?.id}
                            organization={props.organization ? {
                                id: props.organization!.id!,
                                name: props.organization!.name!,
                                deleted: props.organization!.deleted!,
                            } : null}
                            onSelected={onPatientsListOrganizationChanged(props)}
                        />
                    </div>

                    <LazyLoader height={60} rows={8}>
                        <PatientsListTableWrapper organization={props.organization}/>
                    </LazyLoader>

                    <div>
                        <ListPagination
                            pages={props.pages}
                            page={props.search.offset}
                            count={props.search.count}
                            label={'Patients per page'}
                            onSelectedPageChanged={onPatientsListPageChanged(props)}
                            onDisplayCountChanged={onPatientsListCountChanged(props)}
                        />
                    </div>

                    <div>
                        <AdminBlockWrapper requiredRole={AdminPatientsPolicies.Create}>
                            <ActionButton
                                disabled={props.organization?.deleted}
                                onSubmit={() => props.showCreatePatientModal(props.organization!)}
                                content={"+ Add New Patient"}
                            />

                            <PatientsCreateModalWrapper/>
                            <PatientDeleteModalWindow/>
                            <PatientRestoreModalWindow/>
                        </AdminBlockWrapper>
                    </div>

                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsListPageOwnProps => {
    return {
        search: PatientsListSelectors.search(state),

        organization: PatientsListSelectors.organization(state),

        patients: PatientsListSelectors.patients(state),

        initialized: PatientsListSelectors.initialized(state),
        loading: PatientsListSelectors.loading(state),
        total: PatientsListSelectors.total(state),
        pages: PatientsListSelectors.pages(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsListPageProps): PatientsListPageEvents => {
    return bindActionCreators({
        refreshPatientsList: FETCH_PATIENTS,
        loadOrganizationInformation: PATIENTS_INITIALIZE,
        showCreatePatientModal: CREATE_PATIENT_USER_SHOW_MODAL
    }, dispatch);
};

export const PatientsListPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsListPage));
