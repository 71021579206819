import {all, call, put, takeEvery} from 'redux-saga/effects';
import {AdminDashboardUserTitlesHttpClient, DashboardUserTitleOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../appConstants";
import {DASHBOARD_USER_TITLES_INITIALIZE_FAILED, DASHBOARD_USER_TITLES_INITIALIZE_SUCCESSFULLY, DashboardUserTitlesActions} from "./dashboard-user-titles-actions";

const API_VERSION = '1';

function* onGetDashboardUserTitles() {
    try {
        const api = new AdminDashboardUserTitlesHttpClient(ORPYXSI_ADMIN_URL);
        const data: DashboardUserTitleOutputDto[] = yield call([api, api.getDashboardUserTitles], API_VERSION);
        yield put(DASHBOARD_USER_TITLES_INITIALIZE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(DASHBOARD_USER_TITLES_INITIALIZE_FAILED(e));
    }
}

export default function* DashboardUserTitlesSagas() {
    yield all([
        takeEvery(DashboardUserTitlesActions.INITIALIZE, onGetDashboardUserTitles)
    ]);
}
