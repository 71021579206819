import {IModalWindow, ModalWindowStore, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientInsolesDeleteActions} from "./patient-insoles-delete-actions";
import {IInsolesListEntry, IListProductsResultEntry} from "admins-dashboard/http-clients/index";

export interface PatientInsolesDeleteState extends IModalWindow {
    insole: IInsolesListEntry | undefined;
    device: IListProductsResultEntry | undefined;
}

const initialState: PatientInsolesDeleteState = {
    toggle: false,
    inProcess: false,

    insole: undefined,
    device: undefined,

    error: undefined,
};

const PatientInsolesDeleteReducers = (state = initialState, action: StoreAction<PatientInsolesDeleteActions>): PatientInsolesDeleteState => {
    return produce(state, (draft: PatientInsolesDeleteState) => {
        switch (action.type) {
            case PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientInsolesDeleteActions.SHOW_MODAL:
                draft.device = action.payload.device;
                draft.insole = action.payload.insole;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientInsolesDeleteActions.HIDE_MODAL:
                draft.device = undefined;
                draft.insole = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    });
};

export default PatientInsolesDeleteReducers;
