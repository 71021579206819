import {call, put, takeEvery} from 'redux-saga/effects';
import {AdminDashboardRolesHttpClient, DashboardRole} from "admins-dashboard/http-clients/index";
import {DASHBOARD_ROLES_INITIALIZE_FAILED, DASHBOARD_ROLES_INITIALIZE_SUCCESSFULLY, DashboardRolesActions} from "./dashboard-roles-actions";
import { ORPYXSI_ADMIN_URL } from 'appConstants';

const API_VERSION = '1';

function* onFetchDashboardRoles() {
    try {
        const api = new AdminDashboardRolesHttpClient(ORPYXSI_ADMIN_URL);
        const data: DashboardRole[] = yield call([api, api.getDashboardRoles], API_VERSION);
        yield put(DASHBOARD_ROLES_INITIALIZE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(DASHBOARD_ROLES_INITIALIZE_FAILED(e));
    }
}

export default function* DashboardRolesSagas() {
    yield takeEvery(DashboardRolesActions.INITIALIZE, onFetchDashboardRoles)
}
