import {Field} from "formik";
import {Select} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import {default as React, memo} from "react";
import {DropdownSelectProps} from "./dropdown-select.interface";

//  TODO: Move to common package
export const DropdownSelect = memo((props: DropdownSelectProps) => {
    const inputProps = {
        id: props.inputPropName,
        name: props.inputPropName,
    };

    return (
        <Field
            component={Select}
            className={props.inputStyle}
            name={props.inputPropName}
            value={props.selectedValues}
            multiple={props.multiple}
            disabled={props.disabled}
            inputProps={inputProps}
            placeholder={props.placeholder}
        >
            {props.values.map(value => (<MenuItem key={value.value} value={value.value}>
                {value.name}
            </MenuItem>))}
        </Field>
    );
});
