import {AuditLoggingDownloadActions} from "./audit-logging-download.actions";
import produce from "immer";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface AuditLoggingDownloadState {
    loading: boolean;
    error: OperationErrorType;
}

const initialState: AuditLoggingDownloadState = {
    loading: false,
    error: undefined
};

const AuditLoggingDownloadReducer = (state = initialState, action: StoreAction<AuditLoggingDownloadActions>): AuditLoggingDownloadState => {
    return produce(state, (draft: AuditLoggingDownloadState) => {
        switch (action.type) {
            case AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING:
                draft.error = undefined;
                draft.loading = true;
                break;
            case AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING_SUCCESSFULLY:
                draft.error = undefined;
                draft.loading = false;
                break;
            case AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default AuditLoggingDownloadReducer;
