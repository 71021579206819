import {createSelector} from "reselect";
import {getAdminStoreState} from "../../store/admin-store-selectors";

export class OrpyxRolesSelectors {
    static readonly state = createSelector(getAdminStoreState, state => state.orpyxRoles);

    static readonly roles = createSelector(OrpyxRolesSelectors.state, state => state.roles);
    static readonly initialized = createSelector(OrpyxRolesSelectors.state, state => state.initialized);
    static readonly error = createSelector(OrpyxRolesSelectors.state, state => state.error);
}


