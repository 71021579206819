import {createSelector} from 'reselect'
import {getOrpyxUsersState} from "../../store/orpyx-users-store-selectors";
import {getPagesCount} from "orpyx-web-common";

export class OrpyxUsersListSelectors {
    static readonly state = createSelector(getOrpyxUsersState, state => state.list);
    static readonly search = createSelector(OrpyxUsersListSelectors.state, state => state.search);
    static readonly users = createSelector(OrpyxUsersListSelectors.state, state => state.users);
    static readonly total = createSelector(OrpyxUsersListSelectors.state, state => state.total);
    static readonly pages = createSelector(OrpyxUsersListSelectors.state, state => getPagesCount(state.total, state.search.count));

    static readonly loading = createSelector(OrpyxUsersListSelectors.state, state => state.loading);
    static readonly error = createSelector(OrpyxUsersListSelectors.state, state => state.error);
}

