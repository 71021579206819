import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {SEARCH_PATIENT_INSOLES} from "../store/patient-insoles-auto-complete.actions";
import {memo} from "react";
import {connect} from "react-redux";
import * as React from "react";
import {PatientInsolesAutoCompleteEvents, PatientInsolesAutoCompleteMeta, PatientInsolesAutoCompleteOwnProps, PatientInsolesAutoCompleteProps} from "./patient-insoles-auto-complete.interface";
import {PatientInsolesAutoCompleteSelectors} from "../store/patient-insoles-auto-complete.selectors";
import {AutocompleteMultipleSelect} from "../../autocomplete-multiple-select/autocomplete-multiple-select";

const PatientInsolesAutoCompleteTemplate = (props: PatientInsolesAutoCompleteMeta) => {
    return <>
        {<AutocompleteMultipleSelect
            scope={props.scope}
            loading={props.loading}
            onSelected={props.onSelected}
            onSearch={props.searchPatientInsoles}
            values={props.insoles.map(insole => ({
                id: insole.insoleId!,
                name: `${insole.insoleSerialNumber} | ${insole.isLeft ? 'L' : 'R'} | ${insole.productSerialNumber}`,
            }))}
            placeholder={'Insole Serial Numbers'}
        />}
    </>
};

const mapStateToProps = (state: IOrpyxApplicationStore, props: PatientInsolesAutoCompleteProps): PatientInsolesAutoCompleteOwnProps => {
    return {
        loading: PatientInsolesAutoCompleteSelectors.loading(props.scope)(state),
        insoles: PatientInsolesAutoCompleteSelectors.insoles(props.scope)(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInsolesAutoCompleteProps): PatientInsolesAutoCompleteEvents => {
    return bindActionCreators({
        searchPatientInsoles: SEARCH_PATIENT_INSOLES(props.scope),
    }, dispatch);
};

export const PatientInsolesAutoComplete = memo(connect(mapStateToProps, mapDispatchToProps)(PatientInsolesAutoCompleteTemplate));

