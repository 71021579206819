import {IAssignedRoleDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrpyxUserRoleRevokeActions {
    REVOKE_ROLE = 'ORPYX_USER_ROLES_REVOKE: REVOKE_ROLE',
    REVOKE_ROLE_SUCCESSFULLY = 'ORPYX_USER_ROLES_REVOKE: REVOKE_ROLE_SUCCESSFULLY',
    REVOKE_ROLE_FAILED = 'ORPYX_USER_ROLES_REVOKE: REVOKE_ROLE_FAILED',

    REVOKE_ROLE_SHOW_MODAL = 'ORPYX_USER_ROLES_REVOKE: REVOKE_ROLE_SHOW_MODAL',
    REVOKE_ROLE_HIDE_MODAL = 'ORPYX_USER_ROLES_REVOKE: REVOKE_ROLE_HIDE_MODAL',
}

export const REVOKE_ROLE = (): StoreAction<OrpyxUserRoleRevokeActions> => ({
    type: OrpyxUserRoleRevokeActions.REVOKE_ROLE,
});

export const REVOKE_ROLE_SUCCESSFULLY = (): StoreAction<OrpyxUserRoleRevokeActions> => ({
    type: OrpyxUserRoleRevokeActions.REVOKE_ROLE_SUCCESSFULLY
});

export const REVOKE_ROLE_FAILED = (error: OperationErrorType): StoreAction<OrpyxUserRoleRevokeActions> => ({
    type: OrpyxUserRoleRevokeActions.REVOKE_ROLE_FAILED, payload: error
});

export const REVOKE_ROLE_SHOW_MODAL = (orpyxUserId: number, userRole: IAssignedRoleDto): StoreAction<OrpyxUserRoleRevokeActions> => ({
    type: OrpyxUserRoleRevokeActions.REVOKE_ROLE_SHOW_MODAL, payload: {
        orpyxUserId, userRole
    }
});

export const REVOKE_ROLE_HIDE_MODAL = (): StoreAction<OrpyxUserRoleRevokeActions> => ({
    type: OrpyxUserRoleRevokeActions.REVOKE_ROLE_HIDE_MODAL,
});
