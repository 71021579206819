import {createSelector} from "reselect";
import {getOrganizationsState} from "../../store/organizations-store-selectors";

export class OrganizationRestoreSelectors {

    static readonly state = createSelector(getOrganizationsState, state => state.restore);
    static readonly error = createSelector(OrganizationRestoreSelectors.state, state => state.error);
    static readonly organization = createSelector(OrganizationRestoreSelectors.state, state => state.organization!);

    static readonly actionAfterComplete = createSelector(OrganizationRestoreSelectors.state, state => state.actionAfterComplete);
    static readonly inProcess = createSelector(OrganizationRestoreSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(OrganizationRestoreSelectors.state, state => state.toggle);
}
