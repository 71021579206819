import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrganizationNameDto} from "admins-dashboard/http-clients/index";
import {OrganizationDropdownFilterScope} from "./organization-dropdown-filters.types";

export enum OrganizationDropdownFilterActions {
    SEARCH_ORGANIZATIONS = 'SEARCH_ORGANIZATIONS: SEARCH_ORGANIZATIONS',
    SEARCH_ORGANIZATIONS_SUCCESSFULLY = 'SEARCH_ORGANIZATIONS: SEARCH_ORGANIZATIONS_SUCCESSFULLY',
    SEARCH_ORGANIZATIONS_FAILED = 'SEARCH_ORGANIZATIONS: SEARCH_ORGANIZATIONS_FAILED',
}

export const SEARCH_ORGANIZATIONS = (scope: OrganizationDropdownFilterScope) => (term: string | undefined | null): StoreAction<OrganizationDropdownFilterActions> => ({
    type: OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS, payload: term, scope
});

export const SEARCH_ORGANIZATIONS_SUCCESSFULLY = (scope: OrganizationDropdownFilterScope) => (organizations: IOrganizationNameDto[]): StoreAction<OrganizationDropdownFilterActions> => ({
    type: OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS_SUCCESSFULLY, payload: organizations, scope
});

export const SEARCH_ORGANIZATIONS_FAILED = (scope: OrganizationDropdownFilterScope) => (error: OperationErrorType): StoreAction<OrganizationDropdownFilterActions> => ({
    type: OrganizationDropdownFilterActions.SEARCH_ORGANIZATIONS_FAILED, payload: error, scope
});
