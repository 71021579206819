import {OrderByQuery, PagingQuery, parseEnum} from "orpyx-web-common";
import {ListDashboardUserPropertyOrderBy} from "admins-dashboard/http-clients/index";

export class DashboardUsersSearchQuery extends OrderByQuery<ListDashboardUserPropertyOrderBy> {
    term?: string | undefined;

    public static parse(term: string | undefined, orderBy: string | undefined, orderByAsc: string | undefined, offset: string | undefined, count: string | undefined): DashboardUsersSearchQuery {
        const orderByValue = parseEnum(ListDashboardUserPropertyOrderBy, orderBy, ListDashboardUserPropertyOrderBy.DashboardUserId);
        return new DashboardUsersSearchQuery
        (
            term,
            orderByValue,
            !!orderByAsc,
            offset ? parseInt(offset) : PagingQuery.DEFAULT_PAGE,
            count ? parseInt(count) : PagingQuery.DEFAULT_COUNT
        );
    }

    constructor(term?: string | undefined, orderBy?: ListDashboardUserPropertyOrderBy, orderByAsc?: boolean, offset: number | undefined = PagingQuery.DEFAULT_PAGE, count: number | undefined = PagingQuery.DEFAULT_COUNT) {
        super(orderBy, orderByAsc, offset, count);
        this.term = term;
    }
}

export const toQueryString = (query: DashboardUsersSearchQuery): string => {
    let str = `${DashboardUsersSearchQuery.PARAM_OFFSET}=${query.offset}&${DashboardUsersSearchQuery.PARAM_COUNT}=${query.count}`;
    if (query.term) {
        str += `&term=${query.term}`;
    }

    if (query.orderBy) {
        str += `&${DashboardUsersSearchQuery.PARAM_ORDER_BY}=${query.orderBy}`;
    }

    if (query.orderByAsc) {
        str += `&${DashboardUsersSearchQuery.PARAM_ORDER_BY_ASC}=${query.orderByAsc}`;
    }

    return str;
}
