import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CREATE_PATIENT_DEVICE_FAILED, CREATE_PATIENT_DEVICE_SUCCESSFULLY, PatientDevicesCreateActionAfterComplete, PatientDevicesCreateActions} from "./patient-devices-create-actions";
import {
    AdminPatientHttpClient,
    CreateProductWith2InsolesDto,
    ICreateProductResult,
    ICreateProductWith2InsolesDto,
    IGetPatientInformationResult,
    IGetPatientsListResult
} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {PatientDevicesCreateSelectors} from "./patient-devices-create-selectors";

const API_VERSIONS = '1';

function* onCreatePatientDevice(action: StoreAction<PatientDevicesCreateActions, ICreateProductWith2InsolesDto>) {
    try {
        const actionAfterComplete: PatientDevicesCreateActionAfterComplete = yield select(PatientDevicesCreateSelectors.actionAfterComplete);
        const patient: IGetPatientsListResult | IGetPatientInformationResult = yield select(PatientDevicesCreateSelectors.patient);

        const model = new CreateProductWith2InsolesDto(action.payload!);
        const api = new AdminPatientHttpClient(ORPYXSI_ADMIN_URL);

        const data: ICreateProductResult = yield call(
            [api, api.createPatientProduct],
            patient.patient!.patientId!,
            API_VERSIONS,
            model
        );

        yield put(CREATE_PATIENT_DEVICE_SUCCESSFULLY(actionAfterComplete));
        if(actionAfterComplete === PatientDevicesCreateActionAfterComplete.RefreshUserInformation) {
            yield put(ENQUEUE_SNACKBAR({
                message: `Successfully added product with sn ${data.productSerialNumber}`,
                options: {variant: "success"}
            }));
        } else {
            yield put(ENQUEUE_SNACKBAR({
                message: `Successfully added product with sn ${data.productSerialNumber} for patient ${fullNamePipe(patient.patient)}`,
                options: {variant: "success"}
            }));
        }

    } catch (e) {
        yield put(CREATE_PATIENT_DEVICE_FAILED(e));
    }
}

export default function* PatientDevicesCreateSagas() {
    yield all([
        takeEvery(PatientDevicesCreateActions.CREATE_PATIENT_DEVICE, onCreatePatientDevice),
    ]);
}

