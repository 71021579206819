import {Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";
import {createStyles, makeStyles} from '@material-ui/styles';

export const PatientsRawTemperaturePageStyle = createStyles({
    root: {
        margin: '30px',
    },
    paging: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        padding: '27px 50px',
    },
    title: {
        fontSize: '20px',
        color: Teal,
    },
    topBlock: {
        marginBottom: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formControl: {
        margin: 0,
        minWidth: 190,
        height: '135px',
    },
    label: {
        top: '-12px'
    },
    header: {
        marginBottom: 0,
    },
    group: {

    }
});

export const usePatientsRawTemperaturePageStyle = makeStyles((theme: Theme) => PatientsRawTemperaturePageStyle);
