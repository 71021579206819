import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationInformationPageEvents, OrganizationInformationPageMeta, OrganizationInformationPageOwnProps, OrganizationInformationPageProps} from "./organization-information-page.interface";
import {onOrganizationDashboardUsersSearchQueryChanged, onOrganizationInformationPageLoaded} from "./organization-information-page.hooks";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import useOrganizationInformationPageStyle from "./organization-information-page.style";
import {Container} from "@material-ui/core";
import {ORGANIZATION_INFORMATION_INITIALIZE, ORGANIZATION_INFORMATION_INITIALIZE_FAILED} from "../../store/organization-information-actions";
import {
    ActionButton, AdvancedIconButton,
    ApplicationBreadcrumbs,
    ApplicationBreadcrumbsItem,
    ButtonStopPropagationPipe,
    DatePipe, deactivatedSuffixPipe,    
    OperationProgress,
    OperationSkeleton,
    StoreActionAfterComplete
} from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {OrganizationUserCreateModalWrapper} from "../../../organization-users/organization-user-create/components/organization-user-create-modal/organization-user-create-modal";
import {CREATE_DASHBOARD_USER_SHOW_MODAL} from "../../../organization-users/organization-user-create/store/organization-user-create-actions";
import {OrganizationInformationSelectors} from "../../store/organization-information-selectors";
import {withRouter} from "react-router";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import {AdminDashboardUsersPolicies} from "../../../../policies/admin-dashboard-users-policies";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import AdminOrganizationsPolicies from "../../../../policies/admin-organizations-policies";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@material-ui/icons/RestoreFromTrashOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {ORGANIZATION_UPDATE_SHOW_MODAL} from "../../../organization-update/store/organization-update.actions";
import {ORGANIZATION_DELETE_SHOW_MODAL} from "../../../organization-delete/store/organization-delete.actions";
import {ORGANIZATION_RESTORE_SHOW_MODAL} from "../../../organization-restore/store/organization-restore.actions";
import {OrganizationUpdateModalWindow} from "../../../organization-update/components/organization-update-modal/organization-update-modal";
import {OrganizationDeleteModalWindow} from "../../../organization-delete/components/organization-delete-modal/organization-delete-modal";
import {OrganizationRestoreModalWindow} from "../../../organization-restore/components/organization-restore-modal/organization-restore-modal";
import OrganizationUsersListSelectors from "../../../organization-users/organization-users-list/store/organization-users-list-selectors";
import {LazyLoader} from "orpyx-web-common";
import {Link} from "react-router-dom";

const OrganizationUsersList = React.lazy(() => import("../../../organization-users/organization-users-list/components/organization-users-list/organization-users-list"));

const generatePageBreadcrumbs = (organization: IOrganizationOutputDto | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Organizations',
        url: '/admin/organizations/list'
    }];

    if (organization) {
        items.push({
            name: deactivatedSuffixPipe(organization.name!, organization.deleted!),
            url: `/admin/organizations/details/${organization.id}`
        });
    }

    return generateBreadcrumbs(items);
};


const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationInformationPageOwnProps => {
    return {
        organization: OrganizationInformationSelectors.organization(state)!,
        initialized: OrganizationInformationSelectors.initialized(state),
        error: OrganizationInformationSelectors.error(state),

        dashboardUsers: {
            total: OrganizationUsersListSelectors.total(state),
        }
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationInformationPageProps): OrganizationInformationPageEvents => {
    return bindActionCreators({
        loadOrganizationInformation: ORGANIZATION_INFORMATION_INITIALIZE,

        showErrorMessage: ORGANIZATION_INFORMATION_INITIALIZE_FAILED,

        showCreateDashboardUserModal: CREATE_DASHBOARD_USER_SHOW_MODAL,

        openUpdateOrganizationModal: ORGANIZATION_UPDATE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
        openDeleteOrganizationModal: ORGANIZATION_DELETE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
        openRestoreOrganizationModal: ORGANIZATION_RESTORE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
    }, dispatch);
};

const OrganizationInformationPageContent = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrganizationInformationPageMeta) => {
    const classes = useOrganizationInformationPageStyle();
    const canUpdateOrganization = !props.organization.deleted!;

    return (
        <>
            <div className={classes.topBlock}>
                <div className={classes.title}>
                    {props.dashboardUsers.total} health care practitioners in {deactivatedSuffixPipe(props.organization.name!, props.organization.deleted!)}                    
                </div>
                <div className={classes.buttons}>
                    {canUpdateOrganization && <AdminBlockWrapper requiredRole={AdminOrganizationsPolicies.Update}>
                        <AdvancedIconButton
                            tooltip={'Edit organization'}
                            icon={EditOutlinedIcon}
                            onClick={ButtonStopPropagationPipe(() => props.openUpdateOrganizationModal(props.organization.id!))}
                        />
                    </AdminBlockWrapper>}

                    {props.organization.deleted ? <AdminBlockWrapper requiredRole={AdminOrganizationsPolicies.Delete}>
                        <AdvancedIconButton
                            tooltip={'Restore organization'}
                            icon={RestoreFromTrashOutlinedIcon}
                            onClick={ButtonStopPropagationPipe(() => props.openRestoreOrganizationModal(props.organization))}
                        />
                    </AdminBlockWrapper> : <AdminBlockWrapper requiredRole={AdminOrganizationsPolicies.Restore}>
                        <AdvancedIconButton
                            tooltip={'Delete organization'}
                            icon={DeleteOutlineOutlinedIcon}
                            onClick={ButtonStopPropagationPipe(() => props.openDeleteOrganizationModal(props.organization))}
                        />
                    </AdminBlockWrapper>}
                </div>
            </div>

            <AdminBlockWrapper requiredRole={AdminDashboardUsersPolicies.List}>
                <div className={classes.table}>
                    <LazyLoader height={60} rows={8}>
                        <OrganizationUsersList
                            organization={props.organization}
                            onSearchQueryChanged={onOrganizationDashboardUsersSearchQueryChanged(props)}
                        />
                    </LazyLoader>

                    <AdminBlockWrapper requiredRole={AdminDashboardUsersPolicies.Create}>
                        <OrganizationUserCreateModalWrapper/>
                        <ActionButton
                            disabled={props.organization.deleted}
                            onSubmit={() => props.showCreateDashboardUserModal(props.organization)}
                            content={"+ Invite health care practitioner"}
                        />
                    </AdminBlockWrapper>
                </div>
            </AdminBlockWrapper>

            <OrganizationUpdateModalWindow/>
            <OrganizationDeleteModalWindow/>
            <OrganizationRestoreModalWindow/>
        </>
    );
})));

const OrganizationInformationPageError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrganizationInformationPageMeta) => {
    const classes = useOrganizationInformationPageStyle();

    return (
        <>
            <div className={classes.topBlock}>
                <div className={classes.title}>
                    User Registration
                </div>
            </div>
            <OperationProgress initialized={props.initialized} error={props.error}/>
            <OperationSkeleton height={60} rows={8}/>
        </>
    );
})));

const OrganizationInformationPage = (props: OrganizationInformationPageMeta) => {
    const classes = useOrganizationInformationPageStyle();

    useEffect(onOrganizationInformationPageLoaded(props), []);

    return (
        <div className={classes.root}>

            {props.organization && <div className={classes.organizationInfo}>
                <div><span>Organization Name:</span> {props.organization.name} </div>
                <div><span>Organization address:</span> {props.organization.address?.street} </div>
                <div><span>Phone number:</span> {props.organization.phoneNumber} </div>
                <div><span>Date added:</span> {DatePipe(props.organization.creationDate)} </div>
            </div>}

            <Container>
                <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.organization)}/>
                <div className={classes.content}>
                    {props.initialized ? <OrganizationInformationPageContent/> : <OrganizationInformationPageError/>}
                </div>
            </Container>
        </div>
    );
};

export const OrganizationInformationPageWrapper = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationInformationPage)));

