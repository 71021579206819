import {IAssignedRoleDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {OrpyxUserRoleRevokeActions} from "./orpyx-user-role-revoke-actions";

export interface OrpyxUserRoleRevokeState {
    error?: OperationErrorType,

    inProcess: boolean,
    toggleModal: boolean,

    userRole: IAssignedRoleDto | undefined,
    userId: number | undefined,
}

const initialState: OrpyxUserRoleRevokeState = {
    inProcess: false,
    toggleModal: false,
    userRole: undefined,
    userId: undefined
};

const OrpyxUserRoleRevokeReducers = (state = initialState, action: StoreAction<OrpyxUserRoleRevokeActions>): OrpyxUserRoleRevokeState => {
    return produce(state, (draft: OrpyxUserRoleRevokeState) => {
        switch (action.type) {
            case OrpyxUserRoleRevokeActions.REVOKE_ROLE:
                draft.inProcess = true;
                draft.error = undefined;
                break;

            case OrpyxUserRoleRevokeActions.REVOKE_ROLE_SUCCESSFULLY:
                draft.inProcess = false;
                draft.toggleModal = false;
                draft.error = undefined;
                break;

            case OrpyxUserRoleRevokeActions.REVOKE_ROLE_FAILED:
                draft.inProcess = false;
                draft.error = action.payload;
                break;

            case OrpyxUserRoleRevokeActions.REVOKE_ROLE_SHOW_MODAL:
                draft.userRole = action.payload.userRole;
                draft.userId = action.payload.orpyxUserId;
                draft.toggleModal = true;
                draft.error = undefined;
                draft.inProcess = false;
                break;

            case OrpyxUserRoleRevokeActions.REVOKE_ROLE_HIDE_MODAL:
                draft.toggleModal = false;
                draft.userRole = undefined;
                draft.userId = undefined;
                break;
        }
    })
};

export default OrpyxUserRoleRevokeReducers;
