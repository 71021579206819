import OrganizationCreateSagas from "../organization-create/store/organization-create-sagas";
import {all} from 'redux-saga/effects';
import OrganizationsListSagas from "../organizations-list/store/organizations-list-sagas";
import OrganizationInformationSagas from "../organization-information/store/organization-information-sagas";
import OrganizationDropdownFilterSagas from "../../shared/organization-dropdown-filter/store/organization-dropdown-filter-sagas";
import OrganizationDeleteSagas from "../organization-delete/store/organization-delete.sagas";
import OrganizationRestoreSagas from "../organization-restore/store/organization-restore.sagas";
import OrganizationUpdateSagas from "../organization-update/store/organization-update.sagas";

export default function* OrganizationsSagas() {
    yield all([
        OrganizationCreateSagas(),
        OrganizationDeleteSagas(),
        OrganizationUpdateSagas(),

        OrganizationRestoreSagas(),
        OrganizationsListSagas(),
        OrganizationInformationSagas(),
        OrganizationDropdownFilterSagas(),
    ]);
}
