import {createSelector} from "reselect";
import {OrganizationUsersSelectors} from "../../store/organization-users-selectors";

export class OrganizationUsersListSelectors {
    static readonly state = createSelector(OrganizationUsersSelectors.state, state => state.list);

    static readonly loading = createSelector(OrganizationUsersListSelectors.state, state => state.loading);
    static readonly initialized = createSelector(OrganizationUsersListSelectors.state, state => state.initialized);

    static readonly search = createSelector(OrganizationUsersListSelectors.state, state => state.search);
    static readonly users = createSelector(OrganizationUsersListSelectors.state, state => state.users);
    static readonly error = createSelector(OrganizationUsersListSelectors.state, state => state.error);

    static readonly total = createSelector(OrganizationUsersListSelectors.state, state => state.total);

    static readonly pages = createSelector(OrganizationUsersListSelectors.state, state => {
        const count = state.search.count ? state.search.count : 1;
        const number = Math.ceil(state.total / count);
        return number;
    });
}

export default OrganizationUsersListSelectors;
