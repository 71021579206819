import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {
    OrganizationUserInformationPageEvents,
    OrganizationUserInformationPageMeta,
    OrganizationUserInformationPageOwnProps,
    OrganizationUserInformationPageProps
} from "./organization-user-information-page.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {IGetDashboardUserInformationResponse} from "admins-dashboard/http-clients/index";
import {
    ApplicationBreadcrumbs,
    ApplicationBreadcrumbsItem,
    deactivatedSuffixPipe,
    fullNamePipe,
    LazyLoader,
    OperationProgress,
    OperationSkeleton,
    StoreActionAfterComplete
} from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../../breadcrumbs/admin-breadcrumbs.generator";
import OrganizationUserInformationSelectors from "../../store/organization-user-information-page.selectors";
import {withRouter} from "react-router";
import {useOrpyxUserInformationPageStyle} from "../../../../../orpyx-users/orpyx-user-information/components/orpyx-user-information-page/orpyx-user-information-page.style";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {INITIALIZE_ORGANIZATION_USER_INFORMATION, INITIALIZE_ORGANIZATION_USER_INFORMATION_FAILED, UPDATE_ORGANIZATION_USER_INFORMATION} from "../../store/organization-user-information-page.actions";
import {onOrganizationUserInformationPageLoaded} from "./organization-user-information-page.hooks";
import Container from "@material-ui/core/Container";
import {DASHBOARD_USER_RESTORE_SHOW_MODAL} from "../../../organization-user-restore/store/organization-user-restore.actions";
import {DELETE_DASHBOARD_USER_SHOW_MODAL} from "../../../organization-user-delete/store/organization-user-delete-actions";
import {OrganizationUserDeleteModalWrapper} from "../../../organization-user-delete/components/organization-user-delete-modal/organization-user-delete-modal";
import {OrganizationUserRestoreModalWrapper} from "../../../organization-user-restore/components/organization-user-restore-modal/organization-user-restore-modal";
import {AdminAuthSelectors} from "../../../../../auth/store/admin-auth-selectors";
import {DASHBOARD_USER_REVOKE_INVITE} from "../../../organization-user-revoke-invite/store/organization-user-revoke-invite.actions";
import {DASHBOARD_USER_SEND_INVITE} from "../../../organization-user-send-invite/store/organization-user-send-invite.actions";
import {OrganizationUserActionsProps} from "../organization-user-information-actions/organization-user-information-actions.interface";
import {OrganizationUserActions} from "../organization-user-information-actions/organization-user-information-actions";

const OrganizationUserInformationForm = React.lazy(() => import("../organization-user-information-form/organization-user-information-form"));

const generatePageBreadcrumbs = (dashboardUser: IGetDashboardUserInformationResponse | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Organizations',
        url: '/admin/organizations/list'
    }];

    if (dashboardUser && dashboardUser.organization) {
        items.push({
            name: deactivatedSuffixPipe(dashboardUser.organization!.name!, dashboardUser.organization!.deleted!),
            url: `/admin/organizations/details/${dashboardUser.organization.id}`
        });

        if (dashboardUser.user) {
            items.push({
                name: fullNamePipe(dashboardUser.user)!,
                url: `/admin/organizations/${dashboardUser.organization.id}/doctors/details/${dashboardUser.user.id}`
            });
        }
    }

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationUserInformationPageOwnProps => {
    return {
        error: OrganizationUserInformationSelectors.error(state),
        user: OrganizationUserInformationSelectors.user(state),

        initialized: OrganizationUserInformationSelectors.initialized(state),
        isUserUpdating: OrganizationUserInformationSelectors.isUserUpdating(state),

        availableRoles: AdminAuthSelectors.availableRoles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationUserInformationPageProps): OrganizationUserInformationPageEvents => {
    return bindActionCreators({
        loadUserInformation: INITIALIZE_ORGANIZATION_USER_INFORMATION,
        updateUserInformation: UPDATE_ORGANIZATION_USER_INFORMATION,
        showErrorMessage: INITIALIZE_ORGANIZATION_USER_INFORMATION_FAILED,

        showDeleteUserModal: DELETE_DASHBOARD_USER_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),
        showRestoreUserModal: DASHBOARD_USER_RESTORE_SHOW_MODAL(StoreActionAfterComplete.RefreshEntityInformation),

        sendUserInvite: DASHBOARD_USER_SEND_INVITE,
        revokeUserInvite: DASHBOARD_USER_REVOKE_INVITE,
    }, dispatch);
};

const OrganizationUserInformationPageContent = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrganizationUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();
    const canDisplayUserActions = !props.user!.organization!.deleted;

    const propsSelector = (): OrganizationUserActionsProps => ({
        dashboardUser: props.user!,
        availableRoles: props.availableRoles,
        events: {
            showDeleteUserModal: props.showDeleteUserModal,
            showRestoreUserModal: props.showRestoreUserModal,

            sendUserInvite: props.sendUserInvite,
            revokeUserInvite: props.revokeUserInvite,
        }
    });

    return (<>
        <div className={classes.title}>
            User information
            {canDisplayUserActions && <OrganizationUserActions
                propsSelector={propsSelector}
                tooltip={'practitioner user actions'}
            />}
        </div>

        <LazyLoader height={80} rows={4}>
            <OrganizationUserInformationForm
                dashboardUser={props.user!}
                error={props.error}
                inProcess={props.isUserUpdating}
                onSubmit={props.updateUserInformation}
            />
        </LazyLoader>

        <OrganizationUserDeleteModalWrapper/>
        <OrganizationUserRestoreModalWrapper/>
    </>);
})));

const OrganizationUserInformationPageError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrganizationUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();

    return (<>
        <div className={classes.title}>
            User information
        </div>
        <OperationProgress initialized={props.initialized} error={props.error}/>
        <OperationSkeleton height={80} rows={4}/>
    </>);
})));

const OrganizationUserInformationPageTemplate = (props: OrganizationUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();
    useEffect(onOrganizationUserInformationPageLoaded(props), [props.location]);

    return (
        <Container>
            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12}>
                    <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.user)}/>
                </Grid>

                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        {props.initialized ? <OrganizationUserInformationPageContent/> : <OrganizationUserInformationPageError/>}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export const OrganizationUserInformationPage = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationUserInformationPageTemplate)));

