import {createSelector} from "reselect";
import {OrganizationUsersSelectors} from "../../store/organization-users-selectors";

export class OrganizationUserRestoreSelectors {

    static readonly state = createSelector(OrganizationUsersSelectors.state, state => state.restore);
    static readonly error = createSelector(OrganizationUserRestoreSelectors.state, state => state.error);

    static readonly inProcess = createSelector(OrganizationUserRestoreSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(OrganizationUserRestoreSelectors.state, state => state.toggle);

    static readonly dashboardUser = createSelector(OrganizationUserRestoreSelectors.state, state => state.dashboardUser);
    static readonly organization = createSelector(OrganizationUserRestoreSelectors.state, state => state.organization);
    static readonly actionAfterComplete = createSelector(OrganizationUserRestoreSelectors.state, state => state.actionAfterComplete);

}

export default OrganizationUserRestoreSelectors;
