import {IModalWindow, ModalWindowStore, StoreAction} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import produce from "immer";
import {OrganizationUserCreateActions} from "./organization-user-create-actions";

export interface OrganizationUserCreateState extends IModalWindow {
    organization: IOrganizationOutputDto | undefined;
}

const initialState: OrganizationUserCreateState = {
    toggle: false,
    inProcess: false,
    organization: undefined,
    error: undefined
};

const OrganizationUserCreateReducers = (state = initialState, action: StoreAction<OrganizationUserCreateActions>): OrganizationUserCreateState => {
    return produce(state, (draft: OrganizationUserCreateState) => {
        switch (action.type) {
            case OrganizationUserCreateActions.CREATE_DASHBOARD_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationUserCreateActions.CREATE_DASHBOARD_USER_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationUserCreateActions.CREATE_DASHBOARD_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrganizationUserCreateActions.SHOW_MODAL:
                draft.organization = action.payload;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationUserCreateActions.HIDE_MODAL:
                draft.organization = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default OrganizationUserCreateReducers;
