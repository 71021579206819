import {isNullOrUndefined, OrderByQuery, PagingQuery, parseEnum} from "orpyx-web-common";
import {ListOrganizationsPropertyOrderBy} from "admins-dashboard/http-clients/index";

export class OrganizationsSearchQuery extends OrderByQuery<ListOrganizationsPropertyOrderBy> {
    term?: string | undefined;
    remotePatientMonitoringProviderId: number | undefined;

    public static parse(remotePatientMonitoringProviderId?: string | undefined, term?: string | undefined, orderBy?: string | undefined, orderByAsc?: string | undefined, offset?: string | undefined, count?: string | undefined): OrganizationsSearchQuery {
        const orderByEnumValue = parseEnum(ListOrganizationsPropertyOrderBy, orderBy, ListOrganizationsPropertyOrderBy.OrganizationId);
        return new OrganizationsSearchQuery
        (
             isNullOrUndefined(remotePatientMonitoringProviderId) ? undefined : parseInt(remotePatientMonitoringProviderId!),
            term,
            orderByEnumValue,
            !!orderByAsc,
            offset ? parseInt(offset) : PagingQuery.DEFAULT_PAGE,
            count ? parseInt(count) : PagingQuery.DEFAULT_COUNT
        );
    }

    constructor(remotePatientMonitoringProviderId?: number | undefined, term?: string | undefined, orderBy?: ListOrganizationsPropertyOrderBy, orderByAsc: boolean = true, offset: number | undefined = PagingQuery.DEFAULT_PAGE, count: number | undefined = PagingQuery.DEFAULT_COUNT) {
        super(orderBy, orderByAsc, offset, count);
        this.term = term;
        this.remotePatientMonitoringProviderId = remotePatientMonitoringProviderId;
        this.orderBy = orderBy;
    }
}

export const toQueryString = (query: OrganizationsSearchQuery): string => {
    let str = `${OrganizationsSearchQuery.PARAM_OFFSET}=${query.offset}&${OrganizationsSearchQuery.PARAM_COUNT}=${query.count}`;
    if (query.term) {
        str += `&term=${query.term}`;
    }

    if (query.remotePatientMonitoringProviderId) {
        str += `&remotePatientMonitoringProviderId=${query.remotePatientMonitoringProviderId}`;
    }

    if (query.orderBy) {
        str += `&${OrganizationsSearchQuery.PARAM_ORDER_BY}=${query.orderBy}`;
    }

    if (query.orderByAsc) {
        str += `&${OrganizationsSearchQuery.PARAM_ORDER_BY_ASC}=${query.orderByAsc}`;
    }

    return str;
}
