import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {PATIENT_DELETE_FAILED, PATIENT_DELETE_SUCCESSFULLY, PatientDeleteActions} from "./patient-delete.actions";
import PatientDeleteSelectors from "./patient-delete.selectors";
import {AdminPatientsHttpClient, IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete} from "orpyx-web-common";

const API_VERSIONS = '1';

function* onDeletePatient() {
    try {
        const patient: IGetPatientsListResult | IGetPatientInformationResult = yield select(PatientDeleteSelectors.patient);
        const actionAfterComplete: StoreActionAfterComplete = yield select(PatientDeleteSelectors.actionAfterComplete);

        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);
        const data: IGetPatientInformationResult = yield call(
            [api, api.deletePatient],
            patient.organization!.id!,
            patient.patient!.patientId!,
            API_VERSIONS
        );

        yield put(PATIENT_DELETE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Patient ${fullNamePipe(data.patient)} successfully deleted`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(PATIENT_DELETE_FAILED(e));
    }
}

export default function* PatientDeleteSagas() {
    yield all([
        takeEvery(PatientDeleteActions.PATIENT_DELETE, onDeletePatient),
    ]);
}
