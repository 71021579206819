import {DropdownSelectValue} from "../../shared/dropdown-select/dropdown-select.interface";
import { ActorType } from "admins-dashboard";

export const ActorTypeValues: DropdownSelectValue[] = [
    {
        value: undefined,
        name: ''
    },
    {
        value: ActorType.DashboardUser,
        name: 'Dashboard user'
    },
    {
        value: ActorType.OrpyxUser,
        name: 'Orpyx user'
    },
    {
        value: ActorType.RPM,
        name: 'RPM'
    },
];
