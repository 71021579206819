import {createSelector} from "reselect";
import {getPatientsStoreState} from "../../store/patients-selectors";

export class PatientInformationSelectors {
    static readonly state = createSelector(getPatientsStoreState, state => state.update);
    static readonly error = createSelector(PatientInformationSelectors.state, state => state.error);
    static readonly initialized = createSelector(PatientInformationSelectors.state, state => state.initialized);

    static readonly inProcess = createSelector(PatientInformationSelectors.state, state => state.inProcess);

    static readonly patient = createSelector(PatientInformationSelectors.state, state => state.patient!);
}
