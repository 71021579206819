import {connect} from "react-redux";
import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {OrpyxCareUsersListPageEvents, OrpyxCareUsersListPageMeta, OrpyxCareUsersListPageOwnProps, OrpyxCareUsersListPageProps} from "./orpyx-care-users-list-page.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {useOrpyxCareUsersListPageStyle} from "./orpyx-care-users-list-page.style";
import {Container} from "@material-ui/core";
import { ApplicationBreadcrumbs, ApplicationBreadcrumbsItem,  LazyLoader,  ListPagination,  ListSearch,  OperationProgress, OperationSkeleton } from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {withRouter} from "react-router";
import { FETCH_USERS, UPDATE_PERMISSIONS_FILTER } from "../../store/orpyx-care-users-list-actions";
import { onOrpyxCareUsersListItemSelected, onOrpyxCareUsersListPageLoaded, onOrpyxCareUsersListSearchDisplayCountChanged, onOrpyxCareUsersListSearchOptionsChanged, onOrpyxCareUsersListSearchPageChanged, onOrpyxCareUsersListSearchTermValidate } from "./orpyx-care-users-list-page.hooks";
import { OrpyxCareUsersListSelectors } from "../../store/orpyx-care-users-list-selectors";
import { orpyxCareUsersListOrderItems } from "../enums/orpyx-care-users-list-order";
import { DASHBOARD_ROLES_INITIALIZE } from "modules/admin/dashboard-roles/dashboard-roles-actions";
import { DashboardRolesSelectors } from "modules/admin/dashboard-roles/dashboard-roles-selectors";
import { OrpyxCareUsersFilterSelect } from "./orpyx-care-users-filter-select/orpyx-care-users-filter-select";
const OrpyxCareUsersListTableWrapper = React.lazy(() => import("../orpyx-care-users-list-table/orpyx-care-users-list-table"));

const generatePageBreadcrumbs = (): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Orpyx Care Users',
        url: '/admin/orpyx-care/users/list'
    }];

    return generateBreadcrumbs(items);
};


const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxCareUsersListPageOwnProps => {
    return {
        initialized: OrpyxCareUsersListSelectors.initialized(state),

        error: OrpyxCareUsersListSelectors.error(state),

        loading: OrpyxCareUsersListSelectors.loading(state),
        search: OrpyxCareUsersListSelectors.search(state),

        users: OrpyxCareUsersListSelectors.users(state),

        pages: OrpyxCareUsersListSelectors.pages(state),
        totals: OrpyxCareUsersListSelectors.total(state),

        filter: OrpyxCareUsersListSelectors.filter(state),
        dashboardRoles: DashboardRolesSelectors.roles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrpyxCareUsersListPageProps): OrpyxCareUsersListPageEvents => {
    return bindActionCreators({
        fetchUsers: FETCH_USERS,
        fetchDashboardRoles: DASHBOARD_ROLES_INITIALIZE,
        updateFilter: UPDATE_PERMISSIONS_FILTER,
    }, dispatch);
};

const OrpyxCareUsersListPageContentTemplate = (props: OrpyxCareUsersListPageMeta) => {
    const classes = useOrpyxCareUsersListPageStyle();

    return (<div className={classes.content}>
        <div className={classes.topBlock}>
            <div className={classes.title}>
                Orpyx Care Users: {props.totals} users
            </div>
            <OrpyxCareUsersFilterSelect
                filter={props.filter}
                dashboardRoles={props.dashboardRoles} 
                onChange={props.updateFilter}
            />
        </div>


        <LazyLoader height={60} rows={8}>
            <OrpyxCareUsersListTableWrapper
                onUserSelected={user => onOrpyxCareUsersListItemSelected(props, user.id!)}
            />
        </LazyLoader>


        <ListPagination
            label={'Users per page'}
            onSelectedPageChanged={page => onOrpyxCareUsersListSearchPageChanged(props, page)}
            onDisplayCountChanged={count => onOrpyxCareUsersListSearchDisplayCountChanged(props, count)}

            count={props.search.count}
            page={props.search.offset}
            pages={props.pages}
        />
    </div>);
};

const OrpyxCareUsersListPageContent = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxCareUsersListPageContentTemplate));

const OrpyxCareUsersListPageError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxCareUsersListPageMeta) => {
    const classes = useOrpyxCareUsersListPageStyle();

    return (<div className={classes.content}>
        <div className={classes.topBlock}>
            <div className={classes.title}>
                Orpyx Care Users
            </div>
        </div>

        <OperationProgress initialized={props.initialized} error={props.error}/>
        <OperationSkeleton height={60} rows={8}/>
    </div>);
})));

export const OrpyxCareUsersListPage = (props: OrpyxCareUsersListPageMeta) => {
    const classes = useOrpyxCareUsersListPageStyle();
    useEffect(onOrpyxCareUsersListPageLoaded(props), [props.location]);

    return (<Container>
        <div className={classes.root}>
            <ListSearch
                    term={props.search.term}
                    inProcess={props.loading}
                    orderBy={props.search.orderBy}
                    orderByItems={orpyxCareUsersListOrderItems}
                    orderByAsc={props.search.orderByAsc}
                    onSearch={onOrpyxCareUsersListSearchOptionsChanged(props)}
                    onSearchValidate={onOrpyxCareUsersListSearchTermValidate}
                    placeholder={"Find your users"}
            />

            <ApplicationBreadcrumbs items={generatePageBreadcrumbs()}/>
            {props.initialized ? <OrpyxCareUsersListPageContent/> : <OrpyxCareUsersListPageError/>}
        </div>
    </Container>);
};

export const OrpyxCareUsersListPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxCareUsersListPage));