import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {AdminDashboardNavbarEvents, AdminDashboardNavbarMeta, AdminDashboardNavbarOwnProps, AdminDashboardNavbarProps} from "./admin-dashboard-navbar.interface";
import {useAdminDashboardNavbarStyle} from "./admin-dashboard-navbar.style";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {NavLink} from "react-router-dom";
import {AdminDashboardNavbarLoginWrapper} from "../admin-dashboard-navbar-login/admin-dashboard-navbar-login";
import {Typography} from "@material-ui/core";
import {AdminAuthSelectors} from "../../../auth/store/admin-auth-selectors";
import {AdminBlockWrapper} from "../../admin-block/admin-block";
import clsx from "clsx";
import {adminDashboardUrls} from "../../../enums/admin-dashboard-urls";

const AdminDashboardNavbar = (props: AdminDashboardNavbarMeta) => {
    const classes = useAdminDashboardNavbarStyle();
    const linkClassName = props.usePrimaryLinkColor ? clsx(classes.link, classes.primaryColor) : classes.link;

    return (
        <div>
            <Typography className={classes.root}>
                {props.isLoggedIn && <>
                    <NavLink to="/Admin" exact={true} className={linkClassName}>
                        Dashboard
                    </NavLink>

                    {adminDashboardUrls.map(url => <AdminBlockWrapper key={url.url} requiredRole={url.requiredRole}>
                        < NavLink to={url.url} className={linkClassName}>
                            {url.title}
                        </NavLink>
                    </AdminBlockWrapper>)}
                </>}

                <AdminDashboardNavbarLoginWrapper/>
            </Typography>

        </div>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): AdminDashboardNavbarOwnProps => {
    return {
        isLoggedIn: AdminAuthSelectors.isLoggedIn(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: AdminDashboardNavbarProps): AdminDashboardNavbarEvents => {
    return bindActionCreators({}, dispatch);
};

export const AdminDashboardNavbarWrapper = memo(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardNavbar));