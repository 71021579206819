import {connect} from "react-redux";
import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {AuditLoggingPageEvents, AuditLoggingPageMeta, AuditLoggingPageOwnProps, AuditLoggingPageProps} from "./audit-logging-page.interface";
import {Container} from "@material-ui/core";
import {AuditLoggingDownloadSelectors} from "../../store/audit-logging-download.selectors";
import {withRouter} from "react-router-dom";
import {generateBreadcrumbs} from "../../../breadcrumbs/admin-breadcrumbs.generator";
import {DOWNLOAD_AUDIT_LOGGING} from "../../store/audit-logging-download.actions";
import AuditLoggingFilterFormWrapper from "../audit-logging-filter-form/audit-logging-filter-form";
import {useAuditLoggingPageStyle} from "./audit-logging-page.styles";

const generatePageBreadcrumbs = (): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Audit logging',
        url: '/admin/audit-logging'
    }];

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): AuditLoggingPageOwnProps => {
    return {
        error: AuditLoggingDownloadSelectors.error(state),
        loading: AuditLoggingDownloadSelectors.loading(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: AuditLoggingPageProps): AuditLoggingPageEvents => {
    return bindActionCreators({
        downloadAuditLoggingReport: DOWNLOAD_AUDIT_LOGGING,
    }, dispatch);
};

export const AuditLoggingPage = (props: AuditLoggingPageMeta) => {
    const classes = useAuditLoggingPageStyle();

    return (<Container>
        <div className={classes.root}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs()}/>
            <div className={classes.content}>
                <div className={classes.title}>
                    Audit logging
                </div>

                <div>
                    <AuditLoggingFilterFormWrapper
                        onSubmit={props.downloadAuditLoggingReport}
                        inProcess={props.loading}
                        error={props.error}
                        classes={classes}
                    />
                </div>

            </div>
        </div>
    </Container>);
};

export const AuditLoggingPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditLoggingPage));
