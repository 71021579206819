import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {AdminOrpyxUsersRolesHttpClient, AssignOrpyxUserRolesResult, OrpyxRoleType,} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";
import {ASSIGN_ROLE_FAILED, ASSIGN_ROLE_SUCCESSFULLY, OrpyxUserRoleAssignActions} from "./orpyx-user-role-assign-actions";
import {getOrpyxUserAssignRoleUserId} from "./orpyx-user-role-assign-selectors";


const API_VERSIONS = '1';

function* onAssignOrpyxUserRole(action: StoreAction<OrpyxUserRoleAssignActions, OrpyxRoleType>) {
    try {
        const userId: number = yield select(getOrpyxUserAssignRoleUserId);
        const roleType = action.payload!;
        const api = new AdminOrpyxUsersRolesHttpClient(ORPYXSI_ADMIN_URL);
        const data: AssignOrpyxUserRolesResult = yield call(
            [api, api.assignOrpyxUserRole],
            userId!,
            roleType!,
            API_VERSIONS
        );

        yield put(ASSIGN_ROLE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Role ${data.assignedRole!.role!.name} successfully assigned for user ${fullNamePipe(data.orpyxUser)}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(ASSIGN_ROLE_FAILED(e));
    }
}

export default function* OrpyxUserRoleAssignSagas() {
    yield all([
        yield takeEvery(OrpyxUserRoleAssignActions.ASSIGN_ROLE, onAssignOrpyxUserRole),

    ]);
}

