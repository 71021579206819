import {OrganizationInformationPageMeta} from "./organization-information-page.interface";
import {OperationResultCode} from "admins-dashboard/http-clients/index";
import {DashboardUsersSearchQuery, toQueryString} from "../../../organization-users/organization-users-list/models/dashboard-users-search-query";

export const onOrganizationInformationPageLoaded = (props: OrganizationInformationPageMeta) => () => {
    if (props.match.isExact && props.match.params.organizationId) {
        const organizationId = parseInt(props.match.params.organizationId);
        if (isNaN(organizationId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid organization id: ${props.match.params.organizationId}`
            });
        } else {
            props.loadOrganizationInformation(organizationId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid organization id: ${props.match.params.organizationId}`
        });
    }
};

export const onOrganizationDashboardUsersSearchQueryChanged = (props: OrganizationInformationPageMeta) => (searchQuery: DashboardUsersSearchQuery) => {
    const urlQuery = toQueryString(searchQuery);
    const url = `/admin/organizations/details/${props.organization.id}?${urlQuery}`;
    props.history!.push(url);
};
