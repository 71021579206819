import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DashboardRolePermissionFilterDto } from "admins-dashboard";
import { useOrpyxUsersListPageStyle } from "modules/admin/orpyx-users/orpyx-users-list/components/orpyx-users-list-page/orpyx-users-list-page.style";
import React from "react";
import { memo } from "react";
import { OrpyxCareUsersFilterSelectMeta } from "./orpyx-care-users-filter-select.interface";

const getPermissionLabel = (permission: string) => {
    switch (permission) {
        case DashboardRolePermissionFilterDto.All:
            return 'All';
        case DashboardRolePermissionFilterDto.Basic:
            return 'Basic Access';
        case DashboardRolePermissionFilterDto.AllOrganization:
            return 'All Organizations';
    }
}

export const OrpyxCareUsersFilterSelect = memo((props: OrpyxCareUsersFilterSelectMeta) => {
    const classes = useOrpyxUsersListPageStyle();
    
    const handleChange = (event: any) => {
        const filter = event.target.value;
        props.onChange(filter);
    }

    return(
        <div className={classes.sort}>
            <span>
                Permissions Filter:
            </span>

            <Select
                value={props.filter}
                onChange={e => handleChange(e)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {Object.keys(DashboardRolePermissionFilterDto).map((role, index) => (
                    <MenuItem key={index} value={role}>
                        {getPermissionLabel(role)}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
});