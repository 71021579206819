import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

export class AdminOrpyxUsersPolicies {
    static readonly Create = OrpyxRoleType.Admin;
    static readonly Update = OrpyxRoleType.Admin;
    static readonly Delete = OrpyxRoleType.Admin;
    static readonly Restore = OrpyxRoleType.Admin;

    static readonly Invite = OrpyxRoleType.Admin;
    static readonly ResetPassword = OrpyxRoleType.Admin;
    static readonly List = OrpyxRoleType.Admin;
    static readonly Information = OrpyxRoleType.Admin;
    static readonly ManageRoles = OrpyxRoleType.Admin;
}

export default AdminOrpyxUsersPolicies;
