import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationUserCreateModalEvents, OrganizationUserCreateModalMeta, OrganizationUserCreateModalOwnProps, OrganizationUserCreateModalProps} from "./organization-user-create-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {CREATE_DASHBOARD_USER, CREATE_DASHBOARD_USER_HIDE_MODAL} from "../../store/organization-user-create-actions";
import OrganizationUserCreateSelectors from "../../store/organization-user-create-selectors";
import {DashboardUserTitlesSelectors} from "../../../../../dashboard-user-titles/store/dashboard-user-titles-selectors";
import {OrganizationInformationSelectors} from "../../../../organization-information/store/organization-information-selectors";
import {createModalWindow, ModalWindowHeader, LazyLoader, LazyLoaderFallback} from "orpyx-web-common";

const OrganizationUserCreateFormWrapper = React.lazy(() => import("../organization-user-create-form/organization-user-create-form"));

const OrganizationUserCreateModal = (props: OrganizationUserCreateModalMeta) => {
    return (
        <>
            <ModalWindowHeader>
                Health care practitioner invite to {props.organization.name}
            </ModalWindowHeader>

            <LazyLoader height={40} width={300} rows={8}>
                {!props.isDashboardUserTitlesInitialized && <LazyLoaderFallback height={40} width={300} rows={8}/>}

                {props.isDashboardUserTitlesInitialized && <OrganizationUserCreateFormWrapper
                    titles={props.titles}
                    inProcess={props.inProcess}
                    error={props.error}
                    onSubmit={props.createDashboardUser}/>}
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationUserCreateModalOwnProps => {
    return {
        organization: OrganizationInformationSelectors.organization(state)!,

        error: OrganizationUserCreateSelectors.error(state),
        inProcess: OrganizationUserCreateSelectors.inProcess(state),

        isDashboardUserTitlesInitialized: DashboardUserTitlesSelectors.initialized(state),
        titles: DashboardUserTitlesSelectors.titles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationUserCreateModalProps): OrganizationUserCreateModalEvents => {
    return bindActionCreators({
        closeModal: CREATE_DASHBOARD_USER_HIDE_MODAL,
        createDashboardUser: CREATE_DASHBOARD_USER,
    }, dispatch);
};

export const OrganizationUserCreateModalWrapper = memo(() => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationUserCreateModal)),
    closeModalActionCreator: CREATE_DASHBOARD_USER_HIDE_MODAL,
    modalToggleSelector: OrganizationUserCreateSelectors.isModalActive
}));
