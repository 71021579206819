import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {OrpyxRoleDto} from "admins-dashboard/http-clients/index";

export enum OrpyxRolesActions {
    INITIALIZE = 'ORPYX_ROLES: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'ORPYX_ROLES: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'ORPYX_ROLES: INITIALIZE_FAILED',
}

export const ORPYX_ROLES_INITIALIZE = (): StoreAction<OrpyxRolesActions> => ({
    type: OrpyxRolesActions.INITIALIZE
});

export const ORPYX_ROLES_INITIALIZE_SUCCESSFULLY = (roles: OrpyxRoleDto[]): StoreAction<OrpyxRolesActions> => ({
    type: OrpyxRolesActions.INITIALIZE_SUCCESSFULLY, payload: roles
});

export const ORPYX_ROLES_INITIALIZE_FAILED = (error: OperationErrorType): StoreAction<OrpyxRolesActions> => ({
    type: OrpyxRolesActions.INITIALIZE_FAILED, payload: error
});
