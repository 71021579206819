import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {
    INITIALIZE_ORPYX_USER_INFORMATION,
    INITIALIZE_ORPYX_USER_INFORMATION_FAILED,
    INITIALIZE_ORPYX_USER_INFORMATION_SUCCESSFULLY,
    OrpyxUserInformationActions,
    UPDATE_ORPYX_USER_INFORMATION_FAILED,
    UPDATE_ORPYX_USER_INFORMATION_SUCCESSFULLY
} from "./orpyx-user-information-actions";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {AdminOrpyxUsersHttpClient, IOrpyxUserDto, UpdateOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {OrpyxUserRoleRevokeActions} from "../../orpyx-user-role-revoke/store/orpyx-user-role-revoke-actions";
import {OrpyxUserRoleAssignActions} from "../../orpyx-user-role-assign/store/orpyx-user-role-assign-actions";
import {OrpyxUserDeleteActions} from "../../orpyx-user-delete/store/orpyx-user-delete-actions";
import OrpyxUserInformationSelectors from "./orpyx-user-information-selectors";
import {OrpyxUserRestoreActions} from "../../orpyx-user-restore/store/orpyx-user-restore.actions";

const API_VERSIONS = '1';

function* onFetchOrpyxUserInformation(action: StoreAction<OrpyxUserInformationActions, number>) {
    try {
        const orpyxUserId = action.payload!;

        const api = new AdminOrpyxUsersHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrpyxUserDto = yield call(
            [api, api.getOrpyxUserInformation],
            orpyxUserId,
            API_VERSIONS
        );

        yield put(INITIALIZE_ORPYX_USER_INFORMATION_SUCCESSFULLY(data));
    } catch (e) {
        yield put(INITIALIZE_ORPYX_USER_INFORMATION_FAILED(e));
    }
}

function* onRefreshOrpyxUserInformation() {
    const orpyxUser: IOrpyxUserDto = yield select(OrpyxUserInformationSelectors.user);
    yield put(INITIALIZE_ORPYX_USER_INFORMATION(orpyxUser.orpyxUserId!));
}

function* onUpdateOrpyxUserInformation(action: StoreAction<OrpyxUserInformationActions, { model: UpdateOrpyxUserDto, orpyxUserId: number }>) {
    try {
        const {orpyxUserId, model} = action.payload!;

        const api = new AdminOrpyxUsersHttpClient(ORPYXSI_ADMIN_URL);
        const data: IOrpyxUserDto = yield call(
            [api, api.updateOrpyxUser],
            orpyxUserId,
            API_VERSIONS,
            model
        );

        yield put(UPDATE_ORPYX_USER_INFORMATION_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `User ${fullNamePipe(data)} successfully updated`,
            options: {variant: 'success'}
        }));
    } catch (e) {
        yield put(UPDATE_ORPYX_USER_INFORMATION_FAILED(e));
    }
}

export default function* OrpyxUserInformationSages() {
    yield all([
        yield takeEvery(OrpyxUserInformationActions.INITIALIZE, onFetchOrpyxUserInformation),

        yield takeEvery(OrpyxUserRoleRevokeActions.REVOKE_ROLE_SUCCESSFULLY, onRefreshOrpyxUserInformation),
        yield takeEvery(OrpyxUserRoleAssignActions.ASSIGN_ROLE_SUCCESSFULLY, onRefreshOrpyxUserInformation),
        yield takeEvery(OrpyxUserInformationActions.UPDATE_USER_SUCCESSFULLY, onRefreshOrpyxUserInformation),

        yield takeEvery(whenStoreActionAfterComplete(OrpyxUserDeleteActions.DELETE_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrpyxUserInformation),
        yield takeEvery(whenStoreActionAfterComplete(OrpyxUserRestoreActions.RESTORE_ORPYX_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrpyxUserInformation),

        yield takeEvery(OrpyxUserInformationActions.UPDATE_USER, onUpdateOrpyxUserInformation),
    ]);
}

