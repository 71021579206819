import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AddInsoleDto, AdminProductHttpClient, IAddInsoleDto, IAddInsoleResult, IListProductsResultEntry, IGetPatientInformationResult} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {CREATE_PATIENT_INSOLE_FAILED, CREATE_PATIENT_INSOLE_SUCCESSFULLY, PatientInsolesCreateActions} from "./patient-insoles-create-actions";
import {PatientInsolesCreateSelectors} from "./patient-insoles-create-selectors";
import {createdInsoleTypePipe} from "../../pipes/createdInsoleTypePipe";
import {productSerialNumberPipe} from "../../pipes/productSerialNumberPipe";
import {PatientInformationSelectors} from "../../../patient-information/store/patient-information-selectors";

const API_VERSIONS = '1';

function* onCreatePatientInsole(action: StoreAction<PatientInsolesCreateActions, IAddInsoleDto>) {
    try {
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient)!;
        const device: IListProductsResultEntry = yield select(PatientInsolesCreateSelectors.device);
        const model = new AddInsoleDto(action.payload!);
        const api = new AdminProductHttpClient(ORPYXSI_ADMIN_URL);

        const data: IAddInsoleResult = yield call(
            [api, api.addInsoleToPatientProduct],
            patient.patient!.patientId!,
            device.productId!,
            API_VERSIONS,
            model
        );

        yield put(CREATE_PATIENT_INSOLE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully added ${createdInsoleTypePipe(data)} insole with sn ${model.insoleSerialNumber} to device ${productSerialNumberPipe(device)}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(CREATE_PATIENT_INSOLE_FAILED(e));
    }
}

export default function* PatientInsolesCreateSagas() {
    yield all([
        takeEvery(PatientInsolesCreateActions.CREATE_PATIENT_INSOLE, onCreatePatientInsole),
    ]);
}

