import * as React from "react";
import {memo} from "react";

import {OrpyxUserCreateModalEvents, OrpyxUserCreateModalMeta, OrpyxUserCreateModalOwnProps} from "./orpyx-user-create-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {getOrpyxUsersCreateModalError, isOrpyxUsersCreateModalActive, isOrpyxUsersCreateProcess} from "../../store/orpyx-user-create-selectors";
import {CREATE_USER, HIDE_MODAL} from "../../store/orpyx-user-create-actions";
import {ORPYX_ROLES_INITIALIZE} from "../../../../orpyx-roles/store/orpyx-roles-actions";
import {OrpyxRolesSelectors} from "../../../../orpyx-roles/store/orpyx-roles-selectors";
import {createModalWindow, ModalWindowHeader} from "orpyx-web-common";
import {LazyLoader, LazyLoaderFallback} from "orpyx-web-common";
const OrpyxUserCreateFormWrapper = React.lazy(() => import("../orpyx-user-create-form/orpyx-user-create-form"));


export const OrpyxUserCreateModal = (props: OrpyxUserCreateModalMeta) => {
    return (<>
            <ModalWindowHeader>
                Create New User
            </ModalWindowHeader>

            <LazyLoader height={40} width={300} rows={8}>
                {!props.isOrpyxRolesLoaded && <LazyLoaderFallback height={40} width={300} rows={8}/>}
                {props.isOrpyxRolesLoaded && <OrpyxUserCreateFormWrapper
                    onSubmit={props.createUser}
                    inProcess={props.inProcess}
                    error={props.error}
                    roles={props.orpyxRoles}
                />}
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUserCreateModalOwnProps => {
    return {
        error: getOrpyxUsersCreateModalError(state),
        inProcess: isOrpyxUsersCreateProcess(state),
        orpyxRoles: OrpyxRolesSelectors.roles(state),
        isOrpyxRolesLoaded: OrpyxRolesSelectors.initialized(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): OrpyxUserCreateModalEvents => {
    return bindActionCreators({
        createUser: CREATE_USER,
        loadOrpyxRoles: ORPYX_ROLES_INITIALIZE,
    }, dispatch);
};

export const OrpyxUserCreateModalWrapper = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrpyxUserCreateModal)),
    closeModalActionCreator: HIDE_MODAL,
    modalToggleSelector: isOrpyxUsersCreateModalActive,
});
