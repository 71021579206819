import {createSelector} from "reselect";
import {getOrpyxUsersState} from "../../store/orpyx-users-store-selectors";

export class OrpyxUserDeleteSelectors {
    static readonly state = createSelector(getOrpyxUsersState, state => state.delete);

    static readonly actionAfterComplete = createSelector(OrpyxUserDeleteSelectors.state, state => state.actionAfterComplete!);

    static readonly error = createSelector(OrpyxUserDeleteSelectors.state, state => state.error);
    static readonly user = createSelector(OrpyxUserDeleteSelectors.state, state => state.user);

    static readonly toggle = createSelector(OrpyxUserDeleteSelectors.state, state => state.toggle);
    static readonly inProcess = createSelector(OrpyxUserDeleteSelectors.state, state => state.inProcess);
}

export default OrpyxUserDeleteSelectors;
