import produce from "immer";
import {AdminAuthActions} from "./admin-auth-actions";
import {StoreAction} from "orpyx-web-common";
import {AdminAzureActor} from "../models/admin-azure-token";
import AdminAzureTokenStorage from "./actor/admin-azure-token-storage";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import AdminActorStorage from "./actor/admin-actor-storage";

export interface AdminAuthState {
    isLoggedIn: boolean,
    inProcess: boolean,

    azureActor: AdminAzureActor | undefined,
    profile: IOrpyxUserDto | undefined,
}

const createInitialState = (): AdminAuthState => {
    const azureActor = AdminAzureTokenStorage.getActor();
    const profile = AdminActorStorage.getActorProfile();

    if (azureActor && azureActor.accessToken && profile) {
        if (azureActor.accessToken.expiredAt > new Date()) {
            return {
                isLoggedIn: true,
                inProcess: false,
                azureActor,
                profile
            };
        }
    }

    return {
        isLoggedIn: false,
        inProcess: false,

        profile: undefined,
        azureActor: undefined,
    };
};

const AdminAuthReducers = (state = createInitialState(), action: StoreAction<AdminAuthActions>): AdminAuthState => {
    return produce(state, (draft: AdminAuthState) => {
        switch (action.type) {
            case AdminAuthActions.LOGIN:
                draft.inProcess = true;
                draft.isLoggedIn = false;
                draft.azureActor = undefined;
                draft.profile = undefined;
                break;

            case AdminAuthActions.LOGOUT:
                draft.inProcess = true;
                break;

            case AdminAuthActions.LOGIN_FAILED:
            case AdminAuthActions.LOGOUT_SUCCESSFULLY:
            case AdminAuthActions.FETCH_USER_PROFILE_FAILED:
                draft.inProcess = false;
                draft.isLoggedIn = false;
                draft.azureActor = undefined;
                draft.profile = undefined;

                break;

            case AdminAuthActions.LOGIN_SUCCESSFULLY:
                draft.azureActor = action.payload as AdminAzureActor;
                break;

            case AdminAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY:
                draft.profile = action.payload as IOrpyxUserDto;
                break;

            case AdminAuthActions.LOGIN_COMPLETE:
                draft.isLoggedIn = true;
                draft.inProcess = false;
                break;

        }
    });
};

export default AdminAuthReducers;


