import {IModalWindow, ModalWindowStore, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientDevicesUpdateActions} from "./patient-devices-update-actions";
import {IListProductsResultEntry} from "admins-dashboard/http-clients/index";

export interface PatientDevicesUpdateState extends IModalWindow {
    device: IListProductsResultEntry | undefined;
}

const initialState: PatientDevicesUpdateState = {
    toggle: false,
    inProcess: false,

    device: undefined,
    error: undefined,
};

const PatientDevicesUpdateReducers = (state = initialState, action: StoreAction<PatientDevicesUpdateActions>): PatientDevicesUpdateState => {
    return produce(state, (draft: PatientDevicesUpdateState) => {
        switch (action.type) {
            case PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientDevicesUpdateActions.UPDATE_PATIENT_DEVICE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientDevicesUpdateActions.SHOW_MODAL:
                draft.device = action.payload;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientDevicesUpdateActions.HIDE_MODAL:
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default PatientDevicesUpdateReducers;
