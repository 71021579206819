import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CREATE_DASHBOARD_USER_FAILED, CREATE_DASHBOARD_USER_SUCCESSFULLY, OrganizationUserCreateActions} from "./organization-user-create-actions";
import {AdminDashboardUserHttpClient, CreateDashboardUserDto, ICreateDashboardUserDto, ICreateDashboardUserResult, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import OrganizationUserCreateSelectors from "./organization-user-create-selectors";
import {DASHBOARD_USER_TITLES_INITIALIZE} from "../../../../dashboard-user-titles/store/dashboard-user-titles-actions";
import {DashboardUserTitlesSelectors} from "../../../../dashboard-user-titles/store/dashboard-user-titles-selectors";

const API_VERSIONS = '1';

function* onCreateDashboardUser(action: StoreAction<OrganizationUserCreateActions, ICreateDashboardUserDto>) {
    try {
        const organization: IOrganizationOutputDto = yield select(OrganizationUserCreateSelectors.organization);

        const model = new CreateDashboardUserDto(action.payload!);
        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);

        const data: ICreateDashboardUserResult = yield call([api, api.createDashboardUser], organization!.id!, API_VERSIONS, model);

        yield put(CREATE_DASHBOARD_USER_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            //  TODO: Add organization model on back-end
            message: `Successfully created health care practitioner ${fullNamePipe(data.user)} (title: ${data.title?.name}) in organization ${data.organization?.name}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(CREATE_DASHBOARD_USER_FAILED(e));
    }
}

function* onShowCreateDashboardUserModal() {
    const isDashboardUserTitlesInitialized: boolean = yield select(DashboardUserTitlesSelectors.initialized);
    if(!isDashboardUserTitlesInitialized) {
        yield put(DASHBOARD_USER_TITLES_INITIALIZE());
    }
}

export default function* OrganizationUserCreateSagas() {
    yield all([
        takeEvery(OrganizationUserCreateActions.CREATE_DASHBOARD_USER, onCreateDashboardUser),
        takeEvery(OrganizationUserCreateActions.SHOW_MODAL, onShowCreateDashboardUserModal),
    ]);
}

