import {OrderByQuery, PagingQuery, parseEnum} from "orpyx-web-common";
import {ListOrpyxCareUserPropertyOrderBy} from "admins-dashboard/http-clients/index";

export class OrpyxCareUsersSearchQuery extends OrderByQuery<ListOrpyxCareUserPropertyOrderBy> {
    /// <summary>
    /// <para>Search users by</para>
    /// <para>- FirstName or LastName</para>
    /// <para>- Pair of FirstName and LastName</para>
    /// </summary>
    term?: string | undefined;

    public static parse(term: string | undefined, orderBy: string | undefined, orderByAsc: string | undefined, offset: string | undefined, count: string | undefined): OrpyxCareUsersSearchQuery {
        const orderByValue = parseEnum(ListOrpyxCareUserPropertyOrderBy, orderBy, ListOrpyxCareUserPropertyOrderBy.FullName);

        return new OrpyxCareUsersSearchQuery
        (
            term,
            orderByValue,
            !!orderByAsc,

            offset ? parseInt(offset) : PagingQuery.DEFAULT_PAGE,
            count ? parseInt(count) : PagingQuery.DEFAULT_COUNT
        );
    }

    constructor(term?: string | undefined, orderBy?: ListOrpyxCareUserPropertyOrderBy, orderByAsc?: boolean, offset: number | undefined = PagingQuery.DEFAULT_PAGE, count: number | undefined = PagingQuery.DEFAULT_COUNT) {
        super(orderBy, orderByAsc, offset, count);
        this.term = term;
    }
}

export const toQueryString = (query: OrpyxCareUsersSearchQuery): string => {
    let str = `offset=${query.offset}&count=${query.count}`;
    if (query.term) {
        str += `&term=${query.term}`;
    }

    if (query.orderBy) {
        str += `&${OrpyxCareUsersSearchQuery.PARAM_ORDER_BY}=${query.orderBy}`;
    }
    if (query.orderByAsc) {
        str += `&${OrpyxCareUsersSearchQuery.PARAM_ORDER_BY_ASC}=${query.orderByAsc}`;
    }

    return str;
};
