import {IListDashboardUsersResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {DashboardUsersSearchQuery} from "../models/dashboard-users-search-query";
import {OrganizationUsersListActions} from "./organization-users-list-actions";

export interface OrganizationUsersListState {
    initialized: boolean;
    loading: boolean;
    total: number,
    users: IListDashboardUsersResultEntry[];
    search: DashboardUsersSearchQuery;
    error: OperationErrorType;
}

const initialState: OrganizationUsersListState = {
    initialized: false,
    loading: false,
    total: 0,
    search: new DashboardUsersSearchQuery(),
    users: [],
    error: undefined
};

export const OrganizationUsersListReducers = (state = initialState, action: StoreAction<OrganizationUsersListActions>): OrganizationUsersListState => {
    return produce(state, (draft: OrganizationUsersListState) => {
        switch (action.type) {
            case OrganizationUsersListActions.FETCH_DASHBOARD_USERS:
                draft.search = action.payload;
                break;

            case OrganizationUsersListActions.FETCH_DASHBOARD_USERS_BEGIN:
                draft.loading = true;
                break;

            case OrganizationUsersListActions.FETCH_DASHBOARD_USERS_SUCCESSFULLY:
                draft.users = action.payload.items;
                draft.total = action.payload.total;
                draft.loading = false;
                break;

            case OrganizationUsersListActions.FETCH_DASHBOARD_USERS_FAILED:
                draft.loading = false;
                draft.error = action.payload!;
                break;
        }
    })
};

export default OrganizationUsersListReducers;
