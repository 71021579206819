import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminOrpyxCareUserHttpClient, IGetOrpyxCareUserInformationResponse, IUpdateDashboardUserDto, IUserOutputDto, UpdateOrpyxCareUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";
import { OrpyxCareUserInformationActions, INITIALIZE_ORPYX_CARE_USER_INFORMATION, INITIALIZE_ORPYX_CARE_USER_INFORMATION_FAILED, INITIALIZE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY, UPDATE_ORPYX_CARE_USER_INFORMATION_FAILED, UPDATE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY } from './orpyx-care-user-information-page.actions';
import OrpyxCareUserInformationSelectors from './orpyx-care-user-information-page.selectors';

const API_VERSIONS = '1';

function* onFetchOrpyxCareUserInformation(action: StoreAction<OrpyxCareUserInformationActions, { dashboardUserId: number }>) {
    try {
        const {dashboardUserId} = action.payload!;

        const api = new AdminOrpyxCareUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IGetOrpyxCareUserInformationResponse = yield call(
            [api, api.getOrpyxCareUserInformation],
            dashboardUserId,
            API_VERSIONS
        );

        yield put(INITIALIZE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY(data));
    } catch (e) {
        yield put(INITIALIZE_ORPYX_CARE_USER_INFORMATION_FAILED(e));
    }
}

function* onRefreshOrpyxCareUserInformation() {
    const dashboardUser: IGetOrpyxCareUserInformationResponse = yield select(OrpyxCareUserInformationSelectors.user)!;
    yield put(INITIALIZE_ORPYX_CARE_USER_INFORMATION(dashboardUser.user!.id!));
}

function* onUpdateOrpyxCareUserInformation(action: StoreAction<OrpyxCareUserInformationActions, IUpdateDashboardUserDto>) {
    try {
        const model = new UpdateOrpyxCareUserDto(action.payload!);
        const dashboardUser: IGetOrpyxCareUserInformationResponse = yield select(OrpyxCareUserInformationSelectors.user)!;

        const api = new AdminOrpyxCareUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IUserOutputDto = yield call(
            [api, api.updateOrpyxCareUser],
            dashboardUser.user!.id!,
            API_VERSIONS,
            model
        );

        yield put(UPDATE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Orpyx Care User ${fullNamePipe(data)} successfully updated`,
            options: {variant: 'success'}
        }));
    } catch (e) {
        yield put(UPDATE_ORPYX_CARE_USER_INFORMATION_FAILED(e));
    }
}

export default function* OrpyxCareUserInformationSagas() {
    yield all([
        yield takeEvery(OrpyxCareUserInformationActions.INITIALIZE, onFetchOrpyxCareUserInformation),
        yield takeEvery(OrpyxCareUserInformationActions.UPDATE_USER, onUpdateOrpyxCareUserInformation),
        yield takeEvery(OrpyxCareUserInformationActions.UPDATE_USER_SUCCESSFULLY, onRefreshOrpyxCareUserInformation),
    ]);
}

