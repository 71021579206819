import {PatientsRawTemperatureActions} from "./patients-raw-temperature.actions";
import produce from "immer";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface PatientRawTemperatureExportState {
    loading: boolean;
    error: OperationErrorType;
}

const initialState: PatientRawTemperatureExportState = {
    loading: false,
    error: undefined
};

const PatientRawTemperatureExportReducer = (state = initialState, action: StoreAction<PatientsRawTemperatureActions>): PatientRawTemperatureExportState => {
    return produce(state, (draft: PatientRawTemperatureExportState) => {
        switch (action.type) {
            case PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE:
                draft.error = undefined;
                draft.loading = true;
                break;
            case PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY:
                draft.error = undefined;
                draft.loading = false;
                break;
            case PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default PatientRawTemperatureExportReducer;
