import {AutocompleteSelect} from "../../autocomplete-select/autocomplete-select";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {SEARCH_ORGANIZATIONS} from "../store/organization-dropdown-filter-actions";
import {memo} from "react";
import {connect} from "react-redux";
import * as React from "react";
import {OrganizationAutoCompleteEvents, OrganizationAutoCompleteMeta, OrganizationAutoCompleteOwnProps, OrganizationAutoCompleteProps} from "./organization-auto-complete.interface";
import {OrganizationDropdownFilterSelectors} from "../store/organization-dropdown-filter-selectors";

const OrganizationAutoCompleteWrapper = (props: OrganizationAutoCompleteMeta) => {
    return <>
        <AutocompleteSelect
            scope={props.scope}
            loading={props.loading}
            onSelected={props.onSelected}
            onSearch={props.searchOrganizations}
            selected={{
                id: props.organization?.id,
                name: props.organization?.name!,
            }}
            values={props.organizations.map(organization => ({
                id: organization.id!,
                name: organization.name!,
                deleted: organization.deleted!,
            }))}
            placeholder={''}
        />
    </>
};

const mapStateToProps = (state: IOrpyxApplicationStore, props: OrganizationAutoCompleteProps): OrganizationAutoCompleteOwnProps => {
    return {
        loading: OrganizationDropdownFilterSelectors.loading(props.scope)(state),
        organizations: OrganizationDropdownFilterSelectors.organizations(props.scope)(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationAutoCompleteProps): OrganizationAutoCompleteEvents => {
    return bindActionCreators({
        searchOrganizations: SEARCH_ORGANIZATIONS(props.scope),
    }, dispatch);
};

export const OrganizationAutoComplete = memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationAutoCompleteWrapper));
