import {OrderByQuery, PagingQuery, parseEnum} from "orpyx-web-common";
import {GetPatientsListPropertyOrderBy} from "admins-dashboard/http-clients/index";

export class PatientsSearchQuery extends OrderByQuery<GetPatientsListPropertyOrderBy> {
    /// <summary>
    /// <para>Search users by</para>
    /// <para>- FirstName or LastName</para>
    /// <para>- Pair of FirstName and LastName</para>
    /// </summary>
    term?: string | undefined;

    /// <summary>
    /// <para>Search patients in Organization</para>
    /// </summary>
    organizationId?: number | undefined;

    private static readonly DEFAULT_ORGANIZATION_ID: number | undefined = undefined;

    public static parse(term?: string | undefined, organizationId?: string | undefined, orderBy?: string | undefined, orderByAsc?: string | undefined, offset?: string | undefined, count?: string | undefined): PatientsSearchQuery {
        const orderByValue = parseEnum(GetPatientsListPropertyOrderBy, orderBy, GetPatientsListPropertyOrderBy.PatientId);

        return new PatientsSearchQuery(
            term,
            organizationId ? parseInt(organizationId) : PatientsSearchQuery.DEFAULT_ORGANIZATION_ID,
            orderByValue,
            !!orderByAsc,
            offset ? parseInt(offset, 10) : PagingQuery.DEFAULT_PAGE,
            count ? parseInt(count, 10) : PagingQuery.DEFAULT_COUNT
        );
    }

    constructor(term?: string | undefined, organizationId?: number | undefined, orderBy?: GetPatientsListPropertyOrderBy, orderByAsc?: boolean, offset: number | undefined = PagingQuery.DEFAULT_PAGE, count: number | undefined = PagingQuery.DEFAULT_COUNT) {
        super(orderBy, orderByAsc, offset, count);
        this.orderBy = orderBy;
        this.organizationId = organizationId;
        this.term = term;
    }
}
