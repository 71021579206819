import {StoreAction} from "orpyx-web-common";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export enum OrpyxUserSendInviteActions {

    ORPYX_USER_SEND_INVITE = 'ORPYX_USER_SEND_INVITE: ORPYX_USER_SEND_INVITE',
    ORPYX_USER_SEND_INVITE_SUCCESSFULLY = 'ORPYX_USER_SEND_INVITE: ORPYX_USER_SEND_INVITE_SUCCESSFULLY',
    ORPYX_USER_SEND_INVITE_FAILED = 'ORPYX_USER_SEND_INVITE: ORPYX_USER_SEND_INVITE_FAILED',
}

export const ORPYX_USER_SEND_INVITE = (user: IOrpyxUserDto): StoreAction<OrpyxUserSendInviteActions> => ({
    type: OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE, payload: user
});

export const ORPYX_USER_SEND_INVITE_SUCCESSFULLY = (user: IOrpyxUserDto): StoreAction<OrpyxUserSendInviteActions> => ({
    type: OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE_SUCCESSFULLY, payload: user
});

export const ORPYX_USER_SEND_INVITE_FAILED = (user: IOrpyxUserDto): StoreAction<OrpyxUserSendInviteActions> => ({
    type: OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE_FAILED, payload: user
});

