import { AdminOrpyxCareUserHttpClient, DashboardRoleType, IUserOutputDto, UpdateOrpyxCareUserDto } from "admins-dashboard";
import { ORPYXSI_ADMIN_URL } from "appConstants";
import { ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete } from "orpyx-web-common";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { OrpyxCareConfirmationModalActions, ORPYX_CARE_CONFIRMATION_MODAL_FAILED, ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY } from "./orpyx-care-confirmation-actions";
import { OrpyxCareConfirmationSelectors } from "./orpyx-care-confirmation-selectors";

const API_VERSIONS = '1';

function* onOrpyxCareUserModalConfirm() {
    try {
        const user: IUserOutputDto = yield select(OrpyxCareConfirmationSelectors.dashboardUser);
        const newPermission: DashboardRoleType = yield select(OrpyxCareConfirmationSelectors.permission);

        const body: UpdateOrpyxCareUserDto = new UpdateOrpyxCareUserDto();
        body.firstName = user.firstName!;
        body.lastName = user.lastName!;
        body.accessGroup = newPermission;
        
        const api = new AdminOrpyxCareUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IUserOutputDto = yield call(
            [api, api.updateOrpyxCareUser],
            user.id!,
            API_VERSIONS,
            body
        );

        yield put(ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY(StoreActionAfterComplete.RefreshEntitiesList));
        yield put(ENQUEUE_SNACKBAR({
            message: `Orpyx Care User ${fullNamePipe(data)} permissions successfully updated`,
            options: {variant: 'success'}
        }));
    } catch (e) {
        yield put(ORPYX_CARE_CONFIRMATION_MODAL_FAILED(e));
    }
}

export default function* OrpyxCareConfirmationModalSagas() {
    yield takeEvery(OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED, onOrpyxCareUserModalConfirm)
}