import {ICreateProductWith2InsolesDto, IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientDevicesCreateActions {
    CREATE_PATIENT_DEVICE = 'CREATE_PATIENT_DEVICE: CREATE_PATIENT_DEVICE',
    CREATE_PATIENT_DEVICE_SUCCESSFULLY = 'CREATE_PATIENT_DEVICE: CREATE_PATIENT_DEVICE_SUCCESSFULLY',
    CREATE_PATIENT_DEVICE_FAILED = 'CREATE_PATIENT_DEVICE: CREATE_PATIENT_DEVICE_FAILED',

    SHOW_MODAL = 'CREATE_PATIENT_DEVICE: SHOW_MODAL',
    HIDE_MODAL = 'CREATE_PATIENT_DEVICE: HIDE_MODAL',
}

export enum PatientDevicesCreateActionAfterComplete {
    None,
    RefreshUserInformation,
}

export const whenPatientDevicesCreateActionAfterComplete = (actionAfterComplete: PatientDevicesCreateActionAfterComplete) => (action: StoreAction<PatientDevicesCreateActions, PatientDevicesCreateActionAfterComplete>): boolean => {
    return action.type === PatientDevicesCreateActions.CREATE_PATIENT_DEVICE_SUCCESSFULLY && action.payload === actionAfterComplete;
};

export const CREATE_PATIENT_DEVICE = (model: ICreateProductWith2InsolesDto): StoreAction<PatientDevicesCreateActions> => ({
    type: PatientDevicesCreateActions.CREATE_PATIENT_DEVICE, payload: model
});

export const CREATE_PATIENT_DEVICE_SUCCESSFULLY = (actionAfterComplete: PatientDevicesCreateActionAfterComplete): StoreAction<PatientDevicesCreateActions> => ({
    type: PatientDevicesCreateActions.CREATE_PATIENT_DEVICE_SUCCESSFULLY, payload: actionAfterComplete
});

export const CREATE_PATIENT_DEVICE_FAILED = (error: OperationErrorType): StoreAction<PatientDevicesCreateActions> => ({
    type: PatientDevicesCreateActions.CREATE_PATIENT_DEVICE_FAILED, payload: error
});

export const CREATE_PATIENT_DEVICE_SHOW_MODAL = (actionAfterComplete: PatientDevicesCreateActionAfterComplete) => (patient: IGetPatientsListResult | IGetPatientInformationResult): StoreAction<PatientDevicesCreateActions> => ({
    type: PatientDevicesCreateActions.SHOW_MODAL, payload: {patient, actionAfterComplete}
});

export const CREATE_PATIENT_DEVICE_HIDE_MODAL = (): StoreAction<PatientDevicesCreateActions> => ({
    type: PatientDevicesCreateActions.HIDE_MODAL
});
