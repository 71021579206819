import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export enum OrganizationDeleteActions {

    ORGANIZATION_DELETE = 'ORGANIZATION_DELETE: ORGANIZATION_DELETE',
    ORGANIZATION_DELETE_SUCCESSFULLY = 'ORGANIZATION_DELETE: ORGANIZATION_DELETE_SUCCESSFULLY',
    ORGANIZATION_DELETE_FAILED = 'ORGANIZATION_DELETE: ORGANIZATION_DELETE_FAILED',

    SHOW_MODAL = 'ORGANIZATION_DELETE: SHOW_MODAL',
    HIDE_MODAL = 'ORGANIZATION_DELETE: HIDE_MODAL',
}

export const ORGANIZATION_DELETE = (): StoreAction<OrganizationDeleteActions> => ({
    type: OrganizationDeleteActions.ORGANIZATION_DELETE,
});

export const ORGANIZATION_DELETE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (organization: IOrganizationOutputDto): StoreAction<OrganizationDeleteActions> => ({
    type: OrganizationDeleteActions.SHOW_MODAL, payload: {organization, actionAfterComplete}
});

export const ORGANIZATION_DELETE_HIDE_MODAL = (): StoreAction<OrganizationDeleteActions> => ({
    type: OrganizationDeleteActions.HIDE_MODAL,
});

export const ORGANIZATION_DELETE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrganizationDeleteActions> => ({
    type: OrganizationDeleteActions.ORGANIZATION_DELETE_SUCCESSFULLY, payload: actionAfterComplete
});

export const ORGANIZATION_DELETE_FAILED = (error: OperationErrorType): StoreAction<OrganizationDeleteActions> => ({
    type: OrganizationDeleteActions.ORGANIZATION_DELETE_FAILED, payload: error
});

