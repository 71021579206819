import * as React from "react";
import {memo, useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {convertToDefaultInputValue} from "orpyx-web-common";
import {AutocompleteSelectMeta, AutocompleteSelectValue} from "./autocomplete-select.interface";


export const AutocompleteSelect = memo((props: AutocompleteSelectMeta<any>) => {
    const [selectedEntity, setSelectedEntity] = useState<AutocompleteSelectValue | null>({
        name: props.selected?.name ?? '',
        id: props.selected?.id ?? -1
    });

    const selectedEntityValue = convertToDefaultInputValue<AutocompleteSelectValue>(selectedEntity, {
        id: -1,
        name: ''
    });

    const onResetSearchTerm = () => {
        setSelectedEntity({
            id: -1,
            name: ''
        });
    };

    const onEntitySelected = (event1: any, value: AutocompleteSelectValue | null) => {
        setSelectedEntity(value);
        props.onSelected(value);
    };

    const onChangeSearchTerm = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.value) {
            props.onSearch(event.target.value);
        }

        setSelectedEntity({
            id: -1,
            name: event.target.value
        });
    };

    return (
        <Autocomplete
            style={{width: 300}}
            getOptionLabel={(option) => option.name!}
            filterOptions={(x) => x}
            value={selectedEntityValue}
            options={props.values}
            loading={props.loading}
            autoComplete
            includeInputInList
            onChange={onEntitySelected}
            onReset={onResetSearchTerm}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.placeholder}
                    variant="outlined"
                    fullWidth
                    onChange={onChangeSearchTerm}
                />
            )}
            renderOption={(option) => option.name}
        />
    );
});
