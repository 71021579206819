import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

export class AdminDashboardUsersPolicies {
    static readonly Create = OrpyxRoleType.OrganizationsAdmin;
    static readonly Update = OrpyxRoleType.OrganizationsAdmin;
    static readonly List = OrpyxRoleType.OrganizationsAdmin;
    static readonly Invite = OrpyxRoleType.OrganizationsAdmin;
    static readonly Delete = OrpyxRoleType.OrganizationsAdmin;
    static readonly Restore = OrpyxRoleType.OrganizationsAdmin;
    static readonly Information = OrpyxRoleType.OrganizationsAdmin;
    static readonly ResetPassword = OrpyxRoleType.OrganizationsAdmin;
}
