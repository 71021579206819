import produce from "immer";
import {DashboardRole} from "admins-dashboard/http-clients/index";
import {DashboardRolesActions} from "./dashboard-roles-actions";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface DashboardRolesState {
    initialized: boolean;
    roles: DashboardRole[];
    error: OperationErrorType;
}

const initialState: DashboardRolesState = {
    initialized: false,
    roles: [],
    error: undefined,
};

const DashboardRolesReducers = (state = initialState, action:  StoreAction<DashboardRolesActions>): DashboardRolesState => {
    return produce(state, (draft: DashboardRolesState) => {
        switch (action.type) {
            case DashboardRolesActions.INITIALIZE:
                break;

            case DashboardRolesActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.roles = action.payload;
                draft.error = undefined;
                break;

            case DashboardRolesActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.error = action.payload;
                draft.roles = [];
                break;
        }
    })
};

export default DashboardRolesReducers;
