import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export enum OrganizationRestoreActions {

    ORGANIZATION_RESTORE = 'ORGANIZATION_RESTORE: ORGANIZATION_RESTORE',
    ORGANIZATION_RESTORE_SUCCESSFULLY = 'ORGANIZATION_RESTORE: ORGANIZATION_RESTORE_SUCCESSFULLY',
    ORGANIZATION_RESTORE_FAILED = 'ORGANIZATION_RESTORE: ORGANIZATION_RESTORE_FAILED',

    SHOW_MODAL = 'ORGANIZATION_RESTORE: SHOW_MODAL',
    HIDE_MODAL = 'ORGANIZATION_RESTORE: HIDE_MODAL',
}

export const ORGANIZATION_RESTORE = (): StoreAction<OrganizationRestoreActions> => ({
    type: OrganizationRestoreActions.ORGANIZATION_RESTORE,
});

export const ORGANIZATION_RESTORE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (organization: IOrganizationOutputDto): StoreAction<OrganizationRestoreActions> => ({
    type: OrganizationRestoreActions.SHOW_MODAL, payload: {organization, actionAfterComplete}
});

export const ORGANIZATION_RESTORE_HIDE_MODAL = (): StoreAction<OrganizationRestoreActions> => ({
    type: OrganizationRestoreActions.HIDE_MODAL,
});

export const ORGANIZATION_RESTORE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrganizationRestoreActions> => ({
    type: OrganizationRestoreActions.ORGANIZATION_RESTORE_SUCCESSFULLY, payload: actionAfterComplete
});

export const ORGANIZATION_RESTORE_FAILED = (error: OperationErrorType): StoreAction<OrganizationRestoreActions> => ({
    type: OrganizationRestoreActions.ORGANIZATION_RESTORE_FAILED, payload: error
});

