import {IModalWindow, ModalWindowStore, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientDevicesCreateActionAfterComplete, PatientDevicesCreateActions} from "./patient-devices-create-actions";
import {IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";

export interface PatientDevicesCreateState extends IModalWindow {
    actionAfterComplete: PatientDevicesCreateActionAfterComplete;
    patient: IGetPatientsListResult | IGetPatientInformationResult | undefined;
}

const initialState: PatientDevicesCreateState = {
    toggle: false,
    inProcess: false,

    actionAfterComplete: PatientDevicesCreateActionAfterComplete.None,

    patient: undefined,
    error: undefined,
};

const PatientDevicesCreateReducers = (state = initialState, action: StoreAction<PatientDevicesCreateActions>): PatientDevicesCreateState => {
    return produce(state, (draft: PatientDevicesCreateState) => {
        switch (action.type) {
            case PatientDevicesCreateActions.CREATE_PATIENT_DEVICE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case PatientDevicesCreateActions.CREATE_PATIENT_DEVICE_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case PatientDevicesCreateActions.CREATE_PATIENT_DEVICE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case PatientDevicesCreateActions.SHOW_MODAL:
                draft.patient = action.payload!.patient;
                draft.actionAfterComplete = action.payload!.actionAfterComplete;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case PatientDevicesCreateActions.HIDE_MODAL:
                draft.patient = undefined;
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default PatientDevicesCreateReducers;
