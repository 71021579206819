import produce from "immer";
import {OrpyxCareConfirmationModalActions} from "./orpyx-care-confirmation-actions";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IUserOutputDto} from "admins-dashboard";

export interface OrpyxCareConfirmationModalState extends IModalWindow {
    dashboardUser: IUserOutputDto | undefined;
    permission: string | null;
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrpyxCareConfirmationModalState = {
    ...ModalWindowInitialState,

    dashboardUser: undefined,
    permission: null,
    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrpyxCareConfirmationModalReducers = (state = initialState, action: StoreAction<OrpyxCareConfirmationModalActions>): OrpyxCareConfirmationModalState => {
    return produce(state, (draft: OrpyxCareConfirmationModalState) => {
        switch (action.type) {
            case OrpyxCareConfirmationModalActions.SHOW_MODAL:
                draft.dashboardUser = action.payload.user;
                draft.permission = action.payload.permission;
                draft.actionAfterComplete = action.payload.actionAfterComplete;

                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_SUCCESSFULLY:
            case OrpyxCareConfirmationModalActions.HIDE_MODAL:
                draft.dashboardUser = undefined;
                draft.permission = null;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_USER_CONFIRMED:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrpyxCareConfirmationModalActions.ORPYX_CARE_CONFIRMATION_MODAL_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    });
};
export default OrpyxCareConfirmationModalReducers;
