import {FormikBag} from "formik";
import {isNullOrEmpty, parseEnum} from "orpyx-web-common";

import {AuditLoggingFilterFormProps, AuditLoggingFilterFormValues} from "./audit-logging-filter-form.interface";
import {ActorType, GenerateReportPatientOptions, PhiAccessType} from "admins-dashboard";
import moment from "moment";

export const onSubmitAuditLoggingFilterForm = (values: AuditLoggingFilterFormValues, bag: FormikBag<AuditLoggingFilterFormProps, AuditLoggingFilterFormValues>) => {
    bag.props.onSubmit({
        organizationId: isNullOrEmpty(values.organizationId) ? undefined : parseInt(values.organizationId, 10),
        patientIdentifier: values.patientIdentifier,
        patientOptions: parseEnum(GenerateReportPatientOptions, values.patientOptions),

        actorType: parseEnum(ActorType, values.actorType),
        actorIdentifier: values.actorIdentifier,

        accessType: parseEnum(PhiAccessType, values.accessType),
        begin: moment.utc(values.begin),
        end: moment.utc(values.end),
    });
};
