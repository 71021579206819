import {createSelector} from "reselect";
import {getOrpyxUsersState} from "../../store/orpyx-users-store-selectors";

export class OrpyxUserRestoreSelectors {
    static readonly state = createSelector(getOrpyxUsersState, state => state.restore);

    static readonly actionAfterComplete = createSelector(OrpyxUserRestoreSelectors.state, state => state.actionAfterComplete!);

    static readonly error = createSelector(OrpyxUserRestoreSelectors.state, state => state.error);
    static readonly user = createSelector(OrpyxUserRestoreSelectors.state, state => state.user);

    static readonly toggle = createSelector(OrpyxUserRestoreSelectors.state, state => state.toggle);
    static readonly inProcess = createSelector(OrpyxUserRestoreSelectors.state, state => state.inProcess);
}

export default OrpyxUserRestoreSelectors;
