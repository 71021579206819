import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {IGetOrpyxCareUserInformationResponse} from "admins-dashboard/http-clients/index";
import {
    ApplicationBreadcrumbs,
    ApplicationBreadcrumbsItem,
    fullNamePipe,
    LazyLoader,
    OperationProgress,
    OperationSkeleton,
} from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {withRouter} from "react-router";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { OrpyxCareUserInformationPageEvents, OrpyxCareUserInformationPageMeta, OrpyxCareUserInformationPageOwnProps, OrpyxCareUserInformationPageProps } from "./orpyx-care-user-information-page.interface";
import { onOrpyxCareUserInformationPageLoaded } from "./orpyx-care-user-information-page.hooks";
import { useOrpyxUserInformationPageStyle } from "../../../../orpyx-users/orpyx-user-information/components/orpyx-user-information-page/orpyx-user-information-page.style";
import { INITIALIZE_ORPYX_CARE_USER_INFORMATION, INITIALIZE_ORPYX_CARE_USER_INFORMATION_FAILED, UPDATE_ORPYX_CARE_USER_INFORMATION } from "../../store/orpyx-care-user-information-page.actions";
import OrpyxCareUserInformationSelectors from "../../store/orpyx-care-user-information-page.selectors";

const OrpyxCareUserInformationForm = React.lazy(() => import("../orpyx-care-user-information-form/orpyx-care-user-information-form"));

const generatePageBreadcrumbs = (dashboardUser: IGetOrpyxCareUserInformationResponse | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Orpyx Care User',
        url: '/admin/orpyx-care/users/list'
    }];

    if (dashboardUser && dashboardUser.user) {
        items.push({
            name: fullNamePipe(dashboardUser!.user)!,
            url: `/admin/orpyx-care/users/details/${dashboardUser.user!.id}`
        });
    }

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxCareUserInformationPageOwnProps => {
    return {
        error: OrpyxCareUserInformationSelectors.error(state),
        user: OrpyxCareUserInformationSelectors.user(state),

        initialized: OrpyxCareUserInformationSelectors.initialized(state),
        isUserUpdating: OrpyxCareUserInformationSelectors.isUserUpdating(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrpyxCareUserInformationPageProps): OrpyxCareUserInformationPageEvents => {
    return bindActionCreators({
        loadUserInformation: INITIALIZE_ORPYX_CARE_USER_INFORMATION,
        updateUserInformation: UPDATE_ORPYX_CARE_USER_INFORMATION,
        showErrorMessage: INITIALIZE_ORPYX_CARE_USER_INFORMATION_FAILED,
    }, dispatch);
};

const OrpyxCareUserInformationPageContent = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxCareUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();

    return (<>
        <div className={classes.title}>
            User information
        </div>

        <LazyLoader height={80} rows={4}>
            <OrpyxCareUserInformationForm
                dashboardUser={props.user!}
                error={props.error}
                inProcess={props.isUserUpdating}
                onSubmit={props.updateUserInformation}
            />
        </LazyLoader>
    </>);
})));

const OrpyxCareUserInformationPageError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxCareUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();

    return (<>
        <div className={classes.title}>
            User information
        </div>
        <OperationProgress initialized={props.initialized} error={props.error}/>
        <OperationSkeleton height={80} rows={4}/>
    </>);
})));

const OrpyxCareUserInformationPageTemplate = (props: OrpyxCareUserInformationPageMeta) => {
    const classes = useOrpyxUserInformationPageStyle();
    useEffect(onOrpyxCareUserInformationPageLoaded(props), [props.location]);

    return (
        <Container>
            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12}>
                    <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.user!)}/>
                </Grid>

                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        {props.initialized ? <OrpyxCareUserInformationPageContent/> : <OrpyxCareUserInformationPageError/>}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export const OrpyxCareUserInformationPage = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxCareUserInformationPageTemplate)));
