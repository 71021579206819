import {IAssignedRoleDto, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {JsonParseResult} from "orpyx-web-common";

export class AdminActorStorage {
    private static readonly keyName = ' AdminActor';

    public static readonly resetActorProfile = (): void => {
        sessionStorage.removeItem(AdminActorStorage.keyName);
    };

    public static readonly saveActorProfile = (profile: IOrpyxUserDto): void => {
        sessionStorage.removeItem(AdminActorStorage.keyName);
        sessionStorage.setItem(AdminActorStorage.keyName, JSON.stringify(profile));
    };

    public static readonly getActorProfile= (): IOrpyxUserDto | undefined => {
        const json = sessionStorage.getItem(AdminActorStorage.keyName);
        if (!json) {
            return undefined;
        }

        const obj: JsonParseResult<IOrpyxUserDto> = JSON.parse(json);
        if (!obj || !obj.roles || !obj.orpyxUserId || !obj.firstName || !obj.lastName) {
            return undefined;
        }

        const roles = obj.roles as IAssignedRoleDto[];
        const allRolesContainIds = roles.every(role => !!role.orpyxUserRoleId && role.role && role.role.orpyxRoleId && role.role.role);

        if (allRolesContainIds) {
            return obj;
        }

        return undefined;
    };
}

export default AdminActorStorage;
