import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'map.prototype.tojson'; // ployfill used to display redux state properly for "map" types in the developer tools
import {history, store} from './modules/store/store';

import log from 'loglevel';
import {SetConfiguration} from "appConstants";
import routes from "./routes";
import './App.css';
import {Application} from 'orpyx-web-common';

// set up logger plugin
var originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
    var rawMethod = originalFactory(methodName, logLevel, loggerName);

    return function (message) {
        let d: Date = new Date();
        rawMethod(d.toISOString() + ": " + message);
    };
};

SetConfiguration();

const Main = () => {
    return (
        <Application history={history} store={store}>
            {routes}
        </Application>
    );
};

ReactDOM.render(
    <Main/>,
    document.getElementById('root') as HTMLElement
);