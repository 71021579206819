import * as React from "react";
import {Box, Container} from "@material-ui/core";
import {useAdminDashboardPageStyleStyles} from "./admin-dashboard-page.style";
import {adminDashboardUrls} from "../../enums/admin-dashboard-urls";
import {AdminDashboardUrlBlock} from "../admin-dashboard-url-block/admin-dashboard-url-block";
import { ApplicationBreadcrumbs } from "orpyx-web-common";
import { generateBreadcrumbs } from "../../breadcrumbs/admin-breadcrumbs.generator";

export const AdminDashboardPage = () => {
    const classes = useAdminDashboardPageStyleStyles();

    return (
        <Container>
            <div className={classes.root}>

                <ApplicationBreadcrumbs items={generateBreadcrumbs([])}/>

                <Box display="flex" flexDirection="row" mb={3}>
                    {adminDashboardUrls.map(url => <AdminDashboardUrlBlock key={url.url} url={url}/>)}
                </Box>
            </div>

        </Container>
    );
};
