import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminProductHttpClient, ICreateProductWith2InsolesDto, IInsolesListEntry, IListProductsResultEntry, IRemoveInsoleResult, IGetPatientInformationResult} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, StoreAction} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {PatientInsolesDeleteSelectors} from "./patient-insoles-delete-selectors";
import {DELETE_PATIENT_INSOLE_FAILED, DELETE_PATIENT_INSOLE_SUCCESSFULLY, PatientInsolesDeleteActions} from "./patient-insoles-delete-actions";
import {createdInsoleTypePipe} from "../../pipes/createdInsoleTypePipe";
import {productSerialNumberPipe} from "../../pipes/productSerialNumberPipe";
import {PatientInformationSelectors} from "../../../patient-information/store/patient-information-selectors";

const API_VERSIONS = '1';

function* onDeletePatientInsole() {
    try {
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient)!;
        const device: IListProductsResultEntry = yield select(PatientInsolesDeleteSelectors.device)!;
        const insole: IInsolesListEntry = yield select(PatientInsolesDeleteSelectors.insole)!;

        const api = new AdminProductHttpClient(ORPYXSI_ADMIN_URL);

        yield call(
            [api, api.removeInsoleFromPatientProduct],
            patient.patient!.patientId!,
            device.productId!,
            insole.insoleId!,
            API_VERSIONS,
        );

        yield put(DELETE_PATIENT_INSOLE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully delete ${createdInsoleTypePipe(insole)} insole with sn ${insole.insoleSerialNumber} in device ${productSerialNumberPipe(device)}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(DELETE_PATIENT_INSOLE_FAILED(e));
    }
}

export default function* PatientInsolesDeleteSagas() {
    yield all([
        takeEvery(PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE, onDeletePatientInsole),
    ]);
}

