import {StoreAction} from "orpyx-web-common";
import {PatientInsolesAutoCompleteActions} from "./patient-insoles-auto-complete.actions";
import {
    initialPatientInsolesAutoCompleteState,
    PatientInsolesAutoCompleteReducer,
    PatientInsolesAutoCompleteState
} from "./patient-insoles-auto-complete.reducer";
import produce from "immer";
import {PatientInsolesAutoCompleteScope} from "./patient-insoles-auto-complete.types";

export interface PatientInsolesAutoCompleteStates {
    common: PatientInsolesAutoCompleteState
}

const initialStates: PatientInsolesAutoCompleteStates = {
    common: initialPatientInsolesAutoCompleteState
};

export const PatientInsolesAutoCompleteReducers = (state = initialStates, action: StoreAction<PatientInsolesAutoCompleteActions, any, PatientInsolesAutoCompleteScope>): PatientInsolesAutoCompleteStates => {
    const scopeName = action.scope!;

    return produce(state, (draft: PatientInsolesAutoCompleteStates) => {
        const scopeState: PatientInsolesAutoCompleteState = draft[scopeName];
        draft[scopeName] = PatientInsolesAutoCompleteReducer(scopeState, action);
    });
};
