import produce from "immer";
import {IUserOutputDto} from "admins-dashboard/http-clients/index";
import {StoreAction, UsersInProcess} from "orpyx-web-common";
import {OrganizationUserSendInviteActions} from "./organization-user-send-invite.actions";

export interface OrganizationUserSendInviteState {
    usersInProcess: UsersInProcess;
}

const initialState: OrganizationUserSendInviteState = {
    usersInProcess: {},
};

const OrganizationUserSendInviteReducer = (state = initialState, action: StoreAction<OrganizationUserSendInviteActions>): OrganizationUserSendInviteState => {
    return produce(state, (draft: OrganizationUserSendInviteState) => {

        switch (action.type) {
            case OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE: {
                const dashboardUser = action.payload.user as IUserOutputDto;
                draft.usersInProcess[dashboardUser.id!] = true;
                break;
            }
            case OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE_SUCCESSFULLY: {
                const dashboardUser = action.payload as IUserOutputDto;
                delete draft.usersInProcess[dashboardUser.id!];
                break;
            }
            case OrganizationUserSendInviteActions.DASHBOARD_USER_SEND_INVITE_FAILED: {
                const dashboardUser = action.payload as IUserOutputDto;
                delete draft.usersInProcess[dashboardUser.id!];
                break;
            }
        }
    });
};

export default OrganizationUserSendInviteReducer;
