import {connect} from "react-redux";
import * as React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientsRawTemperaturePageEvents, PatientsRawTemperaturePageMeta, PatientsRawTemperaturePageOwnProps, PatientsRawTemperaturePageProps} from "./patients-raw-temperature-page.interface";
import {Container} from "@material-ui/core";
import {PatientsRawTemperatureSelectors} from "../../store/patients-raw-temperature.selectors";
import {withRouter} from "react-router-dom";
import PatientsRawTemperatureForm from "../patients-raw-temperature-form/patients-raw-temperature-form";
import {usePatientsRawTemperaturePageStyle} from "./patients-raw-temperature-page.styles";
import {generateBreadcrumbs} from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {DOWNLOAD_RAW_PATIENT_TEMPERATURE} from "../../store/patients-raw-temperature.actions";

const generatePageBreadcrumbs = (): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Export Temperature',
        url: '/admin/organizations/patients/statistics/temperature'
    }];

    return generateBreadcrumbs(items);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsRawTemperaturePageOwnProps => {
    return {
        error: PatientsRawTemperatureSelectors.error(state),
        loading: PatientsRawTemperatureSelectors.loading(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsRawTemperaturePageProps): PatientsRawTemperaturePageEvents => {
    return bindActionCreators({
        downloadRawPatientTemperatureReport: DOWNLOAD_RAW_PATIENT_TEMPERATURE,
    }, dispatch);
};

const PatientsRawTemperaturePageTemplate = (props: PatientsRawTemperaturePageMeta) => {
    const classes = usePatientsRawTemperaturePageStyle();

    return (<Container>
        <div className={classes.root}>
            <ApplicationBreadcrumbs items={generatePageBreadcrumbs()}/>
            <div className={classes.content}>
                <div className={classes.title}>
                    Export raw patient temperature
                </div>

                <div>
                    <PatientsRawTemperatureForm
                        onSubmit={props.downloadRawPatientTemperatureReport}
                        inProcess={props.loading}
                        error={props.error}
                        classes={classes}
                    />
                </div>

            </div>
        </div>
    </Container>);
};

export const PatientsRawTemperaturePage = withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsRawTemperaturePageTemplate));
