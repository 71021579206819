import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";

export enum PatientDeleteActions {

    PATIENT_DELETE = 'PATIENT_DELETE: PATIENT_DELETE',
    PATIENT_DELETE_SUCCESSFULLY = 'PATIENT_DELETE: PATIENT_DELETE_SUCCESSFULLY',
    PATIENT_DELETE_FAILED = 'PATIENT_DELETE: PATIENT_DELETE_FAILED',

    SHOW_MODAL = 'PATIENT_DELETE: SHOW_MODAL',
    HIDE_MODAL = 'PATIENT_DELETE: HIDE_MODAL',
}

export const PATIENT_DELETE = (): StoreAction<PatientDeleteActions> => ({
    type: PatientDeleteActions.PATIENT_DELETE,
});

export const PATIENT_DELETE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (patient: IGetPatientsListResult | IGetPatientInformationResult): StoreAction<PatientDeleteActions> => ({
    type: PatientDeleteActions.SHOW_MODAL, payload: {patient, actionAfterComplete}
});

export const PATIENT_DELETE_HIDE_MODAL = (): StoreAction<PatientDeleteActions> => ({
    type: PatientDeleteActions.HIDE_MODAL,
});

export const PATIENT_DELETE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<PatientDeleteActions> => ({
    type: PatientDeleteActions.PATIENT_DELETE_SUCCESSFULLY, payload: actionAfterComplete
});

export const PATIENT_DELETE_FAILED = (error: OperationErrorType): StoreAction<PatientDeleteActions> => ({
    type: PatientDeleteActions.PATIENT_DELETE_FAILED, payload: error
});

