import {all} from 'redux-saga/effects';
import OrpyxCareUserInformationSagas from '../orpyx-care-user-information/store/orpyx-care-user-information-page.sagas';
import OrpyxCareUsersListSagas from '../orpyx-care-care-users-list/store/orpyx-care-users-list-sagas';
import OrpyxCareConfirmationModalSagas from '../orpyx-care-confirmation-modal/store/orpyx-care-confirmation-sagas';


export function* OrpyxCareUsersSagas() {
    yield all([
        OrpyxCareUsersListSagas(),
        OrpyxCareUserInformationSagas(),
        OrpyxCareConfirmationModalSagas()
    ]);
}
