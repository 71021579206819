import {all, call, put, select, take, takeEvery} from 'redux-saga/effects';
import {PatientsRawTemperatureActions, DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED, DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY} from "./patients-raw-temperature.actions";
import {FileResponse, AdminPatientsRawStatisticsExporterHttpClient} from 'admins-dashboard';
import {base64ToBlob, ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import FileSaver from "file-saver";
import {ExportRawPatientTemperatureStatisticsQuery} from "../models/export-raw-patient-temperature-statistics-query";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";

const API_VERSION = '1';

function* onDownloadRawPatientTemperature(action: StoreAction<ExportRawPatientTemperatureStatisticsQuery>) {
    const query = action.payload!;
    const api = new AdminPatientsRawStatisticsExporterHttpClient(ORPYXSI_ADMIN_URL);
    try {

        const data: FileResponse = yield call(
            [api, api.exportRawPatientTemperatureStatistics],
            query.saveInsolesToSeparateFiles,
            query.begin,
            query.end,
            query.insolesSerialNumbers,
            API_VERSION
        );

        yield put(DOWNLOAD_RAW_PATIENT_TEMPERATURE_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully downloaded raw temperature statistic`,
            options: {variant: "success"}
        }));

        const blob = base64ToBlob(data.content!, data.contentType!);
        FileSaver.saveAs(blob, data.fileName!);
    } catch (e) {
        yield put(DOWNLOAD_RAW_PATIENT_TEMPERATURE_FAILED(e));
        yield put(ENQUEUE_SNACKBAR({
            message: `Failed download raw temperature statistic, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* DownloadRawPatientTemperatureSages() {
    yield all([
        yield takeEvery(PatientsRawTemperatureActions.DOWNLOAD_RAW_PATIENT_TEMPERATURE, onDownloadRawPatientTemperature),
    ]);
}
