import {DropdownSelectValue} from "../../shared/dropdown-select/dropdown-select.interface";
import { PhiAccessType } from "admins-dashboard";

export const AccessTypeValues: DropdownSelectValue[] = [
    {
        value: undefined,
        name: ''
    },
    {
        value: PhiAccessType.Created,
        name: 'Created'
    },
    {
        value: PhiAccessType.Deleted,
        name: 'Deleted'
    },
    {
        value: PhiAccessType.Got,
        name: 'Got'
    },
    {
        value: PhiAccessType.Listed,
        name: 'Listed'
    },
    {
        value: PhiAccessType.Restored,
        name: 'Restored'
    },
    {
        value: PhiAccessType.Updated,
        name: 'Updated'
    },
];
