import {OrpyxRoleType} from "admins-dashboard/http-clients/index";
import {AdminPageRouteMeta, AdminPageRouteOwnProps, AdminPageRouteRenderProps} from "./admin-page-route.interface";
import {IOrpyxApplicationStore} from "../../../store/application.reducers";
import {connect} from "react-redux";
import * as React from "react";
import {memo} from "react";
import {Route, withRouter} from "react-router";
import {AdminAuthSelectors} from "../../auth/store/admin-auth-selectors";
import adminHasRequiredRolePipe from "../../pipes/admin-has-required-role-pipe";
import adminAvailableRolesPipe from "../../pipes/admin-available-roles-pipe";

const AdminPageRouteRender = memo((props: AdminPageRouteRenderProps) => {
    const Component = props.component;

    if (!props.isLoggedIn || !props.roles || !props.roles.length) {
        return <div>You are not authorized to access this page.</div>
    }

    if (!props.requiredRole) {
        //  TODO: Add warn or exception
        return <Component/>;
    }

    const availableRoles: OrpyxRoleType[] = adminAvailableRolesPipe(props.roles);
    const containsRequiredRole = adminHasRequiredRolePipe(props.requiredRole, availableRoles);
    if (!containsRequiredRole) {
        return <div>You do not have the required permissions to access this page.</div>
    }

    return <Component/>;
});

const AdminPageRoute = (props: AdminPageRouteMeta) => {
    return <Route path={props.path} exact={props.exact} render={() => <AdminPageRouteRender
        requiredRole={props.requiredRole}
        component={props.component}
        isLoggedIn={props.isLoggedIn}
        roles={props.roles}
    />}/>
};

const mapStateToProps = (state: IOrpyxApplicationStore): AdminPageRouteOwnProps => {
    return {
        roles: AdminAuthSelectors.roles(state),
        isLoggedIn: AdminAuthSelectors.isLoggedIn(state),
    };
};

const AdminPageRouteWrapper = memo(withRouter(connect(mapStateToProps)(AdminPageRoute)));
export default AdminPageRouteWrapper;
