import {ISearchPatientInsolesResponseEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientInsolesAutoCompleteActions} from "./patient-insoles-auto-complete.actions";

export interface PatientInsolesAutoCompleteState {
    loading: boolean;

    insoles: ISearchPatientInsolesResponseEntry[];

    term: string | undefined;
    error?: OperationErrorType;
}

export const initialPatientInsolesAutoCompleteState: PatientInsolesAutoCompleteState = {
    loading: false,
    error: undefined,

    term: undefined,
    insoles: [],
};

export const PatientInsolesAutoCompleteReducer = (state = initialPatientInsolesAutoCompleteState, action: StoreAction<PatientInsolesAutoCompleteActions>): PatientInsolesAutoCompleteState => {
    return produce(state, (draft: PatientInsolesAutoCompleteState) => {
        switch (action.type) {
            case PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES:
                draft.loading = true;
                draft.term = action.payload;
                break;

            case PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES_SUCCESSFULLY: {
                const result = action.payload! as ISearchPatientInsolesResponseEntry[];
                draft.insoles = result!;
                draft.loading = false;
                draft.error = undefined;
                break;
            }

            case PatientInsolesAutoCompleteActions.SEARCH_PATIENT_INSOLES_FAILED:
                draft.loading = false;
                draft.error = action.payload!;
                draft.insoles = [];
                break;
        }
    })
};
