import {createSelector} from "reselect";
import {getPatientsStoreState} from "../../store/patients-selectors";

export class PatientRestoreSelectors {

    static readonly state = createSelector(getPatientsStoreState, state => state.restore);
    static readonly error = createSelector(PatientRestoreSelectors.state, state => state.error);
    static readonly patient = createSelector(PatientRestoreSelectors.state, state => state.patient!);

    static readonly inProcess = createSelector(PatientRestoreSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(PatientRestoreSelectors.state, state => state.toggle);

    static readonly actionAfterComplete = createSelector(PatientRestoreSelectors.state, state => state.actionAfterComplete);
}

export default PatientRestoreSelectors;
