import {makeStyles} from "@material-ui/core/styles";

export const useAdminDashboardPageStyleStyles = makeStyles((theme) => ({
    root: {
        margin: 30,
    },
    link: {
        textDecoration: 'unset',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        cursor: 'pointer',

        outline: 'none',
        textDecoration: 'none',
        "&:hover": {
            backgroundColor: '#00B6B1',
            color: 'white',
        }
    },
}));