import {PatientsListPageMeta} from "./patients-list-page.interface";
import {ChangedSearchOption, ChangedSearchOptions, ControlValidator, FormControlValidators, ListSearchOrderByItem, PagingQuery} from "orpyx-web-common";
import {PatientsSearchQuery} from "../../models/PatientsSearchQuery";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {AutocompleteSelectValue} from "../../../../shared/autocomplete-select/autocomplete-select.interface";

const PatientsListTermValidators = new ControlValidator([
    FormControlValidators.minLength(2),
    FormControlValidators.maxLength(32),
]);

export const onPatientsListOrganizationReload = (props: PatientsListPageMeta) => () => {
    if (props.match.params.organizationId) {
        //  Load organization information, then load organization patients
        const organizationId = parseInt(props.match.params.organizationId, 10);
        props.loadOrganizationInformation(organizationId);
    } else {
        //  Reset organization information, then load all patients
        props.loadOrganizationInformation(undefined);
    }
};

export const onPatientsListPageLoaded = (props: PatientsListPageMeta) => () => {
    const params = new URLSearchParams(props.location.search);

    const query = PatientsSearchQuery.parse(
        params.has('term') ? params.get('term')! : undefined,
        props.match.params.organizationId,

        params.has(PatientsSearchQuery.PARAM_ORDER_BY) ? params.get(PatientsSearchQuery.PARAM_ORDER_BY)! : undefined,
        params.has(PatientsSearchQuery.PARAM_ORDER_BY_ASC) ? params.get(PatientsSearchQuery.PARAM_ORDER_BY_ASC)! : undefined,

        params.has(PatientsSearchQuery.PARAM_OFFSET) ? params.get(PatientsSearchQuery.PARAM_OFFSET)! : undefined,
        params.has(PatientsSearchQuery.PARAM_COUNT) ? params.get(PatientsSearchQuery.PARAM_COUNT)! : undefined,
    );

    props.refreshPatientsList(query);
};

export const onPatientsListOrganizationChanged = (props: PatientsListPageMeta) => (organization: AutocompleteSelectValue | null) => {
    const organizationId = organization ? organization.id! : undefined;
    const searchQuery: PatientsSearchQuery = {...props.search, organizationId, offset: PagingQuery.DEFAULT_PAGE};
    onSearchQueryChanged(props, searchQuery);
};

export const onPatientsListTermValidation = (term: string | undefined) => {
    return PatientsListTermValidators.validate(term);
};

export const onPatientsListSearchOptionsChanged = (props: PatientsListPageMeta) => (options: ChangedSearchOptions): void => {
    if (options.changed === ChangedSearchOption.Term) {
        const searchQuery: PatientsSearchQuery = {
            ...props.search,
            term: options.term,
            offset: PagingQuery.DEFAULT_PAGE
        };
        onSearchQueryChanged(props, searchQuery);
    } else if (options.changed === ChangedSearchOption.OrderBy) {
        const searchQuery: PatientsSearchQuery = {
            ...props.search,
            orderBy: options.orderBy?.value,
            orderByAsc: options.orderBy?.orderByAsc ?? PatientsSearchQuery.DEFAULT_ORDER_BY_METHOD
        };
        onSearchQueryChanged(props, searchQuery);
    }
};

export const onPatientsListPageChanged = (props: PatientsListPageMeta) => (page: number) => {
    const searchQuery: PatientsSearchQuery = {...props.search, offset: page};
    onSearchQueryChanged(props, searchQuery);
};

export const onPatientsListCountChanged = (props: PatientsListPageMeta) => (count: number) => {
    const searchQuery: PatientsSearchQuery = {...props.search, count: count, offset: PagingQuery.DEFAULT_PAGE};
    onSearchQueryChanged(props, searchQuery);
};

export const onSearchQueryChanged = (props: PatientsListPageMeta, searchQuery: PatientsSearchQuery) => {
    const urlQuery: string = toQueryString(searchQuery);
    props.history!.push(urlQuery);
};

export const toQueryString = (query: PatientsSearchQuery): string => {
    let str = '/admin/organizations/patients';
    if (query.organizationId) {
        str = `/admin/organizations/${query.organizationId.toString()}/patients`;
    }

    str += `?${PatientsSearchQuery.PARAM_OFFSET}=${query.offset}&${PatientsSearchQuery.PARAM_COUNT}=${query.count}`;
    if (query.term) {
        str += `&term=${query.term}`;
    }

    if (query.orderBy) {
        str += `&${PatientsSearchQuery.PARAM_ORDER_BY}=${query.orderBy}`;
    }

    if (query.orderByAsc) {
        str += `&${PatientsSearchQuery.PARAM_ORDER_BY_ASC}=${query.orderByAsc}`;
    }

    return str;
};
