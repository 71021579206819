import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {AuditLoggingDownloadQuery} from "../models/audit-logging-download-query";

export enum AuditLoggingDownloadActions {
    DOWNLOAD_AUDIT_LOGGING = 'AUDIT_LOGGING: DOWNLOAD_AUDIT_LOGGING',
    DOWNLOAD_AUDIT_LOGGING_SUCCESSFULLY = 'AUDIT_LOGGING: DOWNLOAD_AUDIT_LOGGING_SUCCESSFULLY',
    DOWNLOAD_AUDIT_LOGGING_FAILED = 'AUDIT_LOGGING: DOWNLOAD_AUDIT_LOGGING_FAILED',
}

export const DOWNLOAD_AUDIT_LOGGING = (query: AuditLoggingDownloadQuery): StoreAction<AuditLoggingDownloadActions> => ({
    type: AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING, payload: query
});

export const DOWNLOAD_AUDIT_LOGGING_SUCCESSFULLY = (): StoreAction<AuditLoggingDownloadActions> => ({
    type: AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING_SUCCESSFULLY
});

export const DOWNLOAD_AUDIT_LOGGING_FAILED = (error: OperationErrorType): StoreAction<AuditLoggingDownloadActions> => ({
    type: AuditLoggingDownloadActions.DOWNLOAD_AUDIT_LOGGING_FAILED, payload: error
});
