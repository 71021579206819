import * as React from "react";
import {memo, Suspense} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationCreateModalEvents, OrganizationCreateModalMeta, OrganizationCreateModalOwnProps, OrganizationCreateModalProps} from "./organization-create-modal.interface";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {getOrganizationCreateModalError, isOrganizationCreateInProcess, isOrganizationCreateModalActive} from "../../store/organization-create-selectors";
import {CREATE_ORGANIZATION, CREATE_ORGANIZATION_HIDE_MODAL} from "../../store/organization-create-actions";
import {createModalWindow, ModalWindowHeader} from "orpyx-web-common";
import {LazyLoader} from "orpyx-web-common";
import { IRemotePatientMonitoringProviderOutputDto } from "admins-dashboard";

const OrganizationCreateFormWrapper = React.lazy(() => import("../organization-create-form/organization-create-form"));

const OrganizationCreateModalTemplate = (provider: IRemotePatientMonitoringProviderOutputDto | undefined) => (props: OrganizationCreateModalMeta) => {
    return (<>
            <ModalWindowHeader>
                Create New Organization
            </ModalWindowHeader>

            <LazyLoader height={40} width={300} rows={8}>
                <OrganizationCreateFormWrapper
                    inProcess={props.inProcess}
                    error={props.error}
                    onSubmit={props.createOrganization}
                />
            </LazyLoader>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationCreateModalOwnProps => {
    return {
        error: getOrganizationCreateModalError(state),
        inProcess: isOrganizationCreateInProcess(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationCreateModalProps): OrganizationCreateModalEvents => {
    return bindActionCreators({
        createOrganization: CREATE_ORGANIZATION,
    }, dispatch);
};

export const OrganizationCreateModalWindow = (props: OrganizationCreateModalProps) => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationCreateModalTemplate(props.provider))),
    closeModalActionCreator: CREATE_ORGANIZATION_HIDE_MODAL,
    modalToggleSelector: isOrganizationCreateModalActive,
});

