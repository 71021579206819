import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationInformationDto, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export enum OrganizationUpdateActions {

    ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE: ORGANIZATION_UPDATE',
    ORGANIZATION_UPDATE_SUCCESSFULLY = 'ORGANIZATION_UPDATE: ORGANIZATION_UPDATE_SUCCESSFULLY',
    ORGANIZATION_UPDATE_FAILED = 'ORGANIZATION_UPDATE: ORGANIZATION_UPDATE_FAILED',

    ORGANIZATION_INITIALIZE_SUCCESSFULLY = 'ORGANIZATION_UPDATE: ORGANIZATION_INITIALIZE_SUCCESSFULLY',
    ORGANIZATION_INITIALIZE_FAILED = 'ORGANIZATION_UPDATE: ORGANIZATION_INITIALIZE_FAILED',

    SHOW_MODAL = 'ORGANIZATION_UPDATE: SHOW_MODAL',
    HIDE_MODAL = 'ORGANIZATION_UPDATE: HIDE_MODAL',
}

export const ORGANIZATION_UPDATE = (model: IOrganizationInformationDto): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.ORGANIZATION_UPDATE, payload: model
});

export const ORGANIZATION_UPDATE_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (organizationId: number): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.SHOW_MODAL, payload: {organizationId, actionAfterComplete}
});

export const ORGANIZATION_UPDATE_HIDE_MODAL = (): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.HIDE_MODAL,
});

export const ORGANIZATION_UPDATE_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.ORGANIZATION_UPDATE_SUCCESSFULLY, payload: actionAfterComplete
});

export const ORGANIZATION_UPDATE_FAILED = (error: OperationErrorType): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.ORGANIZATION_UPDATE_FAILED, payload: error
});

export const INITIALIZE_ORGANIZATION_UPDATE_SUCCESSFULLY = (organization: IOrganizationOutputDto): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.ORGANIZATION_INITIALIZE_SUCCESSFULLY, payload: organization
});

export const INITIALIZE_ORGANIZATION_UPDATE_FAILED = (error: OperationErrorType): StoreAction<OrganizationUpdateActions> => ({
    type: OrganizationUpdateActions.ORGANIZATION_INITIALIZE_FAILED, payload: error
});

