import { ApplicationBreadcrumbsItem } from "orpyx-web-common";

export const generateBreadcrumbs = (items: ApplicationBreadcrumbsItem[]): ApplicationBreadcrumbsItem[] => {
    const breadcrumbs: ApplicationBreadcrumbsItem[] =
        [{
            name: 'Orpyx',
            url: '/'
        }, {
            name: 'Dashboard',
            url: '/Admin'
        }, ...items];

    return breadcrumbs;
};
