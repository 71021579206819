import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrganizationNameDto} from "admins-dashboard/http-clients/index";
import {PatientDropdownFilterScope} from "./patient-dropdown-filter.types";

export enum PatientDropdownFilterActions {
    SEARCH_PATIENTS = 'SEARCH_PATIENTS: SEARCH_PATIENTS',
    SEARCH_PATIENTS_SUCCESSFULLY = 'SEARCH_PATIENTS: SEARCH_PATIENTS_SUCCESSFULLY',
    SEARCH_PATIENTS_FAILED = 'SEARCH_PATIENTS: SEARCH_PATIENTS_FAILED',
}

export const SEARCH_PATIENTS = (scope: PatientDropdownFilterScope) => (organizationId: number | undefined | null, term: string | undefined | null): StoreAction<PatientDropdownFilterActions> => ({
    type: PatientDropdownFilterActions.SEARCH_PATIENTS, payload: {organizationId, term}, scope
});

export const SEARCH_PATIENTS_SUCCESSFULLY = (scope: PatientDropdownFilterScope) => (organizations: IOrganizationNameDto[]): StoreAction<PatientDropdownFilterActions> => ({
    type: PatientDropdownFilterActions.SEARCH_PATIENTS_SUCCESSFULLY, payload: organizations, scope
});

export const SEARCH_PATIENTS_FAILED = (scope: PatientDropdownFilterScope) => (error: OperationErrorType): StoreAction<PatientDropdownFilterActions> => ({
    type: PatientDropdownFilterActions.SEARCH_PATIENTS_FAILED, payload: error, scope
});
