import * as React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {NavLink} from 'react-router-dom';
import {MediumDarkGrey, Teal} from "orpyx-web-common";
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/Help';
import PhoneIcon from '@material-ui/icons/Phone';
import OrpyxLogo from 'images/roundLogo.svg'
import {Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    menu: {
        display: 'block',
        float: 'left',
        [theme.breakpoints.down(1550)]: {
            float: 'initial',
          },
        '& ul': {
            margin: '0 0 0 100px',
            padding: '0',
            listStyle: 'none',
            [theme.breakpoints.down(1550)]: {
                margin: '0 0 50px 0',
                display: 'flex',
                justifyContent: 'center',
              },
            '& li': {
                marginBottom: '10px',
                [theme.breakpoints.down(1550)]: {
                    margin: '0 30px 0 0',
                  },
                '& a': {
                    textDecoration: 'none',
                    color: MediumDarkGrey,
                    fontSize: '16px',
                    fontWeight: 500,
                    padding: '5px 0 5px 14px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down(1550)]: {
                        paddingLeft: 0,
                      },
                    '& svg': {
                        marginRight: '7px',
                        color: Teal,
                    },
                    '& img': {
                        marginRight: '7px',
                        position: 'relative',
                        marginBottom: '-9px',
                    },
                    '&.active': {
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: '0',
                            top: '0',
                            width: '4px',
                            height: '100%',
                            background: Teal,
                            borderRadius: '3px',
                            [theme.breakpoints.down(1550)]: {
                                top: 'initial',
                                bottom: 0,
                                height: 4,
                                width: '100%',
                              },
                        }
                    },
                },
            },
        },
    },
    iconBg: {
        background: Teal,
        borderRadius: '3px',
        color: '#FFF!important',
    },
}));

type Props = {};

export const Menu: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <div className={classes.menu}>
        <ul>
            <li>
                <NavLink to="/dashboard"><DashboardIcon /> Dashboard</NavLink>
            </li>
            <li>
                <NavLink to="/aboutus"><img src={OrpyxLogo} alt="logo" width="22" />About Us</NavLink>
            </li>
            <li>
                <NavLink to="/faq"><HelpIcon />FAQ</NavLink>
            </li>
            <li>
                <NavLink to="/support"><PhoneIcon className={classes.iconBg} />Support</NavLink>
            </li>
        </ul>
    </div>
  );
};