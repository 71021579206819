import produce from "immer";
import {PatientsSearchQuery} from "../models/PatientsSearchQuery";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {PatientsListActions} from "./patients-list-actions";
import {IOrganizationOutputDto, IGetPatientsListResult, IGetPatientsListResultPagingResult} from "admins-dashboard/http-clients/index";

export interface PatientsListState {
    initialized: boolean;

    loading: boolean;
    total: number,
    patients: IGetPatientsListResult[];

    organization: IOrganizationOutputDto | undefined;

    search: PatientsSearchQuery;
    error: OperationErrorType;
}

const initialState: PatientsListState = {
    initialized: false,
    loading: false,

    total: 0,
    search: new PatientsSearchQuery(),

    organization: undefined,
    error: undefined,
    patients: []
};

export const PatientsListReducers = (state = initialState, action: StoreAction<PatientsListActions>): PatientsListState => {
    return produce(state, (draft: PatientsListState) => {
        switch (action.type) {
            case PatientsListActions.FETCH_PATIENTS:
                draft.loading = true;
                draft.search = action.payload;
                draft.error = undefined;
                draft.patients = [];
                break;

            case PatientsListActions.INITIALIZE:
                draft.initialized = false;
                draft.organization = undefined;
                draft.error = undefined;
                break;

            case PatientsListActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.organization = action.payload;
                break;

            case PatientsListActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.organization = undefined;
                draft.error = action.payload;
                break;

            case PatientsListActions.FETCH_PATIENTS_SUCCESSFULLY: {
                const result = action.payload! as IGetPatientsListResultPagingResult;
                draft.patients = result.items!;
                draft.total = result.total!;
                draft.loading = false;
                draft.error = undefined;
                break;
            }

            case PatientsListActions.FETCH_PATIENTS_FAILED:
                draft.loading = false;
                draft.error = action.payload!;
                break;
        }
    })
};

export default PatientsListReducers;
