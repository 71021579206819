import {createSelector} from 'reselect'
import {getPagesCount} from "orpyx-web-common";
import { OrpyxCareUsersStoreSelectors } from '../../store/orpyx-care-users-store-selectors';

export class OrpyxCareUsersListSelectors {
    static readonly state = createSelector(OrpyxCareUsersStoreSelectors.state, state => state.list);

    static readonly search = createSelector(OrpyxCareUsersListSelectors.state, state => state.search);
    static readonly users = createSelector(OrpyxCareUsersListSelectors.state, state => state.users);
    static readonly total = createSelector(OrpyxCareUsersListSelectors.state, state => state.total);
    static readonly pages = createSelector(OrpyxCareUsersListSelectors.state, state => getPagesCount(state.total, state.search.count));

    static readonly initialized = createSelector(OrpyxCareUsersListSelectors.state, state => state.initialized);
    static readonly loading = createSelector(OrpyxCareUsersListSelectors.state, state => state.loading);
    static readonly error = createSelector(OrpyxCareUsersListSelectors.state, state => state.error);

    static readonly filter = createSelector(OrpyxCareUsersListSelectors.state, state => state.filter);
}

