import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {AdminDashboardNavbarLoginEvents, AdminDashboardNavbarLoginMeta, AdminDashboardNavbarLoginOwnProps, AdminDashboardNavbarLoginProps} from "./admin-dashboard-navbar-login.interface";
import {useAdminDashboardNavbarLoginStyle} from "./admin-dashboard-navbar-login.style";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {ADMIN_LOGIN, ADMIN_LOGOUT} from "../../../auth/store/admin-auth-actions";
import {Button, CircularProgress} from "@material-ui/core";
import {AdminAuthSelectors} from "../../../auth/store/admin-auth-selectors";

export const AdminDashboardNavbarLogin = (props: AdminDashboardNavbarLoginMeta) => {
    const classes = useAdminDashboardNavbarLoginStyle();

    if (props.isLoggedIn) {
        return (
            <Button
                variant = "contained"
                color = "primary"

                disabled={props.inProcess}
                className = {classes.buttonRoot}
                onClick = {props.logout}>

                {props.inProcess && <CircularProgress/>}
                Logout
            </Button>);
    }

    return (
        <Button
            variant = "contained"
            color = "primary"
            disabled={props.inProcess}
            className = {classes.buttonRoot}
            onClick = {props.login}>
            
            {props.inProcess && <CircularProgress/>}
            Login
        </Button>);
};


const mapStateToProps = (state: IOrpyxApplicationStore): AdminDashboardNavbarLoginOwnProps => {
    return {
        isLoggedIn: AdminAuthSelectors.isLoggedIn(state),
        inProcess: AdminAuthSelectors.inProcess(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: AdminDashboardNavbarLoginProps): AdminDashboardNavbarLoginEvents => {
    return bindActionCreators({
        login: ADMIN_LOGIN,
        logout: ADMIN_LOGOUT
    }, dispatch);
};

export const AdminDashboardNavbarLoginWrapper = memo(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardNavbarLogin));
