import {IListOrpyxCareUsersResultEntryPagingResult} from "admins-dashboard/http-clients/index";
import {OrpyxCareUsersSearchQuery} from "../models/orpyx-care-users-search-query";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrpyxCareUsersListActions {
    FETCH_USERS = 'ORPYX_CARE_USERS_LIST: FETCH_USERS',
    FETCH_USERS_BEGIN = 'ORPYX_CARE_USERS_LIST: FETCH_USERS_BEGIN',
    FETCH_USERS_SUCCESSFULLY = 'ORPYX_CARE_USERS_LIST: FETCH_USERS_SUCCESSFULLY',
    FETCH_USERS_FAILED = 'ORPYX_CARE_USERS_LIST: FETCH_USERS_FAILED',

    UPDATE_PERMISSIONS_FILTER = 'ORPYX_CARE_USERS_LIST: UPDATE_PERMISSIONS_FILTER',
    UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY = 'ORPYX_CARE_USERS_LIST: UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY',
    UPDATE_PERMISSIONS_FILTER_FAILED = 'ORPYX_CARE_USERS_LIST: UPDATE_PERMISSIONS_FILTER_FAILED',
}

export const FETCH_USERS = (query: OrpyxCareUsersSearchQuery): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.FETCH_USERS, payload: query
});

export const FETCH_USERS_BEGIN = (): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.FETCH_USERS_BEGIN,
});

export const FETCH_USERS_SUCCESSFULLY = (users: IListOrpyxCareUsersResultEntryPagingResult): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.FETCH_USERS_SUCCESSFULLY, payload: users
});

export const FETCH_USERS_FAILED = (error: OperationErrorType): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.FETCH_USERS_FAILED, payload: error
});

export const UPDATE_PERMISSIONS_FILTER = (filter: string): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER, payload: filter
});

export const UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY = (filter: string): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER_SUCCESSFULLY, payload: filter
});

export const UPDATE_PERMISSIONS_FILTER_FAILED = (error: OperationErrorType): StoreAction<OrpyxCareUsersListActions> => ({
    type: OrpyxCareUsersListActions.UPDATE_PERMISSIONS_FILTER_FAILED, payload: error
})