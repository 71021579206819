import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {AdminPatientsHttpClient, IPatientOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {PatientDropdownFilterSelectors} from "./patient-dropdown-filter.selectors";
import {ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import {PatientDropdownFilterActions, SEARCH_PATIENTS_FAILED, SEARCH_PATIENTS_SUCCESSFULLY} from "./patient-dropdown-filter.actions";
import {PatientDropdownFilterScope} from "./patient-dropdown-filter.types";

const API_VERSIONS = '1';

function* onSearchOrganizations(action: StoreAction<PatientDropdownFilterActions, any, PatientDropdownFilterScope>) {
    //  Search typing debounce 500ms then takeLatest & search
    yield delay(500);

    try {
        const scope = action.scope!;
        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);

        const organizationId: number | undefined = yield select(PatientDropdownFilterSelectors.organizationId(scope))!;
        const searchTerm: string = yield select(PatientDropdownFilterSelectors.term(scope))!;

        const data: IPatientOutputDto[] = yield call([api, api.searchPatients],
            organizationId,
            searchTerm,
            API_VERSIONS
        );

        yield put(SEARCH_PATIENTS_SUCCESSFULLY(action.scope!)(data));
    } catch (e) {
        yield put(SEARCH_PATIENTS_FAILED(action.scope!)(e));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed search patients, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* PatientDropdownFilterSagas() {
    yield all([
        yield takeLatest(PatientDropdownFilterActions.SEARCH_PATIENTS, onSearchOrganizations),
    ]);
}
