import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminDashboardUserHttpClient, IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete} from "orpyx-web-common";
import OrganizationUserRestoreSelectors from "./organization-user-restore.selectors";
import {DASHBOARD_USER_RESTORE_FAILED, DASHBOARD_USER_RESTORE_SUCCESSFULLY, OrganizationUserRestoreActions} from "./organization-user-restore.actions";

const API_VERSIONS = '1';

function* onRestoreDashboardUser() {
    try {
        const dashboardUser: IUserOutputDto = yield select(OrganizationUserRestoreSelectors.dashboardUser);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrganizationUserRestoreSelectors.actionAfterComplete);

        const organization: IOrganizationOutputDto = yield select(OrganizationUserRestoreSelectors.organization);

        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IUserOutputDto = yield call(
            [api, api.restoreDashboardUser],
            organization.id!,
            dashboardUser.id!,
            API_VERSIONS
        );

        yield put(DASHBOARD_USER_RESTORE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully restored deleted health care practitioner ${fullNamePipe(data)}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(DASHBOARD_USER_RESTORE_FAILED(e));
    }
}

export default function* OrganizationUserRestoreSagas() {
    yield all([
        takeEvery(OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE, onRestoreDashboardUser),
    ]);
}
