import {connect} from "react-redux";
import {FETCH_USERS} from "../../store/orpyx-users-list-actions";
import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {OrpyxUsersListPageEvents, OrpyxUsersListPageMeta, OrpyxUsersListPageOwnProps, OrpyxUsersListPageProps} from "./orpyx-users-list-page.interface";
import {
    onOrpyxUsersListItemSelected,
    onOrpyxUsersListPageLoaded,
    onOrpyxUsersListSearchDisplayCountChanged,
    onOrpyxUsersListSearchOptionsChanged,
    onOrpyxUsersListSearchPageChanged,
    onOrpyxUsersListSearchRoleChanged,
    onOrpyxUsersListSearchTermValidate
} from "./orpyx-users-list-page.hooks";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {useOrpyxUsersListPageStyle} from "./orpyx-users-list-page.style";
import {Container} from "@material-ui/core";
import {OrpyxUserCreateModalWrapper} from "../../../orpyx-user-create/components/orpyx-user-create-modal/orpyx-user-create-modal";
import {SHOW_MODAL} from "../../../orpyx-user-create/store/orpyx-user-create-actions";
import {OrpyxUsersListSearchRoleFilter} from "../orpyx-users-list-search-role-filter/orpyx-users-list-search-role-filter";
import {OrpyxRolesSelectors} from "../../../../orpyx-roles/store/orpyx-roles-selectors";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import AdminOrpyxUsersPolicies from "../../../../policies/admin-orpyx-users-policies";
import {OrpyxUserDeleteModalWindow} from "../../../orpyx-user-delete/components/orpyx-user-delete-modal/orpyx-user-delete-modal";
import {OrpyxUserRestoreModalWindow} from "../../../orpyx-user-restore/components/orpyx-user-restore-modal/orpyx-user-restore-modal";
import {orpyxUsersListOrderItems} from "../../enums/orpyx-users-list-order";
import { ActionButton, ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, LazyLoader, ListPagination, ListSearch, OperationProgress, OperationSkeleton } from "orpyx-web-common";
import { generateBreadcrumbs } from "../../../../breadcrumbs/admin-breadcrumbs.generator";
import {withRouter} from "react-router";
import {OrpyxUsersListSelectors} from "../../store/orpyx-users-list-selectors";
const OrpyxUsersListTableWrapper = React.lazy(() => import("../orpyx-users-list-table/orpyx-users-list-table"));


const generatePageBreadcrumbs = (): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Orpyx Admin Users',
        url: '/Admin/Orpyx/Users/List'
    }];

    return generateBreadcrumbs(items);
};


const mapStateToProps = (state: IOrpyxApplicationStore): OrpyxUsersListPageOwnProps => {
    return {
        initialized: OrpyxRolesSelectors.initialized(state),

        roles: OrpyxRolesSelectors.roles(state),
        error: OrpyxRolesSelectors.error(state),

        loading: OrpyxUsersListSelectors.loading(state),
        search: OrpyxUsersListSelectors.search(state),

        users: OrpyxUsersListSelectors.users(state),

        pages: OrpyxUsersListSelectors.pages(state),
        totals: OrpyxUsersListSelectors.total(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrpyxUsersListPageProps): OrpyxUsersListPageEvents => {
    return bindActionCreators({
        fetchUsers: FETCH_USERS,
        showUserCreateModal: SHOW_MODAL
    }, dispatch);
};

const OrpyxUsersListPageContentTemplate = (props: OrpyxUsersListPageMeta) => {
    const classes = useOrpyxUsersListPageStyle();

    return (<div className={classes.content}>
        <div className={classes.topBlock}>
            <div className={classes.title}>
                Orpyx Admin Users: {props.totals} users
            </div>

            <OrpyxUsersListSearchRoleFilter
                roles={props.roles}
                orpyxRoleType={props.search.orpyxRoleType}
                onRoleSelected={orpyxRoleId => onOrpyxUsersListSearchRoleChanged(props, orpyxRoleId)}
            />
        </div>

        <LazyLoader height={60} rows={8}>
            <OrpyxUsersListTableWrapper
                onUserSelected={user => onOrpyxUsersListItemSelected(props, user.orpyxUserId!)}
            />
        </LazyLoader>


        <ListPagination
            label={'Users per page'}
            onSelectedPageChanged={page => onOrpyxUsersListSearchPageChanged(props, page)}
            onDisplayCountChanged={count => onOrpyxUsersListSearchDisplayCountChanged(props, count)}

            count={props.search.count}
            page={props.search.offset}
            pages={props.pages}
        />

        <AdminBlockWrapper requiredRole={AdminOrpyxUsersPolicies.Create}>
            <ActionButton onSubmit={props.showUserCreateModal} content={"+ Create New User"}/>
            <OrpyxUserCreateModalWrapper/>
        </AdminBlockWrapper>

        <OrpyxUserDeleteModalWindow/>
        <OrpyxUserRestoreModalWindow/>
    </div>);
};

const OrpyxUsersListPageContent = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxUsersListPageContentTemplate));


const OrpyxUsersListPageError = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)((props: OrpyxUsersListPageMeta) => {
    const classes = useOrpyxUsersListPageStyle();

    return (<div className={classes.content}>
        <div className={classes.topBlock}>
            <div className={classes.title}>
                Orpyx Admin Users
            </div>
        </div>

        <OperationProgress initialized={props.initialized} error={props.error}/>
        <OperationSkeleton height={60} rows={8}/>
    </div>);
})));

export const OrpyxUsersListPage = (props: OrpyxUsersListPageMeta) => {
    const classes = useOrpyxUsersListPageStyle();
    useEffect(onOrpyxUsersListPageLoaded(props), [props.location]);

    return (<Container>
        <div className={classes.root}>
            <ListSearch
                term={props.search.term}
                inProcess={props.loading}
                orderBy={props.search.orderBy}
                orderByItems={orpyxUsersListOrderItems}
                onSearch={onOrpyxUsersListSearchOptionsChanged(props)}
                onSearchValidate={onOrpyxUsersListSearchTermValidate}
                placeholder={"Find your users"}
            />

            <ApplicationBreadcrumbs items={generatePageBreadcrumbs()}/>
            {props.initialized ? <OrpyxUsersListPageContent/> : <OrpyxUsersListPageError/>}
        </div>
    </Container>);
};

export const OrpyxUsersListPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrpyxUsersListPage));