import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {PATIENT_RESTORE_FAILED, PATIENT_RESTORE_SUCCESSFULLY, PatientRestoreActions} from "./patient-restore.actions";
import {AdminPatientsHttpClient, IGetPatientInformationResult, IGetPatientsListResult} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete} from "orpyx-web-common";
import PatientRestoreSelectors from "./patient-restore.selectors";

const API_VERSIONS = '1';

function* onRestorePatient() {
    try {
        const patient: IGetPatientsListResult | IGetPatientInformationResult = yield select(PatientRestoreSelectors.patient);
        const actionAfterComplete: StoreActionAfterComplete = yield select(PatientRestoreSelectors.actionAfterComplete);

        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);
        const data: IGetPatientInformationResult = yield call(
            [api, api.restorePatient],
            patient.organization!.id!,
            patient.patient!.patientId!,
            API_VERSIONS
        );

        yield put(PATIENT_RESTORE_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Patient ${fullNamePipe(data.patient)} successfully restored after delete`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(PATIENT_RESTORE_FAILED(e));
    }
}

export default function* PatientRestoreSagas() {
    yield all([
        takeEvery(PatientRestoreActions.PATIENT_RESTORE, onRestorePatient),
    ]);
}
