import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import produce from "immer";
import {OrpyxUserRestoreActions} from "./orpyx-user-restore.actions";

export interface OrpyxUserRestoreState extends IModalWindow {
    user: IOrpyxUserDto | undefined,
    actionAfterComplete: StoreActionAfterComplete;
}

const initialState: OrpyxUserRestoreState = {
    ...ModalWindowInitialState,

    user: undefined,
    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrpyxUserRestoreReducers = (state = initialState, action: StoreAction<OrpyxUserRestoreActions>): OrpyxUserRestoreState => {
    return produce(state, (draft: OrpyxUserRestoreState) => {
        switch (action.type) {
            case OrpyxUserRestoreActions.RESTORE_ORPYX_USER:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrpyxUserRestoreActions.RESTORE_ORPYX_USER_SUCCESSFULLY:
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrpyxUserRestoreActions.RESTORE_ORPYX_USER_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;

            case OrpyxUserRestoreActions.SHOW_MODAL:
                draft.actionAfterComplete = action.payload!.actionAfterComplete;
                draft.user = action.payload!.user;
                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrpyxUserRestoreActions.HIDE_MODAL:
                ModalWindowStore.onToggleModal(draft, false);
                break;
        }
    })
};

export default OrpyxUserRestoreReducers;
