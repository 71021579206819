import {createSelector} from "reselect";
import {getPatientDevicesStoreState} from "../../store/patient-devices-selectors";

export class PatientDevicesCreateSelectors {
    static readonly state = createSelector(getPatientDevicesStoreState, state => state.create);
    static readonly error = createSelector(PatientDevicesCreateSelectors.state, state => state.error);
    static readonly patient = createSelector(PatientDevicesCreateSelectors.state, state => state.patient);

    static readonly actionAfterComplete = createSelector(PatientDevicesCreateSelectors.state, state => state.actionAfterComplete);

    static readonly inProcess = createSelector(PatientDevicesCreateSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(PatientDevicesCreateSelectors.state, state => state.toggle);
}
