import * as React from "react";
import {memo} from "react";

import {useHomePageNavbarStyle} from "./home-page-navbar.style";
import {NavLink} from "react-router-dom";
import logo from "../../../../../../images/Orpyx-SI-Logo-Knockout.png";
import {AdminDashboardNavbarWrapper} from "../../../../../admin/components/admin-dashboard-navbar/admin-dashboard-navbar/admin-dashboard-navbar";

const StyledAppBar = (props: { children: React.ReactNode; }) => {
    const classes = useHomePageNavbarStyle();
    return (
        <header className = {classes.appBar}>
            {props.children}
        </header>
    );
};

export const HomePageNavbar = memo(() => {
    const classes = useHomePageNavbarStyle();

    return (
        <StyledAppBar>
            <div>
                <NavLink
                    exact = {true}
                    to = "/"
                    activeClassName = "active"
                    className = {`Title-header ${classes.logoClass}`}>
                    <img src = {logo} alt = "Home" height = "50px" />
                </NavLink>
            </div>
            <div>
                <AdminDashboardNavbarWrapper usePrimaryLinkColor={false}/>
            </div>
        </StyledAppBar>
    );
});
