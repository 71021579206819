import {createSelector} from 'reselect'
import { OrpyxCareUsersStoreSelectors } from '../../store/orpyx-care-users-store-selectors';

export class OrpyxCareConfirmationSelectors {
    static readonly state = createSelector(OrpyxCareUsersStoreSelectors.state, state => state.confirmation);
    static readonly error = createSelector(OrpyxCareConfirmationSelectors.state, state => state.error);

    static readonly dashboardUser = createSelector(OrpyxCareConfirmationSelectors.state, state => state.dashboardUser!);
    static readonly permission = createSelector(OrpyxCareConfirmationSelectors.state, state => state.permission);

    static readonly actionAfterComplete = createSelector(OrpyxCareConfirmationSelectors.state, state => state.actionAfterComplete);
    static readonly inProcess = createSelector(OrpyxCareConfirmationSelectors.state, state => state.inProcess);
    static readonly toggle = createSelector(OrpyxCareConfirmationSelectors.state, state => state.toggle);
}

