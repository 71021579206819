import {OperationResultCode} from "admins-dashboard/http-clients/index";
import {PatientInformationPageMeta} from "./patient-information-page.interface";


export const onPatientInformationPageLoaded = (props: PatientInformationPageMeta) => () => {

    if (props.match.isExact && props.match.params.patientId && props.match.params.organizationId) {
        const patientId = parseInt(props.match.params.patientId);
        const organizationId = parseInt(props.match.params.organizationId);
        if (isNaN(patientId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid patient id: ${props.match.params.patientId}`
            });
        } else if (isNaN(organizationId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid organization id: ${props.match.params.organizationId}`
            });
        } else {
            props.loadPatientInformation(organizationId, patientId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid route, organizationId: ${props.match.params.organizationId}, patientId: ${props.match.params.patientId}`
        });
    }
};
