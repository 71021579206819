import {default as React, useEffect, useState} from "react";
import {Field, withFormik} from "formik";
import FormLabel from '@material-ui/core/FormLabel';
import {TextField} from "formik-material-ui";
import {Box} from "@material-ui/core";
import {getFirstMonthDay, getLastMonthDay, isNullOrEmpty, ModalWindowSubmitButton, OperationProgress, PropertyNamePipe, useModalWindowStyle} from "orpyx-web-common";
import {onSubmitAuditLoggingFilterForm} from "./audit-logging-filter-form.hooks";
import {AuditLoggingFilterFormMeta, AuditLoggingFilterFormProps, AuditLoggingFilterFormValues} from "./audit-logging-filter-form.interface";
import {AuditLoggingFilterFormValidator} from "./audit-logging-filter-form.validator";
import moment from "moment";
import {DropdownSelect} from "../../../shared/dropdown-select/dropdown-select";
import {ActorTypeValues} from "../../models/actor-types";
import {PatientOptionValues} from "../../models/patient-option-values";
import {AccessTypeValues} from "../../models/access-type-values";
import {GenerateReportPatientOptions} from "admins-dashboard";
import {OrganizationAutoComplete} from "../../../shared/organization-dropdown-filter/components/organization-auto-complete";
import {PatientAutoComplete} from "../../../shared/patient-dropdown-filter/components/patient-auto-complete";
import {AutocompleteSelectValue} from "../../../shared/autocomplete-select/autocomplete-select.interface";

const AuditLoggingFilterForm = (props: AuditLoggingFilterFormMeta) => {
    const classes = useModalWindowStyle();
    useEffect(() => {
        props.setSubmitting(props.inProcess)
    }, [props.inProcess]);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState();
    const [selectedPatientId, setSelectedPatientId] = useState('');

    const onPatientSelected = (patient: AutocompleteSelectValue | null) => {
        if (patient) {
            props.values.patientIdentifier = patient!.id!.toString();
        } else {
            props.values.patientIdentifier = '';
        }

        setSelectedPatientId(props.values.patientIdentifier);
    }

    const onOrganizationSelected = (organization: AutocompleteSelectValue | null) => {
        if (organization) {
            props.values.organizationId = organization!.id!.toString();
            setSelectedOrganizationId(organization?.id);
        } else {
            props.values.organizationId = '';
            setSelectedOrganizationId(undefined);
        }
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <Box display="flex" flexDirection="row" mb={3}>
                <Box width="50%">
                    <h3 className={props.classes.header}>
                        Date
                    </h3>

                    <div className={props.classes.group}>

                        <FormLabel required={true}>
                            Begin
                        </FormLabel>
                        <Field
                            type="date"
                            component={TextField}
                            className={classes.input}
                            placeholder={'Enter date'}
                            name={PropertyNamePipe(props.values, 'begin')}
                        />
                    </div>

                    <div className={props.classes.group}>
                        <FormLabel required={true}>
                            End
                        </FormLabel>
                        <Field
                            type="date"
                            component={TextField}
                            className={classes.input}
                            placeholder={'Enter date'}
                            name={PropertyNamePipe(props.values, 'end')}
                        />
                    </div>
                </Box>

                <Box ml={5} width="50%">

                    <h3 className={props.classes.header}>
                        Actor
                    </h3>

                    <div className={props.classes.group}>
                        <FormLabel>
                            Actor type
                        </FormLabel>

                        <DropdownSelect
                            values={ActorTypeValues}
                            inputStyle={classes.input}
                            inputPropName={PropertyNamePipe(props.values, 'actorType')}
                            selectedValues={props.values.actorType}
                        />
                    </div>

                    <div className={props.classes.group}>
                        <FormLabel>
                            Actor
                        </FormLabel>
                        <Field
                            component={TextField}
                            className={classes.input}
                            placeholder={'Enter user email'}
                            name={PropertyNamePipe(props.values, 'actorIdentifier')}
                            disabled={isNullOrEmpty(props.values.actorType)}
                            type="text"
                        />
                    </div>
                </Box>

                <Box ml={5} width="50%">

                    <h3 className={props.classes.header}>
                        Options
                    </h3>

                    <div className={props.classes.group}>

                        <FormLabel>
                            Access type
                        </FormLabel>

                        <DropdownSelect
                            values={AccessTypeValues}
                            inputStyle={classes.input}
                            inputPropName={PropertyNamePipe(props.values, 'accessType')}
                            selectedValues={props.values.accessType}
                        />
                    </div>

                    <div className={props.classes.group}>
                        <FormLabel>
                            Patient options
                        </FormLabel>
                        <DropdownSelect
                            values={PatientOptionValues}
                            inputStyle={classes.input}
                            inputPropName={PropertyNamePipe(props.values, 'patientOptions')}
                            selectedValues={props.values.patientOptions}
                            disabled={isNullOrEmpty(selectedPatientId)}
                        />
                    </div>


                </Box>

                <Box ml={5} width="50%">

                    <h3 className={props.classes.header}>
                        Filter
                    </h3>

                    <div className={props.classes.group}>
                        <OrganizationAutoComplete
                            organization={null}
                            scope={'auditLoggingPage'}
                            onSelected={onOrganizationSelected}
                        />
                    </div>

                    <div className={props.classes.group}>
                        <PatientAutoComplete
                            scope={'auditLoggingPage'}
                            organizationId={selectedOrganizationId}
                            onSelected={onPatientSelected}
                        />
                    </div>


                </Box>

            </Box>


            <OperationProgress error={props.error} inProcess={props.isSubmitting}/>

            <ModalWindowSubmitButton
                disabled={props.isSubmitting}
                content={'Submit'}
            />
        </form>
    );
};

const AuditLoggingFilterFormWrapper = withFormik<AuditLoggingFilterFormProps, AuditLoggingFilterFormValues>({

    validate: AuditLoggingFilterFormValidator,
    handleSubmit: onSubmitAuditLoggingFilterForm,

    mapPropsToValues: (props: AuditLoggingFilterFormProps): AuditLoggingFilterFormValues => ({
        begin: getFirstMonthDay(moment())!.format("yyyy-MM-DD"),
        end: getLastMonthDay(moment())!.format("yyyy-MM-DD"),

        organizationId: '',

        actorType: '',
        actorIdentifier: '',
        patientIdentifier: '',

        accessType: '',
        patientOptions: GenerateReportPatientOptions.ContainsPatientIdentifier.toString(),
    }),
})(AuditLoggingFilterForm);


export default AuditLoggingFilterFormWrapper;


