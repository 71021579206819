import {OrpyxUsersListActions} from "./orpyx-users-list-actions";
import produce from "immer";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {OrpyxUsersSearchQuery} from "../models/orpyx-users-search-query";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface OrpyxUsersListState {
    loading: boolean;
    error: OperationErrorType;

    total: number,
    search: OrpyxUsersSearchQuery;

    users: IOrpyxUserDto[];
}

const initialState: OrpyxUsersListState = {
    loading: false,
    error: undefined,

    total: 0,
    search: new OrpyxUsersSearchQuery(),

    users: [],
};

const OrpyxUsersListReducer = (state = initialState, action: StoreAction<OrpyxUsersListActions>): OrpyxUsersListState => {
    return produce(state, (draft: OrpyxUsersListState) => {
        switch (action.type) {
            case OrpyxUsersListActions.FETCH_USERS:
                draft.search = action.payload;
                draft.error = undefined;
                break;
            case OrpyxUsersListActions.FETCH_USERS_BEGIN:
                draft.error = undefined;
                draft.loading = true;
                break;
            case OrpyxUsersListActions.FETCH_USERS_SUCCESSFULLY:
                draft.error = undefined;
                draft.users = action.payload.items;
                draft.total = action.payload.total;
                draft.loading = false;
                break;
            case OrpyxUsersListActions.FETCH_USERS_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default OrpyxUsersListReducer;
