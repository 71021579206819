import {combineReducers} from "redux";
import PatientDevicesListReducers from "../patient-devices-list/store/patient-devices-list-reducers";
import PatientDevicesCreateReducers from "../patient-devices-create/store/patient-devices-create-reducers";
import PatientInsolesCreateReducers from "../patient-insoles-create/store/patient-insoles-create-reducers";
import PatientDevicesUpdateReducers from "../patient-devices-update/store/patient-devices-update-reducers";
import PatientInsolesDeleteReducers from "../patient-insoles-delete/store/patient-insoles-delete-reducers";


export const PatientDevicesReducers = combineReducers({
    list: PatientDevicesListReducers,
    create: PatientDevicesCreateReducers,
    update: PatientDevicesUpdateReducers,

    deleteInsole: PatientInsolesDeleteReducers,
    createInsole: PatientInsolesCreateReducers,
});
