import {AutocompleteSelect} from "../../autocomplete-select/autocomplete-select";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {memo} from "react";
import {connect} from "react-redux";
import * as React from "react";
import {PatientAutoCompleteEvents, PatientAutoCompleteMeta, PatientAutoCompleteOwnProps, PatientAutoCompleteProps} from "./patient-auto-complete.interface";
import {SEARCH_PATIENTS} from "../store/patient-dropdown-filter.actions";
import {PatientDropdownFilterSelectors} from "../store/patient-dropdown-filter.selectors";

const PatientAutoCompleteWrapper = (props: PatientAutoCompleteMeta) => {
    return <>
        <AutocompleteSelect
            scope={props.scope}
            loading={props.loading}
            onSelected={props.onSelected}
            onSearch={props.search}
            selected={{
                id: props.patient?.id,
                name: props.patient?.name!,
            }}
            values={props.patients.map(patient => ({
                id: patient.patientId!,
                name: `${patient.firstName} ${patient.lastName} | ${patient.clientPatientIdentifier}`,
                deleted: patient.deleted!,
            }))}
            placeholder={'Patient'}
        />
    </>
};

const mapStateToProps = (state: IOrpyxApplicationStore, props: PatientAutoCompleteProps): PatientAutoCompleteOwnProps => {
    return {
        loading: PatientDropdownFilterSelectors.loading(props.scope)(state),
        patients: PatientDropdownFilterSelectors.patients(props.scope)(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientAutoCompleteProps): PatientAutoCompleteEvents => {
    return bindActionCreators({
        search: term => SEARCH_PATIENTS(props.scope)(props.organizationId, term),
    }, dispatch);
};

export const PatientAutoComplete = memo(connect(mapStateToProps, mapDispatchToProps)(PatientAutoCompleteWrapper));
