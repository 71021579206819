import * as React from 'react';
import {memo, useEffect} from 'react';
import {IOrpyxApplicationStore} from 'modules/store/application.reducers';
import {connect} from 'react-redux';
import {OrganizationCreateModalWindow} from "../../../organization-create/components/organization-create-modal/organization-create-modal";
import {useOrganizationsListTemplateStyles} from "./organizations-list-template.style";
import {OrganizationsListTemplateEvents, OrganizationsListTemplateMeta, OrganizationsListTemplateOwnProps, OrganizationsListTemplateProps} from "./organizations-list-template.interface";
import {bindActionCreators, Dispatch} from "redux";
import {CREATE_ORGANIZATION_SHOW_MODAL} from "../../../organization-create/store/organization-create-actions";
import {OrganizationsListSelectors} from "../../store/organizations-list-selectors";
import {FETCH_ORGANIZATIONS, ORGANIZATIONS_INITIALIZE_SUCCESSFULLY} from "../../store/organizations-list-actions";
import {
    onOrganizationsListTemplateLoaded,
    onOrganizationsListSearchDisplayCountChanged,
    onOrganizationsListSearchPageChanged,
    onOrganizationsListSearchTermValidation,
    onOrganizationsListSearchOptionsChanged
} from "./organizations-list-template.hooks";
import {Container, Grid} from "@material-ui/core";
import {withRouter} from "react-router";
import {AdminBlockWrapper} from "../../../../components/admin-block/admin-block";
import AdminOrganizationsPolicies from "../../../../policies/admin-organizations-policies";
import {ActionButton, ApplicationBreadcrumbs, ListPagination, ListSearch} from "orpyx-web-common";
import {organizationsListOrderByItems} from "../../enums/organizations-list-order";
import {LazyLoader} from "orpyx-web-common";

const OrganizationsListTableWrapper = React.lazy(() => import("../organizations-list-table/organizations-list-table"));

const OrganizationsListTemplate = (props: OrganizationsListTemplateMeta) => {
    const classes = useOrganizationsListTemplateStyles();
    useEffect(onOrganizationsListTemplateLoaded(props), [props.location]);

    return (<Container>
        <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
                <div className={classes.root}>
                    <div>
                        <ListSearch
                            term={props.search.term}
                            inProcess={props.loading}
                            orderBy={props.search.orderBy}
                            orderByAsc={props.search.orderByAsc}
                            orderByItems={organizationsListOrderByItems}
                            onSearch={onOrganizationsListSearchOptionsChanged(props)}
                            onSearchValidate={onOrganizationsListSearchTermValidation}
                            placeholder={"Find your organization"}
                        />
                    </div>

                    {props.breadcrumbs && <div>
                        <ApplicationBreadcrumbs items={props.breadcrumbs}/>
                    </div>}

                    <div className={classes.content}>
                        <div className={classes.topBlock}>
                            <div className={classes.title}>
                                Organizations: {props.totals}
                            </div>
                        </div>

                        <LazyLoader height={60} rows={8}>
                            <OrganizationsListTableWrapper onOrganizationSelected={props.onOrganizationSelected}/>
                        </LazyLoader>

                        <ListPagination
                            label={'Organizations per page'}
                            onSelectedPageChanged={page => onOrganizationsListSearchPageChanged(props, page)}
                            onDisplayCountChanged={count => onOrganizationsListSearchDisplayCountChanged(props, count)}

                            count={props.search.count}
                            page={props.search.offset}
                            pages={props.pages}
                        />

                        <AdminBlockWrapper requiredRole={AdminOrganizationsPolicies.Create}>
                            <ActionButton onSubmit={props.openCreateOrganizationModal} content={"+ Create New Organization"}/>
                            <OrganizationCreateModalWindow provider={props.provider}/>
                        </AdminBlockWrapper>
                    </div>
                </div>
            </Grid>
        </Grid>
    </Container>);
};

const mapStateToProps: (state: IOrpyxApplicationStore) => OrganizationsListTemplateOwnProps = (state) => {
    return {
        initialized: OrganizationsListSelectors.initialized(state),
        loading: OrganizationsListSelectors.loading(state),

        organizations: OrganizationsListSelectors.organizations(state),
        pages: OrganizationsListSelectors.pages(state),
        search: OrganizationsListSelectors.search(state),
        totals: OrganizationsListSelectors.total(state),
    }
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationsListTemplateProps): OrganizationsListTemplateEvents => bindActionCreators({
    openCreateOrganizationModal: CREATE_ORGANIZATION_SHOW_MODAL,
    fetchOrganizations: FETCH_ORGANIZATIONS,
    onPageInitialized: ORGANIZATIONS_INITIALIZE_SUCCESSFULLY,
}, dispatch);

export const OrganizationsListBlock = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsListTemplate)));
