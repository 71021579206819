import * as React from 'react';
import {NavLink} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {createStyles, Typography} from '@material-ui/core';
import {withStyles, WithStyles} from '@material-ui/styles';
import {ApplicationBuildVersion} from "../../generated-version";
import {Teal} from "orpyx-web-common";

const styles = () => createStyles({
  root: {
    fontSize: 'smaller',
    position: 'fixed',
    left: '0',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    color: Teal,
  },
  activeLinkStyle: {
    color:  '#2D363B',
  },
  indented: {
    marginLeft: '10px',
  },
  version: {
    color:  '#2D363B',
  }
});

const activeLinkStyle = {
  color:  '#2D363B',
};

const footer = (props: WithStyles<typeof styles>) => (
      <div className={props.classes.root}>
        <Grid container>
          <Grid item xs={9}>
            <Typography className={props.classes.indented}>
              &copy; 2019 Orpyx&reg; Medical Technologies Inc.
              Version at <span className={props.classes.version}>
               {ApplicationBuildVersion}
            </span>
            </Typography>
          </Grid>
          <Grid item xs={3} style={{marginTop: 'auto', paddingRight: '12px'}}>
            <Grid container spacing={1} direction='row' justify-content='flex-end' alignItems='center'>
              <Grid item>
                <NavLink to='/aboutus' className="Title-header Title-center" activeStyle={activeLinkStyle}>
                  <Typography className={props.classes.indented}>About Us</Typography>
                </NavLink>
              </Grid>
              <Grid item>
                <Typography className={props.classes.indented}>
                  <NavLink to='/faq' className="Title-header" activeStyle={activeLinkStyle}>FAQ</NavLink>
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={props.classes.indented}>
                  <NavLink to='/support' className="Title-header" activeStyle={activeLinkStyle}>Support</NavLink>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );

    export default withStyles(styles)(footer);
