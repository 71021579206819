import {combineReducers} from 'redux';
import {AdminStoreState} from "../admin/store/admin-store-state";
import {AdminStoreReducers} from "../admin/store/admin-store-reducers";
import {applicationReducers, IApplicationState} from "orpyx-web-common";
import {connectRouter, RouterState} from "connected-react-router";

export interface IOrpyxApplicationStore {
    readonly app: IApplicationState;
    readonly admin: AdminStoreState;
    readonly router: RouterState,
}

const rootReducer = (history: any) => {
    return combineReducers({
        app: applicationReducers,
        router: connectRouter(history),
        admin: AdminStoreReducers,
    });
};

export default rootReducer;