import {FormControlValidators} from "orpyx-web-common";
import {PatientsRawTemperatureFormProps, PatientsRawTemperatureFormValues} from "./patients-raw-temperature-form.interface";

const PatientsRawTemperatureFormValidators = FormControlValidators.createValidators<PatientsRawTemperatureFormValues>({
    begin: [
        FormControlValidators.required,
    ],
    end: [
        FormControlValidators.required,
    ],
    insolesSerialNumbers: [
        FormControlValidators.required,
    ]
});

export const PatientsRawTemperatureFormValidator = (values: PatientsRawTemperatureFormValues, props: PatientsRawTemperatureFormProps) => {
    const errors = PatientsRawTemperatureFormValidators.validate(values);
    return errors;
}
