import {IInsolesListEntry, IListProductsResultEntry} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientInsolesDeleteActions {
    DELETE_PATIENT_INSOLE = 'DELETE_PATIENT_INSOLE: DELETE_PATIENT_INSOLE',
    DELETE_PATIENT_INSOLE_SUCCESSFULLY = 'DELETE_PATIENT_INSOLE: DELETE_PATIENT_INSOLE_SUCCESSFULLY',
    DELETE_PATIENT_INSOLE_FAILED = 'DELETE_PATIENT_INSOLE: DELETE_PATIENT_INSOLE_FAILED',

    SHOW_MODAL = 'DELETE_PATIENT_INSOLE: SHOW_MODAL',
    HIDE_MODAL = 'DELETE_PATIENT_INSOLE: HIDE_MODAL',
}

export const DELETE_PATIENT_INSOLE = (): StoreAction<PatientInsolesDeleteActions> => ({
    type: PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE
});

export const DELETE_PATIENT_INSOLE_SUCCESSFULLY = (): StoreAction<PatientInsolesDeleteActions> => ({
    type: PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE_SUCCESSFULLY
});

export const DELETE_PATIENT_INSOLE_FAILED = (error: OperationErrorType): StoreAction<PatientInsolesDeleteActions> => ({
    type: PatientInsolesDeleteActions.DELETE_PATIENT_INSOLE_FAILED, payload: error
});

export const DELETE_PATIENT_INSOLE_SHOW_MODAL = (device: IListProductsResultEntry, insole: IInsolesListEntry): StoreAction<PatientInsolesDeleteActions> => ({
    type: PatientInsolesDeleteActions.SHOW_MODAL, payload: {device, insole}
});

export const DELETE_PATIENT_INSOLE_HIDE_MODAL = (): StoreAction<PatientInsolesDeleteActions> => ({
    type: PatientInsolesDeleteActions.HIDE_MODAL
});
