import {IGetPatientInformationResult, IUpdatePatientDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum PatientInformationActions {
    INITIALIZE_PATIENT_USER = 'ORGANIZATION_PATIENT_UPDATE: INITIALIZE_PATIENT_USER',
    INITIALIZE_PATIENT_USER_SUCCESSFULLY = 'ORGANIZATION_PATIENT_UPDATE: INITIALIZE_PATIENT_USER_SUCCESSFULLY',
    INITIALIZE_PATIENT_USER_FAILED = 'ORGANIZATION_PATIENT_UPDATE: INITIALIZE_PATIENT_USER_FAILED',

    UPDATE_PATIENT_USER = 'ORGANIZATION_PATIENT_UPDATE: UPDATE_PATIENT_USER',
    UPDATE_PATIENT_USER_SUCCESSFULLY = 'ORGANIZATION_PATIENT_UPDATE: UPDATE_PATIENT_USER_SUCCESSFULLY',
    UPDATE_PATIENT_USER_FAILED = 'ORGANIZATION_PATIENT_UPDATE: UPDATE_PATIENT_USER_FAILED',
}

export const INITIALIZE_PATIENT_USER_SUCCESSFULLY = (model: IGetPatientInformationResult): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.INITIALIZE_PATIENT_USER_SUCCESSFULLY, payload: model
});

export const INITIALIZE_PATIENT_USER_FAILED = (error: OperationErrorType): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.INITIALIZE_PATIENT_USER_FAILED, payload: error
});

export const UPDATE_PATIENT_USER = (model: IUpdatePatientDto): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.UPDATE_PATIENT_USER, payload: model
});

export const UPDATE_PATIENT_USER_SUCCESSFULLY = (): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.UPDATE_PATIENT_USER_SUCCESSFULLY
});

export const UPDATE_PATIENT_USER_FAILED = (error: OperationErrorType): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.UPDATE_PATIENT_USER_FAILED, payload: error
});


export const INITIALIZE_PATIENT_USER = (organizationId: number, patientId: number): StoreAction<PatientInformationActions> => ({
    type: PatientInformationActions.INITIALIZE_PATIENT_USER, payload: {patientId, organizationId}
});
