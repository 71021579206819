import produce from "immer";
import {StoreAction, UsersInProcess} from "orpyx-web-common";
import {OrpyxUserSendInviteActions} from "./orpyx-user-send-invite.actions";
import {IOrpyxUserDto} from "admins-dashboard/http-clients/index";

export interface OrpyxUserSendInviteState {
    usersInProcess: UsersInProcess;
}

const initialState: OrpyxUserSendInviteState = {
    usersInProcess: {},
};

const OrpyxUserSendInviteReducer = (state = initialState, action: StoreAction<OrpyxUserSendInviteActions>): OrpyxUserSendInviteState => {
    return produce(state, (draft: OrpyxUserSendInviteState) => {

        switch (action.type) {
            case OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE: {
                const orpyxUser = action.payload as IOrpyxUserDto;
                draft.usersInProcess[orpyxUser.orpyxUserId!] = true;
                break;
            }
            case OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE_SUCCESSFULLY: {
                const orpyxUser = action.payload as IOrpyxUserDto;
                delete draft.usersInProcess[orpyxUser.orpyxUserId!];
                break;
            }
            case OrpyxUserSendInviteActions.ORPYX_USER_SEND_INVITE_FAILED: {
                const orpyxUser = action.payload as IOrpyxUserDto;
                delete draft.usersInProcess[orpyxUser.orpyxUserId!];
                break;
            }
        }
    });
};

export default OrpyxUserSendInviteReducer;
