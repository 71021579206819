import {ChangedSearchOption, ChangedSearchOptions, ControlValidator, FormControlValidators, isNullOrUndefined, ListSearchOrderByItem, PagingQuery, ValidatorResultMessage} from "orpyx-web-common";
import {OrganizationsListTemplateMeta} from "./organizations-list-template.interface";
import {OrganizationsSearchQuery} from "../../models/organizations-search-query";

const OrganizationsListSearchTermValidators = new ControlValidator([
    FormControlValidators.minLength(2),
    FormControlValidators.maxLength(32),
]);

const getRemotePatientMonitoringProviderId = (props: OrganizationsListTemplateMeta, params: URLSearchParams): string | undefined => {
    if (isNullOrUndefined(props.provider)) {
        return params.has('remotePatientMonitoringProviderId') ? params.get('remotePatientMonitoringProviderId')! : undefined;
    }
    return props.provider!.remotePatientMonitoringProviderId!.toFixed();
}

export const onOrganizationsListTemplateLoaded = (props: OrganizationsListTemplateMeta) => () => {
    const params = new URLSearchParams(props.location.search);
    const remotePatientMonitoringProviderId = getRemotePatientMonitoringProviderId(props, params);

    const query = OrganizationsSearchQuery.parse(
        remotePatientMonitoringProviderId,
        params.has('term') ? params.get('term')! : undefined,

        params.has(OrganizationsSearchQuery.PARAM_ORDER_BY) ? params.get(OrganizationsSearchQuery.PARAM_ORDER_BY)! : undefined,
        params.has(OrganizationsSearchQuery.PARAM_ORDER_BY_ASC) ? params.get(OrganizationsSearchQuery.PARAM_ORDER_BY_ASC)! : undefined,

        params.has(OrganizationsSearchQuery.PARAM_OFFSET) ? params.get(OrganizationsSearchQuery.PARAM_OFFSET)! : undefined,
        params.has(OrganizationsSearchQuery.PARAM_COUNT) ? params.get(OrganizationsSearchQuery.PARAM_COUNT)! : undefined,
    );

    props.onPageInitialized(query);
};

export const onOrganizationsListSearchPageChanged = (props: OrganizationsListTemplateMeta, page: number): void => {
    const searchQuery: OrganizationsSearchQuery = {...props.search, offset: page};
    props.onSearchQueryChanged(searchQuery);
};

export const onOrganizationsListSearchDisplayCountChanged = (props: OrganizationsListTemplateMeta, count: number): void => {
    const searchQuery: OrganizationsSearchQuery = {...props.search, count: count, offset: PagingQuery.DEFAULT_PAGE};
    props.onSearchQueryChanged(searchQuery);
};

export const onOrganizationsListSearchTermValidation = (term: string | undefined): ValidatorResultMessage => {
    return OrganizationsListSearchTermValidators.validate(term);
};

export const onOrganizationsListSearchOptionsChanged = (props: OrganizationsListTemplateMeta) => (options: ChangedSearchOptions): void => {
    if (options.changed === ChangedSearchOption.Term) {
        const searchQuery: OrganizationsSearchQuery = {
            ...props.search,
            term: options.term,
            offset: PagingQuery.DEFAULT_PAGE
        };
        props.onSearchQueryChanged(searchQuery);
    } else {
        const searchQuery: OrganizationsSearchQuery = {
            ...props.search,
            orderBy: options.orderBy?.value,
            orderByAsc: options.orderBy?.orderByAsc ?? OrganizationsSearchQuery.DEFAULT_ORDER_BY_METHOD
        };
        props.onSearchQueryChanged(searchQuery);
    }
};