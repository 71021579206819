import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CREATE_PATIENT_USER_FAILED, CREATE_PATIENT_USER_SUCCESSFULLY, PatientsCreateActions} from "./patients-create-actions";
import {AdminPatientsHttpClient, CreatePatientDto, ICreatePatientDto, IGetPatientInformationResult, IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";
import {PatientsCreateSelectors} from "./patients-create-selectors";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";

const API_VERSIONS = '1';

function* onCreateOrganizationPatient(action: StoreAction<PatientsCreateActions, ICreatePatientDto>) {
    try {
        const organization: IOrganizationOutputDto = yield select(PatientsCreateSelectors.organization);
        const organizationId = organization.id!;

        const model = new CreatePatientDto(action.payload!);
        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);

        const data: IGetPatientInformationResult = yield call(
            [api, api.createPatient],
            organizationId,
            API_VERSIONS,
            model
        );

        yield put(CREATE_PATIENT_USER_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully created patient ${fullNamePipe(data.patient)} in organization ${organization.name}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(CREATE_PATIENT_USER_FAILED(e));
    }
}

export default function* PatientsCreateSagas() {
    yield all([
        takeEvery(PatientsCreateActions.CREATE_PATIENT_USER, onCreateOrganizationPatient),
    ]);
}

