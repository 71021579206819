import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export enum OrganizationInformationActions {
    INITIALIZE = 'ORGANIZATION_INFORMATION: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'ORGANIZATION_INFORMATION: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'ORGANIZATION_INFORMATION: INITIALIZE_FAILED',
}

export const ORGANIZATION_INFORMATION_INITIALIZE = (organizationId: number): StoreAction<OrganizationInformationActions> => ({
    type: OrganizationInformationActions.INITIALIZE,
    payload: organizationId
});

export const ORGANIZATION_INFORMATION_INITIALIZE_SUCCESSFULLY = (organization: IOrganizationOutputDto): StoreAction<OrganizationInformationActions> => ({
    type: OrganizationInformationActions.INITIALIZE_SUCCESSFULLY,
    payload: organization
});

export const ORGANIZATION_INFORMATION_INITIALIZE_FAILED = (error: OperationErrorType): StoreAction<OrganizationInformationActions> => ({
    type: OrganizationInformationActions.INITIALIZE_FAILED,
    payload: error
});
