import {OperationResultCode} from "admins-dashboard/http-clients/index";
import {OrganizationUserInformationPageMeta} from "./organization-user-information-page.interface";

export const onOrganizationUserInformationPageLoaded = (props: OrganizationUserInformationPageMeta) => () => {

    if (props.match.isExact && props.match.params.dashboardUserId && props.match.params.organizationId) {
        const dashboardUserId = parseInt(props.match.params.dashboardUserId);
        const organizationId = parseInt(props.match.params.organizationId);

        if (isNaN(dashboardUserId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid dashboardUserId: ${props.match.params.dashboardUserId}`
            });
        } else if (isNaN(organizationId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid organizationId: ${props.match.params.organizationId}`
            });
        } else {
            props.loadUserInformation(organizationId, dashboardUserId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid query`
        });
    }
};