import {createSelector} from 'reselect'
import {getOrganizationsState} from "../../store/organizations-store-selectors";
import {getPagesCount} from "orpyx-web-common";

export class OrganizationsListSelectors {
    static readonly state = createSelector(getOrganizationsState, state => state.list);
    static readonly search = createSelector(OrganizationsListSelectors.state, state => state.search);
    static readonly organizations = createSelector(OrganizationsListSelectors.state, state => state.organizations);
    static readonly total = createSelector(OrganizationsListSelectors.state, state => state.total);
    static readonly pages = createSelector(OrganizationsListSelectors.state, state => getPagesCount(state.total, state.search.count));

    static readonly error = createSelector(OrganizationsListSelectors.state, state => state.error);
    static readonly loading = createSelector(OrganizationsListSelectors.state, state => state.loading);
    static readonly initialized = createSelector(OrganizationsListSelectors.state, state => state.initialized);

}

