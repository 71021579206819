import * as React from 'react';
import {Theme, Typography} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Teal} from "orpyx-web-common";
import {Menu} from '../../components/Menu';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: '30px',
    },
    title: {
        color: Teal,
        fontSize: 'larger',
    },
    text: {
        marginTop: '20px',
        textAlign: 'justify',
    },
    link: {
        color: Teal,
        textDecoration: 'none'
    },
    content:{
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        maxWidth: 900,
        padding: '27px 50px',
        margin: '0 auto',
    },
}));

function AboutUs() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Menu />
            <div className={classes.content}>
                <Typography className={classes.title}>About Orpyx Medical Technologies Inc.</Typography>
                <Typography className={classes.text}>
                Founded in 2010, Orpyx® Medical Technologies empowers people with diabetes to maintain mobility. 
                Orpyx’s core product line, the SurroSense® intelligent insole systems, help manage and prevent diabetic foot ulcers
                and limb loss through FDA cleared, advanced foot sensor technology and analytics.  
                Orpyx’s technology is used by researchers to access clinical-grade plantar pressure measurement data for health 
                and human performance applications. 
                Orpyx is focused on collaborating with stakeholders around the world to achieve a healthcare future that is prevention 
                focused and sustainable in cost.
                </Typography>
                <Typography className={classes.text}>
                Bay 2, 1440 – 28 Street N.E.<br/>
                Calgary, Alberta  T2A 7W6  Canada
                </Typography>
                <Typography className={classes.text}>
                    Email: <a href="mailto:info@orpyx.com" className={classes.link}>info@orpyx.com</a><br/>
                    Call us: +1.855.996.7799<br/>
                    <a href="https://www.orpyx.com" className={classes.link}>www.orpyx.com</a>
                </Typography>
            </div>
        </div>
    );
}

export default AboutUs;
