import {ListOrpyxCareUserPropertyOrderBy} from "admins-dashboard/http-clients/index";
import {createListSearchOrderItem, ListSearchOrderByItem, ListSearchOrderByItemTextResolver, orderByAlphabetPipe} from "orpyx-web-common";

export const ListOrpyxCareUserPropertyOrderByPipe: ListSearchOrderByItemTextResolver<ListOrpyxCareUserPropertyOrderBy> = (order: ListOrpyxCareUserPropertyOrderBy, orderByAsc?: boolean): string => {
    switch (order) {
        case ListOrpyxCareUserPropertyOrderBy.FirstName:
            return orderByAlphabetPipe('First name', orderByAsc);
        case ListOrpyxCareUserPropertyOrderBy.LastName:
            return orderByAlphabetPipe('Last name', orderByAsc);
        case ListOrpyxCareUserPropertyOrderBy.FullName:
            return orderByAlphabetPipe('Full name', orderByAsc);
        case ListOrpyxCareUserPropertyOrderBy.CreationDate:
            return 'Date added';
        case ListOrpyxCareUserPropertyOrderBy.Status:
            return 'Status';
    }
    return `Unknown: ${order}`;
};

export const orpyxCareUsersListOrderItems: ListSearchOrderByItem[] = [
    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.FirstName, ListOrpyxCareUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.FirstName, ListOrpyxCareUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.LastName, ListOrpyxCareUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.LastName, ListOrpyxCareUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.FullName, ListOrpyxCareUserPropertyOrderByPipe, true),
    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.FullName, ListOrpyxCareUserPropertyOrderByPipe, false),

    createListSearchOrderItem<ListOrpyxCareUserPropertyOrderBy>(ListOrpyxCareUserPropertyOrderBy.CreationDate, ListOrpyxCareUserPropertyOrderByPipe, false),
];
