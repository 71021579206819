import produce from "immer";
import {OrganizationDeleteActions} from "./organization-delete.actions";
import {IModalWindow, ModalWindowInitialState, ModalWindowStore, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";
import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";

export interface OrganizationDeleteState extends IModalWindow {
    organization: IOrganizationOutputDto | undefined;
    actionAfterComplete: StoreActionAfterComplete;

}

const initialState: OrganizationDeleteState = {
    ...ModalWindowInitialState,

    organization: undefined,
    actionAfterComplete: StoreActionAfterComplete.None,
};

const OrganizationDeleteReducers = (state = initialState, action: StoreAction<OrganizationDeleteActions>): OrganizationDeleteState => {
    return produce(state, (draft: OrganizationDeleteState) => {
        switch (action.type) {
            case OrganizationDeleteActions.SHOW_MODAL:
                draft.organization = action.payload.organization;
                draft.actionAfterComplete = action.payload.actionAfterComplete;

                ModalWindowStore.onToggleModal(draft, true);
                break;

            case OrganizationDeleteActions.ORGANIZATION_DELETE_SUCCESSFULLY:
            case OrganizationDeleteActions.HIDE_MODAL:
                draft.organization = undefined;
                ModalWindowStore.onProcessSuccessfully(draft);
                break;

            case OrganizationDeleteActions.ORGANIZATION_DELETE:
                ModalWindowStore.onProcessBegin(draft);
                break;

            case OrganizationDeleteActions.ORGANIZATION_DELETE_FAILED:
                ModalWindowStore.onProcessFailed(draft, action.payload);
                break;
        }
    });
};
export default OrganizationDeleteReducers;
