import {AdminOrpyxUsersHttpClient, CreateOrpyxUserDto, ICreateOrpyxUserDto, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {CREATE_USER_FAILED, CREATE_USER_SUCCESSFULLY, OrpyxUserCreateActions} from "./orpyx-user-create-actions";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction} from "orpyx-web-common";

const API_VERSION = '1';

function* onCreateOrpyxUser(action: StoreAction<OrpyxUserCreateActions, ICreateOrpyxUserDto>) {

    try {
        const api = new AdminOrpyxUsersHttpClient(ORPYXSI_ADMIN_URL);
        const model = new CreateOrpyxUserDto(action.payload);
        const data: IOrpyxUserDto = yield call(
            [api, api.createOrpyxUser],
            API_VERSION,
            model
        );

        const roles = data.roles!.map(role => role.role!.name).join(', ');

        yield put(CREATE_USER_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `User ${fullNamePipe(data)} created with roles: ${roles}`,
            options: {variant: "success"}
        }));
    } catch (e) {
        yield put(CREATE_USER_FAILED(e));
    }
}

export default function* OrpyxUserCreateSagas() {
    yield all([
        takeEvery(OrpyxUserCreateActions.CREATE_USER, onCreateOrpyxUser)
    ]);
}
