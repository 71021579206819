import {all} from 'redux-saga/effects';
import {OrpyxUsersSages} from "../orpyx-users/store/orpyx-users-store-sagas";
import OrganizationsSagas from "../organizations/store/organizations-store-sagas";
import OrganizationUsersSagas from "../organizations/organization-users/store/organization-users-sagas";
import DashboardUserTitlesSagas from "../dashboard-user-titles/store/dashboard-user-titles-sagas";
import {PatientsSagas} from "../patients/store/patients-sagas";
import {AdminAuthSagas} from "../auth/store/admin-auth-sagas";
import AuditLoggingDownloadSages from '../audit-logging/store/audit-logging-download.sagas';
import PatientDropdownFilterSagas from '../shared/patient-dropdown-filter/store/patient-dropdown-filter.sagas';
import PatientInsolesAutoCompleteSagas
    from "../shared/patient-insoles-auto-complete/store/patient-insoles-auto-complete.sagas";
import { OrpyxCareUsersSagas } from '../orpyx-care-users/store/orpyx-care-users-store-sagas';
import DashboardRolesSagas from '../dashboard-roles/dashboard-roles-sagas';

export function* AdminStoreSages() {
    yield all([
        OrpyxUsersSages(),
        OrpyxCareUsersSagas(),
        OrganizationsSagas(),
        OrganizationUsersSagas(),
        DashboardUserTitlesSagas(),
        DashboardRolesSagas(),
        PatientsSagas(),
        AdminAuthSagas(),
        AuditLoggingDownloadSages(),
        PatientDropdownFilterSagas(),
        PatientInsolesAutoCompleteSagas()
    ]);
}
