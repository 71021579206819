import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {OrganizationDeleteModalEvents, OrganizationDeleteModalMeta, OrganizationDeleteModalOwnProps, OrganizationDeleteModalProps} from "./organization-delete-modal.interface";
import {OrganizationDeleteSelectors} from "../../store/organization-delete.selectors";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {createModalWindow, ModalWindowHeader, ModalWindowSubmitButton, OperationProgress} from "orpyx-web-common";
import {ORGANIZATION_DELETE, ORGANIZATION_DELETE_HIDE_MODAL} from "../../store/organization-delete.actions";

const OrganizationDeleteModalTemplate = (props: OrganizationDeleteModalMeta) => {

    return (
        <>
            <ModalWindowHeader>
                Confirm delete organization
            </ModalWindowHeader>

            <div>
                Are you sure you want to delete organization <b> {props.organization.name}</b>?
            </div>

            <OperationProgress error = {props.error} inProcess = {props.inProcess} />

            <ModalWindowSubmitButton
                disabled = {props.inProcess}
                onSubmit = {props.confirmOrganizationDelete}
                content = {'Confirm'}
            />
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): OrganizationDeleteModalOwnProps => {
    return {
        inProcess: OrganizationDeleteSelectors.inProcess(state),
        error: OrganizationDeleteSelectors.error(state),

        organization: OrganizationDeleteSelectors.organization(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OrganizationDeleteModalProps): OrganizationDeleteModalEvents => {
    return bindActionCreators({
        confirmOrganizationDelete: ORGANIZATION_DELETE
    }, dispatch);
};

export const OrganizationDeleteModalWindow = () => createModalWindow({
    component: memo(connect(mapStateToProps, mapDispatchToProps)(OrganizationDeleteModalTemplate)),
    closeModalActionCreator: ORGANIZATION_DELETE_HIDE_MODAL,
    modalToggleSelector: OrganizationDeleteSelectors.toggle
});



