import {IGetOrpyxCareUserInformationResponse, IUpdateOrpyxCareUserDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrpyxCareUserInformationActions {
    INITIALIZE = 'ORPYX_CARE_USER_INFORMATION: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'ORPYX_CARE_USER_INFORMATION: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'ORPYX_CARE_USER_INFORMATION: INITIALIZE_FAILED',

    UPDATE_USER = 'ORPYX_CARE_USER_INFORMATION: UPDATE_USER',
    UPDATE_USER_SUCCESSFULLY = 'ORPYX_CARE_USER_INFORMATION: UPDATE_USER_SUCCESSFULLY',
    UPDATE_USER_FAILED = 'ORPYX_CARE_USER_INFORMATION: UPDATE_USER_FAILED',
}

export const INITIALIZE_ORPYX_CARE_USER_INFORMATION = (dashboardUserId: number): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.INITIALIZE,
    payload: {dashboardUserId}
});

export const INITIALIZE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY = (dashboardUser: IGetOrpyxCareUserInformationResponse): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.INITIALIZE_SUCCESSFULLY,
    payload: dashboardUser
});

export const INITIALIZE_ORPYX_CARE_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.INITIALIZE_FAILED,
    payload: error
});

export const UPDATE_ORPYX_CARE_USER_INFORMATION = (model: IUpdateOrpyxCareUserDto): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.UPDATE_USER,
    payload: model
});

export const UPDATE_ORPYX_CARE_USER_INFORMATION_SUCCESSFULLY = (): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.UPDATE_USER_SUCCESSFULLY
});

export const UPDATE_ORPYX_CARE_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrpyxCareUserInformationActions> => ({
    type: OrpyxCareUserInformationActions.UPDATE_USER_FAILED,
    payload: error
});
