import {createSelector} from "reselect";
import {getOrpyxUsersState} from "../../store/orpyx-users-store-selectors";
import {OrpyxRolesSelectors} from "../../../orpyx-roles/store/orpyx-roles-selectors";

export const getOrpyxUserAssignRoleState = createSelector(getOrpyxUsersState, state => state.assignUserRole);
export const getOrpyxUserAssignRoleError = createSelector(getOrpyxUserAssignRoleState, state => state.error);
export const getOrpyxUserAssignRoleUser = createSelector(getOrpyxUserAssignRoleState, state => state.user);
export const getOrpyxUserAssignRoleUserRoles = createSelector(getOrpyxUserAssignRoleUser, user => user ? user.roles! : []);

export const getOrpyxUserAssignRoleUserId = createSelector(getOrpyxUserAssignRoleUser, state => state!.orpyxUserId);

export const getOrpyxUserAssignAvailableRoles = createSelector(
    getOrpyxUserAssignRoleUserRoles,
    OrpyxRolesSelectors.roles,
    (userRoles, orpyxRoles) => orpyxRoles.filter(role => userRoles.every(userRole => userRole!.role!.orpyxRoleId! !== role.orpyxRoleId))
);

export const isOrpyxUserAssignRoleModalActive = createSelector(getOrpyxUserAssignRoleState, state => state.toggleModal);
export const isOrpyxUserAssignRoleInProcess = createSelector(getOrpyxUserAssignRoleState, state => state.inProcess);
