import {createSelector} from "reselect";
import { getAdminStoreState } from "../store/admin-store-selectors";

export class DashboardRolesSelectors {
    static readonly state = createSelector(getAdminStoreState, state => state.dashboardRoles);

    static readonly roles = createSelector(DashboardRolesSelectors.state, state => state.roles);
    static readonly initialized = createSelector(DashboardRolesSelectors.state, state => state.initialized);
    static readonly error = createSelector(DashboardRolesSelectors.state, state => state.error);
}


