import {OrpyxRoleType} from "admins-dashboard/http-clients/index";

export class AdminPatientsPolicies {
    static readonly Create = OrpyxRoleType.Manufacturing;
    static readonly Delete = OrpyxRoleType.Manufacturing;
    static readonly Restore = OrpyxRoleType.Manufacturing;

    static readonly Update = OrpyxRoleType.Manufacturing;
    static readonly List = OrpyxRoleType.Manufacturing;
    static readonly Information = OrpyxRoleType.Manufacturing;
    static readonly ManageDevices = OrpyxRoleType.Manufacturing;
}

export default AdminPatientsPolicies;
