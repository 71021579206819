import produce from "immer";
import {OrganizationUserRevokeInviteActions} from "./organization-user-revoke-invite.actions";
import {IUserOutputDto} from "admins-dashboard/http-clients/index";
import {StoreAction, UsersInProcess} from "orpyx-web-common";

export interface OrganizationUserRevokeInviteState {
    usersInProcess: UsersInProcess;
}

const initialState: OrganizationUserRevokeInviteState = {
    usersInProcess: {},
};

const OrganizationUserRevokeInviteReducer = (state = initialState, action: StoreAction<OrganizationUserRevokeInviteActions>): OrganizationUserRevokeInviteState => {
    return produce(state, (draft: OrganizationUserRevokeInviteState) => {
        switch (action.type) {
            case OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE:{
                const dashboardUser = action.payload.user as IUserOutputDto;
                draft.usersInProcess[dashboardUser.id!] = true;
                break;
            }
            case OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE_SUCCESSFULLY:{
                const dashboardUser = action.payload as IUserOutputDto;
                delete draft.usersInProcess[dashboardUser.id!];
                break;
            }
            case OrganizationUserRevokeInviteActions.DASHBOARD_USER_REVOKE_INVITE_FAILED:{
                const dashboardUser = action.payload as IUserOutputDto;
                delete draft.usersInProcess[dashboardUser.id!];
                break;
            }
        }
    })
};

export default OrganizationUserRevokeInviteReducer;