import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {OrpyxRoleDto} from "admins-dashboard/http-clients/index";

export enum DashboardUserTitlesActions {
    INITIALIZE = 'DASHBOARD_USER_TITLES: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'DASHBOARD_USER_TITLES: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'DASHBOARD_USER_TITLES: INITIALIZE_FAILED',
}

export const DASHBOARD_USER_TITLES_INITIALIZE = (): StoreAction<DashboardUserTitlesActions> => ({
    type: DashboardUserTitlesActions.INITIALIZE
});

export const DASHBOARD_USER_TITLES_INITIALIZE_SUCCESSFULLY = (roles: OrpyxRoleDto[]): StoreAction<DashboardUserTitlesActions> => ({
    type: DashboardUserTitlesActions.INITIALIZE_SUCCESSFULLY, payload: roles
});

export const DASHBOARD_USER_TITLES_INITIALIZE_FAILED = (error: OperationErrorType): StoreAction<DashboardUserTitlesActions> => ({
    type: DashboardUserTitlesActions.INITIALIZE_FAILED, payload: error
});
