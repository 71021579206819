import {IGetPatientInformationResult} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientInformationActions} from "./patient-information-actions";

export interface PatientsUpdateState {
    inProcess: boolean;
    initialized: boolean,

    patient: IGetPatientInformationResult | undefined;
    error: OperationErrorType;
}

const initialState: PatientsUpdateState = {
    initialized: false,
    inProcess: false,

    patient: undefined,
    error: undefined,
};

const PatientInformationReducers = (state = initialState, action: StoreAction<PatientInformationActions>): PatientsUpdateState => {
    return produce(state, (draft: PatientsUpdateState) => {
        switch (action.type) {
            case PatientInformationActions.INITIALIZE_PATIENT_USER_SUCCESSFULLY:
                draft.initialized = true;
                draft.patient = action.payload;
                draft.error = undefined;
                break;

            case PatientInformationActions.INITIALIZE_PATIENT_USER_FAILED:
                draft.initialized = false;
                draft.patient = undefined;
                draft.error = action.payload;
                break;

            case PatientInformationActions.UPDATE_PATIENT_USER:
                draft.error = undefined;
                draft.inProcess = true;
                break;

            case PatientInformationActions.UPDATE_PATIENT_USER_SUCCESSFULLY:
                draft.error = undefined;
                draft.inProcess = false;
                break;

            case PatientInformationActions.UPDATE_PATIENT_USER_FAILED:
                draft.error = action.payload;
                draft.inProcess = false;
                break;

            case PatientInformationActions.INITIALIZE_PATIENT_USER:
                draft.patient = undefined;
                draft.initialized = false;
                draft.error = undefined;
                break;

        }
    })
};

export default PatientInformationReducers;
