import {IGetDashboardUserInformationResponse, IUpdateDashboardUserDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum OrganizationUserInformationActions {
    INITIALIZE = 'ORGANIZATION_USER_INFORMATION: INITIALIZE',
    INITIALIZE_SUCCESSFULLY = 'ORGANIZATION_USER_INFORMATION: INITIALIZE_SUCCESSFULLY',
    INITIALIZE_FAILED = 'ORGANIZATION_USER_INFORMATION: INITIALIZE_FAILED',

    UPDATE_USER = 'ORGANIZATION_USER_INFORMATION: UPDATE_USER',
    UPDATE_USER_SUCCESSFULLY = 'ORGANIZATION_USER_INFORMATION: UPDATE_USER_SUCCESSFULLY',
    UPDATE_USER_FAILED = 'ORGANIZATION_USER_INFORMATION: UPDATE_USER_FAILED',
}

export const INITIALIZE_ORGANIZATION_USER_INFORMATION = (organizationId: number, dashboardUserId: number): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.INITIALIZE,
    payload: {organizationId, dashboardUserId}
});

export const INITIALIZE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY = (dashboardUser: IGetDashboardUserInformationResponse): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.INITIALIZE_SUCCESSFULLY,
    payload: dashboardUser
});

export const INITIALIZE_ORGANIZATION_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.INITIALIZE_FAILED,
    payload: error
});

export const UPDATE_ORGANIZATION_USER_INFORMATION = (model: IUpdateDashboardUserDto): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.UPDATE_USER,
    payload: model
});

export const UPDATE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY = (): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.UPDATE_USER_SUCCESSFULLY
});

export const UPDATE_ORGANIZATION_USER_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<OrganizationUserInformationActions> => ({
    type: OrganizationUserInformationActions.UPDATE_USER_FAILED,
    payload: error
});
