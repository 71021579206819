import {all, call, put, select, takeEvery} from 'redux-saga/effects';

import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {
    INITIALIZE_PATIENT_USER_FAILED,
    INITIALIZE_PATIENT_USER_SUCCESSFULLY,
    PatientInformationActions,
    UPDATE_PATIENT_USER_FAILED,
    UPDATE_PATIENT_USER_SUCCESSFULLY
} from "./patient-information-actions";
import {AdminPatientsHttpClient, IGetPatientInformationResult, IUpdatePatientDto, UpdatePatientDto} from "admins-dashboard/http-clients/index";
import {PatientInformationSelectors} from "./patient-information-selectors";
import {FETCH_PATIENT_DEVICES} from "../../patient-devices/patient-devices-list/store/patient-devices-list-actions";
import {PatientDeleteActions} from "../../patient-delete/store/patient-delete.actions";
import {PatientRestoreActions} from "../../patient-restore/store/patient-restore.actions";

const API_VERSIONS = '1';


function* onFetchPatientInformation(patientId: number, organizationId: number) {
    const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);

    const data: IGetPatientInformationResult = yield call(
        [api, api.getPatientInformation],
        organizationId,
        patientId,
        API_VERSIONS
    );

    yield put(INITIALIZE_PATIENT_USER_SUCCESSFULLY(data));
}

function* onInitializePatientInformation(action: StoreAction<PatientInformationActions, { patientId: number, organizationId: number }>) {
    try {
        const {patientId, organizationId} = action.payload!;
        yield onFetchPatientInformation(patientId, organizationId);
        yield put(FETCH_PATIENT_DEVICES());
    } catch (e) {
        yield put(INITIALIZE_PATIENT_USER_FAILED(e));
    }
}

function* onRefreshPatientInformation() {
    try {
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient);
        yield onFetchPatientInformation(patient.patient!.patientId!, patient.organization!.id!);
    } catch (e) {
        yield put(INITIALIZE_PATIENT_USER_FAILED(e));
    }
}

function* onUpdatePatientInformation(action: StoreAction<PatientInformationActions, IUpdatePatientDto>) {
    try {
        const model = new UpdatePatientDto(action.payload!);
        const patient: IGetPatientInformationResult = yield select(PatientInformationSelectors.patient);

        const api = new AdminPatientsHttpClient(ORPYXSI_ADMIN_URL);

        yield call(
            [api, api.updatePatient],
            patient.organization!.id!,
            API_VERSIONS,
            model
        );

        yield put(UPDATE_PATIENT_USER_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Patient ${fullNamePipe(model)} successfully updated in organization: ${patient.organization!.name}`,
            options: {variant: "success"}
        }));
    } catch (e) {
        yield put(UPDATE_PATIENT_USER_FAILED(e));
    }
}

export default function* PatientInformationSagas() {
    yield all([
        takeEvery(PatientInformationActions.INITIALIZE_PATIENT_USER, onInitializePatientInformation),
        takeEvery(PatientInformationActions.UPDATE_PATIENT_USER, onUpdatePatientInformation),
        takeEvery(PatientInformationActions.UPDATE_PATIENT_USER_SUCCESSFULLY, onRefreshPatientInformation),

        takeEvery(whenStoreActionAfterComplete(PatientDeleteActions.PATIENT_DELETE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshPatientInformation),
        takeEvery(whenStoreActionAfterComplete(PatientRestoreActions.PATIENT_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshPatientInformation),
    ]);
}

