import {createSelector} from "reselect";
import {OrganizationUsersSelectors} from "../../store/organization-users-selectors";

export class OrganizationUserDeleteSelectors {

    static readonly state = createSelector(OrganizationUsersSelectors.state, state => state.delete);
    static readonly error = createSelector(OrganizationUserDeleteSelectors.state, state => state.error);

    static readonly inProcess = createSelector(OrganizationUserDeleteSelectors.state, state => state.inProcess);
    static readonly isModalActive = createSelector(OrganizationUserDeleteSelectors.state, state => state.toggle);

    static readonly dashboardUser = createSelector(OrganizationUserDeleteSelectors.state, state => state.dashboardUser);
    static readonly organization = createSelector(OrganizationUserDeleteSelectors.state, state => state.organization);
    static readonly actionAfterComplete = createSelector(OrganizationUserDeleteSelectors.state, state => state.actionAfterComplete);
}

export default OrganizationUserDeleteSelectors;
