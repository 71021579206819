import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ORPYX_USER_REVOKE_INVITE_FAILED, ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY, OrpyxUserRevokeInviteActions} from "./orpyx-user-revoke-invite.actions";
import {AdminOrpyxUsersInviteHttpClient, IOrpyxUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, OperationErrorPipe, StoreAction} from "orpyx-web-common";

const API_VERSIONS = '1';

function* onOrpyxUserRevokeInvite(action: StoreAction<OrpyxUserRevokeInviteActions, IOrpyxUserDto>) {
    const user = action.payload!;

    try {
        const api = new AdminOrpyxUsersInviteHttpClient(ORPYXSI_ADMIN_URL);

        const data: IOrpyxUserDto = yield call(
            [api, api.revokeOrpyxUserInvite],
            user.orpyxUserId!,
            API_VERSIONS
        );

        yield put(ORPYX_USER_REVOKE_INVITE_SUCCESSFULLY(user));

        yield put(ENQUEUE_SNACKBAR({
            message: `Invitation for admin ${fullNamePipe(data)} successfully revoked`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(ORPYX_USER_REVOKE_INVITE_FAILED(user));


        yield put(ENQUEUE_SNACKBAR({
            message: `Failed revoke invitation for admin ${fullNamePipe(user)}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

export default function* OrpyxUserRevokeInviteSagas() {
    yield all([
        takeEvery(OrpyxUserRevokeInviteActions.ORPYX_USER_REVOKE_INVITE, onOrpyxUserRevokeInvite),
    ]);
}