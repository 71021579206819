import {IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction, StoreActionAfterComplete} from "orpyx-web-common";

export enum OrganizationUserDeleteActions {
    DELETE_DASHBOARD_USER = 'DELETE_DASHBOARD_USER: DELETE_DASHBOARD_USER',
    DELETE_DASHBOARD_USER_SUCCESSFULLY = 'DELETE_DASHBOARD_USER: DELETE_DASHBOARD_USER_SUCCESSFULLY',
    DELETE_DASHBOARD_USER_FAILED = 'DELETE_DASHBOARD_USER: DELETE_DASHBOARD_USER_FAILED',

    SHOW_MODAL = 'DELETE_DASHBOARD_USER: SHOW_MODAL',
    HIDE_MODAL = 'DELETE_DASHBOARD_USER: HIDE_MODAL',
}

export const DELETE_DASHBOARD_USER = (): StoreAction<OrganizationUserDeleteActions> => ({
    type: OrganizationUserDeleteActions.DELETE_DASHBOARD_USER
});

export const DELETE_DASHBOARD_USER_SUCCESSFULLY = (actionAfterComplete: StoreActionAfterComplete): StoreAction<OrganizationUserDeleteActions> => ({
    type: OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_SUCCESSFULLY, payload: actionAfterComplete
});

export const DELETE_DASHBOARD_USER_FAILED = (error: OperationErrorType): StoreAction<OrganizationUserDeleteActions> => ({
    type: OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_FAILED, payload: error
});

export const DELETE_DASHBOARD_USER_SHOW_MODAL = (actionAfterComplete: StoreActionAfterComplete) => (organization: IOrganizationOutputDto, user: IUserOutputDto): StoreAction<OrganizationUserDeleteActions> => ({
    type: OrganizationUserDeleteActions.SHOW_MODAL, payload: {organization, user, actionAfterComplete}
});

export const DELETE_DASHBOARD_USER_HIDE_MODAL = (): StoreAction<OrganizationUserDeleteActions> => ({
    type: OrganizationUserDeleteActions.HIDE_MODAL
});
