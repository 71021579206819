import {OrpyxUserInformationPageMeta} from "./orpyx-user-information-page.interface";
import {OperationResultCode} from "admins-dashboard/http-clients/index";

export const onOrpyxUserInformationPageLoaded = (props: OrpyxUserInformationPageMeta) => () => {

    if (props.match.isExact && props.match.params.orpyxUserId) {
        const orpyxUserId = parseInt(props.match.params.orpyxUserId);
        if (isNaN(orpyxUserId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid Orpyx Admin User Id: ${props.match.params.orpyxUserId}`
            });
        } else {
            props.loadUserInformation(orpyxUserId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid Orpyx Admin User Id: ${props.match.params.orpyxUserId}`
        });
    }
};
