import {AdminOrganizationHttpClient, IOrganizationOutputDto, IOrganizationOutputDtoPagingResult} from "admins-dashboard/http-clients/index";
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_ADMIN_URL} from "../../../../../appConstants";
import {FETCH_ORGANIZATIONS_BEGIN, FETCH_ORGANIZATIONS_FAILED, FETCH_ORGANIZATIONS_SUCCESSFULLY, OrganizationsListActions} from "./organizations-list-actions";
import {OrganizationsSearchQuery} from "../models/organizations-search-query";
import {OrganizationsListSelectors} from "./organizations-list-selectors";
import {convertOffsetToPage, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import {OrganizationCreateActions} from "../../organization-create/store/organization-create-actions";
import {OrganizationDeleteActions} from "../../organization-delete/store/organization-delete.actions";
import {OrganizationRestoreActions} from "../../organization-restore/store/organization-restore.actions";
import {push} from "connected-react-router";
import {OrganizationUpdateActions} from "../../organization-update/store/organization-update.actions";

const API_VERSIONS = '1';

function* onFetchOrganizations() {
    yield put(FETCH_ORGANIZATIONS_BEGIN());
    yield onRefreshOrganizations();
}

function* onRefreshOrganizations() {
    try {
        const api = new AdminOrganizationHttpClient(ORPYXSI_ADMIN_URL);
        const searchQuery: OrganizationsSearchQuery = yield select(OrganizationsListSelectors.search);

        //  TODO: Need rename & refactory on back-end offset to page
        //  Now if page 1, offset 0
        const data: IOrganizationOutputDtoPagingResult = yield call(
            [api, api.getOrganizationsList],
            searchQuery.remotePatientMonitoringProviderId,
            searchQuery.orderByAsc,
            searchQuery.orderBy,
            searchQuery.term,
            convertOffsetToPage(searchQuery.offset),
            searchQuery.count,
            API_VERSIONS);

        yield put(FETCH_ORGANIZATIONS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(FETCH_ORGANIZATIONS_FAILED(e));
    }
}

export default function* OrganizationsListSagas() {
    yield all([
        yield takeEvery(OrganizationsListActions.FETCH_ORGANIZATIONS, onFetchOrganizations),
        yield takeEvery(OrganizationsListActions.INITIALIZE_SUCCESSFULLY, onFetchOrganizations),

        yield takeEvery(whenStoreActionAfterComplete(OrganizationUpdateActions.ORGANIZATION_UPDATE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrganizations),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationDeleteActions.ORGANIZATION_DELETE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrganizations),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationRestoreActions.ORGANIZATION_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntitiesList), onRefreshOrganizations),

        yield takeEvery(OrganizationCreateActions.CREATE_ORGANIZATION_SUCCESSFULLY, onRefreshOrganizations),
    ]);
}

