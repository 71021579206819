import {IOrganizationOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {OrganizationInformationActions} from "./organization-information-actions";

export interface OrganizationInformationState {
    initialized: boolean;
    organization: IOrganizationOutputDto | undefined,
    error: OperationErrorType,
}

const initialState: OrganizationInformationState = {
    initialized: false,

    organization: undefined,
    error: undefined,
};

const OrganizationInformationReducers = (state = initialState, action: StoreAction<OrganizationInformationActions>): OrganizationInformationState => {
    return produce(state, (draft: OrganizationInformationState) => {
        switch (action.type) {
            case OrganizationInformationActions.INITIALIZE:
                draft.initialized = false;
                draft.organization = undefined;
                draft.error = undefined;
                break;
            case OrganizationInformationActions.INITIALIZE_SUCCESSFULLY:
                draft.initialized = true;
                draft.error = undefined;
                draft.organization = action.payload;
                break;
            case OrganizationInformationActions.INITIALIZE_FAILED:
                draft.initialized = false;
                draft.organization = undefined;
                draft.error = action.payload;
                break;
        }
    })
};

export default OrganizationInformationReducers;
