import log from 'loglevel';
import {BaseHttpClient} from 'admins-dashboard/http-clients/base-http-client';
import {IOrpyxApplicationStore} from "./modules/store/application.reducers";
import {store} from "./modules/store/store";
import {AdminAuthSelectors} from "./modules/admin/auth/store/admin-auth-selectors";
import {isNullOrUndefined, OrpyxApplicationException} from "orpyx-web-common";

export const azureBlobHost: string = process.env.REACT_APP_AZURE_BLOB_HOST!;
export const ORPYXSI_ADMIN_URL: string = process.env.REACT_APP_API_HOST!;

if (isNullOrUndefined(azureBlobHost)) {
    throw new OrpyxApplicationException('Missed REACT_APP_AZURE_BLOB_HOST')
}

if (isNullOrUndefined(ORPYXSI_ADMIN_URL)) {
    throw new OrpyxApplicationException('Missed REACT_APP_API_HOST')
}

export function SetConfiguration() {
    log.setDefaultLevel("DEBUG");

    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
            registration.unregister()
        }
    }).catch(function(err) {  
        console.log('Service Worker delete failed: ', err);
    });

    BaseHttpClient.TokenSelector = () => {
        const state: any = store.getState();
        const accessToken = AdminAuthSelectors.accessToken(state);

        if (accessToken && accessToken.token) {
            //  We allow execute with expired token
            return accessToken.token;
        }
        return '';
    };
}
