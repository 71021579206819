import {IPatientOutputDto} from "admins-dashboard/http-clients/index";
import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientDropdownFilterActions} from "./patient-dropdown-filter.actions";

export interface PatientDropdownFilterState {
    loading: boolean;

    patients: IPatientOutputDto[];

    term: string | undefined;
    organizationId: number | undefined;
    error?: OperationErrorType;
}

export const initialPatientDropdownFilterState: PatientDropdownFilterState = {
    loading: false,
    error: undefined,

    organizationId: undefined,
    term: undefined,
    patients: [],
};

export const PatientDropdownFilterReducers = (state = initialPatientDropdownFilterState, action: StoreAction<PatientDropdownFilterActions>): PatientDropdownFilterState => {
    return produce(state, (draft: PatientDropdownFilterState) => {
        switch (action.type) {
            case PatientDropdownFilterActions.SEARCH_PATIENTS:
                draft.loading = true;
                draft.term = action.payload.term;
                draft.organizationId = action.payload.organizationId;
                break;

            case PatientDropdownFilterActions.SEARCH_PATIENTS_SUCCESSFULLY: {
                const result = action.payload! as IPatientOutputDto[];
                draft.patients = result!;
                draft.loading = false;
                draft.error = undefined;
                break;
            }

            case PatientDropdownFilterActions.SEARCH_PATIENTS_FAILED:
                draft.loading = false;
                draft.error = action.payload!;
                draft.patients = [];
                break;
        }
    })
};
