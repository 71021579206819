import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {
    INITIALIZE_ORGANIZATION_USER_INFORMATION,
    INITIALIZE_ORGANIZATION_USER_INFORMATION_FAILED,
    INITIALIZE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY,
    OrganizationUserInformationActions,
    UPDATE_ORGANIZATION_USER_INFORMATION_FAILED,
    UPDATE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY
} from "./organization-user-information-page.actions";
import {AdminDashboardUserHttpClient, IGetDashboardUserInformationResponse, IUpdateDashboardUserDto, IUserOutputDto, UpdateDashboardUserDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreAction, StoreActionAfterComplete, whenStoreActionAfterComplete} from "orpyx-web-common";
import OrganizationUserInformationSelectors from "./organization-user-information-page.selectors";
import {OrganizationUserDeleteActions} from "../../organization-user-delete/store/organization-user-delete-actions";
import {OrganizationUserRestoreActions} from "../../organization-user-restore/store/organization-user-restore.actions";

const API_VERSIONS = '1';

function* onFetchOrganizationUserInformation(action: StoreAction<OrganizationUserInformationActions, { organizationId: number, dashboardUserId: number }>) {
    try {
        const {organizationId, dashboardUserId} = action.payload!;

        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IGetDashboardUserInformationResponse = yield call(
            [api, api.getDashboardUserInformation],
            organizationId,
            dashboardUserId,
            API_VERSIONS
        );

        yield put(INITIALIZE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY(data));
    } catch (e) {
        yield put(INITIALIZE_ORGANIZATION_USER_INFORMATION_FAILED(e));
    }
}

function* onRefreshOrganizationUserInformation() {
    const dashboardUser: IGetDashboardUserInformationResponse = yield select(OrganizationUserInformationSelectors.user)!;
    yield put(INITIALIZE_ORGANIZATION_USER_INFORMATION(dashboardUser.organization!.id!, dashboardUser.user!.id!));
}

function* onUpdateOrganizationUserInformation(action: StoreAction<OrganizationUserInformationActions, IUpdateDashboardUserDto>) {
    try {
        const model = new UpdateDashboardUserDto(action.payload!);
        const dashboardUser: IGetDashboardUserInformationResponse = yield select(OrganizationUserInformationSelectors.user)!;

        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IUserOutputDto = yield call(
            [api, api.updateDashboardUser],
            dashboardUser.organization!.id!,
            dashboardUser.user!.id!,
            API_VERSIONS,
            model
        );

        yield put(UPDATE_ORGANIZATION_USER_INFORMATION_SUCCESSFULLY());
        yield put(ENQUEUE_SNACKBAR({
            message: `Health care practitioner ${fullNamePipe(data)} successfully updated`,
            options: {variant: 'success'}
        }));
    } catch (e) {
        yield put(UPDATE_ORGANIZATION_USER_INFORMATION_FAILED(e));
    }
}

export default function* OrganizationUserInformationSages() {
    yield all([
        yield takeEvery(OrganizationUserInformationActions.INITIALIZE, onFetchOrganizationUserInformation),
        yield takeEvery(OrganizationUserInformationActions.UPDATE_USER, onUpdateOrganizationUserInformation),
        yield takeEvery(OrganizationUserInformationActions.UPDATE_USER_SUCCESSFULLY, onRefreshOrganizationUserInformation),

        yield takeEvery(whenStoreActionAfterComplete(OrganizationUserDeleteActions.DELETE_DASHBOARD_USER_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrganizationUserInformation),
        yield takeEvery(whenStoreActionAfterComplete(OrganizationUserRestoreActions.DASHBOARD_USER_RESTORE_SUCCESSFULLY, StoreActionAfterComplete.RefreshEntityInformation), onRefreshOrganizationUserInformation),
    ]);
}

