import {createSelector} from "reselect";
import {getAdminStoreState} from "../../store/admin-store-selectors";
import adminAvailableRolesPipe from "../../pipes/admin-available-roles-pipe";

export class AdminAuthSelectors {
    static readonly state = createSelector(getAdminStoreState, state => state.auth);

    static readonly inProcess = createSelector(AdminAuthSelectors.state, state => state.inProcess);
    static readonly isLoggedIn = createSelector(AdminAuthSelectors.state, state => state.isLoggedIn);

    static readonly profile = createSelector(AdminAuthSelectors.state, state => state.profile);
    static readonly roles = createSelector(AdminAuthSelectors.profile, profile => profile ? profile.roles! : []);

    static readonly availableRoles = createSelector(AdminAuthSelectors.roles, roles => adminAvailableRolesPipe(roles));

    static readonly azureActor = createSelector(AdminAuthSelectors.state, state => state.azureActor);
    static readonly accessToken = createSelector(AdminAuthSelectors.azureActor, actor => actor ? actor.accessToken : null);
}
