import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AdminDashboardUserHttpClient, IOrganizationOutputDto, IUserOutputDto} from "admins-dashboard/http-clients/index";
import {ORPYXSI_ADMIN_URL} from "../../../../../../appConstants";
import {ENQUEUE_SNACKBAR, fullNamePipe, StoreActionAfterComplete} from "orpyx-web-common";
import {DELETE_DASHBOARD_USER_FAILED, DELETE_DASHBOARD_USER_SUCCESSFULLY, OrganizationUserDeleteActions} from "./organization-user-delete-actions";
import OrganizationUserDeleteSelectors from "./organization-user-delete-selectors";

const API_VERSIONS = '1';

function* onDeleteDashboardUser() {
    try {
        const dashboardUser: IUserOutputDto  = yield select(OrganizationUserDeleteSelectors.dashboardUser);
        const actionAfterComplete: StoreActionAfterComplete = yield select(OrganizationUserDeleteSelectors.actionAfterComplete);

        const organization: IOrganizationOutputDto = yield select(OrganizationUserDeleteSelectors.organization);

        const api = new AdminDashboardUserHttpClient(ORPYXSI_ADMIN_URL);
        const data: IUserOutputDto = yield call(
            [api, api.deleteDashboardUser],
            organization.id!,
            dashboardUser.id!,
            API_VERSIONS
        );

        yield put(DELETE_DASHBOARD_USER_SUCCESSFULLY(actionAfterComplete));
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully deleted health care practitioner ${fullNamePipe(data)}`,
            options: {variant: "success"}
        }));

    } catch (e) {
        yield put(DELETE_DASHBOARD_USER_FAILED(e));
    }
}

export default function* OrganizationUserDeleteSagas() {
    yield all([
        takeEvery(OrganizationUserDeleteActions.DELETE_DASHBOARD_USER, onDeleteDashboardUser),
    ]);
}
